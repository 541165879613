import "./issue.css";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "axios";
import ModalIssue from "../modalissue/modal_issue";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { baseURL } from "../../global_variable_.jsx";

export default function Issue({ titlePage }) {
  const [ready, setReady] = useState(false);
  const [issues, setIssues] = useState([]);
  const [currIssue, setIssue] = useState(null);
  const [viewIssue, setViewIssue] = useState(false);

  const openViewIssue = (issue) => {
    setIssue(issue);
    setViewIssue(true);
    console.log(viewIssue);
  };

  const getIssues = async () => {
    try {
      let response = await axios.get(`${baseURL}/submit-issue`);
      setIssues(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getAPI = async () => {
    await getIssues();
    setReady(true);
  };

  useEffect(() => {
    getAPI();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idDeleteData, setIdDeleteData] = useState(null);
  const handleOpenDeleteModal = (data) => {
    setIdDeleteData(data.id);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleRowDelete = (id) => {
    setIssues(issues.filter((issue) => issue.id !== id));
  };
  const handleSubmitData = async () => {
    // postAPI -- Delete Row
    // console.log(idDeleteData)
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        await axios.delete(baseURL + `/submit-issue/${idDeleteData}`, {
          headers,
        });
        handleRowDelete(idDeleteData);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Gagal menghapus item:", error);
      }
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className="iconTableCell"
            onClick={() => {
              handleOpenDeleteModal(params.row);
            }}
          >
            <CloseRoundedIcon />
          </div>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <div className="iconTableCell">
          <VisibilityOutlinedIcon
            onClick={() => {
              openViewIssue(params.row);
            }}
          />
        </div>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      headerClassName: "customColumnHeader",
      flex: 2,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "customColumnHeader",
      flex: 6,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "customColumnHeader",
      flex: 5,
      renderCell: (params) => {
        const formattedDate = formatDate(params.value);
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "message",
      headerName: "Message",
      headerClassName: "customColumnHeader",
      flex: 10,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      cellClassName: (params) => {
        return params.value === "Done" ? "done-cell" : "new-cell";
      },
    },
  ];

  let formattedIssues = [];
  if (Array.isArray(issues)) {
    formattedIssues = issues.map((issue) => ({
      id: issue.id,
      name: issue.fullname,
      date: issue.createdAt,
      subject: issue.subject,
      message: issue.message,
      status: issue.is_replied ? "Done" : "New",
    }));
  }
  const options = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
  ];

  return ready === true ? (
    <>
      <div className="issue">
        {titlePage}
        <div className="issueContent">
          <DataGrid
            className="issuetable"
            rows={formattedIssues}
            disableRowSelectionOnClick
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "status", sort: "desc" }],
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
          />
        </div>
      </div>
      <ModalIssue
        isOpen={viewIssue}
        onClose={() => setViewIssue(false)}
        currIssue={currIssue}
        Update={getAPI}
      />
      <ConfirmSubmitChanges
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        warningText={
          "Are you sure want to delete?\nThis action cannot be undone."
        }
        handleSubmitData={handleSubmitData}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
