import styles from "./ConfirmSubmitChanges.module.css";
import { Box, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 347,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

export default function ConfirmSubmitChanges({
  openModal,
  closeModal,
  warningText,
  handleSubmitData,
}) {
  useEffect(() => {
    setBtnClicked(false);
  }, [openModal]);

  const [btnClicked, setBtnClicked] = useState(false);
  // const [warningString, setWarningString] = useState(warningText)
  const handleOkBtn = async () => {
    setBtnClicked(true);
    await handleSubmitData();
    setBtnClicked(false);
    closeModal();
    // window.location.reload(false);
  };
  return (
    <Modal open={openModal}>
      <Box sx={styleBox}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ErrorOutlineIcon className={styles.errorIconModal} />
          </Grid>
          <Grid xs={12}>
            <div className={styles.confirmSubmitText}>
              {!warningText
                ? "Are you sure want to submit these changes?"
                : warningText}
            </div>
          </Grid>
          <Grid xs={6}>
            {!btnClicked ? (
              <button onClick={handleOkBtn} className={styles.yesBtn}>
                Yes
              </button>
            ) : (
              <button
                disabled
                className={styles.yesBtn}
                style={{ backgroundColor: `#DADBDD` }}
              >
                Yes
              </button>
            )}
          </Grid>
          <Grid xs={6}>
            <button onClick={closeModal} className={styles.noBtn}>
              No
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
