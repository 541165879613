// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rolecms{
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
}

.statusCellcmsuser{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    height: 30px;
    border-radius: 10px;
  }

.greenCell {
    background-color: #4CD96440 !important;
  }
  
  .redCell {
    background-color: #FF44441A !important;
  }

  .newRoleCMSBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-weight: 400;
    font-style: 14px;
    color: #BE1620;
    border-color: #BE1620;
    border-radius: 10px;
    padding: 10px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    margin-left: auto;
  }
  
  .topContentRoleCMS{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    margin-bottom: 20px;
  }

  .AddRoleUserCMSLink{
    margin-left: auto;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/rolecms/rolecms.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,mBAAmB;EACrB;;AAEF;IACI,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".rolecms{\n    display: flex;\n    width: 100%;\n    padding: 20px;\n    flex-direction: column;\n}\n\n.statusCellcmsuser{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0 2px;\n    height: 30px;\n    border-radius: 10px;\n  }\n\n.greenCell {\n    background-color: #4CD96440 !important;\n  }\n  \n  .redCell {\n    background-color: #FF44441A !important;\n  }\n\n  .newRoleCMSBtn{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: none;\n    font-weight: 400;\n    font-style: 14px;\n    color: #BE1620;\n    border-color: #BE1620;\n    border-radius: 10px;\n    padding: 10px;\n    width: 200px;\n    height: 40px;\n    cursor: pointer;\n    text-decoration: none;\n    margin-left: auto;\n  }\n  \n  .topContentRoleCMS{\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    width: 100%;\n    margin-bottom: 20px;\n  }\n\n  .AddRoleUserCMSLink{\n    margin-left: auto;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
