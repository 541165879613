import { useEffect, useState } from "react";
import "./colortype.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import EditCategoryYear from "../editcategoryyear/EditCategoryYear";
import NewCategoryYear from "../newcategoryyear/NewCategoryYear";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ModalError400 from "../../components/ModalError400/ModalError400";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import { baseURL } from "../../global_variable_.jsx";

export default function ColorAreas({ titlePage }) {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [years, setYears] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedYear, setEditedYear] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [error400, setOpenError400] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  useEffect(() => {
    APICalls();
  }, []);

  const APICalls = async () => {
    await getYears();
    setIsLoading(false);
  };

  const getYears = async () => {
    try {
      let response = await axios.get(
        `${baseURL}/year-of-production?all_data=true`
      );
      setYears(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const openEditModal = (year) => {
    setEditedYear(year);
    setModalIsOpen(true);
  };

  const handleEditYear = async (editedData, headers) => {
    try {
      const response = await axios.put(
        `${baseURL}/year-of-production`,
        editedData,
        { headers }
      );
      console.log(response);
      console.log(editedData);
      console.log(headers);
      setModalIsOpen(false);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setEditSuccessModal(true);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Year of Production has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
      console.error("Gagal mengupdate year of production:", error);
    }
  };

  const openNewCategoryYearModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const handleAddYear = async (newCategory, headers) => {
    try {
      const response = await axios.post(
        `${baseURL}/year-of-production`,
        newCategory,
        { headers }
      );
      console.log(response);
      setNewCategoryModalIsOpen(false);
      console.log(newCategory);
      console.log(headers);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setAddSuccessModal(true);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Year of Production has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
      console.error("Gagal mengupdate year of production:", error);
    }
  };

  const deleteYear = async (ModelsId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log("Deleted manufacturer ID:", ModelsId);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      if (tesToken) {
        const response = await axios.delete(
          `${baseURL}/year-of-production/${ModelsId}`,
          { headers }
        );
        console.log(response);
        console.log(response.status);
        if (response.status === 200) {
          console.log("berhasil menghapus year");
          APICalls();
          setModalIsOpen(false);
        } else {
          console.log("Error deleting year");
        }
      } else {
        console.log("Error deleting year");
      }
    } catch (error) {
      console.error("Failed to delete year", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteYear} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    //{ field: 'id', headerName: 'ID', width: 240 },
    // { field: '', headerName: '', width: 70 },
    {
      field: "year",
      headerName: "Year of Production",
      headerClassName: "customColumnHeader",
      flex: 16,
    },
    // { field: 'manufactures', headerName: 'Manufactures', width: 325 },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  let formattedYears = [];

  const [allowedSubMenus, setAllowedSubMenus] = useState([
    "color-category",
    "color",
  ]);
  const checkSubMenuAuth = () => {
    const allowedSubMenu = localStorage.getItem("colorAllowedComponents");
    if (allowedSubMenu) {
      setAllowedSubMenus(allowedSubMenu.split(","));
    }
  };
  const btnSubMenuContainer = () => {
    return (
      <div className="outSideBtnGroup">
        {allowedSubMenus.includes("color-category") ? (
          <button className="categorybuttonType">Category</button>
        ) : null}
        {allowedSubMenus.includes("color") ? (
          <Link to="/color-formula">
            <button className="colorbuttonType">Color</button>
          </Link>
        ) : null}
      </div>
    );
  };
  useEffect(() => {
    checkSubMenuAuth();
    getYears();
  }, []);
  if (Array.isArray(years)) {
    formattedYears = years.map((year) => ({
      id: year.id,
      year: year.production_year,
      status: year.isDeleted ? "Non-Active" : "Active",
    }));

    return isLoading !== true ? (
      <>
        <div className="colorTypePage">
          {titlePage}
          {btnSubMenuContainer()}
          <div className="tableContainerYear">
            <div className="inSideBtnGroup">
              <Link to="/color">
                <button className="manufacturesbuttonType">Manufactures</button>
              </Link>
              <Link to="/color-models">
                <button className="modelsbuttonType">Models</button>
              </Link>
              <button className="typebuttonType">Years</button>
              <Link to="/color-areas">
                <button className="areasbuttonType">Areas</button>
              </Link>
              <ExportImportBtn
                btnType="Export"
                addStyle={{ marginLeft: `auto`, marginRight: `20px` }}
                onClick={() =>
                  DownloadDataCsv(
                    "year_list",
                    `${baseURL}/year-of-production/csv`
                  )
                }
              />
              <button
                onClick={openNewCategoryYearModal}
                className="newsubcategorybuttonType"
              >
                Add New Year
                <AddIcon className="addIcon1" />
              </button>
            </div>
            <DataGrid
              rows={formattedYears}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: "year", sort: "asc" }],
                },
              }}
              pageSizeOptions={[5, 10]}
              getRowClassName={(params) => "customRow"}
              getCellClassName={(params) => "customCell"}
              disableRowSelectionOnClick={true}
            />
          </div>
        </div>
        <WarningFileSizeModal
          openModal={warningModal}
          closeModal={() => setWarningModal(false)}
          warningText={warningMsg}
        />
        <NewCategoryYear
          isOpen={newCategoryModalIsOpen}
          onClose={() => setNewCategoryModalIsOpen(false)}
          onSave={handleAddYear}
        />
        <EditCategoryYear
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          editedYear={editedYear}
          onSave={handleEditYear}
        />
        <ModalError400
          openModal={error400}
          closeModal={() => setOpenError400(false)}
          warningText={"This year of production is already in use."}
        />
        <ModalError400
          openModal={addSuccessModal}
          closeModal={() => setAddSuccessModal(false)}
          warningText={"Data Added Succsesfully"}
        />
        <ModalError400
          openModal={editSuccessModal}
          closeModal={() => setEditSuccessModal(false)}
          warningText={"Data Edited Succsesfully"}
        />
      </>
    ) : (
      <div>loading...</div>
    );
  }
}
