import styles from "./sub_category.module.css";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomDelete from "../../../icon/CustomDelete";
import CustomEdit from "../../../icon/CustomEdit";
import CircleIcon from "@mui/icons-material/Circle";
import EditSubCategoryModal from "../../../pages/edit_sub_category/edit_sub_category_modal";
import AddSubCategoryModal from "../../../pages/add_sub_category/add_sub_category_modal";
import { baseURL } from "../../../global_variable_";

export default function ProductSub({ titlePage }) {
  useEffect(() => {
    APICalls();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModalOpen] = useState(false);
  const [addModal, setAddModalOpen] = useState(false);
  const [subCategories, setSubCategories] = useState(null);
  const [editedSubCategories, setEditedSubCategories] = useState(null);
  const [editModal, setEditModalOpen] = useState(false);

  const openEditModal = (subCategories) => {
    setEditedSubCategories(subCategories);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };
  const APICalls = async () => {
    await getSubCategories();
    setIsLoading(false);
  };

  const getSubCategories = async () => {
    try {
      let response = await axios.get(`${baseURL}/sub-category?all_data=true`);
      setSubCategories(response.data.result);
      console.log(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // const addSubCategory = async () => {
  //   const token = JSON.parse(localStorage.getItem("currAdmin")).token;
  //   const header = { Authorization: `Bearer ${token}` };
  //   try {
  //     const response = await axios.post(
  //       `https://abca-rev-api-v2.cranium.id/sub-category`,
  //       { header }
  //     );
  //     // if(response.data.status === )
  //   } catch (error) {
  //     console.log("error");
  //   }
  // };

  const deleteSubCategory = async (id) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    console.log(token);
    console.log(headers);
    try {
      const response = await axios.delete(`${baseURL}/sub-category/${id}`, {
        headers,
      });
      if (response.data.status === 200) {
        APICalls();
        console.log("berhasil");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete
          itemId={params.row.id}
          onDelete={deleteSubCategory}
          closeModal={closeDeleteModal}
          Update={APICalls}
        />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    {
      field: "category",
      headerName: "Sub Category Name",
      headerClassName: "customColumnHeader",
      flex: 7,
    },
    {
      field: "name_category",
      headerName: "Category Name",
      headerClassName: "customColumnHeader",
      flex: 7,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  let formattedSubCategories = [];

  if (Array.isArray(subCategories)) {
    formattedSubCategories = subCategories.map((subCategory) => ({
      id: subCategory.id,
      category_id: subCategory.id_category,
      name_category: subCategory.name_category,
      category: subCategory.name_sub_category,
      status: subCategory.isDeleted ? "Non-Active" : "Active",
    }));
  }

  return isLoading !== true ? (
    <>
      <button
        onClick={() => setAddModalOpen(true)}
        className="newcategorybtn-modal"
      >
        Add New Sub Category
        <AddIcon className="addIcon1" />
      </button>
      <DataGrid
        className={styles.subCategoryPageTable}
        rows={formattedSubCategories}
        columns={columns}
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: "category", sort: "asc" }],
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => "customRow"}
        getCellClassName={(params) => "customCell"}
        disableRowSelectionOnClick={true}
      />
      <AddSubCategoryModal
        Update={APICalls}
        isOpen={addModal}
        onClose={closeAddModal}
      />
      <EditSubCategoryModal
        isOpen={editModal}
        onClose={closeEditModal}
        editedSub={editedSubCategories}
        Update={APICalls}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
