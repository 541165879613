import { useState, useEffect } from "react";
import "./userList.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import DefaultImage from "../../icon/defaultImage.png";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import ChangeEmailOrPasswordModal from "../change_email_or_password_modal/change_email_or_password_modal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  width: "97%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function UserList(props) {
  const [users, setUsers] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [fullname, setFullname] = useState("");
  const [totalUser, setTotalUser] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [currAuth, setCurrAuth] = useState(false);
  const [changeEmailIDUser, setChangeEmailIDUser] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [modalType, setModalType] = useState("");

  //Change Password Modal
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
  const handleOpenChangeEmailModal = (id, prevValue, type) => {
    setModalType(`${type.toLowerCase()}`);
    setPrevValue(prevValue);
    setChangeEmailIDUser(id);
    setOpenChangeEmailModal(true);
  };
  const handleCloseChangeEmailModal = () => {
    setOpenChangeEmailModal(false);
  };

  const getCurrAdmin = () => {
    const roleName = JSON.parse(localStorage.getItem(`currAdmin`)).role;
    if (roleName === "Super Admin") {
      setCurrAuth(true);
    } else {
      setCurrAuth(false);
    }
  };

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    // const filtered = users.filter((user) =>
    //   user.fullname.toLowerCase().includes(input.toLowerCase()) ||
    //   user.phone.toLowerCase().includes(input.toLowerCase()) ||
    //   user.email.toLowerCase().includes(input.toLowerCase())
    // );
    // setFilteredUsers(filtered);
  };
  const filterAndFormatUsers = () => {
    console.log(users);
    const filtered = users.filter(
      (user) =>
        user.fullname.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.phone.toLowerCase().includes(searchInput.toLowerCase())
    );

    return filtered.map((user) => ({
      id: user.id,
      name: user.fullname,
      email: user.email,
      status: user.is_verified ? "Active" : "Non-Active",
      phone: user.phone,
      role: user.role.role_name,
    }));
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/user`);
      // Assuming 'role' is available in the API response
      const usersData = response.data.result[0].data;
      // .map(user => ({
      //   ...user,
      //   role: user.role_name, // Replace 'user.role' with the actual field name if it's different
      // }));

      const totalUser = response.data.result[0].totalCount;
      setTotalUser(totalUser);
      setUsers(usersData);
      console.log(response.data.result[0].totalCount);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getAvatars = async () => {
    try {
      const response = await axios.get(`${baseURL}/user-photo`);

      setAvatars(response.data.result[0].data);
      console.log(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getCurrAdmin();
    const storedFullname = localStorage.getItem("fullname");
    if (storedFullname) {
      setFullname(storedFullname);
    }
    getUsers();
    getAvatars();
  }, []);

  // useEffect(() => {
  //   const filtered = users.filter((user) =>
  //     user.fullname.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  //   setFilteredUsers(filtered);
  // }, [searchInput, users]);

  const getImageUrl = (userId) => {
    const avatar = avatars.find((avatar) => avatar.id_user === userId);
    if (avatar && avatar.image_user_photo) {
      return `${baseURL}/${avatar.image_user_photo}`;
    }
    return DefaultImage;
  };

  const columns = [
    {
      field: "photo",
      headerName: "Photo",
      flex: 1,
      headerClassName: "customColumnHeader",
      renderCell: (params) => {
        const imageUrl = getImageUrl(params.row.id);
        return (
          <div className="centeredImageContainer">
            <img className="centeredImage" src={imageUrl} alt="" />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 8,
      headerClassName: "customColumnHeader",
      valueGetter: (params) => params.row.name || params.row.fullname,
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: `100%`,
              height: `100%`,
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
          >
            <div className="currentUserRole">{params.row.role}</div>
            {currAuth ? (
              <div>
                <button
                  className="btnEditEmailUser"
                  onClick={() => {
                    handleOpenChangeEmailModal(
                      params.row.id,
                      params.row.role,
                      `role`
                    );
                  }}
                >
                  <EditOutlinedIcon alt="Edit Icon" />
                </button>
              </div>
            ) : null}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 3,
      padding: "5",
      headerClassName: "customColumnHeader",
      renderCell: (params) => {
        const cellColor = params.value === "Active" ? "greenCell" : "redCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 7,
      headerClassName: "customColumnHeader",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: `100%`,
              height: `100%`,
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
            }}
          >
            <div className="currentUserEmail">{params.row.email}</div>
            {currAuth ? (
              <div>
                <button
                  className="btnEditEmailUser"
                  onClick={() => {
                    handleOpenChangeEmailModal(
                      params.row.id,
                      params.row.email,
                      "email"
                    );
                  }}
                >
                  <EditOutlinedIcon alt="Edit Icon" />
                </button>
              </div>
            ) : null}
          </div>
        );
      },
    },

    {
      field: "phone",
      headerName: "Phone",
      flex: 4,
      headerClassName: "customColumnHeader",
    },
  ];

  // let formattedUsers = [];
  // if (Array.isArray(users)) {
  //   formattedUsers = users.map(user => ({
  //     id: user.id,
  //     name: user.fullname,
  //     email: user.email,
  //     status: user.is_verified ? 'Active' : 'Non-Active',
  //     phone: user.phone,
  //   }));
  // }

  return (
    <>
      <div className="userList">
        {props.titlePage}
        <Box sx={styleBox}>
          <div className="userGridBar">
            <div className="totaluser">{totalUser} users</div>
            <div className="exportUserBtn">
              <ExportImportBtn
                addStyle={{ marginRight: `20px` }}
                btnType="Export"
                onClick={() =>
                  DownloadDataCsv("user_list", `${baseURL}/user/csv`)
                }
              />
            </div>
            <div className="SearchBar">
              <input
                className="SearchBox"
                placeholder="Search user"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <div className="searchIcon">
                {" "}
                <SearchIcon />{" "}
              </div>
            </div>
          </div>
          <DataGrid
            className="usertable"
            // rows={formattedUsers}
            rows={filterAndFormatUsers()}
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
          />
        </Box>
      </div>
      <ChangeEmailOrPasswordModal
        isOpen={openChangeEmailModal}
        onClose={handleCloseChangeEmailModal}
        passwordOrEmail={modalType}
        iD={changeEmailIDUser}
        Update={getUsers}
        previousValue={prevValue}
      />
    </>
  );
}
