import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./newcategoryarea.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  // maxHeight: "357px",
};

const NewCategoryArea = ({ isOpen, onClose, onSave }) => {
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [areaName, setAreaName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [selectedYearOfProduction, setSelectedYearOfProduction] = useState("");
  const [optionYearOfProduction, setOptionYearOfProduction] = useState([]);
  const [areaNameError, setAreaNameError] = useState("");
  const [yearError, setYearError] = useState("");

  const handleOpenModal = () => {
    const areaIsValid = validateAreaName();
    console.log(yearError);
    if (areaIsValid) {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/year-of-production`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          setOptionYearOfProduction(response.data.result[0].data);
        } else {
          console.error(
            "Data years of production yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data years of production:", error);
      });
  }, []);

  const handleSave = () => {
    if (validateAreaName()) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log(tesToken);

      const isDeleted = isActive ? "false" : "true";
      const newCategory = {
        name_area: areaName,
        // id_year_of_production: selectedYearOfProduction,
        isDeleted: isDeleted,
      };
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      onSave(newCategory, headers);
      setOpenModal(false);
    }
  };
  const validateAreaName = () => {
    if (areaName.trim() === "") {
      setAreaNameError("Please enter a Area Name.");
      return false;
    }
    setAreaNameError("");
    return true;
  };

  const handleAreaNameChange = (e) => {
    setAreaName(e.target.value);
  };

  useEffect(() => {
    setAreaName("");
    setYearError("");
    if (!isOpen) {
      setAreaNameError("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>NEW AREA</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxWidth: "491px",
              maxHeight: "307px",
              overflow: "auto",
            }}
          >
            <form>
              <div className="form-group">
                <label htmlFor="productName" className={styles.categorynamenew}>
                  Area Name
                </label>
                <br></br>
                <input
                  type="text"
                  id="categoryName"
                  className={styles.productnamenew}
                  value={areaName}
                  onChange={handleAreaNameChange}
                />
                {areaNameError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {areaNameError}
                  </div>
                )}
              </div>
              {/* <div className={styles.formGroup}>
                <label htmlFor="yearOfProduction" className={styles.YOPtitle}>
                  Year of Production
                </label>
                <br></br>
                <select
                  name="yearOfProduction"
                  id="yearOfProduction"
                  className={styles.YopDropdown}
                  value={selectedYearOfProduction}
                  onChange={(e) => setSelectedYearOfProduction(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {optionYearOfProduction.map((year) => (
                    <option key={year.id} value={year.id}>
                      {year.production_year}
                    </option>
                  ))}
                </select>
                {yearError.length !== 0 ? (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {yearError}
                  </div>
                ) : null}
              </div> */}
              <div className="form-group">
                <label className={styles.labelstatusnew}>Status:</label>
                <br></br>
                <br></br>
                <label className={styles.radiogroupnew}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />{" "}
                  Active
                </label>
                <label className={styles.radiogroupnew2}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />{" "}
                  Non-Active
                </label>
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={handleOpenModal}
              className={styles.btnGroupNewCategoryProduct}
            >
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={onClose}
              className={styles.btnGroupNewCategoryProduct}
            >
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewCategoryArea;
