// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetSm_widgetSm__SvCXb{
    
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background-color: #BE1620;
    width: 287px;
    height: 88px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 179px 47px;
}

.widgetSm_widgetSm__SvCXb img{
    width: 68px;
    height: 68px;
    border-radius: 100%;
}

.widgetSm_widgetSmStringContent__oGafb{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 20px;
}

.widgetSm_topString__sbvZ\\+, .widgetSm_bottomString__PKB5n{
    color: #FFF;
}

.widgetSm_topString__sbvZ\\+{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.widgetSm_bottomString__PKB5n{
    font-size: 14px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/widgetSm/widgetSm.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gDAAgD;IAChD,wBAAwB;IACxB,4BAA4B;IAC5B,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".widgetSm{\n    \n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    align-items: center;\n    background-color: #BE1620;\n    width: 287px;\n    height: 88px;\n    border-radius: 20px;\n    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: right top;\n    background-size: 179px 47px;\n}\n\n.widgetSm img{\n    width: 68px;\n    height: 68px;\n    border-radius: 100%;\n}\n\n.widgetSmStringContent{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: start;\n    white-space: nowrap;\n    overflow: hidden;\n    margin: 0 20px;\n}\n\n.topString, .bottomString{\n    color: #FFF;\n}\n\n.topString{\n    font-size: 20px;\n    font-weight: 600;\n    margin-bottom: 5px;\n}\n\n.bottomString{\n    font-size: 14px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetSm": `widgetSm_widgetSm__SvCXb`,
	"widgetSmStringContent": `widgetSm_widgetSmStringContent__oGafb`,
	"topString": `widgetSm_topString__sbvZ+`,
	"bottomString": `widgetSm_bottomString__PKB5n`
};
export default ___CSS_LOADER_EXPORT___;
