import React, { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "./newroleusermobile.css";
import { Box } from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from "@mui/icons-material/Circle";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function NewRoleUserMobile({ titlePage }) {
  const [users, setUsers] = useState([]);

  const getRoleUser = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/role-admin?all_data=true`;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      let response = await axios.get(`${url}role`, {
        headers: { Authorization: `Bearer ${currAdmin.token}` },
      });
      console.log(response);
      setUsers(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getRoleUser();
  }, []);

  const columns = [
    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 0.25,
    },

    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 0.25,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Deleted" : "Active"}
          </div>
        );
      },
    },
  ];
  return (
    <div className="newrolecms">
      {titlePage}
      <Box sx={styleBox}>
        <div className="topContentRoleUserMobile"></div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <label className="textLabelNewRoleUserMobile">Role Name</label>

            <div className="inputContainerNewRoleUserMobile">
              <input
                type="text"
                placeholder="Add Role User Mobile here..."
                //   value={}
                className=""
                //   onChange={}
              />
            </div>

            <FormControl>
              {/* Product */}
              <FormLabel id="demo-radio-buttons-group-label">Product</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="show"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="show"
                  control={<Radio />}
                  label="Show"
                />
                <FormControlLabel
                  value="hide"
                  control={<Radio />}
                  label="Hide"
                />
              </RadioGroup>

              {/* About Us */}
              <FormLabel id="demo-radio-buttons-group-label">
                About Us
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="show"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="show"
                  control={<Radio />}
                  label="Show"
                />
                <FormControlLabel
                  value="hide"
                  control={<Radio />}
                  label="Hide"
                />
              </RadioGroup>

              {/* Contact Us */}
              <FormLabel id="demo-radio-buttons-group-label">
                Contact Us
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="show"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="show"
                  control={<Radio />}
                  label="Show"
                />
                <FormControlLabel
                  value="hide"
                  control={<Radio />}
                  label="Hide"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <DataGrid
              rows={users}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: "role_name", sort: "asc" }],
                },
              }}
              pageSizeOptions={[5, 10]}
              getRowClassName={(params) => "customRow"}
              getCellClassName={(params) => "customCell"}
              disableRowSelectionOnClick={true}
            />
          </Grid>
          <Grid item xs={12}>
            <button className="saveButtonNewRoleUser">Save</button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
