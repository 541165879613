import React, { useState } from "react";
import { useEffect } from "react";
import "./edit_role_cms.css";
import { Box } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function EditRoleCms({ titlePage }) {
  const navigate = useNavigate();
  const { role_id: id } = useParams();

  const [roleName, setRoleName] = useState("");
  const [menuStates, setMenuStates] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [listMenu, setListMenu] = useState([]);
  const [sublistMenu, setSubListMenu] = useState({});
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [selectedRow, setSelectRow] = useState(null);
  const [submenuStates, setSubmenuStates] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [roleNameError, setRoleNameError] = useState(null);
  const [submenuVisibility, setSubmenuVisibility] = useState({});

  const handleSelectRow = (row) => {
    setSelectRow(row);
  };

  useEffect(() => {
    const initialMenuStates = {};
    listMenu.forEach((menu) => {
      initialMenuStates[menu.id] = { is_visible: "show" };
    });
    setMenuStates(initialMenuStates);

    const initialSubmenuStates = {};
    listMenu.forEach((menu) => {
      initialSubmenuStates[menu.id] = { is_visible: "show" };
    });
    setSubmenuStates(initialSubmenuStates);
    setLoading2(false);
  }, [listMenu]);

  const getMenuStates = (menuId) => {
    return menuStates[menuId] && menuStates[menuId].is_visible === "show"
      ? "show"
      : "hide";
  };

  const getSubmenuStates = (submenuId) => {
    const submenuState = submenuStates[submenuId];

    if (!submenuState) {
      return "hide";
    }

    return submenuState.is_visible ? submenuState.is_visible : "show";
  };

  const APICalls = async () => {
    await getMenuCMS();
    await getSubMenuCMS();
    await getRoleNameById();
    await getRoleDataById();
    setLoading(false);
  };

  useEffect(() => {
    APICalls();
  }, []);

  const getRoleNameById = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${baseURL}/role-admin/?id=${id}`, {
        headers,
      });
      setRoleName(response.data.result.role_name);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoleDataById = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get(
        `${baseURL}/admin-role-menu/?id_role_admin=${id}`,
        { headers }
      );

      const dataArray = response.data.result[0].data;
      console.log("Data Array:", dataArray);
      const Id = response.data.result[0].data.map((item) => item.id);
      console.log("id:", Id);

      const menuData = dataArray.reduce((acc, item) => {
        acc[item.id_admin_menu] = {
          is_visible: item.is_visible,
          menu_id: item.id_admin_menu,
          id: item.id,
        };
        return acc;
      }, {});

      console.log("Menu Data:", menuData);
      setMenuStates(menuData);

      const submenuData = dataArray.reduce((acc, item) => {
        acc[item.id_admin_menu] = {
          is_visible: item.is_visible,
          sub_menu_id: item.id_admin_menu,
          id: item.id,
        };
        return acc;
      }, {});

      setSubmenuStates(submenuData);
    } catch (error) {
      console.error(error);
    }
  };

  const getMenuCMS = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/admin-menu`;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${url}`, { headers });
      if (response.data.status === 200) {
        setListMenu(response.data.result[0].data);
        const MenuIds = response.data.result[0].data.map((item) => item.id);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getSubMenuCMS = async (menuId) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/admin-menu`;
    const headers = { Authorization: `Bearer ${token}` };
    const params = { parent: "all" };

    try {
      const response = await axios.get(`${url}`, { headers, params });
      if (response.data.status === 200) {
        const submenus = response.data.result[0].data;
        const subMenuMap = submenus.reduce((acc, submenu) => {
          const parentId = submenu.parent_id || "root";
          if (!acc[parentId]) {
            acc[parentId] = [];
          }
          acc[parentId].push(submenu);
          return acc;
        }, {});

        setSubListMenu(subMenuMap);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleRadioChange = async (menuId, value) => {
    setMenuStates((prevStates) => {
      const updatedStates = { ...prevStates };
      updatedStates[menuId] = {
        ...prevStates[menuId],
        is_visible: value === "show" ? "show" : "hide",
      };
      return updatedStates;
    });

    if (value === "hide") {
      setSubmenuStates((prevStates) => {
        const updatedSubmenuStates = { ...prevStates };
        const submenusToHide = sublistMenu[menuId];
        if (submenusToHide) {
          submenusToHide.forEach((submenu) => {
            updatedSubmenuStates[submenu.id] = {
              ...prevStates[submenu.id],
              is_visible: "hide",
            };
          });
        }
        return updatedSubmenuStates;
      });
      setMenuStates((prevStates) => {
        const updatedSubmenuStates = { ...prevStates };
        const submenusToHide = sublistMenu[menuId];
        if (submenusToHide) {
          submenusToHide.forEach((submenu) => {
            updatedSubmenuStates[submenu.id] = {
              ...prevStates[submenu.id],
              is_visible: "hide",
            };
          });
        }
        return updatedSubmenuStates;
      });
      setSubmenuVisibility((prevStates) => {
        const updatedVisibility = { ...prevStates };
        const submenusToHide = sublistMenu[menuId];
        if (submenusToHide) {
          submenusToHide.forEach((submenu) => {
            updatedVisibility[submenu.id] = false;
          });
        }
        return updatedVisibility;
      });
    }

    if (value === "show") {
      setSelectedMenuId(menuId);
      const selectedMenu = listMenu.find((menu) => menu.id === menuId);
      setSelectedParentId(selectedMenu.parent_id);
      if (menuStates[menuId].is_visible === "hide") {
        setSubmenuStates((prevStates) => {
          const updatedSubmenuStates = { ...prevStates };
          const submenusToShow = sublistMenu[menuId];
          if (submenusToShow) {
            submenusToShow.forEach((submenu) => {
              updatedSubmenuStates[submenu.id] = {
                ...prevStates[submenu.id],
                is_visible: "show",
              };
            });
          }
          return updatedSubmenuStates;
        });
        setMenuStates((prevStates) => {
          const updatedSubmenuStates = { ...prevStates };
          const submenusToShow = sublistMenu[menuId];
          if (submenusToShow) {
            submenusToShow.forEach((submenu) => {
              updatedSubmenuStates[submenu.id] = {
                ...prevStates[submenu.id],
                is_visible: "show",
              };
            });
          }
          return updatedSubmenuStates;
        });
      }
      await getSubMenuCMS(menuId);
    } else {
      setSelectedMenuId(null);
      setSelectedParentId(null);
      setSubmenuVisibility((prevStates) => {
        const updatedVisibility = { ...prevStates };
        const submenusToHide = sublistMenu[menuId];
        if (submenusToHide) {
          submenusToHide.forEach((submenu) => {
            updatedVisibility[submenu.id] = false;
          });
        }
        return updatedVisibility;
      });
    }
  };

  const handleSubmenuChange = async (submenuId, value) => {
    setSubmenuStates((prevStates) => {
      const updatedSubmenuStates = { ...prevStates };
      updatedSubmenuStates[submenuId] = {
        ...prevStates[submenuId],
        is_visible: value === "show" ? "show" : "hide",
      };
      return updatedSubmenuStates;
    });

    setMenuStates((prevStates) => {
      const updatedMenuStates = { ...prevStates };
      updatedMenuStates[submenuId] = {
        ...prevStates[submenuId],
        is_visible: value === "show" ? "show" : "hide",
      };
      return updatedMenuStates;
    });
  };

  const handleSave = async () => {
    const response = await EditAdminroleMenu(id);
    console.log(response);
  };

  const EditAdminroleMenu = async (role_id) => {
    console.log(role_id);
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    const requests = [];
    Object.keys(menuStates).forEach((menuId) => {
      const state = menuStates[menuId];
      const data = {
        id: `${menuStates[menuId].id || ""}`,
        // id_admin_menu: `${menuId}`,
        // id_role_admin: `${role_id}`,
        is_visible: `${getMenuStates(menuId)}`,
      };
      console.log("Data to be sent to API:", data);

      const request = axios.put(`${baseURL}/admin-role-menu`, data, {
        headers,
      });
      console.log("Axios request:", request);
      requests.push(request);
    });
    console.log("All requests:", requests);

    try {
      const responses = await Promise.all(requests);
      responses.forEach((response) => console.log("Axios response:", response));
      console.warn("All menu edit success");
      setOpenSuccessModal(true);
      APICalls();
      return true;
    } catch (error) {
      console.warn("Some menu edit failed");
      console.error(error);
      return false;
    }
  };

  const columns = [
    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 2,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Deleted" : "Active"}
          </div>
        );
      },
    },
  ];

  return !isLoading && !isLoading2 ? (
    <div className="newrolecms">
      {titlePage}
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            <label className="textLabelNewRoleCMS">Role Name</label>

            <div className="inputContainerNewRoleCMS">
              <input
                type="text"
                value={roleName}
                className="customFieldCMS"
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
            {roleNameError && (
              <div style={{ color: "#BE1620", marginTop: "5px" }}>
                {roleNameError}
              </div>
            )}
          </Grid>
        </Grid>
        {/* grid buat menu */}
        <Grid
          container
          spacing={10}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* column 1 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu, index) => index < 5 && !sublistMenu[menu.id])
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "5px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "25px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 2 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu, index) => index >= 5 && !sublistMenu[menu.id])
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "5px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "25px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 3 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu) => sublistMenu[menu.id])
                .slice(0, 1)
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "8px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "22px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                    <div
                      style={{
                        marginLeft: "33px",
                        marginTop: "2ppx",
                      }}
                    >
                      {sublistMenu[menu.id] && (
                        <FormControl>
                          {sublistMenu[menu.id].map((submenu) => (
                            <div
                              key={submenu.id}
                              style={{
                                marginTop: "7px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderBottom: "1px solid #000",
                                  paddingBottom: "5px",
                                }}
                              >
                                <FormLabel
                                  id={`demo-radio-buttons-group-label-${submenu.id}`}
                                >
                                  {submenu.name_menu}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={`demo-radio-buttons-group-label-${submenu.id}`}
                                  name={`radio-buttons-group-${submenu.id}`}
                                  value={getSubmenuStates(submenu.id)}
                                  onChange={(e) =>
                                    handleSubmenuChange(
                                      submenu.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={getMenuStates(menu.id) === "hide"}
                                >
                                  <FormControlLabel
                                    value="show"
                                    control={<Radio />}
                                    label="Show"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                  <FormControlLabel
                                    value="hide"
                                    control={<Radio />}
                                    label="Hide"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          ))}
                        </FormControl>
                      )}
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 4 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu) => sublistMenu[menu.id])
                .slice(1, 2)
                .map((menu) => (
                  <div
                    key={menu.id}
                    style={{ marginBottom: "20px", position: "relative" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "8px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "22px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                    <div
                      style={{
                        marginLeft: "33px",
                        marginTop: "15px",
                      }}
                    >
                      {sublistMenu[menu.id] && (
                        <FormControl>
                          {sublistMenu[menu.id].map((submenu) => (
                            <div
                              key={submenu.id}
                              style={{
                                marginTop: "7px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderBottom: "1px solid #000",
                                  paddingBottom: "5px",
                                }}
                              >
                                <FormLabel
                                  id={`demo-radio-buttons-group-label-${submenu.id}`}
                                >
                                  {submenu.name_menu}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={`demo-radio-buttons-group-label-${submenu.id}`}
                                  name={`radio-buttons-group-${submenu.id}`}
                                  value={getSubmenuStates(submenu.id)}
                                  onChange={(e) =>
                                    handleSubmenuChange(
                                      submenu.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={getMenuStates(menu.id) === "hide"}
                                >
                                  <FormControlLabel
                                    value="show"
                                    control={<Radio />}
                                    label="Show"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                  <FormControlLabel
                                    value="hide"
                                    control={<Radio />}
                                    label="Hide"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          ))}
                        </FormControl>
                      )}
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>
        </Grid>

        {/* save button */}
        <Grid
          container
          spacing={8}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid>
            <button
              className="saveButtonNewRoleCMS"
              onClick={() => setConfirmModal(true)}
            >
              Save
            </button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          await handleSave();
        }}
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
      />
      <WarningFileSizeModal
        openModal={openSuccessModal}
        closeModal={() => {
          navigate(-1);
          setOpenSuccessModal(false);
        }}
        warningText={"Data Edited Succsesfully"}
      />
    </div>
  ) : (
    <div>loading...</div>
  );
}
