import { useEffect, useState } from "react";
import styles from "./ContactMessage.module.css";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import ConfirmSubmitChanges from "../ConfirmSubmitChanges/ConfirmSubmitChanges";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { baseURL } from "../../global_variable_";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "430px",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

export default function ContactMessage() {
  const [contacts, setContacts] = useState(null);
  const [message, setMessage] = useState("");

  const [openViewMessageModal, setOpenViewMessageModal] = useState(false); // Modal Delete Contact
  const handleOpenViewMessageModal = (data) => {
    console.log(data);
    setMessage(data);
    setOpenViewMessageModal(true);
  };
  const handleCloseViewMessageModal = () => {
    setOpenViewMessageModal(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Modal Delete Contact
  const [idDeleteData, setIdDeleteData] = useState(null);
  const handleOpenDeleteModal = (data) => {
    setIdDeleteData(data.id);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleRowDelete = (id) => {
    setContacts(contacts.filter((contact) => contact.id !== id));
  };
  const handleSubmitData = async () => {
    // postAPI -- Delete Row
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        await axios.delete(baseURL + `/contact-us/${idDeleteData}`, {
          headers,
        });
        handleRowDelete(idDeleteData);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Gagal menghapus item:", error);
      }
    }
  };

  const getContacts = async () => {
    // getAPI
    try {
      const response = await axios.get(baseURL + "/contact-us");
      console.log(response.data.result[0].data);
      const contatcsData = response.data.result[0].data;
      setContacts(convertArrayData(contatcsData));
      // console.log(contacts)
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  const convertArrayData = (datas) => {
    let newData = [];

    if (Array.isArray(datas)) {
      newData = datas.map((data) => ({
        id: data.id,
        title: data.title,
        fullname: data.fullname,
        phone: data.phone,
        email: data.email,
        subject: data.subject,
        request: data.request,
      }));
    }
    return newData;
  };

  const columns = [
    // columnHeaderDataGrid
    {
      field: "isDeleted",
      headerName: "",
      align: "center",
      flex: 0.3,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              handleOpenDeleteModal(params.row);
            }}
          >
            <ClearRoundedIcon className={styles.cellIcon} />
          </div>
        );
      },
    },
    {
      field: "openMessage",
      headerName: "",
      flex: 0.3,
      align: "center",
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              handleOpenViewMessageModal(params.row);
            }}
          >
            <VisibilityOutlinedIcon className={styles.cellIcon} />
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 0.3,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "fullname",
      headerName: "Name",
      flex: 1,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "request",
      headerName: "Message",
      flex: 1,
      headerClassName: `${styles.columnHeader}`,
    },
  ];

  return (
    // <div>{!contacts ? "null" : console.log(contacts)}</div>
    <Box sx={{ width: "100%" }}>
      {!contacts ? (
        "error"
      ) : (
        <DataGrid
          rows={contacts}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
            sorting: {
              sortModel: [{ field: "fullname", sort: "asc" }],
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          // headerClassName={styles.columnHeader}
          className={styles.dataGrid}
        />
      )}
      <ConfirmSubmitChanges
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        warningText={
          "Are you sure want to delete?\nThis action cannot be undone."
        }
        handleSubmitData={handleSubmitData}
      />
      <Modal
        open={openViewMessageModal}
        // onClose={handleCloseViewMessageModal}
      >
        <Box sx={styleBox}>
          <div className={styles.modalBox}>
            <CloseRoundedIcon
              onClick={() => {
                handleCloseViewMessageModal();
              }}
              className={styles.closeIconModal}
            />
            <div className={styles.contentModal}>
              <div className={styles.contentOneLine}>
                <div className={styles.contentTitle}>From</div>
                <div className={styles.contentVariable}>
                  {`${message.title} ${message.fullname}`}
                </div>
              </div>
              <div className={styles.contentOneLine}>
                <div className={styles.contentTitle}>Phone Number</div>
                <div className={styles.contentVariable}>
                  {`${message.phone}`}
                </div>
              </div>
              <div className={styles.contentOneLine}>
                <div className={styles.contentTitle}>Email</div>
                <div className={styles.contentVariable}>
                  {`${message.email}`}
                </div>
              </div>
              <div className={styles.contentOneLine}>
                <div className={styles.contentTitle}>Subject</div>
                <div className={styles.contentVariable}>
                  {`${message.subject}`}
                </div>
              </div>
              <div
                className={`${styles.contentTitle} ${styles.contentMessageTitle}`}
              >
                Message
              </div>
              <div className={styles.contentMessage}>{message.request}</div>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}
