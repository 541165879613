import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./editcategoryyear.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  maxHeight: "307px",
};

const EditCategoryYear = ({ isOpen, onClose, editedYear, onSave }) => {
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [yearError, setYearError] = useState("");
  const [year, setYear] = useState("");
  const [isActive, setIsActive] = useState(true);

  const handleOpenModal = () => {
    if (validateYear()) {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (editedYear) {
      setYear(editedYear.year);
      setIsActive(editedYear.status === "Active");
    }
  }, [editedYear]);

  const handleSave = () => {
    if (validateYear()) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log(tesToken);

      const isDeleted = isActive ? "false" : "true";

      const editedData = {
        id: editedYear.id,
        production_year: year,
        isDeleted: isDeleted,
      };
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };

      onSave(editedData, headers);
      setOpenModal(false);
    }
  };

  const validateYear = () => {
    if (year.trim() === "") {
      setYearError("Please enter Year of Production.");
      return false;
    } else if (!/^\d+$/.test(year)) {
      setYearError("Please enter Year of Production as numeric.");
      return false;
    }
    setYearError("");
    return true;
  };

  const handleYearChange = (e) => {
    const inputValue = e.target.value;
    setYear(inputValue);

    // if (inputValue.trim() === "") {
    //   setYearError("Please enter year of production");
    // } else if (!/^\d+$/.test(inputValue)) {
    //   setYearError("Please enter year of production as numeric");
    // } else {
    //   setYearError("");
    // }
  };

  useEffect(() => {
    if (!isOpen) {
      setYearError("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>EDIT YEAR OF PRODUCTION</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxWidth: "491px",
              maxHeight: "307px",
              overflow: "auto",
            }}
          >
            <form>
              <div className="form-group">
                <label htmlFor="productName" className={styles.categorynamenew}>
                  Year of Production
                </label>
                <br></br>
                <input
                  type="text"
                  id="categoryName"
                  className={styles.productnamenew}
                  value={year}
                  onChange={handleYearChange}
                />
                <div
                  className={`error-msg ${styles.errorMsg}`}
                  style={{ color: "#BE1620" }}
                >
                  {yearError}
                </div>
              </div>
              <div className="form-group">
                <label className={styles.labelstatusnew}>Status:</label>
                <br></br>
                <br></br>
                <label className={styles.radiogroupnew}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />{" "}
                  Active
                </label>
                <label className={styles.radiogroupnew2}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />{" "}
                  Non-Active
                </label>
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={handleOpenModal}
              className={styles.btnGroupNewCategoryProduct}
            >
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={onClose}
              className={styles.btnGroupNewCategoryProduct}
            >
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditCategoryYear;
