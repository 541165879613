import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./NewsletterSubscribers.module.css";
import { DataGrid } from "@mui/x-data-grid";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConfirmSubmitChanges from "../ConfirmSubmitChanges/ConfirmSubmitChanges";
import ExportImportBtn from "../button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import { baseURL } from "../../global_variable_";

export default function NewsletterSubscribers() {
  const [subscribers, setSubscribers] = useState(null);
  const getSubscribers = async () => {
    try {
      const response = await axios.get(baseURL + `/subscribe`);
      console.log(response.data.result[0].data);
      setSubscribers(convertArrayData(response.data.result[0].data));
      console.log(convertArrayData(response.data.result[0].data));
    } catch (e) {
      console.error(e);
    }
  };
  const convertArrayData = (datas) => {
    let newData = [];
    if (Array.isArray(datas)) {
      newData = datas.map((data) => ({
        date: convertDataDate(data.createdAt),
        email: data.email_subscribe,
        id: data.id,
      }));
    }
    return newData;
  };

  const convertDataDate = (input) => {
    const date = new Date(input);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    return formattedDate;
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Modal Delete Contact
  const [idDeleteData, setIdDeleteData] = useState(null);
  const handleOpenDeleteModal = (data) => {
    setIdDeleteData(data.id);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleRowDelete = (id) => {
    setSubscribers(subscribers.filter((subscribers) => subscribers.id !== id));
  };
  const handleDeleteData = async () => {
    // postAPI -- Delete Row
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        await axios.delete(baseURL + `/subscribe/${idDeleteData}`, { headers });
        handleRowDelete(idDeleteData);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Gagal menghapus item:", error);
      }
    }
  };

  const columns = [
    {
      field: "isDeleted",
      headerName: "",
      align: "center",
      flex: 0.2,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div
            className={styles.iconContainer}
            onClick={() => {
              handleOpenDeleteModal(params.row);
            }}
          >
            <ClearRoundedIcon className={styles.cellIcon} />
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 4,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 4,
      headerClassName: `${styles.columnHeader}`,
    },
  ];

  useEffect(() => {
    getSubscribers();
  }, []);
  return (
    <div className={styles.SubsComponent}>
      <div className={styles.TitleRow}>
        <div className={styles.TitleText}>SUBSCRIBERS</div>
        <div className={styles.exportImportContainer}>
          <ExportImportBtn
            onClick={() =>
              DownloadDataCsv(
                "newsletter_subscribers",
                `${baseURL}/subscribe/csv`
              )
            }
            btnType="Export"
          />
        </div>
      </div>
      <div className={styles.DataTable}>
        {!subscribers ? (
          "...loading"
        ) : (
          <DataGrid
            rows={subscribers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            className={styles.dataGrid}
            getRowClassName={(params) => styles.customCell}
            getCellClassName={(params) => styles.customCell}
          />
        )}
      </div>
      <ConfirmSubmitChanges
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        warningText={
          "Are you sure want to delete?\nThis action cannot be undone."
        }
        handleSubmitData={handleDeleteData}
      />
    </div>
  );
}
