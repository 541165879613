import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./newformula.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const NewFormula = ({ isOpen, onClose, onSave, colorId, selectedCategory }) => {
  const [baseFormula, setBaseFormula] = useState("");
  const [amountFormula, setAmountFormula] = useState("");
  const [cumulative, setCumulative] = useState("");
  const [unit, setUnit] = useState("gr");
  const [formula, setFormula] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [baseError, setBaseError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [formulaCategories, setFormulaCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryCumulativeData, setCategoryCumulativeData] = useState({});

  console.log("Nilai selectedCategory:", selectedCategory);

  const handleOpenModal = () => {
    const baseError = validateBase(baseFormula);
    const amountError = validateAmount(amountFormula);

    if (!baseError && !amountError) {
      setOpenModal(true);
    } else {
      setBaseError(baseError);
      setAmountError(amountError);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/formula`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          setFormula(response.data.result[0].data);
        } else {
          console.error(
            "Data kategori yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori:", error);
      });
  }, []);

  const getFormulaCategory = () => {
    axios
      .get(`${baseURL}/category-formula`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          const categoryData = response.data.result[0].data;
          setFormulaCategories(categoryData);
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori formula:", error);
      });
  };

  useEffect(() => {
    getFormulaCategory();
  }, []);

  // useEffect(() => {
  //   console.log("Data Formula: ", formula)
  //   const formulasForCategory = formula.filter(
  //     (item) => item.id_category_formula === selectedCategory
  //   );

  //   let cumulativeValue = 0;
  //   formulasForCategory.forEach((item) => {
  //     const amount = parseFloat(item.amount_formula);
  //     cumulativeValue += amount;
  //   });

  //   setCumulative(cumulativeValue.toFixed(2));
  //   console.log(cumulativeValue.toFixed(2));
  // }, [selectedCategory, formula]);

  useEffect(() => {
    const formulasForColor = formula.filter(
      (item) => item.id_color === colorId
    );

    const cumulativeData = {};

    formulasForColor.forEach((item) => {
      const selectedCategory = item.id_category_formula;
      const amount = parseFloat(item.amount_formula);

      if (!cumulativeData[selectedCategory]) {
        cumulativeData[selectedCategory] = 0;
      }

      cumulativeData[selectedCategory] += amount;
    });

    setCategoryCumulativeData(cumulativeData);
    console.log(categoryCumulativeData);
  }, [colorId, formula]);

  const validateBase = (base) => {
    if (base.trim() === "") {
      return "Please enter the base of the formula";
    }
    return "";
  };

  const validateAmount = (amount) => {
    if (amount.trim() === "") {
      return "Please enter the amount of the formula";
    } else if (!/^\d*(\.\d*)?$/.test(amount)) {
      return "Please enter a valid numeric amount";
    }
    return "";
  };

  const handleSave = () => {
    const selectedCategoryData = formulaCategories.find(
      (categoryData) => categoryData.category_name === selectedCategory
    );

    const selectedCategoryId = selectedCategoryData
      ? selectedCategoryData.id
      : null;

    const getToken = JSON.parse(localStorage.getItem("currAdmin"));
    const token = getToken.token;
    localStorage.setItem("tokenAdmin", token);
    const tesToken = localStorage.getItem("tokenAdmin");

    const editedData = {
      id_color: colorId,
      base_formula: baseFormula,
      id_category_formula: selectedCategory,
      amount_formula: amountFormula,
      unit_formula: unit,
    };

    const headers = {
      Authorization: `Bearer ${tesToken}`,
    };

    onSave(editedData, headers);
  };
  console.log(selectedCategory);

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid container spacing={2} style={{ margin: 0 }}>
          <Grid xs={11}>
            <div className={styles.modalTitle}>NEW FORMULA</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid xs={12} style={{ maxHeight: "560px", overflow: "auto" }}>
            <form>
              <div className={styles.formGroup}>
                <label htmlFor="baseFormula" className={styles.textLabel}>
                  Base
                </label>
                <input
                  type="text"
                  id="baseFormula"
                  className={styles.customField}
                  value={baseFormula}
                  onChange={(e) => {
                    setBaseFormula(e.target.value);
                    setBaseError(validateBase(e.target.value));
                  }}
                />
                {baseError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {baseError}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="amountFormula" className={styles.textLabel}>
                  Amount (Current Cumulative:{" "}
                  {selectedCategory === "1"
                    ? categoryCumulativeData[1]
                    : selectedCategory === "2"
                    ? categoryCumulativeData[2]
                    : categoryCumulativeData[null]}{" "}
                  gr)
                </label>
                <input
                  type="text"
                  id="amountFormula"
                  className={styles.customField}
                  value={amountFormula}
                  onChange={(e) => {
                    setAmountFormula(e.target.value);
                    setAmountError(validateAmount(e.target.value));
                  }}
                />
                {amountError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {amountError}
                  </div>
                )}
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="unit" className={styles.textLabel}>
                  Unit
                </label>
                <input
                  type="text"
                  id="gr"
                  className={styles.customField}
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  readOnly
                  style={{ backgroundColor: "#D3D3D3" }}
                />
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button onClick={handleOpenModal} className={styles.btnGroup}>
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button onClick={onClose} className={styles.btnGroup}>
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewFormula;
