import { Modal, Box, Grid } from "@mui/material";
import { ModalContent, ModalHeader } from "semantic-ui-react";
import styles from "./csv_template_modal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const CSVTemplateModal = ({
  isOpen,
  onClose,
  downloadTemplate,
  triggerInput,
}) => {
  return (
    <Modal className={styles.Modal} open={isOpen}>
      <Box className={styles.modalOuterContainer}>
        <ModalHeader className={styles.modalHeader}>
          <div className={styles.Title}>IMPORT CSV</div>
          <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
        </ModalHeader>
        <ModalContent className={styles.modalContent}>
          <button
            className={styles.Button}
            onClick={() => {
              triggerInput();
            }}
          >
            IMPORT CSV
          </button>
          <button
            style={{ marginLeft: `16px` }}
            className={styles.Button}
            onClick={async () => {
              //   console.log(`test`);
              await downloadTemplate();
            }}
          >
            DOWNLOAD TEMPLATE
          </button>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default CSVTemplateModal;
