import styles from "./widgetSm.module.css"
import theThing from "../../../icon/Intersect.png"

export default function UserGreeting({name, username}) {

  const containerBackground = {
    backgroundImage:`url(${theThing})`,
  };

  return (
    <div style={containerBackground} className={styles.widgetSm}>
      <div className={styles.widgetSmStringContent}>
        <div className={styles.topString}>Hi {name}!</div>
        <div className={styles.bottomString}>Username: {username}</div>
      </div>
    </div>
  )
}