import styles from "./show_pass_btn.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const ShowPassBtn = ({ onClick, state, inputStyle }) => {
  return (
    <button
      style={inputStyle}
      className={styles.toggleShowPassBtn}
      onClick={onClick}
    >
      {state === `text` ? (
        <VisibilityIcon className={styles.showPassIcon} />
      ) : (
        <VisibilityOffIcon />
      )}
    </button>
  );
};

export default ShowPassBtn;
