import { useEffect, useState } from "react";
import styles from "./modal_issue.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const replyBox = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
};
const viewStyles = {
  position: "absolute",
  padding: "20px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "470px",
  height: "auto",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
};

const ModalIssue = ({ isOpen, onClose, currIssue, Update }) => {
  const [replyMessage, setReplyMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [openReplyBox, setOpenReplyBox] = useState(false);
  const handleCloseReplyBox = () => {
    setOpenReplyBox(false);
  };
  const handleInputReplyMessage = (event) => {
    setReplyMessage(event.target.value);
  };

  const isEmpty = () => {
    if (replyMessage.trim().length === 0) {
      setErrorMsg("Please enter a Message Reply.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const handleSubmitReply = async () => {
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      try {
        const newData = {
          id: currIssue.id,
          replyMessage: replyMessage,
        };
        const response = await axios.post(
          `${baseURL}/submit-issue/reply`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${currAdmin.token}`,
            },
          }
        );
        console.log("Reply Sent Successfully:", response.data);

        // console.log(currIssue.id);
        // console.log(newData);
        Update();
        handleCloseReplyBox();
        onClose();
      } catch (e) {
        console.error("Error sending reply:", e);
      }
    }
  };

  const [ready, setReady] = useState(false);
  const [finalDate, setDate] = useState("");
  const [open, setOpen] = useState(isOpen);
  const [openConfirmModal, setConfirmModal] = useState(false);

  const handleOpenConfirm = () => {
    if (!isEmpty()) return;
    setConfirmModal(true);
  };
  const handleCloseConfirm = () => {
    setConfirmModal(false);
  };

  const changeDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    // console.log(date)
    const day = String(date.getDate()).padStart(2, "0");

    // console.log(day)
    var month = String(date.getMonth() + 1).padStart(2, "0");
    switch (month) {
      case "01":
        month = "January";
        break;
      case "02":
        month = "February";
        break;
      case "03":
        month = "March";
        break;
      case "04":
        month = "April";
        break;
      case "05":
        month = "May";
        break;
      case "06":
        month = "June";
        break;
      case "07":
        month = "July";
        break;
      case "08":
        month = "August";
        break;
      case "09":
        month = "September";
        break;
      case "10":
        month = "October";
        break;
      case "11":
        month = "Novermber";
        break;
      case "12":
        month = "December";
        break;
    }
    // console.log(date.getMonth())
    const year = date.getFullYear();
    // console.log(year)

    const formattedDate = `${day} ${month} ${year}`;
    // console.log(formattedDate)
    return formattedDate;
  };
  useEffect(() => {
    console.log("in");
    if (currIssue) {
      // console.log(currIssue.date)
      setDate(changeDateFormat(currIssue.date));
      console.log(finalDate);
      setReady(true);
    }
    setErrorMsg(null);
    setReplyMessage("");
  }, [isOpen, openReplyBox]);

  return ready === true ? (
    <>
      <Modal open={isOpen}>
        <Box sx={viewStyles}>
          <div className={styles.Container}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
            <div className={styles.Header}>
              {currIssue.subject}{" "}
              <button
                onClick={() => {
                  console.log(currIssue.message);
                  setOpenReplyBox(true);
                }}
                className={styles.replyBtn}
              >
                Reply
              </button>
            </div>
            <div className={styles.topContent}>
              <div className={styles.topContentSub}>
                <div className={styles.label}>Report From</div>
                <div className={styles.Text}>{currIssue.name}</div>
              </div>
              <div className={styles.topContentSub}>
                <div className={styles.label}>Date</div>
                <div className={styles.Text}>{finalDate}</div>
              </div>
            </div>
            <div className={styles.bottomContent}>
              <div className={`${styles.label} ${styles.bottomLabel}`}>
                Message
              </div>
              <div className={`${styles.Text} ${styles.messageText}`}>
                {currIssue.message}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openReplyBox}>
        <Box sx={replyBox}>
          <Grid className={styles.replyContainer} container spacing={2}>
            <Grid item xs={11}>
              <div className={styles.replyHeader}>Reply Report</div>
            </Grid>
            <Grid item xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={handleCloseReplyBox}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={styles.messageBox}>
                <label className={styles.messageLabel}>Message</label>
                <div className={`${styles.Text} ${styles.messageText}`}>
                  {currIssue.message}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Reply Message</label>
                <div className={`${styles.customField}`}>
                  <textarea
                    type="text"
                    value={replyMessage}
                    className={`${styles.inputField}`}
                    onChange={handleInputReplyMessage}
                  />
                </div>
                {errorMsg !== null ? (
                  <div className={styles.errorMsg}>{errorMsg}</div>
                ) : null}
              </div>
            </Grid>
            <Grid xs={6}>
              <button
                onClick={() => {
                  handleOpenConfirm();
                }}
                className={styles.btnGroup}
              >
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button onClick={handleCloseReplyBox} className={styles.btnGroup}>
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={openConfirmModal}
        closeModal={handleCloseConfirm}
        warningText={
          "Are you sure want to reply the issue?\nThis action cannot be undone."
        }
        handleSubmitData={handleSubmitReply}
      />
    </>
  ) : null;
};

export default ModalIssue;
