import React, { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import styles from "./edit_role_menu_user.module.css";
import { Box } from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from "@mui/icons-material/Circle";
import { useRef } from "react";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400";
import { useParams } from "react-router-dom";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const EditRoleMenuUser = ({ titlePage }) => {
  const { role_name, role_id } = useParams();

  const styleBox = {
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "20px",
    padding: "20px 16px",
    marginTop: "20px",
    boxShadow: "0px 4px 10px #0000001a",
  };

  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [roleMenu, setRoleMenu] = useState({});
  const [warningModal, setWarningModal] = useState(false);
  const [currRoleMenu, setCurrRoleMenu] = useState([]);
  const [warningMsg, setWarningMsg] = useState(null);
  const menuLength = useRef(0);

  const createRoleMenu = () => {
    console.log(menuList);
    const newRoleMenu = {};

    Object.keys(menuList).forEach((menu) => {
      menuList[menu].forEach((list, childIdx) => {
        newRoleMenu[list.id] = {
          id_role_menu: IDRoleMenuFinder(list.id),
          id_menu: list.id,
          id_role: role_id,
          is_visible: checkVisibility(list.id),
          name: list.name_menu,
        };
        console.log("no", list.id);
        console.log("name:", newRoleMenu[list.id].name);
        console.log("is_visible:", checkVisibility(list.id));
      });
    });
    setRoleMenu(newRoleMenu);
  };

  const IDRoleMenuFinder = (menuID) => {
    let idRoleMenu = null;
    if (currRoleMenu && Array.isArray(currRoleMenu)) {
      currRoleMenu.forEach((menu) => {
        // console.log(menu.id_menu, menuID);
        if (menu.id_menu === menuID) {
          idRoleMenu = menu.id;
          return;
        }
      });
    }
    return idRoleMenu;
  };

  const checkVisibility = (menuID) => {
    let isVisible = true;
    if (currRoleMenu && Array.isArray(currRoleMenu)) {
      currRoleMenu.forEach((menu) => {
        // console.log(menu.id_menu, menuID);
        if (menu.id_menu === menuID) {
          console.log(menu.is_visible);
          isVisible = menu.is_visible;
          return;
        }
      });
    }
    return isVisible;
  };

  const textFormater = (string) => {
    string = string.replace(`_`, " ");
    return string
      .split(" ")
      .map((char) => char.charAt(0).toUpperCase() + char.slice(1))
      .join(" ");
  };

  const handleChangeVisibility = (id, value) => {
    setRoleMenu((prevRoleMenu) => ({
      ...prevRoleMenu,
      [id]: {
        ...prevRoleMenu[id],
        is_visible: value,
      },
    }));
    console.log("isVisible " + id + ": " + roleMenu[id].is_visible);
  };

  const platformSpliter = (listMenu) => {
    if (!Array.isArray(listMenu)) {
      return;
    }
    var platformList = [];
    var formattedMenu = {};
    listMenu.map((menu) => {
      let num = 0;
      if (!platformList.includes(menu.platform_type)) {
        platformList[num] = menu.platform_type;
        num++;
        formattedMenu[menu.platform_type] = [
          {
            id: menu.id,
            name_menu: menu.name_menu,
          },
        ];
      } else {
        formattedMenu[menu.platform_type].push({
          id: menu.id,
          name_menu: menu.name_menu,
        });
      }
    });
    setMenuList(formattedMenu);
    console.log(formattedMenu);
  };

  const getRoleMenuUser = async () => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(
        `${baseURL}/role-menu/?id_role=${role_id}`,
        { headers }
      );
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        setCurrRoleMenu(response.data.result[0].data);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const getListMenu = async () => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${baseURL}/menu`, { headers });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        menuLength.current = response.data.result[0].data.length;
        platformSpliter(response.data.result[0].data);
        // setMenuList(response.data.result[0].data);

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getAPI = async () => {
    await getListMenu();
    await getRoleMenuUser();
    setIsLoading(false);
  };

  const editAllRoleMenu = async () => {
    console.log(role_id);
    console.log(roleMenu);
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    const requests = [];
    // Convert roleMenu to an array
    const roleMenuArray = Object.values(roleMenu);

    roleMenuArray.forEach((list) => {
      console.log(list);
      var request;
      if (list.id_role_menu === null) {
        request = axios.post(
          `${baseURL}/role-menu`,
          {
            id_menu: `${list.id_menu}`,
            id_role: `${role_id}`,
            is_visible: `${list.is_visible}`,
          },
          { headers }
        );
      } else {
        request = axios.put(
          `${baseURL}/role-menu`,
          {
            id: `${list.id_role_menu}`,
            id_menu: `${list.id_menu}`,
            id_role: `${role_id}`,
            is_visible: `${list.is_visible}`,
          },
          { headers }
        );
      }
      console.log(request);
      requests.push(request);
    });

    console.log(requests);
    try {
      const responses = await Promise.all(requests);
      requests.map((e) => console.log(e));
      console.log(responses);
      console.warn("All requests succeeded");
      return true;
    } catch (error) {
      console.warn("Some requests failed");
      console.error(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const response = await editAllRoleMenu();
    if (response) {
      setAddSuccessModal(true);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  useEffect(() => {
    if (menuList && currRoleMenu) {
      console.log("im here");
      createRoleMenu();
      console.log(roleMenu);
    }
    setIsLoading2(false);
  }, [menuList, currRoleMenu]);

  const [confirmModal, setConfirmModal] = useState(false);
  console.log(menuList);
  return !isLoading && !isLoading2 ? (
    <>
      <div className="newrolecms">
        {titlePage}
        <Box sx={styleBox}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <label className="textLabelNewRoleUserMobile">Role Name</label>

              <div className="inputContainerNewRoleUserMobile">{role_name}</div>
              <div style={{ display: `flex`, marginTop: `25px` }}>
                {Object.keys(menuList).map((menu, parentIdx) => (
                  <>
                    <br />
                    <div key={menu}>
                      <h3>{textFormater(menu)}</h3>
                      <br />
                      {menuList[menu]
                        .filter(
                          (menu) =>
                            menu.name_menu.toLowerCase() != "about-us" &&
                            menu.name_menu.toLowerCase() != "edit-profile"
                        )
                        .map((list, childIdx) => (
                          <FormControl
                            style={{
                              marginRight: `20px`,
                              marginBottom: `25px`,
                            }}
                            key={list.id}
                          >
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              style={{ textDecoration: `underline` }}
                            >
                              {textFormater(list.name_menu)}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={true}
                              value={roleMenu[list.id].is_visible}
                              name="radio-buttons-group"
                              onChange={(e) => {
                                console.log(e.target.value);
                                handleChangeVisibility(list.id, e.target.value);
                                console.log(roleMenu);
                              }}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Show"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Hide"
                              />
                            </RadioGroup>
                            <hr />
                          </FormControl>
                        ))}
                    </div>
                  </>
                ))}
              </div>
            </Grid>
            <Grid className={styles.btnContainer} item xs={12}>
              <button
                onClick={() => setConfirmModal(true)}
                className="saveButtonNewRoleUser"
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Box>
      </div>
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => setAddSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          await handleSubmit();
        }}
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
      />
    </>
  ) : (
    <div>loading...</div>
  );
};

export default EditRoleMenuUser;
