// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export_import_btn_TitleButton__EfaGQ{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--color_red-primer);
    border-radius: 10px;
    height: 40px;
    padding: 6px 12px;
    min-width: 200px;    
    cursor: pointer;
}

.export_import_btn_TitleButton__EfaGQ:hover{
    background-color: #F1F1F1;
}

.export_import_btn_TitleButtonText__QPMO1{
    color: var(--color_red-primer);
    font-family: "SF Pro Display-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
}

.export_import_btn_DownloadIcon__Mf5HR{
    scale: 1.5;
    margin-right: 5px;
    margin-left: auto;
    color: var(--color_red-primer);
}`, "",{"version":3,"sources":["webpack://./src/components/button/export_import_btn/export_import_btn.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;IAC9B,gDAAgD;IAChD,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,8BAA8B;AAClC","sourcesContent":[".TitleButton{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff;\n    border: 1px solid;\n    border-color: var(--color_red-primer);\n    border-radius: 10px;\n    height: 40px;\n    padding: 6px 12px;\n    min-width: 200px;    \n    cursor: pointer;\n}\n\n.TitleButton:hover{\n    background-color: #F1F1F1;\n}\n\n.TitleButtonText{\n    color: var(--color_red-primer);\n    font-family: \"SF Pro Display-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    margin-right: 10px;\n}\n\n.DownloadIcon{\n    scale: 1.5;\n    margin-right: 5px;\n    margin-left: auto;\n    color: var(--color_red-primer);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleButton": `export_import_btn_TitleButton__EfaGQ`,
	"TitleButtonText": `export_import_btn_TitleButtonText__QPMO1`,
	"DownloadIcon": `export_import_btn_DownloadIcon__Mf5HR`
};
export default ___CSS_LOADER_EXPORT___;
