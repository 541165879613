import styles from "./export_import_btn.module.css";
import { BiExport } from "react-icons/bi";
import { BiImport } from "react-icons/bi";

const ExportImportBtn = ({ onClick, btnType, addStyle, string }) => {
  return (
    <button style={addStyle} className={styles.TitleButton} onClick={onClick}>
      {string ? (
        <div className={styles.TitleButtonText}>{string}</div>
      ) : (
        <div className={styles.TitleButtonText}>{btnType} as CSV</div>
      )}
      {btnType === `Export` ? (
        <BiExport className={styles.DownloadIcon} />
      ) : (
        <BiImport className={styles.DownloadIcon} />
      )}
    </button>
  );
};

export default ExportImportBtn;
