import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./editcategorymodel.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  // maxHeight: "357px",
};

const EditCategoryModel = ({ isOpen, onClose, editedModel, onSave }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modelName, setModelName] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturers, setManufacturers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [modelNameError, setModelNameError] = useState("");

  useEffect(() => {
    if (editedModel) {
      setModelName(editedModel.category);
      setIsActive(editedModel.status === "Active");
      if (editedModel.manufactures && Array.isArray(manufacturers)) {
        const matchedManufacturer = manufacturers.find(
          (manufacturer) =>
            manufacturer.name_manufacturer === editedModel.manufactures
        );
        if (matchedManufacturer) {
          setManufacturer(matchedManufacturer.id);
        }
      }
    }

    console.log(editedModel);

    axios
      .get(`${baseURL}/manufacturer`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          const sortedManufacturers = response.data.result[0].data.sort(
            (a, b) => a.name_manufacturer.localeCompare(b.name_manufacturer)
          );
          setManufacturers(sortedManufacturers);
          console.log(response.data.result[0].data);
        } else {
          console.error(
            "Data manufaktur yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data manufaktur:", error);
      });
  }, [editedModel]);

  const handleOpenModal = () => {
    if (validateModelName()) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSave = () => {
    if (validateModelName()) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");

      const isDeleted = isActive ? "false" : "true";

      const newModelData = {
        id: editedModel.id,
        name_car_model: modelName,
        id_manufacturer: manufacturer,
        isDeleted: isDeleted,
      };

      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };

      onSave(newModelData, headers);
      setOpenModal(false);
    }
  };

  const validateModelName = () => {
    if (modelName.trim() === "") {
      setModelNameError("Please enter a Model Name");
      return false;
    }
    setModelNameError("");
    return true;
  };

  const handleModelNameChange = (e) => {
    // if (e.target.value.trim() === "") {
    //   setModelNameError("Please enter a Model Name");
    // } else {
    //   setModelNameError("");
    // }
    setModelName(e.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setModelNameError("");
    }
  }, [isOpen]);

  console.log(manufacturer);
  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>EDIT MODEL</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxWidth: "491px",
              maxHeight: "307px",
              overflow: "auto",
            }}
          >
            <form>
              <div className="form-group">
                <label htmlFor="productName" className={styles.categorynamenew}>
                  Model Name
                </label>
                <br></br>
                <input
                  type="text"
                  id="categoryName"
                  className={styles.productnamenew}
                  value={modelName}
                  onChange={handleModelNameChange}
                />
                {modelNameError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {modelNameError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="manufacturer" className="categorynamenew">
                  Manufacturer
                </label>
                <br />
                <select
                  id="manufacturer"
                  className={styles.manufacturernamenew}
                  value={manufacturer}
                  onChange={(e) => setManufacturer(e.target.value)}
                >
                  {manufacturers.map((manu) => (
                    <option key={manu.id} value={manu.id}>
                      {manu.name_manufacturer}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className={styles.labelstatusnew}>Status:</label>
                <br></br>
                <br></br>
                <label className={styles.radiogroupnew}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />{" "}
                  Active
                </label>
                <label className={styles.radiogroupnew2}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />{" "}
                  Non-Active
                </label>
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={handleOpenModal}
              className={styles.btnGroupNewCategoryProduct}
            >
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={onClose}
              className={styles.btnGroupNewCategoryProduct}
            >
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};
export default EditCategoryModel;
