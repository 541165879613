import { Modal, Box } from "@mui/material";
import styles from "./edit_admin_modal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../global_variable_.jsx";

const EditAdminModal = ({ isOpen, onClose, Update, editedAdmin, warning }) => {
  console.log(editedAdmin);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);

  useEffect(() => {
    setSuper(false);
    // console.clear();
    if (editedAdmin) {
      if (editedAdmin.id === 1) {
        setSuper(true);
      }
      console.log(editedAdmin);
      setAdminData({
        id_role_admin: editedAdmin.roleAdminID,
        name: editedAdmin.name,
        username: editedAdmin.username,
        isDeleted: editedAdmin.status !== "Active" ? true : false,
      });
      setIsActive(editedAdmin.status !== "Active" ? false : true);
      console.log(editedAdmin.status);
      console.log("isActive", isActive);
      console.log(adminData.isDeleted);
    }
    // setAdminData({
    //   name: "",
    //   username: "",
    //   id_role_admin: "",
    // });
    setErrorMsg({ nameError: null, usernameError: null });
    APICalls();
  }, [isOpen]);

  const APICalls = async () => {
    await getRoleAdmins();
  };

  const validateFields = () => {
    const nameIsValid = nameValidation();
    const usernameIsValid = usernameValidation();
    return nameIsValid && usernameIsValid;
  };

  const nameValidation = () => {
    if (adminData.name.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        nameError: "Please enter a Name.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, nameError: null }));
    return true;
  };

  const usernameValidation = () => {
    if (adminData.username.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        usernameError: "Please enter a Username.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, usernameError: null }));
    return true;
  };

  const getRoleAdmins = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    // console.clear();
    console.log("Fetching role admins...");
    try {
      let response = await axios.get(`${baseURL}/role-admin`, { headers });
      console.log(response.data);
      setRoleAdmins(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const editAdminData = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    // console.clear();
    console.log(adminData);

    try {
      const response = await axios.put(
        `${baseURL}/admin`,
        {
          id: editedAdmin.id,
          name: adminData.name,
          username: adminData.username,
          id_role_admin: adminData.id_role_admin,
          isDeleted: adminData.isDeleted,
        },
        { headers }
      );
      if (response.data.status === 200) {
        console.log(response);
        console.log("success");
        return true;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Username has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const [adminData, setAdminData] = useState({
    name: "",
    username: "",
    id_role_admin: "",
    isDeleted: null,
  });

  const [errorMsg, setErrorMsg] = useState({
    nameError: null,
    usernameError: null,
  });

  const [roleAdmins, setRoleAdmins] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isSuper, setSuper] = useState(false);

  const openConfirmModal = () => {
    if (!validateFields()) {
      return;
    }
    setConfirmModal(true);
  };

  const closeConfirm = () => {
    setConfirmModal(false);
  };

  const handleSubmit = async () => {
    const response = await editAdminData();
    if (response) {
      Update();
      onClose();
    }
  };

  const userNameInput = (string) => {
    var finalString = string.replace(/[^a-zA-Z]/g, "");
    return finalString.toLowerCase();
  };

  return (
    <>
      <Modal className={styles.Modal} open={isOpen}>
        <Box className={styles.editAdminModalContainer}>
          <div className={styles.Header}>
            <div className={styles.Title}>EDIT ADMIN </div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.adminNameContainer}>
              <label className={styles.Label}>Name</label>
              <input
                className={styles.adminNameField}
                type="text"
                value={adminData.name}
                onChange={(e) => {
                  setAdminData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
              />
              {errorMsg.nameError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.nameError}</div>
              ) : null}
            </div>
            <div className={styles.adminUsernameContainer}>
              <label className={styles.Label}>Username</label>
              <input
                className={styles.adminUsernameField}
                value={adminData.username}
                type="text"
                onChange={(e) => {
                  const finalString = userNameInput(e.target.value.trim());
                  setAdminData((prevData) => ({
                    ...prevData,
                    username: finalString,
                  }));
                }}
              />
              {errorMsg.usernameError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.usernameError}</div>
              ) : null}
            </div>
            <div className={styles.roleAdminContainer}>
              <label className={styles.Label}>Role Admin</label>
              <select
                disabled={isSuper}
                defaultValue="-1"
                value={adminData.id_role_admin}
                className={styles.roleAdminDropdown}
                name="id_role_admin"
                id="id_role_admin"
                onChange={(e) => {
                  setAdminData((prevData) => ({
                    ...prevData,
                    id_role_admin: e.target.value,
                  }));
                }}
              >
                <option disabled value="-1">
                  (Select)
                </option>
                {roleAdmins.map((roleAdmin) => {
                  if (roleAdmin.id !== 1) {
                    return (
                      <option key={roleAdmin.id} value={roleAdmin.id}>
                        {roleAdmin.role_name} {/* Comment here */}
                      </option>
                    );
                  } else {
                    return (
                      <option disabled key={roleAdmin.id} value={roleAdmin.id}>
                        {roleAdmin.role_name} {/* Comment here */}
                      </option>
                    );
                  }
                })}
              </select>
              {/* edit error message display for role admin if needed */}
            </div>
            <div className={styles.radioGroupContainer}>
              <label className={styles.Label}>Status</label>
              <div className={styles.radioGroupContent}>
                <div className={styles.radioActive}>
                  <input
                    type="radio"
                    name="status"
                    value={false}
                    checked={isActive}
                    onChange={(e) => {
                      console.log(e);
                      setIsActive(true);
                      setAdminData((prevData) => ({
                        ...prevData,
                        isDeleted: e.target.value,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Active</label>
                </div>
                <div className={styles.radioNonActive}>
                  <input
                    type="radio"
                    name="status"
                    value={true}
                    checked={!isActive}
                    onChange={(e) => {
                      console.log(e);
                      setIsActive(false);
                      setAdminData((prevData) => ({
                        ...prevData,
                        isDeleted: e.target.value,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Non-Active</label>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.Button}
                onClick={async () => {
                  // console.clear();
                  console.log(adminData.isDeleted);
                  if (
                    adminData.id_role_admin === 1 &&
                    (adminData.isDeleted === true || isActive === false)
                  ) {
                    warning();
                    return;
                  }
                  openConfirmModal();
                }}
              >
                Save
              </button>
              <button className={styles.Button} onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirm}
        handleSubmitData={handleSubmit}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
    </>
  );
};

export default EditAdminModal;
