import { Box, Modal } from "@mui/material";
import styles from "./change_email_or_password_modal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import ShowPassBtn from "../../components/button/show_pass_btn/show_pass_btn";
import axios from "axios";
import { baseURL } from "../../global_variable_";
import validator from "validator";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400";

const ChangeEmailOrPasswordModal = ({
  isOpen,
  onClose,
  passwordOrEmail,
  iD,
  Update,
  previousValue,
}) => {
  // console.log(iD);
  const [errorMsg, setErrorMsg] = useState(null);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listRoles, setListRoles] = useState([]);
  const [role, setRole] = useState(null);

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const emailValidation = () => {
    //isEmpty
    if (email.trim().length === 0) {
      setErrorMsg("Please enter an Email.");
      return false;
    }
    //maxChar
    if (email.trim().length > 30) {
      setErrorMsg("Email must be no more than 30 characters.");
      return false;
    }
    //isNotEmail
    if (!validator.isEmail(email.trim())) {
      setErrorMsg("Input Email in this field.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const roleValidation = () => {
    //isEmpty
    if (role === null) {
      setErrorMsg("Please pick a role");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const passwordValidation = () => {
    if (password.length === 0) {
      setErrorMsg("Please enter a Password.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const handlePasswordInput = (string) => {
    var finalString = string.trim();
    return finalString;
  };

  const [showPass, setShowPass] = useState(`password`);
  const toggleShowPass = () => {
    showPass === `password` ? setShowPass(`text`) : setShowPass(`password`);
  };

  const getRoles = async () => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get(`${baseURL}/role`, { headers });
      if (response.data.status === 200) {
        // console.log(response.data.result[0].data);
        setListRoles(response.data.result[0].data);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const generateBody = async () => {
    switch (passwordOrEmail.toLowerCase()) {
      case `password`: {
        return { id: iD, new_password: `${password}` };
      }
      case `email`: {
        return { id: iD, email: `${email}` };
      }
      case `role`: {
        return { id_user: iD, id_role: `${role}` };
      }
    }
  };

  const generateParam = async () => {
    switch (passwordOrEmail.toLowerCase()) {
      case `password`: {
        return "admin/change-password";
      }
      case `email`: {
        return "user/change-email";
      }
      case `role`: {
        return "user/role";
      }
    }
  };

  const updateData = async () => {
    const body = await generateBody();
    const param = await generateParam();

    const headers = {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem(`currAdmin`)).token
      }`,
    };

    console.log(param);
    console.log(body);
    try {
      const response = await axios.put(`${baseURL}/${param}`, body, {
        headers,
      });
      console.log(response);
      if (response.data.status === 200 || response.data.status === 201) {
        if (Update) {
          Update();
        }
        console.log(response.data);
        setAddSuccessModal(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      if (error.response.data.status === 409) {
        setWarningMsg("Please enter a unique Email, Email already used.");
        setWarningModal(true);
      } else {
        setWarningMsg(`Failed to update ${titleToLabel()}, try again later.`);
        setWarningModal(true);
      }
      return false;
    }
  };

  const handleBtnSaveClick = () => {
    let validity = true;
    switch (passwordOrEmail.toLowerCase()) {
      case `password`: {
        validity = passwordValidation();
        break;
      }
      case `email`: {
        validity = emailValidation();
        break;
      }
      case `role`: {
        validity = roleValidation();
        break;
      }
    }
    if (validity) {
      handleOpenConfirmModal();
    }
  };

  const titleToLabel = () => {
    var string =
      passwordOrEmail.charAt(0).toUpperCase() + passwordOrEmail.slice(1);
    return string;
  };

  useEffect(() => {
    APICalls();
    setErrorMsg(null);
    // setRole(null);
    setPassword("");
    setEmail("");
  }, [isOpen]);

  const APICalls = async () => {
    await getRoles();
    setIsLoading(false);
  };

  const titleModal = () => {
    return `CHANGE ${passwordOrEmail.toUpperCase()}`;
  };

  const currentValue = () => {
    return passwordOrEmail.toLowerCase() !== "password" ? (
      <div className={styles.prevValueContainer}>
        <div className={styles.prevValueLabel}>
          CURRENT {passwordOrEmail.toUpperCase()}:
        </div>
        {previousValue}
      </div>
    ) : null;
  };

  const contentModal = () => {
    switch (passwordOrEmail.toLowerCase()) {
      case `email`: {
        return (
          <div className={styles.inputBox}>
            <input
              className={styles.passwordField}
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        );
      }
      case `password`: {
        return (
          <div className={styles.inputBox}>
            <input
              className={styles.passwordField}
              type={showPass}
              value={password}
              onChange={(e) => {
                setPassword(handlePasswordInput(e.target.value));
              }}
            />
            <ShowPassBtn
              onClick={toggleShowPass}
              state={showPass}
              inputStyle={{ marginRight: `10px` }}
            />
          </div>
        );
      }
      case `role`: {
        return (
          <div className={styles.inputBox}>
            <select
              defaultValue={-1}
              className={styles.dropDownRole}
              name=""
              id=""
              onChange={(e) => {
                setRole(e.target.value);
                console.log(role);
              }}
            >
              <option value={-1} disabled>
                -- Please pick a role --
              </option>
              {Array.isArray(listRoles) ? (
                listRoles.map((role) => {
                  return (
                    <option value={role.id} key={role.id}>
                      {role.role_name}
                    </option>
                  );
                })
              ) : (
                <option value={null} disabled>
                  empty
                </option>
              )}
            </select>
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  return !isLoading ? (
    <>
      <Modal className={styles.Modal} open={isOpen}>
        <Box className={styles.boxModal}>
          <div className={styles.Header}>
            <div className={styles.Title}>{titleModal()}</div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.mainContent}>
            {currentValue()}
            <div className={styles.Container}>
              <label className={styles.Label}>{titleToLabel()}</label>
              {contentModal()}
              {errorMsg !== null ? (
                <div className={styles.errorMsg}>{errorMsg}</div>
              ) : null}
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.Button} onClick={handleBtnSaveClick}>
                Save
              </button>
              <button className={styles.Button} onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={openConfirmModal}
        handleSubmitData={updateData}
        closeModal={handleCloseConfirmModal}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => {
          setAddSuccessModal(false);
          onClose();
        }}
        warningText={"Data Updated Succsesfully"}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
    </>
  ) : null;
};

export default ChangeEmailOrPasswordModal;
