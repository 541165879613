import React from "react";
import eyeIcon from "./eyeIcon.svg";
import "./customIcon.css";

const CustomEye = ({ onClick }) => (
    <div className="custom-icon">
    <button className="icon-button" onClick={onClick}>
      <img src={eyeIcon} alt="Eye Icon" />
    </button>
  </div>
);

export default CustomEye;