import styles from "./user.module.css";

const User = ({userCount}) => {
  // console.log(userCount)
  return (
    <div className={styles.userContainer}>
      <div className={styles.Number}>{userCount}</div>
      <div className={styles.String}>All Users</div>
    </div>
  );
};

export default User;
