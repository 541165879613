import { useState } from "react";
import NewsletterSubscribers from "../../components/NewsletterComponent/NewsletterSubscribers";
import styles from "./Newsletter.module.css";
import Grid from '@mui/material/Unstable_Grid2';


export default function Newsletter({ titlePage }) {

    return (
     <div className={styles.NewsPage}>
        {titlePage}
        <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} className={styles.componentTab}>
                <NewsletterSubscribers />
            </Grid>
        </Grid>
     </div>   
    )
}