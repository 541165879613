import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./edit_role_modal.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  width: "491px",
};

const EditRole = ({
  isOpen,
  onClose,
  id,
  roleNamE,
  isDeleteD,
  Update,
  version,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  const APICalls = async () => {
    await getMenuList();
    setLoading(false);
  };

  //To Be Continued
  const getRoleByID = async () => {
    try {
      const response = await axios.get(`${baseURL}/`);
    } catch (error) {
      console.error(error);
    }
  };

  const [listMenu, setListMenu] = useState({});

  const getMenuList = async () => {
    try {
      const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`${baseURL}/menu`, { headers });
      if (response.data.status === 200) {
        // if (Array.isArray(response.data.result[0].data)) {
        //   response.data.result[0].data.map((e) => {
        //     console.log(e);
        //   });
        // }
        setListMenu(response.data.result[0].data);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateRole = async (iD, ver) => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      if (ver === "user") {
        const response = await axios.put(
          `${baseURL}/role`,
          { id: `${iD}`, role_name: `${roleName}`, isDeleted: `${isDeleted}` },
          { headers }
        );
        if (response.data.status === 201) {
          console.warn("success updating : )");
          setEditSuccessModal(true);
          return true;
        }
      } else {
        const response = await axios.put(
          `${baseURL}/role-admin`,
          { id: `${iD}`, role_name: `${roleName}`, isDeleted: `${isDeleted}` },
          { headers }
        );
        if (response.data.status === 201) {
          console.warn("success updating :)");
          return true;
        }
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Role Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.error(error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const response = await updateRole(id, version);
    if (response) {
      setEditSuccessModal(true);
      Update();
      onClose();
    }
  };

  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [isDeleted, setIsDeleted] = useState(null);

  useEffect(() => {
    // if (id && roleName && isDeleted) {
    setRoleName(roleNamE);
    setIsDeleted(isDeleteD);
    // }
    APICalls();
  }, [isOpen]);

  return !isLoading ? (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>EDIT ROLE</div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.bodyRoleName}>
              <label className={styles.Label} htmlFor="">
                Role Name
              </label>
              <input
                className={styles.inputField}
                type="text"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
            <div className={styles.modalRadioGroup}>
              <label className={styles.Label}>Status</label>
              <div className={styles.radioGroupContent}>
                <div className={styles.radioActive}>
                  <input
                    type="radio"
                    name="status"
                    // value={!isDeleted}
                    checked={!isDeleted}
                    onChange={() => {
                      setIsDeleted(false);
                    }}
                  />
                  <label className={styles.radioLabel}>Active</label>
                </div>
                <div className={styles.radioNonActive}>
                  <input
                    type="radio"
                    name="status"
                    // value={isDeleted}
                    checked={isDeleted}
                    onChange={() => {
                      setIsDeleted(true);
                    }}
                  />
                  <label className={styles.radioLabel}>Non-Active</label>
                </div>
              </div>
            </div>
            {/* <div className={styles.bodyMenuList}>
              {listMenu.map((menu) => (
                <div key={menu.id}>test:{menu.name_menu}</div>
              ))}
            </div> */}
            <div className={styles.btnContainer}>
              <button
                className={styles.saveBtn}
                onClick={() => setConfirmModal(true)}
              >
                Save
              </button>
              <button className={styles.saveBtn} onClick={onClose}>
                Back
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
        handleSubmitData={handleSubmit}
      />
      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => setWarningModal(false)}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
    </>
  ) : (
    <div>loading...</div>
  );
};

export default EditRole;
