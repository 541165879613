// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_PageNotFound__MAvTF{ 
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.PageNotFound_PageNotFound__MAvTF img{ 
    height: 80px;
    width: 80px;
}

.PageNotFound_bigText__qUf-t{
    margin-top: 30px;
    text-align: center;
    color: var(--color_gray);
    font-family: "SF Pro Display-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
}
.PageNotFound_smallText__cA2nI{
    margin-top: 10px;
    text-align: center;
    color: var(--color_gray);
    font-family: "SF Pro Display-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/PageNotFound/PageNotFound.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;IACxB,6CAA6C;IAC7C,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;IACxB,gDAAgD;IAChD,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".PageNotFound{ \n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n}\n\n.PageNotFound img{ \n    height: 80px;\n    width: 80px;\n}\n\n.bigText{\n    margin-top: 30px;\n    text-align: center;\n    color: var(--color_gray);\n    font-family: \"SF Pro Display-Bold\", Helvetica;\n    font-size: 16px;\n    font-weight: 700;\n}\n.smallText{\n    margin-top: 10px;\n    text-align: center;\n    color: var(--color_gray);\n    font-family: \"SF Pro Display-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageNotFound": `PageNotFound_PageNotFound__MAvTF`,
	"bigText": `PageNotFound_bigText__qUf-t`,
	"smallText": `PageNotFound_smallText__cA2nI`
};
export default ___CSS_LOADER_EXPORT___;
