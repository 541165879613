import axios from "axios";
import { CurrentDateReverse } from "./CurrentDateReverse";

export async function DownloadDataCsv (title, link) {
    try {
        const response = await axios.get(link);
        const csvContent = response.data;
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}_${CurrentDateReverse()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (e) {
        console.error(e) 
    }
}