import { useEffect, useState } from "react";
import "./cmsuser.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import SearchIcon from "@mui/icons-material/Search";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import TitleBar from "../../components/titleBar/TitleBar";
import { Diversity1, SearchOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddAdminModal from "../CmsAdd/add_admin_modal";
import EditAdminModal from "../CmsEdit/edit_admin_modal";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ChangeEmailOrPasswordModal from "../change_email_or_password_modal/change_email_or_password_modal";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
  width: "97%",
};

export default function CmsUser(props) {
  useEffect(() => {
    getCurrAdmin();
    APICalls();
  }, []);
  const [currAuth, setCurrAuth] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [admins, setAdmins] = useState([]);
  const [editedAdmin, setEditedAdmin] = useState(null);
  const [name, setname] = useState("");
  const [totalAdmin, setTotalAdmin] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filteredAdmins, setFilteredAdmins] = useState(admins);
  const [deleteModal, setDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
  const [isEditAdminModalOpen, setIsEditAdminModalOpen] = useState(false);
  const [warningModal, setWarning] = useState(false);
  const [changePassIDAdmin, setChangePassIDAdmin] = useState(null);

  //Change Password Modal
  const [openChangePassModal, setOpenChangePassModal] = useState(false);
  const handleOpenChangePassModal = (id) => {
    setChangePassIDAdmin(id);
    setOpenChangePassModal(true);
  };
  const handleCloseChangePassModal = () => {
    setOpenChangePassModal(false);
  };

  const handleSearchInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    const filtered = admins.filter(
      (admin) =>
        admin.name.toLowerCase().includes(input.toLowerCase()) ||
        admin.username.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredAdmins(filtered);
  };

  const filterAndFormatAdmins = () => {
    const filtered = admins.filter(
      (admin) =>
        admin.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        admin.username.toLowerCase().includes(searchInput.toLowerCase())
    );

    return filtered.map((admin) => ({
      id: admin.id,
      name: admin.name,
      username: admin.username,
      roleName: admin.role_name,
      roleAdminID: admin.role_admin.id,
      status: admin.isDeleted ? "Non-Active" : "Active",
    }));
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const APICalls = async () => {
    await getAdmins();
    setIsLoading(false);
  };

  const openAddAdminModal = () => {
    setIsAddAdminModalOpen(true);
  };

  const openEditAdminModal = (user) => {
    setEditedAdmin(user);
    setIsEditAdminModalOpen(true);
  };

  const getCurrAdmin = () => {
    const roleName = JSON.parse(localStorage.getItem(`currAdmin`)).role;
    if (roleName === "Super Admin") {
      setCurrAuth(true);
    } else {
      setCurrAuth(false);
    }
  };

  const getAdmins = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("currAdmin")).token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${baseURL}/admin?all_data=true`, {
        headers,
      });
      const totalAdmin = response.data.result[0].totalCount;
      console.log(response);
      console.log(token);
      setTotalAdmin(totalAdmin);
      setAdmins(response.data.result[0].data);
      console.log(response.data.result[0].totalCount);
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteAdmin = async (adminId) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    console.log(token);
    console.log(headers);
    try {
      const response = await axios.delete(`${baseURL}/admin/${adminId}`, {
        headers,
      });
      if (response.data.status === 200) {
        getAdmins();
        console.log("Admin deleted successfully");
      }
    } catch (error) {
      console.error("Failed to delete admin:", error);
    }
  };

  useEffect(() => {
    const storedname = localStorage.getItem("name");
    if (storedname) {
      setname(storedname);
    }
    getAdmins();
  }, []);

  useEffect(() => {
    const filtered = admins.filter((admin) =>
      admin.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredAdmins(filtered);
  }, [searchInput, admins]);

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) =>
        params.row.roleAdminID === 1 ? (
          <CustomDelete
            itemId={params.row.id}
            onDelete={deleteAdmin}
            closeModal={closeDeleteModal}
            Update={APICalls}
            enabled={false}
            warning={() => {
              setWarningMsg("Not Allowed to Delete This Role.");
              setWarning(true);
            }}
          />
        ) : (
          <CustomDelete
            itemId={params.row.id}
            onDelete={deleteAdmin}
            closeModal={closeDeleteModal}
            Update={APICalls}
          />
        ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit
          itemId={params.row.id}
          onClick={() => {
            openEditAdminModal(params.row);
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 5,
      headerClassName: "customColumnHeader",
    },
    {
      field: "username",
      headerName: "Username",
      flex: 5,
      headerClassName: "customColumnHeader",
    },
    {
      field: "roleName",
      headerName: "Role",
      flex: 3,
      headerClassName: "customColumnHeader",
    },

    {
      field: "status",
      headerName: "Status",
      flex: 3,
      padding: "5",
      headerClassName: "customColumnHeader",
      renderCell: (params) => {
        const cellColor = params.value === "Active" ? "greenCell" : "redCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
    {
      flex: 3,
      padding: 5,
      headerClassName: "customColumnHeader",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: `100%`,
              height: `100%`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <button
              onClick={() => {
                handleOpenChangePassModal(params.row.id);
              }}
              className="changePassBtn"
            >
              Change Password
            </button>
          </div>
        );
      },
    },
  ];

  return isLoading === false ? (
    currAuth === true ? (
      <>
        <div className="cmsuserList">
          {props.titlePage}
          <Box sx={styleBox}>
            <div className="cmsuserGridBar">
              <div className="totalcmsuser">
                {totalAdmin > 1
                  ? `${totalAdmin} admins`
                  : `${totalAdmin} admin`}
              </div>
              <div className="topContent">
                <div className="adminBtnContainer">
                  <button
                    className="newadminbutton"
                    onClick={openAddAdminModal}
                  >
                    Add New Admin
                  </button>
                  <AddIcon className="addIcon" />
                </div>
                <div className="SearchBarcmsuser">
                  <input
                    className="SearchBoxcmsuser"
                    placeholder="Search admin"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                  <div className="searchIcon">
                    <SearchIcon />
                  </div>
                </div>
              </div>
            </div>
            <DataGrid
              className="cmsusertable"
              rows={filterAndFormatAdmins()}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }],
                },
              }}
              pageSizeOptions={[5, 10]}
              getRowClassName={() => "customRow"}
              getCellClassName={() => "customCell"}
            />
            <WarningFileSizeModal
              closeModal={() => setWarning(false)}
              openModal={warningModal}
              warningText={warningMsg}
            />
            <AddAdminModal
              isOpen={isAddAdminModalOpen}
              onClose={() => setIsAddAdminModalOpen(false)}
              Update={APICalls}
            />
            <EditAdminModal
              warning={() => {
                setWarningMsg("Not Allowed to Delete This Role.");
                setWarning(true);
              }}
              isOpen={isEditAdminModalOpen}
              onClose={() => setIsEditAdminModalOpen(false)}
              Update={APICalls}
              editedAdmin={editedAdmin}
            />
          </Box>
        </div>
        <ChangeEmailOrPasswordModal
          isOpen={openChangePassModal}
          onClose={handleCloseChangePassModal}
          passwordOrEmail="password"
          iD={changePassIDAdmin}
        />
      </>
    ) : (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        You are not authorized to view this page
      </div>
    )
  ) : (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      loading...
    </div>
  );
}
