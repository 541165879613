// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newformula_modalTitle__LF4Bl{
    color: var(--color_red-primer);
    font-family: "SF Pro Display-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
}

.newformula_closeIcon__J7DKq{
    color: var(--color_red-primer);
    cursor: pointer;
}

.newformula_formGroup__tVbGs{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.newformula_textLabel__fc57S{
    color: var(--color_gray);
    font-family: "SF Pro Display-Semibold", Helvetica;
    font-size: 16px;
    font-weight: 400;
}

.newformula_customField__RKAe4{
    margin-top: 5px;
    background-color: var(--color_white);
    border: 1px solid;
    border-color: var(--color_red-primer);
    border-radius: 10px;
    height: 50px;
    padding: 0 10px;
}


.newformula_btnGroup__Ap8mG{
    width: 100%;
    padding: 6px 12px;
    border-radius: 10px;
    background: var(--color_red-primer);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
    cursor: pointer;
    color: var(--color_white);
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/newformula/newformula.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6CAA6C;IAC7C,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;IACxB,iDAAiD;IACjD,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,oCAAoC;IACpC,iBAAiB;IACjB,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,mCAAmC;IACnC,gDAAgD;IAChD,eAAe;IACf,yBAAyB;IACzB,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".modalTitle{\n    color: var(--color_red-primer);\n    font-family: \"SF Pro Display-Bold\", Helvetica;\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.closeIcon{\n    color: var(--color_red-primer);\n    cursor: pointer;\n}\n\n.formGroup{\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n}\n\n.textLabel{\n    color: var(--color_gray);\n    font-family: \"SF Pro Display-Semibold\", Helvetica;\n    font-size: 16px;\n    font-weight: 400;\n}\n\n.customField{\n    margin-top: 5px;\n    background-color: var(--color_white);\n    border: 1px solid;\n    border-color: var(--color_red-primer);\n    border-radius: 10px;\n    height: 50px;\n    padding: 0 10px;\n}\n\n\n.btnGroup{\n    width: 100%;\n    padding: 6px 12px;\n    border-radius: 10px;\n    background: var(--color_red-primer);\n    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);\n    cursor: pointer;\n    color: var(--color_white);\n    font-family: \"Inter-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `newformula_modalTitle__LF4Bl`,
	"closeIcon": `newformula_closeIcon__J7DKq`,
	"formGroup": `newformula_formGroup__tVbGs`,
	"textLabel": `newformula_textLabel__fc57S`,
	"customField": `newformula_customField__RKAe4`,
	"btnGroup": `newformula_btnGroup__Ap8mG`
};
export default ___CSS_LOADER_EXPORT___;
