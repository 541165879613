// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ModalError400_errorIconModal__s5kd6{
    color: var(--color_red-primer);
    width: 100% !important;
    margin: auto;
}

.ModalError400_confirmSubmitText__5c53d{
    color: var(--color_red-primer);
    font-family: "SF Pro Display-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
}

.ModalError400_okBtn__ruPJR {
  background-color: var(--color_red-primer);
  color: var(--color_white);
  border: none;
  width: 350px;
  font-family: "SF Pro Display-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid;
  border-radius: 10px;
  padding: 14px 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalError400/ModalError400.module.css"],"names":[],"mappings":";;AAEA;IACI,8BAA8B;IAC9B,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,gDAAgD;IAChD,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;EACE,yCAAyC;EACzC,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,6CAA6C;EAC7C,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":["\n\n.errorIconModal{\n    color: var(--color_red-primer);\n    width: 100% !important;\n    margin: auto;\n}\n\n.confirmSubmitText{\n    color: var(--color_red-primer);\n    font-family: \"SF Pro Display-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    text-align: center;\n    white-space: pre-line;\n}\n\n.okBtn {\n  background-color: var(--color_red-primer);\n  color: var(--color_white);\n  border: none;\n  width: 350px;\n  font-family: \"SF Pro Display-Bold\", Helvetica;\n  font-size: 14px;\n  font-weight: 700;\n  border: 1px solid;\n  border-radius: 10px;\n  padding: 14px 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorIconModal": `ModalError400_errorIconModal__s5kd6`,
	"confirmSubmitText": `ModalError400_confirmSubmitText__5c53d`,
	"okBtn": `ModalError400_okBtn__ruPJR`
};
export default ___CSS_LOADER_EXPORT___;
