import axios from "axios";
import { baseURL } from "../global_variable_";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import NotAuthorizedPage from "../pages/not_authorized_page/not_authorized_page";
import { useEffect } from "react";

const RoleProtectedRoutes = ({
  routeName,
  listRoleMenu,
  roleMenuFunc,
  setLoading,
}) => {
  const { component } = useParams();
  const navigate = useNavigate();
  console.log("component from RoleProtectedRoutes:", component);
  useEffect(() => {
    // console.clear();
    const roleMenuSetup = async () => {
      await roleMenuFunc();
      setLoading();
    };
    console.log("im here 1");
    if (roleMenuFunc) {
      console.log("im here 2");
      roleMenuSetup();
      // console.log(routeName);
      // roleMenuFunc();
    }
  }, []);

  const productSubMenuArr = ["category", "sub-category", "product", "favorite"];
  const colorSubMenuArr = ["color-category", "color"];
  let subMenuName = null;

  const colorNavigate = (subMenuName) => {
    if (subMenuName === "color-category") {
      navigate("/color");
    } else {
      navigate("/color-formula");
    }
  };

  const showHideTrueFalse = (isVisiblE) => {
    if (isVisiblE === true || isVisiblE === "true" || isVisiblE === "show") {
      return true;
    } else {
      return false;
    }
  };

  const formatRoleMenuName = (string) => {
    return string.replace(" ", "-").toLowerCase();
  };

  const parentMenuAuth = () => {
    let found = "false";
    console.log(listRoleMenu);
    listRoleMenu.map((roleMenu) => {
      if (roleMenu.name_menu === routeName) {
        found = showHideTrueFalse(roleMenu.is_visible).toString();
      }
    });
    console.log(routeName);
    if (routeName && routeName.toLowerCase().startsWith("color")) {
      checkOtherSubMenu("color");
      if (!showHideTrueFalse(found)) {
        checkAuthorization("color");
      } else {
        return "true";
      }
    }

    console.log(found);
    return found;
  };

  //SUB MENU
  const subMenuAuth = () => {
    console.log(component);
    var response = findSubMenu(component);
    console.log(response);
    if (response === null) {
      return "true";
    }
    checkOtherSubMenu("product");
    if (showHideTrueFalse(response.isVisible)) {
      return "true";
    } else {
      checkAuthorization("product");
    }
  };

  const checkOtherSubMenu = (parent) => {
    let idx = 0;
    let allowedComponents = [];
    let subMenuList =
      parent === "product" ? productSubMenuArr : colorSubMenuArr;
    while (idx < subMenuList.length) {
      const temp = findSubMenu(subMenuList[idx]);
      if (temp) {
        console.log(`Component: ${subMenuList[idx]}`);
        console.log(`IsVisible: ${temp.isVisible}`);
        if (showHideTrueFalse(temp.isVisible)) {
          allowedComponents.push(temp.nameMenu);
        }
      }
      idx++;
    }
    console.log(allowedComponents);
    localStorage.setItem(`${parent}AllowedComponents`, allowedComponents);
  };

  const checkAuthorization = (parent) => {
    var allowedComponents = [];
    if (localStorage.getItem(`${parent}AllowedComponents`)) {
      allowedComponents = localStorage
        .getItem(`${parent}AllowedComponents`)
        .split(",");
    }

    if (allowedComponents.length > 0) {
      parent === "product"
        ? navigate(`product/${allowedComponents[0]}`)
        : colorNavigate(allowedComponents[0]);
    }
    return "false";
  };

  const findSubMenu = (menu) => {
    let responseArr = null;
    listRoleMenu.map((roleMenu) => {
      if (formatRoleMenuName(roleMenu.name_menu) === menu) {
        responseArr = {
          nameMenu: formatRoleMenuName(roleMenu.name_menu),
          isVisible: roleMenu.is_visible,
        };
        console.log(responseArr);
      }
    });
    return responseArr;
  };

  if (routeName === undefined) {
    console.log(routeName);
    return <PageNotFound />;
  }
  if (listRoleMenu.length !== 0 || routeName === "Allow Me") {
    let auth = "false";
    if (routeName === "Allow Me") {
      auth = "true";
    }
    if (component !== undefined) {
      auth = subMenuAuth();
    } else if (routeName !== "Allow Me") {
      auth = parentMenuAuth();
    }

    if (showHideTrueFalse(auth)) {
      return <Outlet />;
    } else {
      return <NotAuthorizedPage />;
    }
  } else {
    return <NotAuthorizedPage />;
  }
};

export default RoleProtectedRoutes;
