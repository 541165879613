// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub_category_productSubPage__A-Pr9{
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
}

.sub_category_productSubPageBtnGroup__gCxHH{
    display: flex;
    flex-direction: row;
}
  
.sub_category_productSubPageBtnGroup__gCxHH button{
    margin-right: 15px;
}

.sub_category_subCategoryPageTable__QtJC2{
    margin-top: 20px;
}

.sub_category_activeTab__vyRuI{
    background-color: #FFC3C3;
    font-weight: 400;
    font-style: 14px;
    color: #BE1620;
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 102px;
    height: 38px;
    cursor: pointer;
}

.sub_category_productSubPageTableContainer__MaJQX{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 10px #0000001a;
  }`, "",{"version":3,"sources":["webpack://./src/components/product/sub_category_component/sub_category.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kCAAkC;EACpC","sourcesContent":[".productSubPage{\n    display: flex;\n    width: 100%;\n    padding: 20px;\n    flex-direction: column;\n}\n\n.productSubPageBtnGroup{\n    display: flex;\n    flex-direction: row;\n}\n  \n.productSubPageBtnGroup button{\n    margin-right: 15px;\n}\n\n.subCategoryPageTable{\n    margin-top: 20px;\n}\n\n.activeTab{\n    background-color: #FFC3C3;\n    font-weight: 400;\n    font-style: 14px;\n    color: #BE1620;\n    border: none;\n    border-radius: 10px;\n    padding: 10px;\n    width: 102px;\n    height: 38px;\n    cursor: pointer;\n}\n\n.productSubPageTableContainer{\n    display: flex;\n    flex-direction: column;\n    margin-top: 20px;\n    padding: 20px;\n    background-color: white;\n    border-radius: 20px;\n    box-shadow: 0px 4px 10px #0000001a;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productSubPage": `sub_category_productSubPage__A-Pr9`,
	"productSubPageBtnGroup": `sub_category_productSubPageBtnGroup__gCxHH`,
	"subCategoryPageTable": `sub_category_subCategoryPageTable__QtJC2`,
	"activeTab": `sub_category_activeTab__vyRuI`,
	"productSubPageTableContainer": `sub_category_productSubPageTableContainer__MaJQX`
};
export default ___CSS_LOADER_EXPORT___;
