import { useEffect, useState } from "react";
import styles from "./login.module.css";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import loginBackground from "../../icon/loginBackground.png";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ShowPassBtn from "../../components/button/show_pass_btn/show_pass_btn";
import { baseURL } from "../../global_variable_";

export default function Login() {
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({
    usernameError: null,
    passwordError: null,
    apiError: null,
  });
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const initialName = localStorage.getItem("name");
  const [name, setName] = useState(initialName || "");
  const [failModal, setFail] = useState(false);
  const [listRoleMenu, setListRoleMenu] = useState([]);
  const navigate = useNavigate();

  let linkURL = [
    "/dashboard",
    "/users",
    "/cms",
    "/role-user",
    "/role-cms",
    "/product",
    "/color",
    "/banner",
    "/about",
    "/newsletter",
    "/issue",
    "/contact",
  ];

  const linkName = [
    "Dashboard",
    "User",
    "CMS User",
    "Role User",
    "Role CMS",
    "Product Menu",
    "Color Category",
    "Banner",
    "About Us",
    "Newsletter",
    "Submit Issue",
    "Contact Us",
  ];

  var listRoleMenU = [];

  const getRoleAdminMenu = async () => {
    console.clear();
    const currAdmin = JSON.parse(localStorage.getItem(`currAdmin`));
    console.log(currAdmin);
    if (currAdmin) {
      const token = currAdmin.token;
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get(
          `${baseURL}/admin-role-menu/?id_role_admin=${currAdmin.id_role}`,
          { headers }
        );
        if (response.data.status === 200) {
          console.log(response.data.result[0].data);
          listRoleMenU = response.data.result[0].data;
          // setListRoleMenu(response.data.result[0].data);
          console.log(listRoleMenU);
          return true;
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          return [false, 500];
        }
        console.error(error);
        return [false];
      }
    }
  };

  const editLinkArray = () => {
    if (Array.isArray(listRoleMenU)) {
      listRoleMenU.forEach((listRole) => {
        console.log("include:", linkName.includes(listRole.name_menu));
        if (
          linkName.includes(listRole.name_menu) &&
          listRole.is_visible === "hide"
        ) {
          console.log("im in");
          var idxOfLink = linkName.indexOf(listRole.name_menu);
          linkURL.splice(idxOfLink, 1);
        }
      });
    }
  };

  const handleBtnPress = async () => {
    if (!isEmpty()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: null,
      }));
      return;
    }
    const response = await handleLogin();
    if (!response) {
      setPassword("");
      setUsername("");
      setFail(true);
      return;
    }
    await getRoleAdminMenu();
    editLinkArray();
    if (linkURL.length > 0) {
      navigate(`${linkURL[0]}`);
    } else {
      navigate("/dashboard");
    }
    window.location.reload();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBtnPress();
    }
  };

  const isEmpty = () => {
    var allG = true;
    if (username.trim().length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        usernameError: "Input field must be filled",
      }));
      allG = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        usernameError: null,
      }));
    }
    if (password.trim().length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        passwordError: "Input field must be filled",
      }));
      allG = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        passwordError: null,
      }));
    }
    return allG;
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${baseURL}/admin/login`, {
        username,
        password,
      });

      if (response.status === 200) {
        const tokeN = response.data.result[0]["token"]["token"];
        const id = response.data.result[0]["id"];
        const adminData = await getAdminByID(id, tokeN);
        console.log(adminData);

        const iD = adminData.id;
        const usernamE = adminData.username;
        const namE = adminData.name;
        const rolE = adminData.role_name;
        const idRolE = adminData.role_admin.id;
        const currAdmin = {
          id: iD,
          name: namE,
          username: usernamE,
          role: rolE,
          token: tokeN,
          id_role: idRolE,
        };
        localStorage.setItem("currAdmin", JSON.stringify(currAdmin));
        setIsLoggedIn(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          apiError: null,
        }));
        return true;
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          apiError: "Login falied, please try again.",
        }));
        return false;
      }
      const errorStatus = error.response.data.status;
      console.log("Login error status:", errorStatus);
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: "Wrong username or password combination",
      }));

      return false;
    }
  };

  const getAdminByID = async (id, token) => {
    try {
      const response = await axios.get(`${baseURL}/admin?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        // console.log(response);
        return response.data.result[0];
      }
    } catch {
      setErrors((prevErrors) => ({
        ...prevErrors,
        apiError: "GET ADMIN BY ID FAILED",
      }));
      console.log("error get admin by id");
    }
  };

  const loginContainerStyle = { backgroundImage: `url(${loginBackground})` };

  const [showPass, setShowPass] = useState("password");
  const toggleShowPass = () => {
    showPass === `password` ? setShowPass(`text`) : setShowPass(`password`);
  };
  return (
    <>
      <div
        style={loginContainerStyle}
        className={styles.backgroundImgContainer}
      >
        <div className={styles.loginContainer}>
          <div className={styles.greetingText}>WELCOME!</div>
          <div className={`${styles.normalText} ${styles.pleaseText}`}>
            {" "}
            Please Login
          </div>
          <div className={styles.loginForm}>
            <div
              className={`${styles.inputContainer} ${styles.loginInputContainer}`}
            >
              <label className={`${styles.normalText} ${styles.labelText}`}>
                Username
              </label>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  placeholder="Username"
                  className={`${styles.normalText} ${styles.inputField}`}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {errors.usernameError === null ? null : (
                <div className={styles.errorText}>{errors.usernameError}</div>
              )}
            </div>
            <div
              className={`${styles.inputContainer} ${styles.passwordInputContainer}`}
            >
              <label className={`${styles.normalText} ${styles.labelText}`}>
                Password
              </label>
              <div className={styles.inputBox}>
                <input
                  type={showPass}
                  placeholder="Password"
                  className={`${styles.normalText} ${styles.inputField}`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <ShowPassBtn
                  onClick={toggleShowPass}
                  state={showPass}
                  inputStyle={{ marginRight: `14px` }}
                />
              </div>

              {errors.passwordError === null ? null : (
                <div className={styles.errorText}>{errors.passwordError}</div>
              )}
            </div>

            <button
              onClick={handleBtnPress}
              type="submit"
              className={styles.loginBtn}
            >
              Login
            </button>
            {/* {errors.apiError === null ? null : (
              <div className={styles.bottomError}>
                <div className={styles.errorText}>{errors.apiError}</div>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <WarningFileSizeModal
        openModal={failModal}
        closeModal={() => {
          setFail(false);
        }}
        warningText={errors.apiError}
      />
    </>
  );
}
