import { DataGrid } from "@mui/x-data-grid";
import CustomDelete from "../../../icon/CustomDelete";
import CustomEdit from "../../../icon/CustomEdit";
import AddIcon from "@mui/icons-material/Add";
import ConfirmSubmitChanges from "../../ConfirmSubmitChanges/ConfirmSubmitChanges";
import CircleIcon from "@mui/icons-material/Circle";
import axios from "axios";
import { useEffect, useState } from "react";
import EditCategoryProduct from "../../../pages/editcategoryproduct/EditCategoryProduct";
import NewCategoryProduct from "../../../pages/newcategoryproduct/NewCategoryProduct";
import { baseURL } from "../../../global_variable_";

const CategoryComponent = () => {
  useEffect(() => {
    APICalls();
  }, []);

  const APICalls = async () => {
    await getCategories();
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedCategory, setEditedCategory] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const handleCloseDeleteModal = () => {
    setIsModalOpen(false);
  };
  const openNewCategoryProductModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const openEditModal = (category) => {
    console.log("Editing category with id:", category.id);
    setEditedCategory(category);
    setModalIsOpen(true);
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const getCategories = async () => {
    try {
      let response = await axios.get(`${baseURL}/category?all_data=true`);
      setCategories(response.data.result);
      // console.log(response.data.result);
    } catch (e) {
      console.log(e.message);
    }
  };

  // Delete Category Product
  const deleteItem = async (itemId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log(tesToken);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      if (tesToken) {
        const response = await axios.delete(`${baseURL}/category/${itemId}`, {
          headers,
        });
        // console.log(response);
        if (response.data.status === 200) {
          APICalls();
          console.log(response.data.status);
        } else {
          console.log("Error deleting category");
        }
      } else {
        console.log("Error delete category");
      }
    } catch (error) {
      console.error("Gagal menghapus category:", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete
          itemId={params.row.id}
          onDelete={deleteItem}
          closeModal={handleCloseDeleteModal}
        />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    {
      field: "category",
      headerName: "Category Name",
      headerClassName: "customColumnHeader",
      flex: 16,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  let formattedCategories = [];

  if (Array.isArray(categories)) {
    formattedCategories = categories.map((category) => ({
      id: category.id,
      category: category.name_category,
      status: category.isDeleted ? "Non-Active" : "Active",
    }));
  }

  return isLoading !== true ? (
    <>
      <button
        onClick={openNewCategoryProductModal}
        className="newcategorybtn-modal"
      >
        Add New Category
        <AddIcon className="addIcon1" />
      </button>
      <DataGrid
        className="productPageTable"
        rows={formattedCategories}
        columns={columns}
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: "category", sort: "asc" }],
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => "customRow"}
        getCellClassName={(params) => "customCell"}
        disableRowSelectionOnClick={true}
      />
      <EditCategoryProduct
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        editedProduct={editedCategory}
        Update={APICalls}
        // onSave={handleEditCategory}
      />
      <NewCategoryProduct
        isOpen={newCategoryModalIsOpen}
        onClose={() => setNewCategoryModalIsOpen(false)}
        Update={APICalls}
        // onSave={handleAddProduct}
      />
    </>
  ) : (
    <div>loading...</div>
  );
};

export default CategoryComponent;
