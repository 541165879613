// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetLg_widgetLg__aV2Jr{
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 40.813rem;
    height: 5.5rem;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.widgetLg_iconContainer__E9kou{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.25rem;
    background-color: #FFC3C3;
    border-radius: 100%;
}

.widgetLg_iconContainer__E9kou img{
    padding: 0.313rem;
}

.widgetLg_stringContainer__\\+\\+8cu{
    color: #000;
    margin-left: 1.25rem;
}

.widgetLg_topString__cGgNe{
    font-size: 1rem;
    font-weight: 600;
}

.widgetLg_bottomString__mrAV8{
    font-size: 0.875rem;
    font-weight: 400;
}

.widgetLg_widgetLg__aV2Jr button{
    margin-left: 9.063rem;
    width: 9.375rem;
    height: 1.875rem;
    background-color: #FFF;
    border: 1px solid  #BE1620;
    border-radius: 10px;
    color: #BE1620;
    font-size: 0.875rem;
    font-weight: 400;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
}

.widgetLg_widgetLg__aV2Jr button:hover{
    background-color: #BE1620;
    border: 1px solid #bb6a70;
    color: #FFF;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/widgetLg/widgetLg.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;IAEnB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,0BAA0B;IAC1B,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;AACpD;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".widgetLg{\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n\n    width: 40.813rem;\n    height: 5.5rem;\n    border-radius: 20px;\n    background: #FFF;\n    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);\n}\n\n.iconContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 1.25rem;\n    background-color: #FFC3C3;\n    border-radius: 100%;\n}\n\n.iconContainer img{\n    padding: 0.313rem;\n}\n\n.stringContainer{\n    color: #000;\n    margin-left: 1.25rem;\n}\n\n.topString{\n    font-size: 1rem;\n    font-weight: 600;\n}\n\n.bottomString{\n    font-size: 0.875rem;\n    font-weight: 400;\n}\n\n.widgetLg button{\n    margin-left: 9.063rem;\n    width: 9.375rem;\n    height: 1.875rem;\n    background-color: #FFF;\n    border: 1px solid  #BE1620;\n    border-radius: 10px;\n    color: #BE1620;\n    font-size: 0.875rem;\n    font-weight: 400;\n    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);\n}\n\n.widgetLg button:hover{\n    background-color: #BE1620;\n    border: 1px solid #bb6a70;\n    color: #FFF;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetLg": `widgetLg_widgetLg__aV2Jr`,
	"iconContainer": `widgetLg_iconContainer__E9kou`,
	"stringContainer": `widgetLg_stringContainer__++8cu`,
	"topString": `widgetLg_topString__cGgNe`,
	"bottomString": `widgetLg_bottomString__mrAV8`
};
export default ___CSS_LOADER_EXPORT___;
