// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.role_user_web_AddRoleUserLink__7L69Y{
    margin-left: auto;
    text-decoration: none;
    margin-bottom: 20px;
}

.role_user_web_newRoleUserBtn__-5szU{
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-weight: 400;
    font-style: 14px;
    color: #BE1620;
    border-color: #BE1620;
    border-radius: 10px;
    padding: 10px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    margin-left: auto;
    margin-bottom: 20px;
    }`, "",{"version":3,"sources":["webpack://./src/components/role_user/role_user_web/role_user_web.module.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB","sourcesContent":["\n.AddRoleUserLink{\n    margin-left: auto;\n    text-decoration: none;\n    margin-bottom: 20px;\n}\n\n.newRoleUserBtn{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: none;\n    font-weight: 400;\n    font-style: 14px;\n    color: #BE1620;\n    border-color: #BE1620;\n    border-radius: 10px;\n    padding: 10px;\n    width: 200px;\n    height: 40px;\n    cursor: pointer;\n    text-decoration: none;\n    margin-left: auto;\n    margin-bottom: 20px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddRoleUserLink": `role_user_web_AddRoleUserLink__7L69Y`,
	"newRoleUserBtn": `role_user_web_newRoleUserBtn__-5szU`
};
export default ___CSS_LOADER_EXPORT___;
