import { useEffect, useState } from "react";
import styles from "./ContactUs.module.css";
import Grid from '@mui/material/Unstable_Grid2';
import ContactAddress from "../../components/ContactAddress/ContactAddress";
import ContactSocial from "../../components/ContactSocial/ContactSocial";
import ContactMessage from "../../components/ContactMessage/ContactMessage";

  const tabs =[
    {text: "Address", element: <ContactAddress />},
    {text: "Social", element: <ContactSocial />},
    {text: "Message", element: <ContactMessage />},
  ]

export default function ContactUs({titlePage}) {
  

  const [activeTab, setActiveTab] = useState(tabs[0].text);

  return (
    <div className={styles.contactUsPage}>
      {titlePage}
      <Grid container spacing={2} style={{ marginTop: 20 }} >
        <div className={styles.tabBtnGroup}>
          { tabs.map((tab, index) =>(
            <button key={index}
              onClick={() => {setActiveTab(tab.text)}}
              className={
                activeTab === tab.text
                ? styles.tabBtnActive
                : styles.tabBtn
              }
              >
              {tab.text}
            </button>
          ))}
        </div>
        <Grid item xs={12} className={styles.componentTab} > 
        { tabs.map((tab) => (
          activeTab === tab.text ? tab.element : null
        ))}
        </Grid>
      </Grid>
    </div>
  )
}
