// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingAnimation_loading__yY32c{
    color: var(--color_red-primer);
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingAnimation/LoadingAnimation.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC","sourcesContent":[".loading{\n    color: var(--color_red-primer);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `LoadingAnimation_loading__yY32c`
};
export default ___CSS_LOADER_EXPORT___;
