import { Modal, Box } from "@mui/material";
import styles from "./add_admin_modal.module.css"; // Update the CSS module import
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ShowPassBtn from "../../components/button/show_pass_btn/show_pass_btn";
import { baseURL } from "../../global_variable_.jsx";

const AddAdminModal = ({ isOpen, onClose, Update }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);

  useEffect(() => {
    // console.clear();
    setAdminData({
      name: "",
      username: "",
      password: "",
      id_role_admin: "",
    });
    setShowPass(`password`);
    setErrorMsg({ nameError: null, usernameError: null });
    APICalls();
  }, [isOpen]);

  const APICalls = async () => {
    await getRoleAdmins();
  };

  const validateFields = () => {
    const nameIsValid = nameValidation();
    const usernameIsValid = usernameValidation();
    const passIsValid = passwordValidation();
    return nameIsValid && usernameIsValid && passIsValid;
  };

  const nameValidation = () => {
    if (adminData.name.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        nameError: "Please enter a Name.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, nameError: null }));
    return true;
  };

  const usernameValidation = () => {
    if (adminData.username.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        usernameError: "Please enter a Username.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, usernameError: null }));
    return true;
  };

  const passwordValidation = () => {
    if (adminData.password.length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        passwordError: "Please enter a Password.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, passwordError: null }));
    return true;
  };

  const handlePasswordInput = (string) => {
    var finalString = string.trim();
    return finalString;
  };

  const getRoleAdmins = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    // console.clear();
    console.log("Fetching role admins...");
    try {
      let response = await axios.get(`${baseURL}/role-admin`, { headers });
      console.log(response.data);
      setRoleAdmins(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const userNameInput = (string) => {
    var finalString = string.replace(/[^a-zA-Z]/g, "");
    return finalString.toLowerCase();
  };

  const addAdminData = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.post(
        `${baseURL}/admin`,
        {
          name: adminData.name,
          username: adminData.username,
          password: adminData.password,
          id_role_admin: adminData.id_role_admin,
        },

        { headers }
      );
      if (response.data.status === 201) {
        console.log(response);
        console.log("success");
        return true;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Username has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const [adminData, setAdminData] = useState({
    name: "",
    username: "",
    password: "",
    id_role_admin: "",
  });

  const [errorMsg, setErrorMsg] = useState({
    nameError: null,
    usernameError: null,
    passwordError: null,
  });

  const [roleAdmins, setRoleAdmins] = useState([]);

  const [isActive, setIsActive] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const openConfirmModal = () => {
    if (!validateFields()) {
      return;
    }
    setConfirmModal(true);
  };

  const closeConfirm = () => {
    setConfirmModal(false);
  };

  const handleSubmit = async () => {
    const response = await addAdminData();
    if (response) {
      Update();
      onClose();
    }
  };

  const [showPass, setShowPass] = useState(`password`);
  const toggleShowPass = () => {
    showPass === `password` ? setShowPass(`text`) : setShowPass(`password`);
  };

  return (
    <>
      <Modal className={styles.Modal} open={isOpen}>
        <Box className={styles.addAdminModalContainer}>
          <div className={styles.Header}>
            <div className={styles.Title}>ADD ADMIN </div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.adminNameContainer}>
              <label className={styles.Label}>Name</label>
              <input
                className={styles.adminNameField}
                type="text"
                onChange={(e) => {
                  setAdminData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
              />
              {errorMsg.nameError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.nameError}</div>
              ) : null}
            </div>
            <div className={styles.adminUsernameContainer}>
              <label className={styles.Label}>Username</label>
              <input
                className={styles.adminUsernameField}
                type="text"
                value={adminData.username}
                onChange={(e) => {
                  const finalString = userNameInput(e.target.value.trim());
                  setAdminData((prevData) => ({
                    ...prevData,
                    username: finalString,
                  }));
                }}
              />
              {errorMsg.usernameError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.usernameError}</div>
              ) : null}
            </div>
            <div className={styles.passwordContainer}>
              <label className={styles.Label}>Password</label>
              <div className={styles.inputBox}>
                <input
                  className={styles.passwordField}
                  type={showPass}
                  value={adminData.password}
                  onChange={(e) => {
                    const input = handlePasswordInput(e.target.value);
                    setAdminData((prevData) => ({
                      ...prevData,
                      password: input,
                    }));
                  }}
                />
                <ShowPassBtn
                  onClick={toggleShowPass}
                  state={showPass}
                  inputStyle={{ marginRight: `10px` }}
                />
              </div>
              {errorMsg.passwordError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.passwordError}</div>
              ) : null}
            </div>
            <div className={styles.roleAdminContainer}>
              <label className={styles.Label}>Role Admin</label>
              <select
                defaultValue="-1"
                className={styles.roleAdminDropdown}
                name="id_role_admin"
                id="id_role_admin"
                onChange={(e) => {
                  setAdminData((prevData) => ({
                    ...prevData,
                    id_role_admin: e.target.value,
                  }));
                }}
              >
                <option disabled value="-1">
                  (Select)
                </option>
                {roleAdmins.map((roleAdmin) => {
                  console.log(roleAdmin);
                  if (roleAdmin.id !== 1) {
                    return (
                      <option key={roleAdmin.id} value={roleAdmin.id}>
                        {roleAdmin.role_name} {/* Adjust the property name */}
                      </option>
                    );
                  }
                })}
              </select>
              {/* Add error message display for role admin if needed */}
            </div>
            <div className={styles.radioGroupContainer}>
              <label className={styles.Label}>Status</label>
              <div className={styles.radioGroupContent}>
                <div className={styles.radioActive}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => {
                      setIsActive(true);
                      setAdminData((prevData) => ({
                        ...prevData,
                        isDeleted: isActive,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Active</label>
                </div>
                <div className={styles.radioNonActive}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => {
                      setIsActive(false);
                      setAdminData((prevData) => ({
                        ...prevData,
                        isDeleted: isActive,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Non-Active</label>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.Button}
                onClick={async () => {
                  openConfirmModal();
                }}
              >
                Save
              </button>
              <button className={styles.Button} onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirm}
        handleSubmitData={handleSubmit}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
    </>
  );
};

export default AddAdminModal;
