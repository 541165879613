import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import WidgetLg from "../../components/dashboard/widgetLg/WidgetLg";
import WidgetSm from "../../components/dashboard/widgetSm/WidgetSm";
import styles from "./home.module.css";
import Product from "../../components/dashboard/product/product";
import Color from "../../components/dashboard/color/color";
import User from "../../components/dashboard/user/user";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../global_variable_.jsx";

export default function Home({ titlePage }) {
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [role, setRole] = useState();
  const [token, setToken] = useState();
  const [issues, setIssues] = useState({
    issueCount: null,
    issueDate: null,
    issueAge: null,
  });
  const [counts, setCounts] = useState({
    productCount: null,
    colorCount: null,
    manufacturerCount: null,
    modelCount: null,
    yearCount: null,
    areaCount: null,
    userCount: null,
  }); //counters except for issues and product categories
  const [productCatCount, setProdCatCount] = useState();

  const getDashboardData = async () => {
    try {
      const response = await axios.get(`${baseURL}/dashboard`);
      if (response.status === 200) {
        return response.data;
      }
    } catch {
      console.log("fail");
      return false;
    }
  };

  useEffect(() => {
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    // console.log(currAdmin)
    if (currAdmin === null) {
      return navigate("/");
    }
    if (currAdmin.token) {
      setName(currAdmin.name);
      setUsername(currAdmin.username);
      setRole(currAdmin.role);
      setToken(currAdmin.token);
    }
    apiGet();
  }, []);

  const apiGet = async () => {
    const dashboardResponse = await getDashboardData();
    // console.log(dashboardResponse)
    if (dashboardResponse) {
      declareSetCounts(dashboardResponse);
      declareSetProdCatCounts(dashboardResponse);
      declareSetIssue(dashboardResponse);
    }

    setDataLoaded(true);
  };

  const declareSetCounts = (dashboardResponse) => {
    setCounts({
      areaCount: dashboardResponse.result[0]["area_count"],
      colorCount: dashboardResponse.result[0]["color_count"],
      manufacturerCount: dashboardResponse.result[0]["manufacturer_count"],
      modelCount: dashboardResponse.result[0]["model_count"],
      productCount: dashboardResponse.result[0]["product_count"],
      userCount: dashboardResponse.result[0]["user_count"],
      yearCount: dashboardResponse.result[0]["year_count"],
    });
  };

  const declareSetProdCatCounts = (dashboardResponse) => {
    setProdCatCount(dashboardResponse.result[0]["product_category"]);
  };

  const declareSetIssue = (dashboardResponse) => {
    setIssues({
      issueCount: dashboardResponse.result[0]["issue_count"],
      issueDate: dashboardResponse.result[0]["issue_date"],
      issueAge: dashboardResponse.result[0]["issue_age"],
    });
  };

  return dataLoaded === true ? (
    <div className={styles.home}>
      {token !== null ? (
        <>
          {titlePage}
          <Grid container spacing={2}>
            <Grid xs={12}>
              <div className={styles.homeWidget}>
                <WidgetSm name={name} username={username} />
                <WidgetLg issues={issues} />
              </div>
            </Grid>

            <div>
              Products{" "}
              <Product
                allProductCount={counts.productCount}
                productCatCount={productCatCount}
              />
            </div>

            <Grid xs={12}>
              <div className={styles.colorsAndUsers}>
                <div>
                  Colors{" "}
                  <Color
                    colorCounts={{
                      allColor: counts.colorCount,
                      modelCount: counts.modelCount,
                      manuCount: counts.manufacturerCount,
                      areaCount: counts.areaCount,
                      yearCount: counts.yearCount,
                    }}
                  />
                </div>
                <div>
                  Users <User userCount={counts.userCount} />
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      ) : null}
    </div>
  ) : (
    <div className={styles.loading}>loading...</div>
  );
}
