import axios from "axios";

const ImportDataCSV = async ({ file, type, url, updateData }) => {
  const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log(file);
  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(`${url}`, formData, { headers });
      const responseCode = response.data.status;
      if (responseCode === 200) {
        console.warn("berhasil import color");
        updateData();
        return [true, responseCode];
      }
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        return [false, 500];
      }
      console.log(error.response.data.result[0]);
      const errorCode = error.response.status;
      const errorResponseColor = error.response.data.result[0];
      const errorResponseFormula = error.response.data.result[0];
      console.log(errorResponseColor);
      console.log(errorResponseFormula);
      if (type === "color_list") {
        return [false, errorCode, errorResponseColor];
      } else if (type === "formula") {
        return [false, errorCode, errorResponseFormula];
      }
    }
  } else {
    console.log("file is null");
  }
};

export default ImportDataCSV;
