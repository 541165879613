import styles from "./ContactAddress.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import axios from "axios";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../ConfirmSubmitChanges/ConfirmSubmitChanges";
import instructPdf from "./instruction.pdf";
import { baseURL } from "../../global_variable_";

export default function ContactAddress() {
  const [adminToken, setAdminToken] = useState(null);
  const [errorMsg, setError] = useState({ address: null, map: null });
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    if (!fieldsValidation()) return;
    setLongitude(getCoordinates(mapUrl, "!2d", "!3d"));
    setLatitude(getCoordinates(mapUrl, "!3d", "!"));
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fieldsValidation = () => {
    const urlIsValid = urlValidation();
    const addressIsValid = addressValidation();

    if (urlIsValid && addressIsValid) return true;
    return false;
  };

  const urlValidation = () => {
    if (mapUrl.trim().length === 0) {
      setError((prev) => ({ ...prev, map: "Please fill the URL field." }));
      return false;
    }
    setError((prev) => ({ ...prev, map: null }));
    return true;
  };
  const addressValidation = () => {
    if (address.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        address: "Please fill the Address field.",
      }));
      return false;
    }
    setError((prev) => ({ ...prev, address: null }));
    return true;
  };

  const handleSubmitData = async () => {
    // postAPI
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    console.log(currAdmin.token);
    if (currAdmin.token) {
      try {
        const updatedData = {
          id: 1,
          alamat: address,
          link: mapUrl,
          latitude: latitude,
          longitude: longitude,
        };
        const response = await axios.put(
          `${baseURL}/company-profile`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${currAdmin.token}`,
            },
          }
        );
        console.warn(address);
        console.warn(mapUrl);
        console.warn(latitude);
        console.warn(longitude);
        console.log(`Address updated successfully:`, response.data);
        handleShowMapChange();
        handleCloseModal();
      } catch (error) {
        console.warn(address);
        console.warn(mapUrl);
        console.warn(latitude);
        console.warn(longitude);
        console.error("Error updating data:", error);
      }
    }
  };

  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [mapUrl, setMapUrl] = useState("");
  const [previewMapUrl, setPreviewMapUrl] = useState("");
  // const [mapURL, setMapURL] = useState('');

  const getAddress = async () => {
    // getAPI Image
    try {
      const response = await axios.get(baseURL + "/company-profile");
      setAddress(response.data.result[0]?.data[0].alamat);
      setLatitude(response.data.result[0]?.data[0].latitude);
      setLongitude(response.data.result[0]?.data[0].longitude);
      setMapUrl(response.data.result[0]?.data[0].link);
      setPreviewMapUrl(response.data.result[0]?.data[0].link);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleInputAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleInputMapUrl = (event) => {
    setMapUrl(event.target.value);
  };
  const handleShowMapChange = () => {
    // const newMapURL = `https://www.google.com/maps/embed/v1/place?key=API_KEY&q=Space+Needle,Seattle+WA`;
    // setMapURL(newMapURL);
    setPreviewMapUrl(mapUrl);
    setLongitude(getCoordinates(mapUrl, "!2d", "!3d"));
    setLatitude(getCoordinates(mapUrl, "!3d", "!"));

    console.log("Updated Longitude:", longitude);
    console.log("Updated Latitude:", latitude);
    console.log(mapUrl);
  };

  const getCoordinates = (string, start, end) => {
    const regex = new RegExp(`${start}(.*?)${end}`);
    const match = string.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      console.error(`getCoordinates (${string}): Value not found`);
      return "Value not found";
    }
  };

  useEffect(() => {
    setError({ address: null, map: null });
    getAddress();
  }, []);

  // const mapURL = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15869.13310701365!2d${longitude}!3d${latitude}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a032ec89e30b9%3A0xf3b0512c970ddcf4!2sPT%20ABCa!5e0!3m2!1sen!2sid!4v1682669148125!5m2!1sen!2sid`;

  return (
    <Grid container spacing={2} columns={{ xs: 6, lg: 12 }}>
      <Grid item xs={6}>
        <div className={styles.wrapper}>
          <div className={styles.formGroup}>
            <label className={styles.textLabel}>Address</label>
            <div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  value={address}
                  className={styles.customField}
                  onChange={handleInputAddress}
                />
              </div>
              {errorMsg.address !== null ? (
                <div className={styles.errorMsg}>{errorMsg.address}</div>
              ) : null}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.textLabel} style={{ marginTop: 20 }}>
              URL
            </label>
            <div>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  value={mapUrl}
                  className={styles.customField}
                  onChange={handleInputMapUrl}
                />
                <LinkRoundedIcon className={styles.iconRight} />
              </div>
              {errorMsg.map !== null ? (
                <div className={styles.errorMsg}>{errorMsg.map}</div>
              ) : null}
            </div>
          </div>
          <div className={styles.coordinatesGroup}>
            <div className={styles.formGroup}>
              <label className={styles.textLabel} style={{ marginTop: 20 }}>
                Latitude
              </label>
              <div className={styles.inputContainerDisabled}>
                <input
                  disabled
                  type="text"
                  value={latitude}
                  className={styles.customField}
                />
              </div>
            </div>
            <div className={styles.formGroup} style={{ marginLeft: "14px" }}>
              <label className={styles.textLabel} style={{ marginTop: 20 }}>
                Longitude
              </label>
              <div className={styles.inputContainerDisabled}>
                <input
                  disabled
                  type="text"
                  value={longitude}
                  className={styles.customField}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "end",
              alignItems: "center",
            }}
          >
            <a
              href={instructPdf}
              download="PDF-URL-Guide"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className={styles.pdfDownloadButton}>
                <div className={styles.pdfDownloadButtonText}>
                  PDF URL Guide
                </div>
              </button>
            </a>
            <button className={styles.mapButton} onClick={handleShowMapChange}>
              Refresh Map
            </button>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={styles.wrapper}>
          <div className={styles.mapImage}>
            <iframe
              title="googlemap"
              src={previewMapUrl}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        <button className={styles.saveButton} onClick={handleOpenModal}>
          Save
        </button>
      </Grid>
      <ConfirmSubmitChanges
        openModal={openModal}
        closeModal={handleCloseModal}
        handleSubmitData={handleSubmitData}
      />
    </Grid>
  );
}
