import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./newcategoryproduct.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import { baseURL } from "../../global_variable_.jsx";

const API_URL = `${baseURL}/category`;

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  maxHeight: "307px",
};

const NewCategoryProduct = ({ isOpen, onClose, onSave, Update }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const handleOpenModal = () => {
    if (isEmpty() === false) {
      return;
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [categoryName, setCategoryName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [errorMessage, setErrorMsg] = useState(null);

  const isEmpty = () => {
    if (categoryName.trim().length === 0) {
      setErrorMsg("Please enter a Category Name.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  useEffect(() => {
    setCategoryName("");
    setErrorMsg(null);
  }, [isOpen]);

  const handleSaveAddCategory = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    console.log(token);

    const isDeleted = isActive ? "false" : "true";

    const newCategory = {
      name_category: `${categoryName}`,
      isDeleted: `${isDeleted}`,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await postCategory(newCategory, headers);
    console.log(newCategory);
    console.log(headers);
    if (response) {
      Update();
      onClose();
    }
  };

  const postCategory = async (newCategory, headers) => {
    try {
      const response = await axios.post(API_URL, newCategory, { headers });
      console.log(response);
      console.log(newCategory);
      if (response.data.status === 201) {
        console.log("Category posted successfully!");
        setAddSuccessModal(true);
        return true;
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Category Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
      console.error("Error posting category:", error);
    }
  };

  return (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
            }}
          >
            <Grid xs={11}>
              <div className={styles.modalTitle}>NEW CATEGORY</div>
            </Grid>
            <Grid xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                maxWidth: "491px",
                maxHeight: "307px",
                overflow: "auto",
              }}
            >
              <form>
                <div className="form-group">
                  <label
                    htmlFor="productName"
                    className={styles.categorynamenew}
                  >
                    Category Name
                  </label>
                  <br></br>
                  <input
                    type="text"
                    id="categoryName"
                    className={styles.productnamenew}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  {errorMessage !== null ? (
                    <div className={styles.errorMsg}>{errorMessage}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className={styles.labelstatusnew}>Status:</label>
                  <br></br>
                  <br></br>
                  <label className={styles.radiogroupnew}>
                    <input
                      type="radio"
                      name="status"
                      value="active"
                      checked={isActive}
                      onChange={() => setIsActive(true)}
                    />{" "}
                    Active
                  </label>
                  <label className={styles.radiogroupnew2}>
                    <input
                      type="radio"
                      name="status"
                      value="nonactive"
                      checked={!isActive}
                      onChange={() => setIsActive(false)}
                    />{" "}
                    Non-Active
                  </label>
                </div>
              </form>
            </Grid>
            <Grid xs={6}>
              <button
                onClick={handleOpenModal}
                className={styles.btnGroupNewCategoryProduct}
              >
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button
                onClick={onClose}
                className={styles.btnGroupNewCategoryProduct}
              >
                Cancel
              </button>
            </Grid>
            <ConfirmSubmitChanges
              openModal={openModal}
              closeModal={handleCloseModal}
              handleSubmitData={handleSaveAddCategory}
            />
          </Grid>
        </Box>
      </Modal>
      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => setWarningModal(false)}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => setAddSuccessModal(false)}
        warningText={"Data Added Succsesfully"}
      />
    </>
  );
};

export default NewCategoryProduct;
