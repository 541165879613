// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Newsletter_NewsPage__l7hVC{
    width: 100%;
    padding: 20px;
}

.Newsletter_tabBtnGroup__HDfbJ{
    display: flex;
    flex-direction: row;
}

.Newsletter_tabBtn__vI3\\+p, .Newsletter_tabBtnActive__13K2V{
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.Newsletter_tabBtn__vI3\\+p{
    /* width: 100%; */
    background: none;
    border: none;
    color: var(--color_red-primer);
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
}
.Newsletter_tabBtnActive__13K2V{
    /* width: 100%; */
    border: none;
    background-color: var(--color_red-2);
    color: var(--color_red-primer);
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    padding: 10px 20px;
}

.Newsletter_componentTab__L9TLs{
    width: 100%;
    background-color: var(--color_white);
    padding: 20px !important;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: 0px 4px 10px #0000001a;
}`, "",{"version":3,"sources":["webpack://./src/pages/Newsletter/Newsletter.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,8BAA8B;IAC9B,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,oCAAoC;IACpC,8BAA8B;IAC9B,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,oCAAoC;IACpC,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;IAChB,kCAAkC;AACtC","sourcesContent":[".NewsPage{\n    width: 100%;\n    padding: 20px;\n}\n\n.tabBtnGroup{\n    display: flex;\n    flex-direction: row;\n}\n\n.tabBtn, .tabBtnActive{\n    width: fit-content;\n    cursor: pointer;\n}\n\n.tabBtn{\n    /* width: 100%; */\n    background: none;\n    border: none;\n    color: var(--color_red-primer);\n    font-family: \"Inter-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    padding: 10px 20px;\n}\n.tabBtnActive{\n    /* width: 100%; */\n    border: none;\n    background-color: var(--color_red-2);\n    color: var(--color_red-primer);\n    font-family: \"Inter-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    border-radius: 10px;\n    padding: 10px 20px;\n}\n\n.componentTab{\n    width: 100%;\n    background-color: var(--color_white);\n    padding: 20px !important;\n    border-radius: 20px;\n    margin-top: 20px;\n    box-shadow: 0px 4px 10px #0000001a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewsPage": `Newsletter_NewsPage__l7hVC`,
	"tabBtnGroup": `Newsletter_tabBtnGroup__HDfbJ`,
	"tabBtn": `Newsletter_tabBtn__vI3+p`,
	"tabBtnActive": `Newsletter_tabBtnActive__13K2V`,
	"componentTab": `Newsletter_componentTab__L9TLs`
};
export default ___CSS_LOADER_EXPORT___;
