import { Box, Modal } from "@mui/material";
import styles from "./edit_sub_category_modal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import axios from "axios";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400";
import { baseURL } from "../../global_variable_.jsx";

const EditSubCategoryModal = ({ isOpen, onClose, editedSub, Update }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [subCategoryName, setName] = useState("");
  const [subCategoryID, setSubID] = useState("");
  const [listCategories, setListCategories] = useState([]);
  const [categoryID, setCategory] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  const isEmpty = () => {
    if (subCategoryName.trim().length === 0) {
      setErrorMsg("Please enter a Sub Category Name.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };
  console.log("test", editedSub);
  useEffect(() => {
    // console.clear();
    console.log("called");
    if (editedSub) {
      setName(editedSub.category);
      setSubID(editedSub.id);
      setCategory(editedSub.category_id);
      setIsActive(editedSub.status === "Deleted");
    }
    setErrorMsg(null);
    APICalls();
  }, [isOpen]);

  const APICalls = async () => {
    await getCategories();
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const openConfirmModal = () => {
    setConfirmModal(true);
  };

  const getCategories = async () => {
    console.log("im here");
    try {
      let response = await axios.get(`${baseURL}/category`);
      const sortedCategories = response.data.result
        .slice()
        .sort((a, b) => a.name_category.localeCompare(b.name_category));
      setListCategories(sortedCategories);
      // response.data.result.map((e) => console.log(e.name_category));
      //   console.log(response.data.result[0]);
    } catch (e) {
      console.log(e.message);
    }
  };
  const editSubCategories = async (id) => {
    console.clear();
    console.log(id);
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    const subCategory = {
      id: `${id}`,
      name_sub_category: `${subCategoryName}`,
      id_category: `${categoryID}`,
      isDeleted: `${isActive}`,
    };
    console.log(headers);
    console.log(id);
    console.log(subCategoryName);
    console.log(categoryID);
    console.log(isActive);
    try {
      console.log("masuk");
      const response = await axios.put(
        `${baseURL}/sub-category`,
        {
          id: `${id}`,
          name_sub_category: `${subCategoryName}`,
          id_category: `${categoryID}`,
          isDeleted: `${isActive}`,
        },
        { headers }
      );
      console.log("edit:", response);
      if (response.data.status === 200) {
        console.log("success");
        setEditSuccessModal(true);
        return true;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Sub Category Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const submitData = async () => {
    const response = await editSubCategories(subCategoryID);
    if (response) {
      Update();
      onClose();
    }
  };

  return (
    <>
      <Modal className={styles.Modal} open={isOpen}>
        <Box className={styles.boxModal}>
          <div className={styles.Header}>
            <div className={styles.Title}>EDIT SUB CATEGORY</div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.subCategoryNameContainer}>
              <label className={styles.Label}> Sub Category Name</label>
              <input
                className={styles.subCategoryNameField}
                type="text"
                value={subCategoryName}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {errorMsg !== null ? (
                <div className={styles.errorMsg}>{errorMsg}</div>
              ) : null}
            </div>
            <div className={styles.categoryContainer}>
              <label className={styles.Label}>Category</label>
              <select
                defaultValue={editedSub ? editedSub.category_id : null}
                value={categoryID}
                onChange={(e) => {
                  setCategory(e.target.value);
                  console.log(categoryID);
                }}
                className={styles.categoryDropdown}
                name=""
                id=""
              >
                {/* <option disabled value="0">
                (Select)
              </option> */}
                {listCategories.map((category) => (
                  <option
                    key={category.id}
                    value={category.id}
                    // onClick={(e) => {
                    //   setCategory(e.id);
                    // }}
                  >
                    {category.name_category}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.radioGroupContainer}>
              <label className={styles.Label}>Status</label>
              <div className={styles.radioGroupContent}>
                <div className={styles.radioActive}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />
                  <label className={styles.radioLabel}>Active</label>
                </div>
                <div className={styles.radioNonActive}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />
                  <label className={styles.radioLabel}>Non-Active</label>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.Button}
                onClick={() => {
                  if (!isEmpty()) {
                    return;
                  }
                  openConfirmModal();
                }}
              >
                Save
              </button>
              <button className={styles.Button} onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirmModal}
        handleSubmitData={submitData}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
    </>
  );
};

export default EditSubCategoryModal;
