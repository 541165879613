import React, { useEffect, useRef, useState } from "react";
import "./colorformula.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import CustomEye from "../../icon/CustomEye";
import EditColorPage from "../editcolorpage/EditColorPage";
import NewColorPage from "../newcolorpage/NewColorPage";
import EyeModal from "../eyemodalformula/EyeModalFormula";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterDrawer from "../../components/DrawerFilterColor/drawerFilterColor";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import CircleIcon from "@mui/icons-material/Circle";
import ModalError400 from "../../components/ModalError400/ModalError400";
import NoImage from "../../icon/no-img.png";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import ImportDataCSV from "../../functions/import_data_csv";
import UploadImage from "../../functions/upload_image";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CSVTemplateModal from "../../components/csv_template_modal/csv_template_modal";
import { baseURL } from "../../global_variable_";
let selectedData = {};
// let selectedFilterNum = null;

export default function ColorFormula({ titlePage }) {
  const [allowedSubMenus, setAllowedSubMenus] = useState([
    "color-category",
    "color",
  ]);

  //Color Img File (.zip)
  const [importErrorModal, setImportErrorModal] = useState(false);
  const [colorImgFile, setColorImgFile] = useState(null);
  const colorImgFileBtn = useRef(null);
  const [colorImgConfirmUpload, setColorImgConfirmUpload] = useState(false);

  //Color List Import
  const [colorConfirmUpload, setColorConfirmUpload] = useState(false);
  const [colorCSV, setColorCSV] = useState(null);
  const colorCSVBtn = useRef(null);

  //Color Formula Import
  const [formulaConfirmUpload, setFormulaConfirmUpload] = useState(false);
  const [formulaCSV, setFormulaCSV] = useState(null);
  const formulaCSVBtn = useRef(null);

  const [importType, setImportType] = useState(null);

  const [importSuccess, setImportSuccess] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [colors, setColors] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editedColor, setEditedColor] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [eyeModalIsOpen, setEyeModalIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [wrongRows, setWrongRows] = useState(null);
  const [openCSVColorModal, setOpenCSVColorModal] = useState(false);
  const [openCSVFormulaModal, setOpenCSVFormulaModal] = useState(false);

  const checkSubMenuAuth = () => {
    const allowedSubMenu = localStorage.getItem("colorAllowedComponents");
    if (allowedSubMenu) {
      setAllowedSubMenus(allowedSubMenu.split(","));
    }
  };

  useEffect(() => {
    checkSubMenuAuth();
    checkAdminStatus();
    APICalls();
  }, []);

  const APICalls = async () => {
    await getColors();
    setIsLoading(false);
  };

  const handleOpenWarningModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
    window.location.reload();
  };

  const getColors = async () => {
    try {
      let response = await axios.get(`${baseURL}/color/all?all_data=true`);
      setColors(response.data.result);
    } catch (e) {
      console.log(e.message);
    }
  };

  const openEyeModal = (color) => {
    setSelectedColor(color);
    setEyeModalIsOpen(true);
  };

  const deleteItem = async (itemId) => {
    if (!isAdmin) {
      setWarningText("You are not permitted to delete category.");
      handleOpenWarningModal();
      return;
    }
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        await axios.delete(`${baseURL}/color/${itemId}`, { headers });
        const updatedColors = colors.filter((color) => color.id !== itemId);
        setColors(updatedColors);
      } catch (error) {
        console.error("Gagal menghapus item:", error);
      }
    }
  };

  const openEditModal = (color) => {
    setEditedColor(color);
    setModalIsOpen(true);
  };

  const handleEditColor = async (editedColor) => {
    try {
      setModalIsOpen(false);
      alert("Produk berhasil diperbarui.");
    } catch (error) {
      console.error("Gagal mengupdate produk:", error);
    }
  };

  const openNewCategoryColorModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const handleAddColor = async (addData, headers) => {
    console.log(addData);
    console.log(headers);
    try {
      const response = await axios.post(
        `${baseURL}/color?all_data=true`,
        addData,
        {
          headers,
        }
      );
      console.log(response);
      if (response.status === 201) {
        console.log("Color created successfully:", response.data);
        setNewCategoryModalIsOpen(false);
        setOpenSuccessModal(true);
        APICalls();
      } else {
        console.error(
          "Failed to create a new color:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg(
          "Duplicate value: Color Name and Color Code has to be unique in the same Car Model."
        );
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.error("Error creating a new color:", error);
      return false;
    }
  };

  const checkAdminStatus = async () => {
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      const adminResponse = await axios.get(`${baseURL}/admin`, {
        headers: {
          Authorization: `Bearer ${currAdmin.token}`,
        },
      });
      const adminArray = adminResponse.data.result[0].data;
      setIsAdmin(adminArray.find((a) => a.username === currAdmin.username));
      // const admin = adminArray.find((a) => a.username === currAdmin.username);
      // if (admin) {
      //   setIsAdmin(true);
      // } else {
      //   setIsAdmin(false);
      // }
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };

  const getTemplateColorList = async () => {
    await DownloadDataCsv(
      "template-csv-color",
      `${baseURL}/color/csv-template`
    );
  };

  const getTemplateFormula = async () => {
    await DownloadDataCsv(
      "template-csv-formula",
      `${baseURL}/formula/csv-template`
    );
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteItem} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    {
      field: "formula",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomEye onClick={() => openEyeModal(params.row)} />
      ),
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "customColumnHeader",
      flex: 2,
      renderCell: (params) => {
        //console.log('Image URL:', params.row.image); // Add this line for debugging
        const imageUrl = `${baseURL}/${params.row.image}`;
        return (
          <div className="imageColor">
            <img
              className="setColorImage"
              src={imageUrl}
              alt={`Image of ${params.row.cName}`}
              onError={(e) => {
                console.error("Error loading image:", e);
                e.target.src = NoImage;
              }}
            />
          </div>
        );
      },
    },
    {
      field: "cName",
      headerName: "Color Name",
      headerClassName: "customColumnHeader",
      flex: 10,
    },
    {
      field: "code",
      headerName: "Code",
      headerClassName: "customColumnHeader",
      flex: 4,
    },
    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        // console.log(`${params.row.id}: ${params.row.isDeleted}`);
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellProduct ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === false ? "Active" : "Non-Active"}
          </div>
        );
      },
    },
  ];
  // function hasProperties(Arr) {
  //   return Object.keys(Arr).length > 0;
  // }
  const handleSelectedData = (data) => {
    // Callback function to handle selected data dari filter
    if (Object.keys(data).length > 0) {
      selectedData = data;
      // console.log(Object.keys(data).length)
    }
    if (
      Object.values(data).every(
        (prop) => Array.isArray(prop) && prop.length === 0
      )
    ) {
      selectedData = {};
      // console.log(Object.values(data).every(prop => Array.isArray(prop) && prop.length === 0))
    }
    console.log("selectedData: ", selectedData);
    // console.log(selectedData);
  };

  const filterAndFormatColors = () => {
    if (Array.isArray(colors)) {
      const filtered =
        Object.keys(selectedData).length < 1
          ? colors
          : // : colors.filter(
            //     (item) =>
            //       selectedData.manufactures.includes(item.id_manufacturer) ||
            //       selectedData.models.includes(item.id_car_model) ||
            //       selectedData.years.includes(item.id_year_of_production) ||
            //       selectedData.areas.includes(item.id_area)
            //   );
            colors.filter(
              (item) =>
                (selectedData.manufactures.length === 0 ||
                  selectedData.manufactures.includes(item.id_manufacturer)) &&
                (selectedData.models.length === 0 ||
                  selectedData.models.includes(item.id_car_model)) &&
                (selectedData.years.length === 0 ||
                  selectedData.years.includes(item.id_year_of_production)) &&
                (selectedData.areas.length === 0 ||
                  selectedData.areas.includes(item.id_area))
            );
      return filtered.map((color) => ({
        id: color.id,
        image: color.image_file,
        cName: color.name_color,
        code: color.code_color,
        idManufacturer: color.id_manufacturer,
        idModel: color.id_car_model,
        idYearOfProduction: color.id_year_of_production,
        idArea: color.id_area,
        isDeleted: color.isDeleted,
      }));
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleImport = (state, statusCode, colorImage, duplicateResponse) => {
    if (state) {
      setImportSuccess(true);
    } else {
      if (statusCode === 400) {
        setWrongRows(duplicateResponse);
        if (!colorImage) {
          setWarningText("Duplicate Entries Found");
          handleOpenWarningModal();
        } else {
          setWarningMsg(
            "Some image(s) will not be updated due to wrong color code. Do you wish to continue?"
          );
          setWarningModal(true);
        }
      } else if (statusCode === 409) {
        setWarningText("All Entries Denied");
        handleOpenWarningModal();
      } else if (statusCode === 407) {
        setWarningText("Exceeding Row Limit of 500");
        handleOpenWarningModal();
      } else {
        setWarningText("Failed to Import File");
        handleOpenWarningModal();
      }
    }
  };
  const [selectedFilterNum, setSelectedFilterNum] = useState([]);
  const handleDeleteFilter = (selectedFilter) => {
    setSelectedFilterNum((prevState) => [...prevState, selectedFilter]);
    console.log(selectedFilterNum);
    switch (selectedFilter) {
      case 1:
        selectedData.manufactures = [];
        selectedData.name_manufactures = [];
        getColors();
        break;
      case 2:
        selectedData.models = [];
        selectedData.car_name_model = [];
        getColors();
        break;
      case 3:
        selectedData.years = [];
        selectedData.production_year = [];
        getColors();
        break;
      case 4:
        selectedData.areas = [];
        selectedData.name_area = [];
        getColors();
        break;
      default:
        console.warn("Delete Filter Failed");
        break;
    }
  };
  useEffect(() => {
    setSelectedFilterNum([]);
  }, [selectedData]);

  const SelectedDataIndicator = (data, targetNum) => {
    if (data && data.length > 0) {
      return (
        <div className="selectedDataContent">
          <div className="selectedDataContentText">{data.join(", ")}</div>
          <CloseRoundedIcon
            className="deleteSelectedIcon"
            onClick={() => handleDeleteFilter(targetNum)}
          />
        </div>
      );
    }
    return null;
  };

  const btnSubMenuContainer = () => {
    return (
      <div className="colorFormulaPageBtnGroup">
        {allowedSubMenus.includes("color-category") ? (
          <Link to="/color">
            <button className="categorybuttonformula">Category</button>
          </Link>
        ) : null}
        {allowedSubMenus.includes("color") ? (
          <button className="colorbuttonformula">Color</button>
        ) : null}
      </div>
    );
  };

  return isLoading !== true ? (
    <>
      <div className="colorFormulaPage">
        {titlePage}
        {btnSubMenuContainer()}
        <div className="colorFormulaTableContainer">
          <div className="topContentColor">
            <div className="filterContainer">
              <button
                onClick={() => setDrawerOpen(true)}
                className="filtercolor"
              >
                Filters
                <FilterAltOutlinedIcon className="filterIcon" />
              </button>
              <FilterDrawer
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onSelectedData={handleSelectedData}
                deletedFilterNum={selectedFilterNum}
              />
            </div>
            <div className="addColorContainer">
              <button
                onClick={openNewCategoryColorModal}
                className="newsubcategorybtn-modalformula"
              >
                Add New Color
                <AddIcon className="addIcon1" />
              </button>
            </div>
          </div>

          {Object.keys(selectedData).length > 0 && (
            <div className="selectedDataContainer">
              {SelectedDataIndicator(selectedData.name_manufactures, 1)}
              {SelectedDataIndicator(selectedData.car_name_model, 2)}
              {SelectedDataIndicator(selectedData.production_year, 3)}
              {SelectedDataIndicator(selectedData.name_area, 4)}
            </div>
          )}

          <DataGrid
            className="tableColorFormula"
            rows={filterAndFormatColors()}
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "cName", sort: "asc" }],
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
            disableRowSelectionOnClick={true}
          />

          <div className="bottomContent">
            <div className="colorListContainer">
              <div className="">Color List</div>
              <input
                ref={colorCSVBtn}
                type="file"
                accept=".csv"
                style={{ display: `none` }}
                onChange={(e) => {
                  console.log(e.target);
                  setColorCSV(e.target.files[0]);
                  setColorConfirmUpload(true);
                }}
              />
              <div className="btnImportExportContainer">
                <ExportImportBtn
                  onClick={() => {
                    setOpenCSVColorModal(true);
                  }}
                  btnType="Import"
                />
                <ExportImportBtn
                  addStyle={{ marginLeft: `10px` }}
                  btnType="Export"
                  onClick={() =>
                    DownloadDataCsv("color_list", `${baseURL}/color/csv`)
                  }
                />
              </div>
            </div>
            <div className="formulaContainer">
              <div className="">Formula</div>{" "}
              <input
                ref={formulaCSVBtn}
                type="file"
                accept=".csv"
                style={{ display: `none` }}
                onChange={(e) => {
                  console.log(e.target);
                  setFormulaCSV(e.target.files[0]);
                  setFormulaConfirmUpload(true);
                }}
              />
              <div className="btnImportExportContainer">
                <ExportImportBtn
                  onClick={() => {
                    setOpenCSVFormulaModal(true);
                  }}
                  btnType="Import"
                />
                <ExportImportBtn
                  btnType="Export"
                  onClick={() =>
                    DownloadDataCsv("formula_list", `${baseURL}/formula/csv`)
                  }
                  addStyle={{ marginLeft: `10px` }}
                />
              </div>
            </div>
            <div className="colorImgContainer">
              <div>Color Image</div>
              <input
                type="file"
                accept=".zip"
                ref={colorImgFileBtn}
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setColorImgFile(e.target.files[0]);
                  setColorImgConfirmUpload(true);
                }}
                style={{ display: `none` }}
              />
              <div className="btnImportExportContainer">
                <ExportImportBtn
                  string="Import ZIP"
                  onClick={() => {
                    colorImgFileBtn.current.click();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewColorPage
        isOpen={newCategoryModalIsOpen}
        onClose={() => setNewCategoryModalIsOpen(false)}
        onSave={handleAddColor}
        baseURL={baseURL}
      />
      <EditColorPage
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        editedColor={editedColor}
        onSave={editedColor}
        baseURL={baseURL}
        Update={getColors}
      />
      <EyeModal
        isOpen={eyeModalIsOpen}
        onClose={() => setEyeModalIsOpen(false)}
        color={selectedColor}
      />
      <WarningFileSizeModal
        openModal={openWarningModal}
        closeModal={handleCloseWarningModal}
        warningText={warningText}
        wrongRows={wrongRows}
        typeImport={importType}
      />
      {/*== Wrong Entries Warning Modal (Color Img) ==*/}

      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => {
          setWarningModal(false);
        }}
        warningText={warningMsg}
        wrongEntries={true}
        forcePostFunc={async () => {
          console.log(colorImgFile);
          const response = await UploadImage({
            zipFile: colorImgFile,
            forcePOST: true,
          });
          console.log(response);
          if (response) {
            setImportType("color_image");
            handleImport(response[0], response[1], true);
          }
        }}
        wrongRows={wrongRows}
        typeImport={importType}
      />

      {/*=====================================*/}
      <WarningFileSizeModal
        openModal={openSuccessModal}
        closeModal={() => setOpenSuccessModal(false)}
        warningText={"Data Added Succsesfully"}
      />
      <ModalError400
        openModal={importSuccess}
        closeModal={() => {
          setImportSuccess(false);
          window.location.reload();
        }}
        warningText={"Data Imported Succsesfully"}
      />
      {/*Color List Import */}
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          console.log("Color-List-File:", colorCSV);
          const response = await ImportDataCSV({
            type: `color_list`,
            file: colorCSV,
            url: `${baseURL}/color/import/csv`,
            updateData: async () => {
              await APICalls();
            },
          });
          setImportType("color_list");
          handleImport(response[0], response[1], false, response[2]);
        }}
        warningText="Are you sure you want to upload this file?"
        openModal={colorConfirmUpload}
        closeModal={() => {
          setColorCSV(null);
          setColorConfirmUpload(false);
        }}
      />
      {/*Formula Import */}
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          console.log("Color-Formula-File:", formulaCSV);
          const response = await ImportDataCSV({
            type: `formula`,
            file: formulaCSV,
            url: `${baseURL}/formula/import/csv`,
            updateData: async () => {
              await APICalls();
            },
          });
          setImportType("formula");
          handleImport(response[0], response[1], false, response[2]);
        }}
        warningText="Are you sure you want to upload this file?"
        openModal={formulaConfirmUpload}
        closeModal={() => {
          setFormulaCSV(null);
          setFormulaConfirmUpload(false);
        }}
      />
      {/*Color Image Import */}
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          console.log("Color-Image-File:", colorImgFile);
          const response = await UploadImage({
            zipFile: colorImgFile,
            forcePOST: false,
          });
          console.log(response);
          setImportType("color_image");
          handleImport(response[0], response[1], true, response[2]);
        }}
        warningText="Are you sure you want to upload this file?"
        openModal={colorImgConfirmUpload}
        closeModal={() => {
          setColorImgConfirmUpload(false);
        }}
      />
      <CSVTemplateModal
        isOpen={openCSVColorModal}
        onClose={() => setOpenCSVColorModal(false)}
        downloadTemplate={getTemplateColorList}
        triggerInput={() => {
          colorCSVBtn.current.click();
        }}
      />
      <CSVTemplateModal
        isOpen={openCSVFormulaModal}
        onClose={() => setOpenCSVFormulaModal(false)}
        downloadTemplate={getTemplateFormula}
        triggerInput={() => {
          formulaCSVBtn.current.click();
        }}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
