import { useEffect, useState } from "react";
import "./colorareas.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import EditCategoryArea from "../editcategoryarea/EditCategoryArea";
import NewCategoryArea from "../newcategoryarea/NewCategoryArea";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal.jsx";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import { baseURL } from "../../global_variable_.jsx";

export default function ColorAreas({ titlePage }) {
  const [allowedSubMenus, setAllowedSubMenus] = useState([
    "color-category",
    "color",
  ]);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [areas, setAreas] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error400, setOpenError400] = useState(false);
  const [editedArea, setEditedArea] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  const checkSubMenuAuth = () => {
    const allowedSubMenu = localStorage.getItem("colorAllowedComponents");
    if (allowedSubMenu) {
      setAllowedSubMenus(allowedSubMenu.split(","));
    }
  };

  useEffect(() => {
    checkSubMenuAuth();
    APICalls();
  }, []);

  const APICalls = async () => {
    await getAreas();
    setIsLoading(false);
  };

  const getAreas = async () => {
    try {
      let response = await axios.get(`${baseURL}/area?all_data=true`);
      setAreas(response.data.result[0].data);
      console.log(response);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getAreas();
  }, []);

  const openEditModal = (area) => {
    setEditedArea(area);
    setModalIsOpen(true);
  };

  const handleEditArea = async (editedData, headers) => {
    try {
      const response = await axios.put(`${baseURL}/area`, editedData, {
        headers,
      });
      console.log(response);
      console.log(editedData);
      console.log(headers);
      setModalIsOpen(false);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setModalIsOpen(false);
        setEditSuccessModal(true);
      } else {
        console.log("Error editing area");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Area Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
    }
  };

  const openNewCategoryAreaModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const handleAddArea = async (newCategory, headers) => {
    try {
      const response = await axios.post(`${baseURL}/area`, newCategory, {
        headers,
      });
      console.log(response);
      setModalIsOpen(false);
      setNewCategoryModalIsOpen(false);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setModalIsOpen(false);
        setAddSuccessModal(true);
      } else {
        console.log("Error add new area");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Area Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
    }
  };

  const deleteArea = async (areaId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log("Deleted manufacturer ID:", areaId);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      if (tesToken) {
        const response = await axios.delete(`${baseURL}/area/${areaId}`, {
          headers,
        });
        console.log(response);
        console.log(response.status);
        if (response.status === 200) {
          console.log("berhasil menghapus area");
          APICalls();
          setModalIsOpen(false);
        } else {
          console.log("Error deleting area");
        }
      } else {
        console.log("Error deleting area");
      }
    } catch (error) {
      console.error("Failed to delete area", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteArea} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    //{ field: 'id', headerName: 'ID', width: 240 },
    // { field: '', headerName: '', width: 70 },
    {
      field: "areas",
      headerName: "Area Name",
      headerClassName: "customColumnHeader",
      flex: 16,
    },
    // { field: 'manufactures', headerName: 'Manufactures', width: 325 },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];
  const btnSubMenuContainer = () => {
    return (
      <div className="outSideBtnGroup">
        {allowedSubMenus.includes("color-category") ? (
          <button className="categorybuttonAreas">Category</button>
        ) : null}
        {allowedSubMenus.includes("color") ? (
          <Link to="/color-formula">
            <button className="colorbuttonAreas">Color</button>
          </Link>
        ) : null}
      </div>
    );
  };

  let formattedAreas = [];
  if (Array.isArray(areas)) {
    formattedAreas = areas.map((area) => ({
      id: area.id,
      areas: area.name_area,
      status: area.isDeleted ? "Non-Active" : "Active",
    }));

    return isLoading !== true ? (
      <>
        <div className="colorAreasPage">
          {titlePage}
          {btnSubMenuContainer()}
          <div className="tableContainerArea">
            <div className="inSideBtnGroup">
              <Link to="/color">
                <button className="manufacturesbuttonAreas">
                  Manufactures
                </button>
              </Link>
              <Link to="/color-models">
                <button className="modelsbuttonAreas">Models</button>
              </Link>
              <Link to="/color-type">
                <button className="typebuttonAreas">Years</button>
              </Link>
              <button className="areasbuttonAreas">Areas</button>
              <ExportImportBtn
                btnType="Export"
                addStyle={{ marginLeft: `auto`, marginRight: `20px` }}
                onClick={() =>
                  DownloadDataCsv("area_list", `${baseURL}/area/csv`)
                }
              />
              <button
                onClick={openNewCategoryAreaModal}
                className="newsubcategorybuttonAreas"
              >
                Add New Area
                <AddIcon className="addIcon1" />
              </button>
            </div>
            <DataGrid
              rows={formattedAreas}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: "areas", sort: "asc" }],
                },
              }}
              pageSizeOptions={[5, 10]}
              getRowClassName={(params) => "customRow"}
              getCellClassName={(params) => "customCell"}
              disableRowSelectionOnClick={true}
            />
          </div>
        </div>
        <NewCategoryArea
          isOpen={newCategoryModalIsOpen}
          onClose={() => setNewCategoryModalIsOpen(false)}
          onSave={handleAddArea}
        />
        <EditCategoryArea
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          editedArea={editedArea}
          onSave={handleEditArea}
        />
        <ModalError400
          openModal={error400}
          closeModal={() => setOpenError400(false)}
        />
        <ModalError400
          openModal={addSuccessModal}
          closeModal={() => setAddSuccessModal(false)}
          warningText={"Data Added Succsesfully"}
        />
        <ModalError400
          openModal={editSuccessModal}
          closeModal={() => setEditSuccessModal(false)}
          warningText={"Data Edited Succsesfully"}
        />
        <WarningFileSizeModal
          openModal={warningModal}
          closeModal={() => setWarningModal(false)}
          warningText={warningMsg}
        />
      </>
    ) : (
      <div>loading...</div>
    );
  }
}
