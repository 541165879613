import { useEffect, useState } from "react";
import styles from "./changepassword.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import backgroundImg from "../../icon/loginBackground.png";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ShowPassBtn from "../../components/button/show_pass_btn/show_pass_btn";
import { baseURL } from "../../global_variable_.jsx";

export default function Changepassword({ titlePage }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({ old: null, new: null, confirm: null });
  const [success, setSuccess] = useState(false);

  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const initialName = localStorage.getItem("currAdmin").name;
  const [name, setName] = useState(initialName || "");
  const navigate = useNavigate();

  useEffect(() => {
    setError({ old: null, new: null, confirm: null });
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }, []);

  const fieldsValidation = () => {
    const oldPassIsValid = oldPassValidation();
    const newPassIsValid = newPassValidation();
    const confirmPassIsValid = confirmPassValidation();

    if (oldPassIsValid && newPassIsValid && confirmPassIsValid) {
      return true;
    }

    return false;
  };

  const handleInputOldPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setOldPassword(string);
  };
  const handleInputNewPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setNewPassword(string);
  };
  const handleInputConfirmPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setConfirmPassword(string);
  };

  const oldPassValidation = () => {
    //isEmpty
    if (oldPassword.length === 0) {
      setError((prev) => ({ ...prev, old: "Please fill Old Password field." }));
      return false;
    }

    setError((prev) => ({ ...prev, old: null }));
    return true;
  };

  const newPassValidation = () => {
    //isEmpty;
    if (newPassword.length === 0) {
      setError((prev) => ({ ...prev, new: "Please fill New Password field." }));
      return false;
    }

    setError((prev) => ({ ...prev, new: null }));
    return true;
  };

  const confirmPassValidation = () => {
    if (newPassValidation()) {
      //isEmpty;
      if (confirmPassword.length === 0) {
        setError((prev) => ({
          ...prev,
          confirm: "Please fill Confirm Password field.",
        }));
        return false;
      }
      //Confirm == New
      if (confirmPassword !== newPassword) {
        setError((prev) => ({
          ...prev,
          confirm: "Confirm Password must be the same as New Password.",
        }));
        return false;
      }
      setError((prev) => ({ ...prev, confirm: null }));
      return true;
    }

    setError((prev) => ({ ...prev, confirm: null }));
    return false;
  };

  const handleChangePassword = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.put(
        `${baseURL}/admin/password`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        { headers }
      );
      if (response.status === 200) {
        console.log("berhasil");
        // navigate("/dashboard");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setSuccess(true);
      }
    } catch (error) {
      console.log("Login error:", error.response.data.result);
      if (error.response.data.result === "Your Old Password is Wrong") {
        setError((prev) => ({ ...prev, old: "Old Password is wrong." }));
        setOldPassword("");
        return false;
      }
      setError("An error occurred during login.");
    }
  };

  const imgContainerStyle = { backgroundImage: `url(${backgroundImg})` };

  const [oldShowPass, setOldShowPass] = useState(`password`);
  const [newShowPass, setNewShowPass] = useState(`password`);
  const [confirmShowPass, setConfirmShowPass] = useState(`password`);

  const toggleShowPass = (inputField) => {
    console.log(inputField);
    switch (inputField) {
      case "old": {
        console.log(`old`);
        oldShowPass === `password`
          ? setOldShowPass(`text`)
          : setOldShowPass(`password`);
        break;
      }
      case "new": {
        console.log(`new`);
        newShowPass === `password`
          ? setNewShowPass(`text`)
          : setNewShowPass(`password`);
        break;
      }
      case "confirm": {
        console.log(`confirm`);
        confirmShowPass === `password`
          ? setConfirmShowPass(`text`)
          : setConfirmShowPass(`password`);
        break;
      }
    }
  };

  const marginBtnShowPass = { marginRight: `16px` };

  return (
    <>
      <div className={styles.OuterContainer}>
        {titlePage}
        <div
          className={`${styles.changePassContainer} `}
          style={imgContainerStyle}
        >
          <div className={`${styles.greetingText}`}>Change Password</div>
          <div className={`${styles.normalText} ${styles.pleaseText}`}>
            {" "}
            Please Enter The Fields Below
          </div>
          <div className={styles.changePassForm}>
            <div className={styles.inputContainer}>
              <label className={`${styles.normalText} ${styles.topLabelText}`}>
                Old Password
              </label>
              <div className={styles.inputBox}>
                <input
                  type={oldShowPass}
                  placeholder="Old Password"
                  className={`${styles.normalText} ${styles.inputField}`}
                  value={oldPassword}
                  onChange={(e) => handleInputOldPassword(e.target.value)}
                />
                <ShowPassBtn
                  onClick={() => {
                    toggleShowPass(`old`);
                  }}
                  state={oldShowPass}
                  inputStyle={marginBtnShowPass}
                />
              </div>

              {error.old !== null ? (
                <div className={styles.errorMsg}>{error.old}</div>
              ) : null}
            </div>
            <div className={styles.inputContainer}>
              <label className={`${styles.normalText} ${styles.labelText}`}>
                New Password
              </label>
              <div className={styles.inputBox}>
                <input
                  type={newShowPass}
                  placeholder="New Password"
                  className={`${styles.normalText} ${styles.inputField}`}
                  value={newPassword}
                  onChange={(e) => handleInputNewPassword(e.target.value)}
                />
                <ShowPassBtn
                  onClick={() => {
                    toggleShowPass(`new`);
                  }}
                  state={newShowPass}
                  inputStyle={marginBtnShowPass}
                />
              </div>
              {error.new !== null ? (
                <div className={styles.errorMsg}>{error.new}</div>
              ) : null}
            </div>
            <div className={styles.inputContainer}>
              <label className={`${styles.normalText} ${styles.labelText}`}>
                Confirm Password
              </label>
              <div className={styles.inputBox}>
                <input
                  type={confirmShowPass}
                  placeholder="Confirm Password"
                  className={`${styles.normalText} ${styles.inputField}`}
                  value={confirmPassword}
                  onChange={(e) => handleInputConfirmPassword(e.target.value)}
                />
                <ShowPassBtn
                  onClick={() => {
                    toggleShowPass(`confirm`);
                  }}
                  state={confirmShowPass}
                  inputStyle={marginBtnShowPass}
                />
              </div>
              {error.confirm !== null ? (
                <div className={styles.errorMsg}>{error.confirm}</div>
              ) : null}
            </div>

            <button
              onClick={() => {
                if (!fieldsValidation()) return;
                handleChangePassword();
              }}
              className={styles.submitBtn}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
      <WarningFileSizeModal
        closeModal={() => setSuccess(false)}
        openModal={success}
        warningText="Successfully Change Your Password."
      />
    </>
  );
}
