// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show_pass_btn_toggleShowPassBtn__6IMD4{
    cursor: pointer;
    background-color: transparent;
    border: 0;
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/button/show_pass_btn/show_pass_btn.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,6BAA6B;IAC7B,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".toggleShowPassBtn{\n    cursor: pointer;\n    background-color: transparent;\n    border: 0;\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleShowPassBtn": `show_pass_btn_toggleShowPassBtn__6IMD4`
};
export default ___CSS_LOADER_EXPORT___;
