import React from "react";
import favouriteIcon from "./Favourite.png";
import FavouriteIconOn from "./FavouriteIconOn.png";
import "./customIcon.css";

const CustomFavourite = ({ onClick, clickedProduct }) => (
  <div className="custom-icon">
    <button className="icon-button" onClick={onClick}>
      {clickedProduct.favourite === false ? (
        <img src={favouriteIcon} alt="Favourite Icon" />
      ) : (
        <img src={FavouriteIconOn} alt="Favourite Icon" />
      )}
    </button>
  </div>
);

export default CustomFavourite;
