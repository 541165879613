import React, { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "./newroleuser.css";
import { Box } from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from "@mui/icons-material/Circle";
import { useRef } from "react";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function NewRoleUser({ titlePage }) {
  const navigate = useNavigate();

  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const roleMenu = useRef([]);
  const menuLength = useRef(0);

  const createDefaultRoleMenu = () => {
    // if (!Array.isArray(roleMenu.current)) {
    //   roleMenu.current = [];
    // }
    // console.log(menuList);
    // Object.keys(menuList).forEach((menu, parentIdx) => {
    //   // console.log(parentIdx);
    //   if (!Array.isArray(roleMenu.current[parentIdx])) {
    //     roleMenu.current[parentIdx] = [];
    //   }
    //   console.log(menu);
    //   menuList[menu].forEach((list, childIdx) => {
    //     console.log(list, parentIdx, childIdx);
    //     roleMenu.current[parentIdx][childIdx] = {
    //       id_menu: list.id,
    //       id_role: null,
    //       is_visible: true,
    //     };
    //   });
    // });

    console.log(menuList);
    Object.keys(menuList).forEach((menu) => {
      menuList[menu].forEach((list, childIdx) => {
        console.log(list, list.id, list.name_menu);
        roleMenu.current[list.id] = {
          id_menu: list.id,
          id_role: null,
          is_visible: true,
          name: list.name_menu,
        };
      });
    });
    console.log(roleMenu);
  };

  const handleChangeVisibility = (id, value) => {
    roleMenu.current[id] = {
      ...roleMenu.current[id],
      is_visible: value,
    };
    console.log(roleMenu);
  };

  const getRoleUser = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/role-admin?all_data=true`;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      let response = await axios.get(`${url}role`, {
        headers: { Authorization: `Bearer ${currAdmin.token}` },
      });
      console.log(response);
      setUsers(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getListMenu = async () => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${baseURL}/menu`, { headers });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        menuLength.current = response.data.result[0].data.length;
        platformSpliter(response.data.result[0].data);
        // setMenuList(response.data.result[0].data);

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  useEffect(() => {
    if (menuList) {
      createDefaultRoleMenu();
    }
    // console.log(roleMenu.current);
  }, [menuList]);

  const getAPI = async () => {
    await getRoleUser();
    await getListMenu();
    setIsLoading(false);
  };

  const platformSpliter = (listMenu) => {
    if (!Array.isArray(listMenu)) {
      return;
    }
    var platformList = [];
    var formattedMenu = {};
    listMenu.map((menu) => {
      let num = 0;
      if (!platformList.includes(menu.platform_type)) {
        platformList[num] = menu.platform_type;
        num++;
        formattedMenu[menu.platform_type] = [
          {
            id: menu.id,
            name_menu: menu.name_menu,
          },
        ];
      } else {
        formattedMenu[menu.platform_type].push({
          id: menu.id,
          name_menu: menu.name_menu,
        });
      }
    });
    setMenuList(formattedMenu);
    console.log(formattedMenu);
  };

  const textFormater = (string) => {
    string = string.replace(`_`, " ");
    return string
      .split(" ")
      .map((char) => char.charAt(0).toUpperCase() + char.slice(1))
      .join(" ");
  };

  const columns = [
    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 0.25,
    },

    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 0.25,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Deleted" : "Active"}
          </div>
        );
      },
    },
  ];
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);

  const addNewRoleName = async (roleNamE) => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.post(
        `${baseURL}/role`,
        { role_name: roleNamE },
        { headers }
      );
      if (response.data.status === 201) {
        console.log(response.data.result.id);
        return [true, response.data.result.id];
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Role Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.error(error);
      return [false];
    }
  };

  const addAllNewRoleMenu = async (roleID) => {
    console.log(roleID);
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    const requests = [];
    roleMenu.current.forEach((list) => {
      console.log(list);
      const request = axios.post(
        `${baseURL}/role-menu`,
        {
          id_menu: `${list.id_menu}`,
          id_role: `${roleID}`,
          is_visible: `${list.is_visible}`,
        },
        { headers }
      );
      console.log(request);
      requests.push(request);
    });
    console.log(requests);
    try {
      const responses = await Promise.all(requests);
      requests.map((e) => console.log(e));
      console.log(responses);
      console.warn("All requests succeeded");
      return true;
    } catch (error) {
      console.warn("Some requests failed");
      console.error(error);
      return false;
    }
  };

  const [roleNameError, setRoleNameError] = useState(null);

  const roleNameIsEmpty = () => {
    if (roleName.length === 0) {
      setRoleNameError("Please enter a Role Name.");
      return false;
    }
    setRoleNameError(null);
    return true;
  };

  const handleSubmit = async () => {
    if (roleNameIsEmpty()) {
      const response = await addNewRoleName(roleName);
      if (!response[0]) {
        return;
      }
      const id = response[1];
      const response2 = await addAllNewRoleMenu(id);
      if (!response2) {
        return;
      }
    }
    setAddSuccessModal(true);
  };

  const [confirmModal, setConfirmModal] = useState(false);

  return !isLoading ? (
    <>
      <div className="newrolecms">
        {titlePage}
        <Box sx={styleBox}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <label className="textLabelNewRoleUserMobile">Role Name</label>

              <div className="inputContainerNewRoleUserMobile">
                <input
                  type="text"
                  value={roleName}
                  className="customFieldUser"
                  onChange={(e) => {
                    setRoleName(e.target.value.trim());
                  }}
                />
              </div>
              <div style={{ display: `flex`, marginTop: `25px` }}>
                {Object.keys(menuList).map((menu, parentIdx) => (
                  <>
                    <br />
                    <div key={menu}>
                      <h3>{textFormater(menu)}</h3>
                      <br />
                      {menuList[menu]
                        .filter(
                          (menu) =>
                            menu.name_menu.toLowerCase() != "about-us" &&
                            menu.name_menu.toLowerCase() != "edit-profile"
                        )
                        .map((list, childIdx) => (
                          <FormControl
                            key={list.id}
                            style={{
                              marginRight: `20px`,
                              marginBottom: `25px`,
                            }}
                          >
                            <FormLabel
                              style={{ textDecoration: `underline` }}
                              id="demo-radio-buttons-group-label"
                            >
                              {textFormater(list.name_menu)}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue={true}
                              name="radio-buttons-group"
                              onChange={(e) => {
                                console.log(e.target);
                                handleChangeVisibility(list.id, e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Show"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Hide"
                              />
                            </RadioGroup>
                            <hr />
                          </FormControl>
                        ))}
                    </div>
                  </>
                ))}
              </div>
            </Grid>
            <Grid
              style={{ display: `flex`, justifyContent: `end` }}
              item
              xs={12}
            >
              <button
                onClick={() => setConfirmModal(true)}
                className="saveButtonNewRoleUser"
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Box>
      </div>
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => {
          navigate(-1);
          setAddSuccessModal(false);
        }}
        warningText={"Data Added Succsesfully"}
      />
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          await handleSubmit();
        }}
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
