import { useEffect, useState } from "react";
import "./color.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import EditCategoryManufacturer from "../editcategorymanufacturer/EditCategoryManufacturer";
import NewCategoryManufacturer from "../newcategorymanufacturer/NewCategoryManufacturer";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ModalError400 from "../../components/ModalError400/ModalError400";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import { baseURL } from "../../global_variable_.jsx";

export default function Color({ titlePage }) {
  const [allowedSubMenus, setAllowedSubMenus] = useState([
    "color-category",
    "color",
  ]);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [cars, setCars] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedManufacturer, setEditedManufacturer] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [error400, setOpenError400] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  useEffect(() => {
    checkSubMenuAuth();
    APICalls();
  }, []);

  const APICalls = async () => {
    await getCars();
    setIsLoading(false);
  };

  const getCars = async () => {
    try {
      let response = await axios.get(`${baseURL}/manufacturer?all_data=true`);
      setCars(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const checkSubMenuAuth = () => {
    const allowedSubMenu = localStorage.getItem("colorAllowedComponents");
    if (allowedSubMenu) {
      setAllowedSubMenus(allowedSubMenu.split(","));
    }
  };

  useEffect(() => {
    getCars();
  }, []);

  const openEditModal = (manufacturer) => {
    setEditedManufacturer(manufacturer);
    setModalIsOpen(true);
  };

  // edit manufacturer
  const handleEditManufacturer = async (editedData, headers) => {
    try {
      const response = await axios.put(`${baseURL}/manufacturer`, editedData, {
        headers,
      });
      console.log(response);
      setModalIsOpen(false);
      console.log(editedData);
      console.log(headers);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setEditSuccessModal(true);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Manufacturer Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.error("Gagal mengupdate manufacturer:", error);
      return false;
    }
  };

  const openNewCategoryManufacturerModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  // add Manufacturer
  const handleAddManufacturer = async (newCategory, headers) => {
    try {
      const response = await axios.post(
        `${baseURL}/manufacturer`,
        newCategory,
        { headers }
      );
      console.log(newCategory);
      console.log(response);
      if (response.status === 201) {
        setNewCategoryModalIsOpen(false);
        console.log("Manufacturer created successfully!");
        setAddSuccessModal(true);
      } else {
        console.log("Manufacturer creation failed.");
      }
      // setNewCategoryModalIsOpen(false);
      console.log("tes submit");
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Manufacturer Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.error("Gagal menambahkan manufacturer:", error);
      return false;
    }
  };

  // delete manufacturer
  const deleteManufacturer = async (manufacturerId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log("Deleted manufacturer ID:", manufacturerId);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      if (tesToken) {
        const response = await axios.delete(
          `${baseURL}/manufacturer/${manufacturerId}`,
          { headers }
        );
        console.log(response);
        // console.log(response.status)
        if (response.status === 200) {
          console.log("berhasil menghapus manufacturer");
          APICalls();
          setModalIsOpen(false);
        } else {
          console.log("Error deleting manufacturer");
        }
      } else {
        console.log("Error deleting manufacturer");
      }
    } catch (error) {
      console.error("Failed to delete manufacturer:", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteManufacturer} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit onClick={() => openEditModal(params.row)} />
      ),
    },

    {
      field: "category",
      headerName: "Manufacturer Name",
      headerClassName: "customColumnHeader",
      flex: 16,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  let formattedCars = [];
  if (Array.isArray(cars)) {
    formattedCars = cars.map((car) => ({
      id: car.id,
      category: car.name_manufacturer,
      status: car.isDeleted ? "Non-Active" : "Active",
    }));
  }

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const btnSubMenuContainer = () => {
    return (
      <div className="outSideBtnGroup">
        {allowedSubMenus.includes("color-category") ? (
          <button className="categorybutton1">Category</button>
        ) : null}
        {allowedSubMenus.includes("color") ? (
          <Link to="/color-formula">
            <button className="colorbutton1">Color</button>
          </Link>
        ) : null}
      </div>
    );
  };

  return isLoading !== true ? (
    <>
      <div className="colorPage">
        {titlePage}
        {btnSubMenuContainer()}
        <div className="tableContainerManufacture">
          <div className="inSideBtnGroup">
            <button className="manufacturesbutton">Manufactures</button>
            <Link to="/color-models">
              <button className="modelsbutton1">Models</button>
            </Link>
            <Link to="/color-type">
              <button className="typebutton1">Year</button>
            </Link>
            <Link to="/color-areas">
              <button className="areasbutton1">Areas</button>
            </Link>
            <ExportImportBtn
              btnType="Export"
              addStyle={{ marginLeft: `auto`, marginRight: `20px` }}
              onClick={() =>
                DownloadDataCsv(
                  "manufacturer_list",
                  `${baseURL}/manufacturer/csv`
                )
              }
            />
            <button
              onClick={openNewCategoryManufacturerModal}
              className="newsubcategorybtn-modal"
            >
              Add New Manufacturer
              <AddIcon className="addIcon1" />
            </button>
          </div>
          <DataGrid
            rows={formattedCars}
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "category", sort: "asc" }],
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
            disableRowSelectionOnClick={true}
          />
        </div>
      </div>
      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => setWarningModal(false)}
        warningText={warningMsg}
      />
      <EditCategoryManufacturer
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        editedManufacturer={editedManufacturer}
        onSave={handleEditManufacturer}
      />
      <NewCategoryManufacturer
        isOpen={newCategoryModalIsOpen}
        onClose={() => setNewCategoryModalIsOpen(false)}
        onSave={handleAddManufacturer}
      />
      <ModalError400
        openModal={error400}
        closeModal={() => setOpenError400(false)}
        warningText={"This manufacturer is already in use."}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => setAddSuccessModal(false)}
        warningText={"Data Added Successfuly"}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Successfuly"}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
