import React, { useState, useEffect } from "react";
import "./productpage.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Link } from "react-router-dom";
import CustomDelete from "../../../icon/CustomDelete";
import CustomEdit from "../../../icon/CustomEdit";
import EditProductPage from "../../../pages/editproductpage/EditProductPage"; // Import EditProductModal
import NewProductPage from "../../../pages/newproductpage/NewProductPage";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import NoImage from "../../../icon/no-img.png";
import { baseURL } from "../../../global_variable_";

export default function ProductComponent({ titlePage }) {
  useEffect(() => {
    APICalls();
  }, []);

  const APICalls = async () => {
    await getProducts();
    await getCategories();
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filterCatID, setFilterCatID] = useState("-1");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [categoryMap, setCategoryMap] = useState({});
  const [subCategoryMap, setSubCategoryMap] = useState({});
  let formattedProducts = [];

  const handleCategoryFilter = async (pickedCatID) => {
    setFilterCatID(pickedCatID);
    if (pickedCatID !== "-1") {
      await getProductsByCategory(pickedCatID);
    } else {
      await getProducts();
    }
  };

  const getProductsByCategory = async (id) => {
    //GET BY CATEGORY ID
    try {
      const response = await axios.get(
        `${baseURL}/product?id_category=${id}&pagination=false&all_data=true`
      );
      setProducts(response.data.result[0].data);
      console.log(response.data);
      return true;
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  const getProducts = async () => {
    //GET ALL
    try {
      let response = await axios.get(
        `${baseURL}/product?pagination=false&all_data=true`
      );
      setProducts(response.data.result[0].data);
      console.log(response.data.result[0].data);
      return true;
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/category?all_data=false`);
      const sortedCategories = response.data.result.sort((a, b) =>
        a.name_category.localeCompare(b.name_category)
      );
      setCategories(sortedCategories);
      console.log(sortedCategories);

      const categoryObj = {};
      sortedCategories.forEach((category) => {
        categoryObj[category.id] = category.name_category;
      });
      setCategoryMap(categoryObj);
      console.log(categoryObj);
    } catch (e) {
      console.log(e.message);
    }
  };

  // delete product
  const deleteItem = async (itemId) => {
    console.log(itemId);
    try {
      const token = JSON.parse(localStorage.getItem("currAdmin")).token;
      console.log(token);
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if (token) {
        const response = await axios.delete(`${baseURL}/product/${itemId}`, {
          headers,
        });
        console.log(response);
        if (response.data.status === 200) {
          if (filterCatID !== "-1") {
            await getProductsByCategory(filterCatID);
          } else {
            await getProducts();
          }
        }

        // setProducts(updatedProducts);
      } else {
        console.log("error delete product");
      }
    } catch (error) {
      console.error("Gagal menghapus item:", error);
    }
  };

  const openEditModal = (product) => {
    setEditedProduct(product);
    setModalIsOpen(true);
  };

  const openNewCategoryProductModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteItem} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomEdit
          onClick={() => {
            openEditModal(params.row);
          }}
        />
      ),
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "customColumnHeader",
      flex: 2,
      renderCell: (params) => {
        //console.log('Image URL:', params.row.image); // Add this line for debugging
        const imageUrl = `${baseURL}/${params.row.image}`;
        return (
          <div className="imageCol">
            <img
              className="setImage"
              src={imageUrl}
              alt={`Image of ${params.row.name}`}
              onError={(e) => {
                console.error("Error loading image:", e);
                e.target.src = NoImage;
              }}
            />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Product Name",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        // console.log(`${params.row.id}: ${params.row.isDeleted}`);
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellProduct ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === false ? "Active" : "Non-Active"}
          </div>
        );
      },
    },
  ];

  if (Array.isArray(products)) {
    formattedProducts = products.map((product) => ({
      id: product.id,
      image: product.image_product,
      name: product.name_product,
      id_category: product.id_category,
      category: categoryMap[product.id_category],
      sub_category: product.id_sub_category,
      description: product.description,
      isDeleted: product.isDeleted,
    }));
  }

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return isLoading !== true ? (
    <>
      <div className="topContent">
        <select
          defaultValue="-1"
          className="productFilter"
          value={filterCatID}
          onChange={(e) => {
            handleCategoryFilter(e.target.value);
          }}
        >
          <option className="productFilterOption" value="-1">
            - All Categories -
          </option>
          {categories.map((category) => {
            return (
              <option
                className="productFilterOption"
                key={category.id}
                value={category.id}
              >
                {category.name_category}
              </option>
            );
          })}
        </select>
        <button
          onClick={openNewCategoryProductModal}
          className="newproductbtn-modal"
        >
          Add New Product
          <AddIcon className="addIcon1" />
        </button>
      </div>

      <DataGrid
        className="productListTable"
        rows={formattedProducts}
        columns={columns}
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => "customRow"}
        getCellClassName={(params) => "customCell"}
        disableRowSelectionOnClick={true}
      />
      <EditProductPage
        currCategoryFilter={filterCatID}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        editedProduct={editedProduct}
        baseURL={baseURL}
        Update={getProducts}
      />
      <NewProductPage
        currCategoryFilter={filterCatID}
        isOpen={newCategoryModalIsOpen}
        onClose={() => setNewCategoryModalIsOpen(false)}
        baseURL={baseURL}
        Update={getProducts}
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
