import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./newcategorymodel.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  // maxHeight: "357px",
};

const NewCategoryModel = ({ isOpen, onClose, onSave }) => {
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [manufacturersData, setManufacturersData] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [modelNameError, setModelNameError] = useState("");
  const [manuError, setManuError] = useState("");

  const handleOpenModal = () => {
    const modelIsValid = validateModelName();
    const manuIsValid = validateManufacturer();
    if (modelIsValid && manuIsValid) {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/manufacturer`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          const sortedManufacturers = response.data.result[0].data.sort(
            (a, b) => a.name_manufacturer.localeCompare(b.name_manufacturer)
          );
          setManufacturersData(sortedManufacturers);
        }
      })
      .catch((error) => {
        console.error("Error fetching manufacturers:", error);
      });
  }, []);

  const handleSave = () => {
    if (validateModelName()) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log(tesToken);

      const isDeleted = isActive ? "false" : "true";

      const newCategory = {
        name_car_model: categoryName,
        id_manufacturer: selectedManufacturer,
        isDeleted: isDeleted,
      };
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };

      onSave(newCategory, headers);
    }
  };
  const validateModelName = () => {
    if (categoryName.trim().length === 0) {
      setModelNameError("Please enter a Model Name.");
      return false;
    }
    setModelNameError("");
    return true;
  };

  const validateManufacturer = () => {
    if (selectedManufacturer === "") {
      setManuError("Please pick a Manufacturer.");
      return false;
    }
    setManuError("");
    return true;
  };

  const handleModelNameChange = (e) => {
    // if (e.target.value.trim() === "") {
    //   setModelNameError("Please enter a Model Name");
    // } else {
    //   setModelNameError("");
    // }
    setCategoryName(e.target.value);
  };

  useEffect(() => {
    setCategoryName("");
    setManuError("");
    if (!isOpen) {
      setModelNameError("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>NEW MODEL</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxWidth: "491px",
              maxHeight: "307px",
              overflow: "auto",
            }}
          >
            <form>
              <div className="form-group">
                <label htmlFor="productName" className={styles.categorynamenew}>
                  Model Name
                </label>
                <br></br>
                <input
                  type="text"
                  id="categoryName"
                  className={styles.productnamenew}
                  value={categoryName}
                  onChange={handleModelNameChange}
                />
                {modelNameError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {modelNameError}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="manufacturer" className="categorynamenew">
                  Manufacturer
                </label>
                <br />
                <select
                  id="manufacturer"
                  className={styles.manufacturernamenew}
                  onChange={(e) => setSelectedManufacturer(e.target.value)}
                >
                  <option value="">Select a manufacturer</option>
                  {manufacturersData.map((manufacturer) => (
                    <option key={manufacturer.id} value={manufacturer.id}>
                      {manufacturer.name_manufacturer}
                    </option>
                  ))}
                </select>
                {manuError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {manuError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className={styles.labelstatusnew}>Status:</label>
                <br></br>
                <br></br>
                <label className={styles.radiogroupnew}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />{" "}
                  Active
                </label>
                <label className={styles.radiogroupnew2}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />{" "}
                  Non-Active
                </label>
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={handleOpenModal}
              className={styles.btnGroupNewCategoryProduct}
            >
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={onClose}
              className={styles.btnGroupNewCategoryProduct}
            >
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewCategoryModel;
