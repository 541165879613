import React, { useState } from "react";
import { useEffect } from "react";
import "./newrolecms.css";
import { Box } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useRadioGroup } from "@mui/material/RadioGroup";
import Grid from "@mui/material/Unstable_Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function NewRoleCms({ titlePage }) {
  const navigate = useNavigate();

  const [roleName, setRoleName] = useState("");
  const [menuStates, setMenuStates] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [listMenu, setListMenu] = useState([]);
  const [sublistMenu, setSubListMenu] = useState({});
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [selectedRow, setSelectRow] = useState(null);
  const [submenuStates, setSubmenuStates] = useState({});
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [roleNameError, setRoleNameError] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);

  const handleSelectRow = (row) => {
    setSelectRow(row);
  };

  const [roleCMSList, setRoleCMSList] = useState([]);
  useEffect(() => {
    APICalls();
  }, []);

  useEffect(() => {
    const initialSubmenuStates = {};
    Object.keys(sublistMenu).forEach((menuId) => {
      const submenus = sublistMenu[menuId];
      const matchingSubmenus = submenus.filter(
        (submenu) => submenu.parent_id === menuId
      );
      matchingSubmenus.forEach((submenu) => {
        initialSubmenuStates[submenu.id] = "show";
      });
    });
    setSubmenuStates(initialSubmenuStates);
  }, [sublistMenu]);

  useEffect(() => {
    const initialMenuStates = {};
    listMenu.forEach((menu) => {
      initialMenuStates[menu.id] = "show";
    });
    setMenuStates(initialMenuStates);
  }, [listMenu]);

  const getMenuStates = (menuId) => {
    return menuStates[menuId] ? "show" : "hide";
  };

  // const getSubmenuStates = (menuId, submenuId) => {
  //   return submenuStates[menuId] && submenuStates[menuId][submenuId]
  //     ? "hide"
  //     : "show";
  // };
  const getSubmenuStates = (menuId, submenuId) => {
    if (getMenuStates(menuId) === "hide") {
      return "hide";
    }

    return submenuStates[menuId] && submenuStates[menuId][submenuId]
      ? "hide"
      : "show";
  };

  const APICalls = async () => {
    await getRoleCMS();
    await getMenuCMS();
    await getSubMenuCMS();
    setLoading(false);
  };

  const roleNameIsEmpty = () => {
    const trimmedRoleName = roleName.trim();

    if (trimmedRoleName.length === 0) {
      setRoleNameError("Please enter a Role Name.");
      return false;
    }

    setRoleNameError(null);
    return true;
  };

  const getRoleCMS = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/role-admin?all_data=true`;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${url}`, { headers });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        setRoleCMSList(response.data.result[0].data);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getMenuCMS = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/admin-menu`;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${url}`, { headers });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        setListMenu(response.data.result[0].data);
        const MenuIds = response.data.result[0].data.map((item) => item.id);
        console.log("Menu IDs:", MenuIds);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getSubMenuCMS = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = `${baseURL}/admin-menu`;
    const headers = { Authorization: `Bearer ${token}` };
    const params = { parent: "all" };

    try {
      const response = await axios.get(`${url}`, { headers, params });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        const submenus = response.data.result[0].data;

        const subMenuMap = submenus.reduce((acc, submenu) => {
          const parentId = submenu.parent_id || "root";
          if (!acc[parentId]) {
            acc[parentId] = [];
          }
          acc[parentId].push(submenu);
          return acc;
        }, {});

        setSubListMenu(subMenuMap);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleRadioChange = async (menuId, value) => {
    setMenuStates((prevStates) => ({
      ...prevStates,
      [menuId]: value === "show",
    }));
  };

  const handleSubmenuChange = (menuId, submenuId, value) => {
    setSubmenuStates((prevStates) => ({
      ...prevStates,
      [menuId]: {
        ...(prevStates[menuId] || {}),
        [submenuId]: value === "hide",
      },
    }));
  };

  const handleSave = async () => {
    console.log("name:", roleName);
    console.log("-- Menu States --");
    listMenu.forEach((menu) => {
      const menuState = menuStates[menu.id] ? true : false;
      console.log(`${menu.name_menu}: ${menuState}`, `${menu.id}`);
      if (sublistMenu[menu.id]) {
        console.log("-- Submenu States --");
        sublistMenu[menu.id].forEach((submenu) => {
          const submenuState =
            submenuStates[menu.id] && submenuStates[menu.id][submenu.id]
              ? true
              : false;
          console.log(`${submenu.name_menu}: ${submenuState}: ${submenu.id}`);
        });
      }
    });

    if (roleNameIsEmpty()) {
      const response = await addNewRoleAdmin();

      if (response[0]) {
        const roleID = response[1];
        const response2 = await addNewAdminroleMenu(roleID);
        const response3 = await addNewAdminSubMenu(roleID);

        if (response2 && response3) {
          console.log("Menus and Sub Menus added successfully");
          setOpenSuccessModal(true);
          APICalls();
        } else {
          console.error("Failed to add menus or submenus");
        }
      } else {
        console.error("Failed to add role");
      }
    }
  };

  // add new role admin name
  const addNewRoleAdmin = async () => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.post(
        `${baseURL}/role-admin`,
        { role_name: roleName },
        { headers }
      );

      console.log(response);

      if (response.data.status === 201) {
        console.log(response.data.result.id);
        return [true, response.data.result.id];
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Role Name has to be unique.");
        setWarningModal(true);
      }
      return [false];
    }
  };

  const addNewAdminroleMenu = async (roleID) => {
    console.log(roleID);
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    const requests = [];
    listMenu.forEach((list) => {
      const request = axios.post(
        `${baseURL}/admin-role-menu`,
        {
          id_admin_menu: `${list.id}`,
          id_role_admin: `${roleID}`,
          is_visible: `${getMenuStates(list.id)}`,
        },
        { headers }
      );
      console.log(request);
      requests.push(request);
    });
    console.log(requests);
    try {
      const responses = await Promise.all(requests);
      requests.map((e) => console.log(e));
      console.log(responses);
      console.warn("All menu upload success");
      return true;
    } catch (error) {
      console.warn("Some menu upload failed");
      console.error(error);
      return false;
    }
  };

  const addNewAdminSubMenu = async (roleID) => {
    console.log(roleID);
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };

    const requests = [];
    Object.keys(sublistMenu).forEach((parentId) => {
      sublistMenu[parentId].forEach((submenu) => {
        const request = axios.post(
          `${baseURL}/admin-role-menu`,
          {
            id_admin_menu: `${submenu.id}`,
            id_role_admin: `${roleID}`,
            is_visible: `${getSubmenuStates(parentId, submenu.id)}`,
          },
          { headers }
        );
        requests.push(request);
      });
    });

    try {
      const responses = await Promise.all(requests);
      console.log(responses);
      console.warn("All sub menu upload success");
      return true;
    } catch (error) {
      console.warn("Some sub menu upload failed");
      console.error(error);
      return false;
    }
  };

  const columns = [
    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 5,
    },
    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 2,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Deleted" : "Active"}
          </div>
        );
      },
    },
  ];

  return !isLoading ? (
    <div className="newrolecms">
      {titlePage}
      <Box sx={styleBox}>
        {/* column rolename */}
        <Grid
          container
          spacing={8}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={6}>
            <label className="textLabelNewRoleCMS">Role Name</label>

            <div className="inputContainerNewRoleCMS">
              <input
                type="text"
                value={roleName}
                className="customFieldCMS"
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
            {roleNameError && (
              <div style={{ color: "#BE1620", marginTop: "5px" }}>
                {roleNameError}
              </div>
            )}
          </Grid>
        </Grid>
        {/* grid buat menu */}
        <Grid
          container
          spacing={10}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* column 1 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu, index) => index < 5 && !sublistMenu[menu.id])
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "5px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "25px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 2 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu, index) => index >= 5 && !sublistMenu[menu.id])
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "5px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "25px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 3 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu) => sublistMenu[menu.id])
                .slice(0, 1)
                .map((menu) => (
                  <div key={menu.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "8px",
                        position: "relative",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "22px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                    <div
                      style={{
                        paddingLeft: "33px",
                        paddingRight: "0px",
                        marginTop: "20px",
                      }}
                    >
                      {sublistMenu[menu.id] && (
                        <FormControl>
                          {sublistMenu[menu.id].map((submenu) => (
                            <div
                              key={submenu.id}
                              style={{
                                marginTop: "7px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderBottom: "1px solid #000",
                                  paddingBottom: "5px",
                                }}
                              >
                                <FormLabel
                                  id={`demo-radio-buttons-group-label-${submenu.id}`}
                                >
                                  {submenu.name_menu}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={`demo-radio-buttons-group-label-${submenu.id}`}
                                  name={`radio-buttons-group-${submenu.id}`}
                                  value={getSubmenuStates(menu.id, submenu.id)}
                                  onChange={(e) =>
                                    handleSubmenuChange(
                                      menu.id,
                                      submenu.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={getMenuStates(menu.id) === "hide"}
                                >
                                  <FormControlLabel
                                    value="show"
                                    control={<Radio />}
                                    label="Show"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                  <FormControlLabel
                                    value="hide"
                                    control={<Radio />}
                                    label="Hide"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          ))}
                        </FormControl>
                      )}
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>

          {/* column 4 */}
          <Grid>
            <FormControl>
              {listMenu
                .filter((menu) => sublistMenu[menu.id])
                .slice(1, 2)
                .map((menu) => (
                  <div
                    key={menu.id}
                    style={{ marginBottom: "20px", position: "relative" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderBottom: "1px solid #000",
                        paddingBottom: "8px",
                      }}
                    >
                      <FormLabel
                        id={`demo-radio-buttons-group-label-${menu.id}`}
                        style={{
                          marginTop: "22px",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                      >
                        {menu.name_menu}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby={`demo-radio-buttons-group-label-${menu.id}`}
                        name={`radio-buttons-group-${menu.id}`}
                        value={getMenuStates(menu.id)}
                        onChange={(e) =>
                          handleRadioChange(menu.id, e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="show"
                          control={<Radio />}
                          label="Show"
                        />
                        <FormControlLabel
                          value="hide"
                          control={<Radio />}
                          label="Hide"
                        />
                      </RadioGroup>
                    </div>
                    <div
                      style={{
                        marginLeft: "33px",
                        marginTop: "15px",
                      }}
                    >
                      {sublistMenu[menu.id] && (
                        <FormControl>
                          {sublistMenu[menu.id].map((submenu) => (
                            <div
                              key={submenu.id}
                              style={{
                                marginTop: "7px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderBottom: "1px solid #000",
                                  paddingBottom: "5px",
                                }}
                              >
                                <FormLabel
                                  id={`demo-radio-buttons-group-label-${submenu.id}`}
                                >
                                  {submenu.name_menu}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby={`demo-radio-buttons-group-label-${submenu.id}`}
                                  name={`radio-buttons-group-${submenu.id}`}
                                  value={getSubmenuStates(menu.id, submenu.id)}
                                  onChange={(e) =>
                                    handleSubmenuChange(
                                      menu.id,
                                      submenu.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={getMenuStates(menu.id) === "hide"}
                                >
                                  <FormControlLabel
                                    value="show"
                                    control={<Radio />}
                                    label="Show"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                  <FormControlLabel
                                    value="hide"
                                    control={<Radio />}
                                    label="Hide"
                                    disabled={getMenuStates(menu.id) === "hide"}
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          ))}
                        </FormControl>
                      )}
                    </div>
                  </div>
                ))}
            </FormControl>
          </Grid>
        </Grid>

        {/* save button */}
        <Grid
          container
          spacing={8}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid>
            <button
              className="saveButtonNewRoleCMS"
              onClick={() => setConfirmModal(true)}
            >
              Save
            </button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmSubmitChanges
        handleSubmitData={async () => {
          await handleSave();
        }}
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
      />
      <WarningFileSizeModal
        openModal={openSuccessModal}
        closeModal={() => {
          navigate(-1);
          setOpenSuccessModal(false);
        }}
        warningText={"Data Added Succsesfully"}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
    </div>
  ) : (
    <div>loading...</div>
  );
}
