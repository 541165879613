import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./editcolorpage.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import ModalError400 from "../../components/ModalError400/ModalError400";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import NoImage from "../../icon/no-img.png";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
};

const EditColorPage = ({
  isOpen,
  onClose,
  editedColor,
  onSave,
  baseURL,
  Update,
}) => {
  const [warningErrModal, setWarningErrModal] = useState(false);
  const [warningErrMsg, setWarningErrMsg] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [colorName, setColorName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [manufactures, setManufactures] = useState([]);
  const [models, setModels] = useState([]);
  const [yearsOfProduction, setYearsOfProduction] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedManufacture, setSelectedManufacture] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYearOfProduction, setSelectedYearOfProduction] =
    useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [optionManufacture, setOptionManufacture] = useState("");
  const [optionModel, setOptionModel] = useState("");
  const [optionYearOfProduction, setOptionYearOfProduction] = useState("");
  const [optionArea, setOptionArea] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const [imgChanged, setImgChanged] = useState(false);
  const [filteredOptionModel, setFilteredOptionModel] = useState("");
  const [editSuccessModal, setEditSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    colorName: "",
    colorCode: "",
    manu: "",
    model: "",
    YoP: "",
    area: "",
  });

  useEffect(() => {
    setColorName("");
    setColorCode("");
    setSelectedManufacture("");
    setSelectedModel("");
    setSelectedYearOfProduction(null);
    setSelectedArea(null);

    setErrorMsg({
      area: "",
      colorCode: "",
      colorName: "",
      manu: "",
      model: "",
      YoP: "",
    });
  }, [isOpen]);

  useEffect(
    () => {
      if (editedColor) {
        setColorName(editedColor.cName);
        setColorCode(editedColor.code);
        setManufactures(editedColor.idManufacturer);
        setSelectedManufacture(editedColor.idManufacturer);
        setModels(editedColor.idModel);
        console.log(editedColor.idYearOfProduction);
        setYearsOfProduction(editedColor.idYearOfProduction);
        setAreas(editedColor.idArea);
        setImage(editedColor.image);
        setSelectedModel(editedColor.idModel);
      }
      setPreview("");
      setImgChanged(false);
      setShowPreview(true);

      axios
        .get(`${baseURL}/manufacturer`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedOptionManufacture = response.data.result[0].data.sort(
              (a, b) => a.name_manufacturer.localeCompare(b.name_manufacturer)
            );
            setOptionManufacture(sortedOptionManufacture);
            // console.log(response.data.result[0].data)
            const selectedManufacturerData = response.data.result[0].data.find(
              (manufacturer) => manufacturer.id === editedColor.idManufacturer
            );
            if (selectedManufacturerData) {
              setSelectedManufacture(
                selectedManufacturerData.name_manufacturer
              );
            } else {
              console.error(
                "Tidak dapat menemukan data manufacturer yang sesuai."
              );
            }
          } else {
            console.error(
              "Data manufactures yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data manufactures:", error);
        });

      axios
        .get(`${baseURL}/car-model`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            setOptionModel(response.data.result[0].data);

            const filteredModels = response.data.result[0].data.filter(
              (model) => model.id_manufacturer === editedColor?.idManufacturer
            );

            filteredModels.sort((a, b) =>
              a.name_car_model.localeCompare(b.name_car_model)
            );

            setFilteredOptionModel(filteredModels);
            console.log(filteredModels);
          } else {
            console.error(
              "Data models yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data models:", error);
        });

      axios
        .get(`${baseURL}/year-of-production`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedYearOfProduction = response.data.result[0].data;
            sortedYearOfProduction.sort((a, b) => a.id - b.id);

            setOptionYearOfProduction(sortedYearOfProduction);
            const selectedYearData = response.data.result[0].data.find(
              (year) => year.id === editedColor.idYearOfProduction
            );
            // const selectedYearData = response.data.result[0].data;
            console.log(selectedYearData);
            if (selectedYearData) {
              setSelectedYearOfProduction(selectedYearData.id);
              console.log(selectedYearData.production_year);
            } else {
              setSelectedYearOfProduction(null);
              console.error("Tidak dapat menemukan data year yang sesuai.");
            }
          } else {
            console.error(
              "Data years of production yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data years of production:", error);
        });

      axios
        .get(`${baseURL}/area`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedAreas = response.data.result[0].data;
            sortedAreas.sort((a, b) => a.id - b.id);
            console.log(sortedAreas);

            const uniqueNamesSet = new Set();
            const distinctAreaNames = sortedAreas.filter((area) => {
              if (!uniqueNamesSet.has(area.name_area)) {
                uniqueNamesSet.add(area.name_area);
                return true;
              }
              return false;
            });
            setOptionArea(distinctAreaNames);
            const selectedAreaData = response.data.result[0].data.find(
              (area) => area.id === editedColor.idArea
            );
            console.log(selectedAreaData);
            if (selectedAreaData) {
              setSelectedArea(selectedAreaData.id);
              console.log(selectedAreaData.name_area);
            } else {
              setSelectedArea(null);
              console.error("Tidak dapat menemukan data year yang sesuai.");
            }
          } else {
            console.error(
              "Data areas yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data areas:", error);
        });
      console.log(selectedYearOfProduction);
      console.log(selectedArea);
    },
    [editedColor],
    selectedManufacture,
    optionModel
  );

  const colorNameValidation = () => {
    if (colorName.trim().length === 0) {
      setErrorMsg((prev) => ({
        ...prev,
        colorName: "Please enter a Color Name.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      colorName: "",
    }));
    return true;
  };
  const colorCodeValidation = () => {
    if (colorCode.trim().length === 0) {
      setErrorMsg((prev) => ({
        ...prev,
        colorCode: "Please enter a Color Code.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      colorCode: "",
    }));
    return true;
  };
  const manufacturerValidation = () => {
    if (selectedManufacture === "") {
      setErrorMsg((prev) => ({
        ...prev,
        manu: "Please pick a Manufacturer.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      manu: "",
    }));
    return true;
  };
  const modelValidation = () => {
    if (selectedModel === "") {
      setErrorMsg((prev) => ({
        ...prev,
        model: "Please pick a Model.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      model: "",
    }));
    return true;
  };
  const YoPValidation = () => {
    if (selectedYearOfProduction === "") {
      setErrorMsg((prev) => ({
        ...prev,
        YoP: "Please pick a Year of Production.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      YoP: "",
    }));
    return true;
  };
  const areaValidation = () => {
    if (selectedArea === "") {
      setErrorMsg((prev) => ({
        ...prev,
        area: "Please pick an Area.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      area: "",
    }));
    return true;
  };

  const handleManufactureChange = (e) => {
    const selectedManufacture = e.target.value;
    console.log("Selected Manufacture:", selectedManufacture);
    setSelectedManufacture(selectedManufacture);
    const filteredModels = optionModel.filter(
      (model) => Number(model.id_manufacturer) === Number(selectedManufacture)
    );

    console.log("Filtered Models:", filteredModels);

    setFilteredOptionModel(filteredModels);

    setManufactures(selectedManufacture);
  };

  const openConfirmModal = () => {
    setConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const validateAllFields = () => {
    const colorNameValid = colorNameValidation();
    const colorCodeValid = colorCodeValidation();
    // const manufacturerValid = manufacturerValidation();
    // const modelValid = modelValidation();
    // const YoPValid = YoPValidation();
    // const areaValid = areaValidation();
    if (
      colorNameValid &&
      colorCodeValid
      // manufacturerValid &&
      // modelValid &&
      // YoPValid &&
      // areaValid
    ) {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    // const editedData = {
    //   id: editedColor.id,
    //   name: colorName,
    //   code: colorCode,
    //   manufacturer: selectedManufacture,
    //   model: selectedModel,
    //   yearOfProduction: selectedYearOfProduction,
    //   area: selectedArea,
    // };
    if (!validateAllFields()) {
      return false;
    }
    openConfirmModal();
    // onSave(editedData);
  };

  const handleEdit = async (id) => {
    await handleEditColorData(id);
    Update();
    onClose();
  };

  const handleEditColorData = async (id) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    // const body = {
    //   id: id,
    //   name_color: colorName,
    //   code_color: colorCode,
    //   id_manufacturer: manufactures,
    //   id_car_model: selectedModel,
    //   id_year_of_production: yearsOfProduction,
    //   id_area: areas,
    //   image_file: image ? image.name : null,
    // };
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name_color", colorName);
    formData.append("code_color", colorCode);
    formData.append("id_manufacturer", manufactures);
    formData.append("id_car_model", selectedModel);
    if (selectedYearOfProduction) {
      formData.append("id_year_of_production", selectedYearOfProduction);
    }

    if (selectedArea) {
      formData.append("id_area", selectedArea);
    }
    console.log(id);
    console.log(colorName);
    console.log(colorCode);
    console.log(manufactures);
    console.log(selectedModel);
    console.log(selectedYearOfProduction);
    console.log(selectedArea);
    console.log(image);
    if (imgChanged && image) {
      formData.append("image_file", image, image.name);
    }
    for (let data of formData.entries()) {
      console.log(`${data[0]}: ${data[1]}`);
    }
    // console.log(body);
    try {
      const response = await axios.put(`${baseURL}/color`, formData, {
        headers,
      });
      if (response.data.status === 200 || response.data.status === 201) {
        console.log("success");
        setEditSuccessModal(true);
        console.log(response);
      }
      console.log(response);
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningErrMsg(
          "Duplicate value: Color Name and Color Code has to be unique in the same Car Model."
        );
        setWarningErrModal(true);
      } else {
        setWarningErrMsg(
          "Failed to execute requested action, Please Try Again."
        );
        setWarningErrModal(true);
      }
      console.error("Error updating color data:", error);
      return false;
    }
  };

  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  let imgErrMsg = null;

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const inputImg = e.target.files[0];
      const imgIsValid = imageValidation(inputImg);
      if (!imgIsValid) {
        openImgWarning();
        return false;
      }
      // if (inputImg.size > 5 * 1024 * 1024) {
      //   setError({
      //     ...errorMsg,
      //     imageError: "Image size exceeds the maximum allowed size (5MB).",
      //   });
      //   return;
      // }

      // const allowedExtensions = ["jpeg", "jpg", "png"];
      // const fileExtension = inputImg.name.split(".").pop().toLowerCase();
      // if (!allowedExtensions.includes(fileExtension)) {
      //   setError({
      //     ...errorMsg,
      //     imageError:
      //       "Invalid file format. Allowed formats: .jpeg, .jpg, .png.",
      //   });
      //   return;
      // }

      setImage(inputImg);
      setImgChanged(true);
      setShowPreview(true);

      if (inputImg) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreview(e.target.result);
        };
        reader.readAsDataURL(inputImg);
      }
      // setError({ ...errorMsg, imageError: null });
    }
  };

  const imageValidation = (inputImg) => {
    console.log("Image:", image);

    const allowedMimes = ["image/jpeg", "image/jpg", "image/png"];
    if (inputImg.size > 5 * 1024 * 1024) {
      imgErrMsg = `Please select a file that is under 5 MB in size.`;
      console.log(2);
      return false;
    }
    if (!allowedMimes.includes(inputImg.type)) {
      imgErrMsg = `Please select a file with a .jpg, .jpeg, or .png file extension.`;
      console.log(3);
      return false;
    }

    imgErrMsg = null;
    return true;
  };

  const openImgWarning = () => {
    console.log(imgErrMsg);
    setWarningMsg(imgErrMsg);
    setWarningModal(true);
  };

  return (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
            }}
          >
            <Grid xs={11}>
              <div className={styles.modalTitle}>EDIT COLOR</div>
            </Grid>
            <Grid xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                maxHeight: "560px",
                overflow: "auto",
              }}
            >
              <form>
                <div className={styles.formGroup}>
                  <label htmlFor="colorName" className={styles.textLabel}>
                    Color Name
                  </label>
                  <input
                    type="text"
                    id="productName"
                    className={styles.customField}
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                  />
                  {errorMsg.colorName.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.colorName}</div>
                  ) : null}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="codeColor" className={styles.textLabel}>
                    Code
                  </label>
                  <input
                    type="text"
                    id="colorName"
                    className={styles.customField}
                    value={colorCode}
                    onChange={(e) => setColorCode(e.target.value)}
                  />
                  {errorMsg.colorCode.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.colorCode}</div>
                  ) : null}
                </div>
                <div className={styles.formGroup}>
                  <label className={styles.textLabel}>Manufactures</label>
                  <select
                    defaultValue={
                      editedColor ? editedColor.id_manufacturer : null
                    }
                    name="selectedManufactures"
                    className={styles.customField}
                    onChange={handleManufactureChange}
                    value={manufactures}
                  >
                    {/* <option value="">(Select)</option> */}
                    {Array.isArray(optionManufacture) &&
                      optionManufacture.map((manufacture) => (
                        <option key={manufacture.id} value={manufacture.id}>
                          {manufacture.name_manufacturer}
                        </option>
                      ))}
                  </select>
                  {/* {errorMsg.manu.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.manu}</div>
                  ) : null} */}
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.textLabel}>Models</label>
                  <select
                    name="selectedModels"
                    className={styles.customField}
                    value={selectedModel}
                    onChange={(e) => setSelectedModel(e.target.value)}
                  >
                    {/* <option value="">(Select)</option> */}
                    {Array.isArray(filteredOptionModel) &&
                      filteredOptionModel.map((model) => (
                        <option key={model.id} value={model.id}>
                          {model.name_car_model}
                        </option>
                      ))}
                  </select>
                  {/* {errorMsg.model.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.model}</div>
                  ) : null} */}
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.textLabel}>Year of Production</label>
                  <select
                    name="selectedYear"
                    className={styles.customField}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value === "null") {
                        setSelectedYearOfProduction(null);
                        return;
                      }
                      setSelectedYearOfProduction(e.target.value);
                    }}
                    value={selectedYearOfProduction}
                  >
                    <option value="null"> none </option>
                    {/* <option value="null">(null)</option> */}
                    {Array.isArray(optionYearOfProduction) &&
                      optionYearOfProduction.map((year) => (
                        <option key={year.id} value={year.id}>
                          {year.production_year}
                        </option>
                      ))}
                  </select>
                  {/* {errorMsg.YoP.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.YoP}</div>
                  ) : null} */}
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.textLabel}>Areas</label>
                  <select
                    name="selectedAreas"
                    className={styles.customField}
                    onChange={(e) => {
                      if (e.target.value === "null") {
                        setSelectedArea(null);
                        return;
                      }
                      setSelectedArea(e.target.value);
                    }}
                    value={selectedArea}
                  >
                    <option value="null"> none</option>
                    {Array.isArray(optionArea) &&
                      optionArea.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name_area}
                        </option>
                      ))}
                  </select>
                  {/* {errorMsg.area.length !== 0 ? (
                    <div className={styles.errorMsg}>{errorMsg.area}</div>
                  ) : null} */}
                </div>
                <div className={styles.formGroup}>
                  <label className={styles.textLabel}>Image</label>
                  <label
                    className={`${styles.customField} ${styles.customUploadImageTextField}`}
                  >
                    <div className={styles.uploadText}>
                      <div>
                        {!imgChanged
                          ? "Upload image. Maximum file size 5 MB."
                          : `${image.name}`}
                      </div>
                    </div>
                    <FileUploadOutlinedIcon className={styles.uploadIcon} />
                    <input
                      type="file"
                      id="image"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>

                {showPreview === true ? (
                  <div className={styles.imageContainer}>
                    {previewImage === "" || imgChanged === false ? (
                      <img
                        src={
                          image && `${baseURL}/${image}` !== `${baseURL}/null`
                            ? `${baseURL}/${image}`
                            : NoImage
                        }
                        alt="No Image"
                        className={styles.productImage}
                      />
                    ) : (
                      <img
                        src={`${previewImage}`}
                        alt="No Image"
                        className={styles.productImage}
                      />
                    )}
                    <button
                      style={{ background: "transparent", border: "none" }}
                      onClick={() => {
                        setShowPreview(false);
                      }}
                    >
                      <CloseRoundedIcon className={styles.closeIcon} />
                    </button>
                  </div>
                ) : null}

                {/* {image ? (
              <div className={styles.imageContainer}>
                <img src={baseURL + image} alt="No Product" className={styles.productImage} />
                {image && <CloseRoundedIcon className={styles.closeIcon} />}
              </div>
            ): null} */}
              </form>
            </Grid>
            <Grid xs={6}>
              <button onClick={handleSave} className={styles.btnGroup}>
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button onClick={onClose} className={styles.btnGroup}>
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirmModal}
        handleSubmitData={() => handleEdit(editedColor.id)}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
      <WarningFileSizeModal
        openModal={warningErrModal}
        closeModal={() => setWarningErrModal(false)}
        warningText={warningErrMsg}
      />
    </>
  );
};

export default EditColorPage;
