import { Navigate, Outlet } from "react-router-dom";

const loginAuth = () => {
  //isLoggedIn
  if (JSON.parse(localStorage.getItem(`currAdmin`))) {
    if (JSON.parse(localStorage.getItem(`currAdmin`)).token) {
      return true;
    }
  }
  return false;
};

const ProtectedRoutes = () => {
  const auth = loginAuth();
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
