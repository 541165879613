import React from "react";
// import editIcon from "./Edit.png";
import "./customIcon.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const CustomEdit = ({ onClick }) => {
  // const onClick = () =>{

  // }

  return (
    <div className="custom-icon">
      <button className="icon-button" onClick={onClick}>
        <EditOutlinedIcon alt="Edit Icon" />
      </button>
    </div>
  );
};

export default CustomEdit;
