import styles from "./Banner.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "920px",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px",
};

export default function Banner({ titlePage }) {
  const [anchorEl, setAnchorEl] = useState(null); // Modal Menu Banner Index
  const [currentImageId, setCurrentImageId] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = (event, id, index) => {
    setCurrentImageId(id);
    setCurrentImageIndex(index);
    handleClick(event);
  };
  const handleClose = () => {
    // console.log("close")
    setCurrentImageIndex("");
    setCurrentImageId("");
    setAnchorEl(null);
  };
  const handleSubmitNewIndex = async (event, index) => {
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        // console.log("image id: ", currentImageId);
        // console.log("target idx: ", index);
        const updatedData = {
          id: currentImageId,
          image_index: index,
        };
        const response = await axios.put(
          baseURL + `/home-photo/index`,
          updatedData,
          { headers }
        );
        console.log(`Banner Index updated successfully:`, response.data);
      } catch (e) {
        console.error("Gagal update index banner:", e);
      }
    }
    getHomePhotos();
    setAnchorEl(null);
  };

  const [openViewBanner, setOpenViewBanner] = useState(false); // Modal View Banner
  const [viewBanner, setViewBanner] = useState("");
  const handleOpenViewBanner = (data) => {
    setViewBanner(data);
    setOpenViewBanner(true);
  };
  const handleCloseViewBanner = () => {
    setViewBanner("");
    setOpenViewBanner(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Modal Delete Banner
  const [idDeleteData, setIdDeleteData] = useState(null);
  const handleOpenDeleteModal = (data) => {
    setIdDeleteData(data.id);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    getHomePhotos();
  };
  const handleRowDelete = (id) => {
    setHomePhotos(homePhotos.filter((homePhotos) => homePhotos.id !== id));
  };
  const handleDeleteData = async () => {
    // postAPI -- Delete Row
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
      };
      try {
        await axios.delete(baseURL + `/home-photo/${idDeleteData}`, {
          headers,
        });
        handleRowDelete(idDeleteData);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Gagal menghapus item:", error);
      }
    }
  };

  const [openWarningModal, setOpenWarningModal] = useState(false); // Warning Image File Size modal
  const handleOpenWarningModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  const [openAddModal, setOpenAddModal] = useState(false); // Modal Add Banner
  const [openAddModalConfirm, setOpenAddModalConfirm] = useState(false);
  const handleOpenAddModalConfirm = () => {
    setOpenAddModalConfirm(true);
  };
  const handleCloseAddModalConfirm = () => {
    setOpenAddModalConfirm(false);
  };
  const [imageTemp, setImageTemp] = useState(null);
  const fileInputRef = useRef(null);
  const handleOpenAddBanner = () => {
    setImageTemp(null);
    setOpenAddModal(true);
  };
  const handleCloseAddBanner = () => {
    setImageTemp(null);
    setOpenAddModal(false);
  };
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (
        selectedFile.size > 2 * 1024 * 1024 ||
        !allowedTypes.includes(selectedFile.type)
      ) {
        handleOpenWarningModal();
        return;
      }
      setImageTemp(selectedFile);
    }
  };
  const handleSubmitData = async () => {
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      if (currAdmin.token) {
        const formData = new FormData();
        formData.append("image_home", imageTemp);
        const response = await axios.post(`${baseURL}/home-photo`, formData, {
          headers: {
            Authorization: `Bearer ${currAdmin.token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Image uploaded successfully:", response.data);
        getHomePhotos();
        handleCloseAddBanner();
      }
    } catch (error) {
      console.error("Error uploading banner:", error);
    }
  };

  const [homePhotos, setHomePhotos] = useState(null);
  const getHomePhotos = async () => {
    try {
      const response = await axios.get(baseURL + `/home-photo`);
      console.log(response.data.result[0].data);
      setHomePhotos(convertArrayData(response.data.result[0].data));
      console.log(convertArrayData(response.data.result[0].data));
    } catch (e) {
      console.error(e);
    }
  };

  const convertArrayData = (datas) => {
    let newData = [];
    if (Array.isArray(datas)) {
      newData = datas.map((data) => ({
        id: data.id,
        bannerIndex: data.image_index,
        image: data.image_home,
        name: data.image_name,
      }));
    }
    return newData;
  };

  const convertImageUrl = (string) => {
    return `${baseURL}/${string}`;
  };
  const columns = [
    {
      field: "isDeleted",
      headerName: "",
      align: "center",
      flex: 2,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div
            className={styles.iconContainer}
            onClick={() => {
              handleOpenDeleteModal(params.row);
            }}
          >
            <ClearRoundedIcon className={styles.cellIcon} />
          </div>
        );
      },
    },
    {
      field: "isViewed",
      headerName: "",
      align: "center",
      flex: 2,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div
            className={styles.iconContainer}
            onClick={() => {
              handleOpenViewBanner(params.row);
            }}
          >
            <VisibilityOutlinedIcon className={styles.cellIcon} />
          </div>
        );
      },
    },
    {
      field: "bannerIndex",
      headerName: "#",
      flex: 2,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div className={styles.indexBtnContainer}>
            <button
              className={styles.indexBtn}
              onClick={(e) =>
                handleOpen(e, params.row.id, params.row.bannerIndex)
              }
            >
              <div className={styles.indexBtnContent}>
                {params.row.bannerIndex}
              </div>
              <div className={styles.indexBtnContent}>
                <KeyboardArrowDownRoundedIcon className={styles.cellIcon} />
              </div>
            </button>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Banner Name",
      flex: 6,
      headerClassName: `${styles.columnHeader}`,
    },
    {
      field: "image",
      headerName: "Banner Image",
      flex: 10,
      headerClassName: `${styles.columnHeader}`,
      renderCell: (params) => {
        return (
          <div className={styles.rowImage}>
            <img
              className={styles.image}
              src={convertImageUrl(params.row.image)}
              alt="preview not available"
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getHomePhotos();
  }, []);
  return (
    <div className={styles.bannerPage}>
      {titlePage}
      <div className={styles.bannerContent}>
        <div className={styles.TitleRow}>
          <button className={styles.TitleButton} onClick={handleOpenAddBanner}>
            <div className={styles.TitleButtonText}>Add New Banner</div>
            <AddIcon className={styles.addIcon} />
          </button>
        </div>
        <div className={styles.DataTable}>
          {!homePhotos ? (
            <LoadingAnimation />
          ) : (
            <DataGrid
              rows={homePhotos}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              className={styles.dataGrid}
              rowHeight={100}
              getRowClassName={(params) => styles.customCell}
              getCellClassName={(params) => styles.customCell}
            />
          )}
        </div>
      </div>
      <Modal // Modal View Banner
        open={openViewBanner}
      >
        <Box sx={styleBox}>
          <div className={styles.viewBannerTop}>
            <div className={styles.viewBannerText}>
              {!viewBanner.name ? "Name is not available" : viewBanner.name}
            </div>
            <ClearRoundedIcon
              className={styles.viewBannerIcon}
              onClick={() => {
                handleCloseViewBanner();
              }}
            />
          </div>
          <div className={styles.viewBannerImage}>
            <img src={`${baseURL}/${viewBanner.image}`} />
          </div>
        </Box>
      </Modal>
      <Modal // Modal Add Banner
        open={openAddModal}
      >
        <Box sx={styleBox}>
          <div className={styles.viewBannerTop}>
            <div className={styles.addBannerText}>NEW BANNER</div>
            <ClearRoundedIcon
              className={styles.viewBannerIcon}
              onClick={() => {
                handleCloseAddBanner();
              }}
            />
          </div>
          <div className={styles.textLabel}>Image</div>
          <label className={styles.imageUploadTextField}>
            <div>
              {!imageTemp
                ? "Upload image. Maximum file size 2 MB."
                : `${imageTemp.name}`}
            </div>
            <AttachmentIcon className={styles.attachmentIcon} />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </label>
          {imageTemp && (
            <div className={styles.viewBannerImage}>
              <img src={URL.createObjectURL(imageTemp)} />
            </div>
          )}
          <div className={styles.saveButtonContainer}>
            {!imageTemp ? (
              <button className={styles.saveButton}>Save</button>
            ) : (
              <button
                className={styles.saveButtonActive}
                onClick={handleOpenAddModalConfirm}
              >
                Save
              </button>
            )}
          </div>
        </Box>
      </Modal>
      <Menu
        // id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!homePhotos ? (
          <LoadingAnimation />
        ) : (
          homePhotos.map((homePhoto, index) => {
            return (
              <MenuItem
                key={index}
                disabled={homePhoto.bannerIndex === currentImageIndex}
                onClick={(event) =>
                  handleSubmitNewIndex(event, homePhoto.bannerIndex)
                }
              >
                {homePhoto.bannerIndex}
              </MenuItem>
            );
          })
        )}
      </Menu>
      <ConfirmSubmitChanges
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        warningText={
          "Are you sure want to delete?\nThis action cannot be undone."
        }
        handleSubmitData={handleDeleteData}
      />
      <ConfirmSubmitChanges
        openModal={openAddModalConfirm}
        closeModal={handleCloseAddModalConfirm}
        handleSubmitData={handleSubmitData}
      />
      <WarningFileSizeModal
        openModal={openWarningModal}
        closeModal={handleCloseWarningModal}
        warningText={`Please select a file that is under 2 MB in size.\nIt should also be in one of the supported formats, such as .jpg, .jpeg, or .png.`}
      />
    </div>
  );
}
