import React, { useState } from "react";
import deleteIcon from "./closeIcon.svg";
import "./customIcon.css";
import axios from "axios";
import ConfirmSubmitChanges from "../components/ConfirmSubmitChanges/ConfirmSubmitChanges";

const CustomDelete = ({ itemId, onDelete, enabled, warning }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Modal Delete Issue
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleSubmitData = () => {
    // postAPI -- Delete Issue
    handleCloseDeleteModal();
  };

  const handleDeleteClick = async () => {
    try {
      console.log("ID:", itemId);
      onDelete(itemId);
    } catch (error) {
      console.error("Failed to delete item:", error);
    }
  };

  return (
    <div className="custom-icon">
      <button
        className="icon-button"
        onClick={() => {
          if (enabled === false) {
            warning();
          } else {
            handleOpenDeleteModal();
          }
        }}
      >
        <img src={deleteIcon} alt="Delete Icon" />
      </button>
      <ConfirmSubmitChanges
        openModal={openDeleteModal}
        closeModal={handleCloseDeleteModal}
        warningText={`Are you sure want to delete?\nThis action cannot be undone.`}
        handleSubmitData={handleDeleteClick}
      />
    </div>
  );
};

export default CustomDelete;
