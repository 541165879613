import { Modal, Box } from "@mui/material";
import styles from "./add_sub_category_modal.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import axios from "axios";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import { baseURL } from "../../global_variable_.jsx";

const AddSubCategoryModal = ({ isOpen, onClose, Update }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  useEffect(() => {
    // console.clear();
    setSubCategory({
      id_category: "",
      isDeleted: "",
      link_name: "",
      name_sub_category: "",
    });
    setErrorMsg({ categoryError: null, nameError: null });
    console.log(subCategory.id_category);
    APICalls();
  }, [isOpen]);

  const APICalls = async () => {
    await getCategories();
  };

  const validateFields = () => {
    const nameIsValid = nameValidation();
    const categoryIsValid = categoryValidation();
    if (nameIsValid && categoryIsValid) {
      return true;
    }
    return false;
  };

  const nameValidation = () => {
    //isEmpty
    if (subCategory.name_sub_category.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        nameError: "Please enter a Sub Category Name.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, nameError: null }));
    return true;
  };

  const categoryValidation = () => {
    //isEmpty
    if (subCategory.id_category.trim().length === 0) {
      setErrorMsg((prevError) => ({
        ...prevError,
        categoryError: "Please pick a Category.",
      }));
      return false;
    }
    setErrorMsg((prevError) => ({ ...prevError, categoryError: null }));
    return true;
  };

  const getCategories = async () => {
    // console.clear();
    console.log("im here");
    try {
      let response = await axios.get(`${baseURL}/category`);
      setListCategories(response.data.result);
      response.data.result.map((e) => console.log(e.name_category));
      //   console.log(response.data.result[0]);
    } catch (e) {
      console.log(e.message);
    }
  };

  const addSubCategories = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    // console.log("Name:", subCategory.name_sub_category);
    // console.log("Category:", subCategory.id_category);
    // console.log("isDeleted:", subCategory.isDeleted);
    // console.log("Link-Name:", subCategory.link_name);
    try {
      const response = await axios.post(
        `${baseURL}/sub-category`,
        {
          name_sub_category: `${subCategory.name_sub_category}`,
          link_name: `${subCategory.link_name}`,
          id_category: `${subCategory.id_category}`,
          isDeleted: `${subCategory.isDeleted}`,
        },
        { headers }
      );
      if (response.data.status === 201) {
        console.log(response);
        Update();
        console.log("success");
        setAddSuccessModal(true);
        return true;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Sub Category Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const [subCategory, setSubCategory] = useState({
    name_sub_category: "",
    link_name: "",
    id_category: "",
    isDeleted: "",
  });
  const [errorMsg, setErrorMsg] = useState({
    nameError: null,
    categoryError: null,
  });
  const [listCategories, setListCategories] = useState([]);
  const [categoryID, setCategory] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const openConfirmModal = () => {
    if (!validateFields()) {
      return;
    }
    setConfirmModal(true);
  };
  const closeConfirm = () => {
    setConfirmModal(false);
  };

  const submitData = async () => {
    const response = await addSubCategories();
    if (response) {
      Update();
      onClose();
    }
  };

  return (
    <>
      <Modal className={styles.Modal} open={isOpen}>
        <Box className={styles.boxModal}>
          <div className={styles.Header}>
            <div className={styles.Title}>ADD SUB CATEGORY</div>
            <div>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.subCategoryNameContainer}>
              <label className={styles.Label}> Sub Category Name</label>
              <input
                className={styles.subCategoryNameField}
                type="text"
                onChange={(e) => {
                  setSubCategory((prevData) => ({
                    ...prevData,
                    name_sub_category: e.target.value,
                    link_name: e.target.value,
                  }));
                }}
              />
              {errorMsg.nameError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.nameError}</div>
              ) : null}
            </div>
            <div className={styles.categoryContainer}>
              <label className={styles.Label}>Category</label>
              <select
                defaultValue="-1"
                className={styles.categoryDropdown}
                name=""
                id=""
                onChange={(e) => {
                  setSubCategory((prevData) => ({
                    ...prevData,
                    id_category: e.target.value,
                  }));
                }}
              >
                <option disabled value="-1">
                  (Select)
                </option>
                {listCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name_category}
                  </option>
                ))}
              </select>
              {errorMsg.categoryError !== null ? (
                <div className={styles.errorMsg}>{errorMsg.categoryError}</div>
              ) : null}
            </div>
            <div className={styles.radioGroupContainer}>
              <label className={styles.Label}>Status</label>
              <div className={styles.radioGroupContent}>
                <div className={styles.radioActive}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => {
                      setIsActive(true);
                      setSubCategory((prevData) => ({
                        ...prevData,
                        isDeleted: isActive,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Active</label>
                </div>
                <div className={styles.radioNonActive}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => {
                      setIsActive(false);
                      setSubCategory((prevData) => ({
                        ...prevData,
                        isDeleted: isActive,
                      }));
                    }}
                  />
                  <label className={styles.radioLabel}>Non-Active</label>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.Button}
                onClick={async () => {
                  console.log(subCategory.name_sub_category);
                  openConfirmModal();
                }}
              >
                Save
              </button>
              <button className={styles.Button} onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirm}
        handleSubmitData={submitData}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => setAddSuccessModal(false)}
        warningText={"Data Added Succsesfully"}
      />
    </>
  );
};

export default AddSubCategoryModal;
