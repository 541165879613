import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./EditProductPage.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import NoImage from "../../icon/no-img.png";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const EditProductPage = ({
  isOpen,
  onClose,
  editedProduct,
  onSave,
  baseURL,
  Update,
}) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(true);
  const [imgChanged, setImgChanged] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editSuccessModal, setEditSuccessModal] = useState(false);
  const [errorMsg, setError] = useState({
    nameError: null,
    categoryError: null,
    subCategoryError: null,
    descError: null,
    imageError: null,
  });
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  //   const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    setPreview("");
    setImgChanged(false);
    setShowPreview(true);
    setError({
      nameError: null,
      categoryError: null,
      subCategoryError: null,
      descError: null,
      imageError: null,
    });

    console.log("Image Changed:", imgChanged);
  }, [isOpen]);

  useEffect(() => {
    if (editedProduct) {
      console.log(editedProduct.category);
      setProductName(editedProduct.name);
      setCategory(editedProduct.id_category);
      setSelectedCategory(editedProduct.id_category);
      handleCategoryChange({ target: { value: editedProduct.id_category } });
      setDescription(editedProduct.description || "");
      setImage(editedProduct.image);
      console.log(editedProduct.sub_category);
      setSelectedSubCategory(editedProduct.sub_category);
      //   if (editedProduct.image) {
      //     setImage(editedProduct.image);
      //     setImageURL(URL.createObjectURL(editedProduct.image));
      //   }
      // if (image) {

      // }
    }

    axios
      .get(`${baseURL}/category`)
      .then((response) => {
        // Pastikan data yang diterima adalah array sebelum menggunakannya
        if (Array.isArray(response.data.result)) {
          const sortedCategories = response.data.result.sort((a, b) =>
            a.name_category.localeCompare(b.name_category)
          );
          setCategories(sortedCategories);
        } else {
          console.error(
            "Data kategori yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori:", error);
      });
    axios
      .get(`${baseURL}/sub-category`)
      .then((response) => {
        console.log(response.data.result);
        console.log(response.data.result);
        if (Array.isArray(response.data.result)) {
          const sortedSubCategories = response.data.result.sort((a, b) =>
            a.name_sub_category.localeCompare(b.name_sub_category)
          );
          setSubCategory(sortedSubCategories);
          console.log("Subcategories:", response.data.result);
        } else {
          console.error(
            "Data kategori yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori:", error);
      });

    console.log(categories);
  }, [editedProduct]);

  console.log(selectedSubCategory);

  const openConfirmModal = () => {
    setConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    console.log("Selected Category:", selectedCategory);
    setSelectedCategory(selectedCategory);
    const filteredSubCats = subCategory.filter(
      (subCat) => Number(subCat.id_category) === Number(selectedCategory)
    );
    console.log("Filtered Subcategories:", filteredSubCats);
    setFilteredSubCategories(filteredSubCats);

    setCategory(selectedCategory);
  };

  const handleImageChange = (e) => {
    console.log(e);
    console.log("Image Changed:", imgChanged);
    if (e.target.files && e.target.files.length > 0) {
      console.log("masuk");
      const inputImg = e.target.files[0];
      const imageValid = imageValidation(inputImg);
      if (!imageValid) {
        openImgWarning();
        return false;
      }
      setImage(inputImg);
      setShowPreview(true);
      if (inputImg) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreview(e.target.result);
        };
        reader.readAsDataURL(inputImg);
      }
    }
  };

  const validateFields = () => {
    const nameIsValid = nameValidation();
    const descIsValid = descValidation();

    console.log(nameIsValid, descIsValid);
    console.log(image);
    if (nameIsValid && descIsValid) {
      return true;
    }
    return false;
  };

  // const subCategoryValidation = () => {
  //   if (filteredSubCategories.length !== 0) {
  //     if (selectedSubCategory.trim().length === 0) {
  //       setError((prev) => ({
  //         ...prev,
  //         subCategoryError: "Please pick a Sub Category.",
  //       }));
  //       return false;
  //     }
  //   }
  //   setError((prev) => ({
  //     ...prev,
  //     subCategoryError: null,
  //   }));
  //   return true;
  // };
  let imgErrMsg = null;

  const openImgWarning = () => {
    console.log(imgErrMsg);
    setWarningMsg(imgErrMsg);
    setWarningModal(true);
  };

  const imageValidation = (inputImg) => {
    // console.log("Image Changed:", imgChanged);
    console.log("Image:", image);

    const allowedMimes = ["image/jpeg", "image/jpg", "image/png"];
    if (inputImg.size > 5 * 1024 * 1024) {
      imgErrMsg = `Please select a file that is under 5 MB in size.`;
      // setError((prev) => ({
      //   ...prev,
      //   imageError: "Please select a file that is under 5 MB in size.",
      // }));
      console.log(2);
      return false;
    }
    if (!allowedMimes.includes(inputImg.type)) {
      imgErrMsg = `Please select a file with a .jpg, .jpeg, or .png file extension.`;

      // setError((prev) => ({
      //   ...prev,
      //   imageError:
      //     "Please select a file with a .jpg, .jpeg, or .png file extension.",
      // }));
      console.log(3);
      return false;
    }

    imgErrMsg = null;
    // setError((prev) => ({
    //   ...prev,
    //   imageError: null,
    // }));
    return true;
  };

  const nameValidation = () => {
    if (productName.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        nameError: "Please enter a Product Name.",
      }));
      return false;
    }
    setError((prev) => ({ ...prev, nameError: null }));
    return true;
  };
  const descValidation = () => {
    if (description.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        descError: "Please enter a Product Description.",
      }));
      return false;
    }
    setError((prev) => ({ ...prev, descError: null }));
    return true;
  };
  const handleSave = () => {
    if (!validateFields()) {
      return;
    }

    openConfirmModal();

    // const editedData = {
    //   id: editedProduct.id,
    //   name_product: productName,
    //   id_category: category,
    //   id_sub_category: selectedSubCategory,
    //   description: description,
    //   image: image,
    // };

    // onSave(editedData);
  };

  const handleEdit = async (id) => {
    const response = await handleEditProductData(id);
    if (response === true) {
      await handleEditProductPhoto(id);
      await Update();
      onClose();
    }
  };

  const handleEditProductData = async (id) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    const body = {
      id: id,
      name_product: productName,
      id_category: category,
      id_sub_category: selectedSubCategory,
      description: description,
    };
    try {
      const response = await axios.put(`${baseURL}/product`, body, { headers });
      if (response.data.status === 201) {
        console.log("success");
        setEditSuccessModal(true);
      }
      console.log(response);
      return true;
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Product Name has to be unique.");
        setWarningModal(true);
        console.error("Axios Error:", error);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const handleEditProductPhoto = async (id) => {
    console.log("masuk");
    console.log(image);

    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const isEmpty = await editPhotoHelper(id, 1);
    if (image) {
      const selectedImage = image;
      console.log(selectedImage);

      const formData = new FormData();
      formData.append("image_product", selectedImage);
      console.log(`${formData}`);
      formData.append("id_product", id);

      if (isEmpty) {
        console.log("POST");
        try {
          const response = await axios.post(
            `${baseURL}/product-photo`,
            formData,
            { headers }
          );
          if (response.data.status === 200) {
            console.log("success");
          }
          console.log(response);
          setImage(selectedImage.name);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      } else {
        console.log("PUT");
        try {
          const response = await axios.put(
            `${baseURL}/product-photo`,
            formData,
            { headers }
          );
          if (response.data.status === 201) {
            console.log("success");
          }
          console.log(response);
          setImage(selectedImage.name);
        } catch (error) {
          console.error("Error uploading image:", error);
          return false;
        }
      }
    } else {
      const photoID = await editPhotoHelper(id, 2);
      console.log(photoID);
      try {
        const response = await axios.delete(
          `${baseURL}/product-photo/${photoID}`,
          { headers }
        );
        if (response.data.status === 200) {
          console.log("success");
          return true;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const editPhotoHelper = async (id, ver) => {
    try {
      const response = await axios.get(
        `${baseURL}/product-photo?id_product=${id}`
      );
      if (response.data.status === 200) {
        if (ver === 1) {
          if (
            Array.isArray(response.data.result[0].data) &&
            response.data.result[0].data.length !== 0
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return response.data.result[0].data[0].id;
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
            }}
          >
            <Grid xs={11}>
              <div className={styles.modalTitle}>EDIT PRODUCT</div>
            </Grid>
            <Grid xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                maxHeight: "560px",
                overflow: "auto",
              }}
            >
              <div className={styles.formGroup}>
                <label htmlFor="productName" className={styles.textLabel}>
                  Product Name
                </label>
                <input
                  type="text"
                  id="productName"
                  className={styles.customField}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
                {errorMsg.nameError !== null ? (
                  <div className={styles.errorMsg}>{errorMsg.nameError}</div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Category</label>
                <select
                  defaultValue={
                    editedProduct ? editedProduct.id_category : null
                  }
                  name="selectedCategory"
                  className={styles.customField}
                  value={category}
                  onChange={handleCategoryChange}
                >
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name_category}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Sub Category</label>
                {filteredSubCategories.length > 0 ? (
                  <select
                    defaultValue={
                      editedProduct ? editedProduct.sub_category : null
                    }
                    name="selectedSubCategory"
                    value={selectedSubCategory}
                    className={styles.customField}
                    onChange={(e) => setSelectedSubCategory(e.target.value)}
                  >
                    <option value="-1" disabled>
                      (Select)
                    </option>
                    {filteredSubCategories.map((filteredSubCat) => (
                      <option key={filteredSubCat.id} value={filteredSubCat.id}>
                        {filteredSubCat.name_sub_category}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    name="selectedSubCategory"
                    className={styles.customField}
                    value={selectedSubCategory}
                    disabled
                  >
                    <option value="">No Sub Categories Available</option>
                  </select>
                )}

                {errorMsg.subCategoryError !== null ? (
                  <div className={styles.errorMsg}>
                    {errorMsg.subCategoryError}
                  </div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="description" className={styles.textLabel}>
                  Description
                </label>
                <textarea
                  id="description"
                  className={`${styles.customField} ${styles.customFieldTextArea}`}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  cols={30}
                  rows={10}
                />{" "}
                {errorMsg.descError !== null ? (
                  <div className={styles.errorMsg}>{errorMsg.descError}</div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Image</label>
                <label
                  className={`${styles.customField} ${styles.customUploadImageTextField}`}
                >
                  <div className={styles.uploadText}>
                    Upload photo max. size 5MB
                  </div>
                  <FileUploadOutlinedIcon className={styles.uploadIcon} />
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    on
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                    style={{ display: "none" }}
                  />
                </label>
              </div>

              {showPreview === true ? (
                <div className={styles.imageContainer}>
                  {previewImage === "" ? (
                    <img
                      src={
                        image && `${baseURL}/${image}` !== `${baseURL}/null`
                          ? `${baseURL}/${image}`
                          : NoImage
                      }
                      alt="No Image"
                      className={styles.productImage}
                    />
                  ) : (
                    <img
                      src={`${previewImage}`}
                      alt="No Image"
                      className={styles.productImage}
                    />
                  )}
                  <button
                    style={{ background: "transparent", border: "none" }}
                    onClick={() => {
                      setImage(null);
                      setShowPreview(false);
                    }}
                  >
                    <CloseRoundedIcon className={styles.closeIcon} />
                  </button>
                </div>
              ) : null}
            </Grid>
            <Grid xs={6}>
              <button onClick={handleSave} className={styles.btnGroup}>
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button onClick={onClose} className={styles.btnGroup}>
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={closeConfirmModal}
        handleSubmitData={() => handleEdit(editedProduct.id)}
      />
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
    </>
  );
};

export default EditProductPage;
