import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./eyemodalformula.module.css";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import AddIcon from "@mui/icons-material/Add";
import EditFormula from "../editformula/EditFormula";
import NewFormula from "../newformula/NewFormula";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ModalError400 from "../../components/ModalError400/ModalError400";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFF",
  border: "none",
  borderRadius: "20px",
  width: "491px",
  height: "550px",
  boxShadow: 24,
  p: 4,
};

const EyeModalFormula = ({ isOpen, onClose, color }) => {
  const [formulas, setFormulas] = useState([]);
  const [newFormulaModalIsOpen, setNewFormulaModalIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedFormula, setEditedFormula] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("null");
  const [colorId, setColorId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalError400Open, setModalError400Open] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openEditSuccessModal, setOpenEditSuccessModal] = useState(false);

  const getFormula = async (colorId) => {
    try {
      if (colorId) {
        const response = await axios.get(
          `${baseURL}/formula?id_color=${colorId}`
        );
        console.log(response);
        if (response.data.result && response.data.result[0].data) {
          setFormulas(response.data.result[0].data);
          console.log("get all formula", response.data.result[0].data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const APICalls = async () => {
    setIsLoading(true);
    await getFormula(colorId);
    setIsLoading(false);
  };

  useEffect(() => {
    APICalls();
  }, [colorId]);

  useEffect(() => {
    if (isOpen && color) {
      setColorId(color.id);
    }
  }, [isOpen, color]);

  if (!isOpen || !color) {
    return null;
  }

  const openEditModal = (formula) => {
    setEditedFormula(formula);
    console.log("Formula Data:", formula);
    setModalIsOpen(true);
  };

  const handleEditFormula = async (editedData, headers) => {
    try {
      const response = await axios.put(`${baseURL}/formula`, editedData, {
        headers,
      });
      console.log(response);
      setModalIsOpen(false);
      if (response.data.status === 201) {
        APICalls();
        console.log(response.data.status);
        setModalIsOpen(false);
        setOpenEditSuccessModal(true);
      }
    } catch (error) {
      console.error("Gagal mengupdate formula:", error);
    }
  };

  // const openNewFormulaColorModal = () => {
  //   setColorId(color.id);
  //   setNewFormulaModalIsOpen(true);
  //   console.log("Color Formula Data:", color);
  // };
  const openNewFormulaColorModal = () => {
    const isBaseFormula = selectedCategory === "null";
    const hasExistingBaseFormulas = formulas.some(
      (formula) =>
        formula.id_category_formula === 1 || formula.id_category_formula === 2
    );
    const hasExistingNullCategoryFormulas = formulas.some(
      (formula) => formula.id_category_formula === null
    );

    if (isBaseFormula && !hasExistingBaseFormulas) {
      setColorId(color.id);
      setNewFormulaModalIsOpen(true);
      console.log("Color Formula Data:", color);
    } else if (!isBaseFormula && !hasExistingNullCategoryFormulas) {
      setColorId(color.id);
      setNewFormulaModalIsOpen(true);
      console.log("Color Formula Data:", color);
    } else {
      let errorMessage = "";
      if (isBaseFormula) {
        errorMessage =
          "Kategori formula tidak valid. Silahkan pilih kategori top coat/under coat.";
      } else {
        errorMessage =
          "Kategori formula tidak valid. Silahkan pilih kategori base formula.";
      }
      setModalError400Open(true);
      setErrorMessage(errorMessage);
    }
  };

  const handleAddFormula = async (editedData, headers) => {
    try {
      if (selectedCategory === "null") {
        delete editedData.id_category_formula;
      }
      const response = await axios.post(`${baseURL}/formula`, editedData, {
        headers,
      });
      console.log(response);
      console.log(editedData);
      setNewFormulaModalIsOpen(false);
      if (response.data.status === 201) {
        APICalls();
        setModalIsOpen(false);
        setOpenSuccessModal(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 408) {
          console.log("Received a 500 Internal Server Error");
          console.log("Server response data:", error.response.data);
          setModalError400Open(true);
          setErrorMessage(error.response.data.result);
        } else if (error.response.status === 409) {
          console.log("Received a 409 Conflict Error");
          console.log("Server response data:", error.response.data);
          setModalError400Open(true);
          setErrorMessage(error.response.data.result);
        } else {
          console.log("Error in making the request:", error);
          console.log("Error message:", error.message);
        }
      }
    }
  };

  const handleDeleteFormula = async (areaId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log("Deleted Formula ID:", areaId);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      const response = await axios.delete(`${baseURL}/formula/${areaId}`, {
        headers,
      });
      console.log(response);
      if (response.data.status === 200) {
        APICalls();
        console.log(response.data.status);
        setModalIsOpen(false);
      }
    } catch (error) {
      console.log("error delete formula", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      width: 51,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={handleDeleteFormula} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      width: 51,
      renderCell: (params) => (
        <CustomeEdit
          onClick={() => {
            console.log(params.row);
            openEditModal(params.row);
          }}
        />
      ),
    },
    {
      field: "base_formula",
      headerName: "Base",
      headerClassName: "customColumnHeader",
      width: 90,
    },
    {
      field: "amount_formula",
      headerName: "Amount",
      headerClassName: "customColumnHeader",
      width: 84,
    },
    {
      field: "cumulative_formula",
      headerName: "Cumulative",
      headerClassName: "customColumnHeader",
      width: 93,
    },
    {
      field: "unit_formula",
      headerName: "Unit",
      headerClassName: "customColumnHeader",
      width: 60,
    },
  ];

  let formattedFormulas = [];
  if (Array.isArray(formulas)) {
    let cumulativeValue = 0;
    formattedFormulas = formulas.map((formula) => {
      const cumulative = cumulativeValue + parseFloat(formula.amount_formula);
      cumulativeValue = cumulative;
      return {
        id: formula.id,
        base_formula: formula.base_formula,
        amount_formula: formula.amount_formula,
        cumulative_formula: cumulative.toFixed(2),
        unit_formula: formula.unit_formula,
        idCategory: formula.id_category_formula,
      };
    });
  }
  console.log(formattedFormulas);

  const nullCategoryFormulas = formattedFormulas.filter(
    (formula) => formula.idCategory === null
  );
  console.log(nullCategoryFormulas);

  const category1Formulas = formattedFormulas.filter(
    (formula) => formula.idCategory === 1
  );
  console.log(category1Formulas);

  const category2Formulas = formattedFormulas.filter(
    (formula) => formula.idCategory === 2
  );
  console.log(category2Formulas);

  const calculateCumulative = (formulas) => {
    let cumulativeValue = 0;
    return formulas.map((formula) => {
      cumulativeValue += parseFloat(formula.amount_formula);
      return {
        ...formula,
        cumulative_formula: cumulativeValue.toFixed(2),
      };
    });
  };

  const nullCategoryCumulativeFormulas =
    calculateCumulative(nullCategoryFormulas);
  const category1CumulativeFormulas = calculateCumulative(category1Formulas);
  const category2CumulativeFormulas = calculateCumulative(category2Formulas);

  const filterFormulasByCategory = (category) => {
    if (category === "null") {
      return nullCategoryCumulativeFormulas;
    } else if (category === "1") {
      return category1CumulativeFormulas;
    } else if (category === "2") {
      return category2CumulativeFormulas;
    }
  };

  return isLoading !== true ? (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid container spacing={2} style={{ margin: 0 }}>
          <Grid xs={11}>
            <div className={styles.modalTitle}>FORMULA</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{ maxWidth: "491px", maxHeight: "490px", overflow: "auto" }}
          >
            <div className={styles.colorFormulaTableContainer}>
              <div className={styles.filterContainer}>
                {selectedCategory !== "" && (
                  <Select
                    value={selectedCategory}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      console.log("Selected category:", selectedValue);
                      setSelectedCategory(selectedValue);
                    }}
                    sx={{
                      width: "150px",
                      height: "40px",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: "#BE1620",
                      fontSize: "14px",
                      color: "white",
                      paddingLeft: "12px",
                      marginRight: "20px",
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    //className={styles.categoryFormulaFilter}
                  >
                    <MenuItem
                      value="null"
                      className={styles.formulaFilterOption}
                    >
                      Base Formula
                    </MenuItem>
                    <MenuItem value="1" className={styles.formulaFilterOption}>
                      Top Coat
                    </MenuItem>
                    <MenuItem value="2" className={styles.formulaFilterOption}>
                      Under Coat
                    </MenuItem>
                  </Select>
                )}

                {selectedCategory !== "" && (
                  <button
                    onClick={openNewFormulaColorModal}
                    className={styles.newsubcategorybtnmodalformula}
                  >
                    Add New Formula
                    <AddIcon className={styles.addIcon1} />
                  </button>
                )}
              </div>

              {selectedCategory !== "" && (
                <DataGrid
                  className={styles.tableBaseFormula}
                  rows={filterFormulasByCategory(selectedCategory)}
                  columns={columns}
                  autoHeight={true}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  getRowClassName={(params) => "customRow"}
                  getCellClassName={(params) => "customCell"}
                  disableRowSelectionOnClick={true}
                />
              )}

              {/* {formulas.length === 0 && (
                  <div>No formulas found for the selected category.</div>
                )} */}
            </div>
            <EditFormula
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              idColor={colorId}
              idCategory={selectedCategory}
              editedFormula={editedFormula}
              onSave={handleEditFormula}
            />

            <NewFormula
              isOpen={newFormulaModalIsOpen}
              onClose={() => setNewFormulaModalIsOpen(false)}
              onSave={handleAddFormula}
              colorId={colorId}
              selectedCategory={selectedCategory}
            />
            <ConfirmSubmitChanges />
            <ModalError400
              openModal={modalError400Open}
              closeModal={() => setModalError400Open(false)}
              warningText={errorMessage}
            />
            <WarningFileSizeModal
              openModal={openSuccessModal}
              closeModal={() => setOpenSuccessModal(false)}
              warningText={"Data Added Succsesfully"}
            />
            <WarningFileSizeModal
              openModal={openEditSuccessModal}
              closeModal={() => setOpenEditSuccessModal(false)}
              warningText={"Data Edited Succsesfully"}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  ) : (
    <div>loading...</div>
  );
};

export default EyeModalFormula;
