import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./NewProductPage.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import { baseURL } from "../../global_variable_.jsx";

const API_URL = `${baseURL}/product`;
const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const NewProductPage = ({
  isOpen,
  onClose,
  editedProduct,
  onSave,
  baseURL,
  Update,
  currCategoryFilter,
}) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreview] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [productID, setProductID] = useState(null);
  const [productAdded, setProductAdded] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [errorMsg, setError] = useState({
    nameError: null,
    categoryError: null,
    subCategoryError: null,
    descError: null,
    imageError: null,
  });

  useEffect(() => {
    setProductName("");
    setCategory("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setDescription("");
    setImage("");
    setPreview("");

    setError({
      categoryError: null,
      descError: null,
      nameError: null,
      subCategoryError: null,
      imageError: null,
    });

    axios
      .get(`${baseURL}/category`)
      .then((response) => {
        if (Array.isArray(response.data.result)) {
          const sortedCategories = response.data.result.sort((a, b) =>
            a.name_category.localeCompare(b.name_category)
          );
          setCategories(sortedCategories);
          console.log("Categories:", response.data.result);
        } else {
          console.error(
            "Data kategori yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori:", error);
      });

    axios
      .get(`${baseURL}/sub-category`)
      .then((response) => {
        console.log(response.data.result);
        console.log(response.data.result);
        if (Array.isArray(response.data.result)) {
          const sortedSubCategories = response.data.result.sort((a, b) =>
            a.name_sub_category.localeCompare(b.name_sub_category)
          );
          setSubCategory(sortedSubCategories);
          console.log("Subcategories:", response.data.result);
        } else {
          console.error(
            "Data kategori yang diterima bukan array:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data kategori:", error);
      });
  }, [editedProduct, isOpen]);

  const handleSave = () => {
    console.log(image);
    if (!validateFields()) {
      return;
    }
    setOpenModal(true);
  };

  const validateFields = () => {
    const nameIsValid = nameValidation();
    const categoryIsValid = categoryValidation();
    const subCategoryIsValid = subCategoryValidation();
    // const imageIsValid = imageValidation();
    const descIsValid = descValidation();
    console.log(
      `${nameIsValid} ${categoryIsValid} ${subCategoryIsValid} ${descIsValid}`
    );

    if (nameIsValid && categoryIsValid && subCategoryIsValid && descIsValid) {
      return true;
    }

    return false;
  };

  const nameValidation = () => {
    if (productName.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        nameError: "Please enter a Product Name.",
      }));
      return false;
    }
    setError((prev) => ({
      ...prev,
      nameError: null,
    }));
    return true;
  };

  const categoryValidation = () => {
    if (category.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        categoryError: "Please pick a Category.",
      }));
      return false;
    }
    setError((prev) => ({
      ...prev,
      categoryError: null,
    }));
    return true;
  };

  const subCategoryValidation = () => {
    if (filteredSubCategories.length !== 0) {
      if (selectedSubCategory.trim().length === 0) {
        setError((prev) => ({
          ...prev,
          subCategoryError: "Please pick a Sub Category.",
        }));
        return false;
      }
    }
    setError((prev) => ({
      ...prev,
      subCategoryError: null,
    }));
    return true;
  };

  const descValidation = () => {
    if (description.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        descError: "Please enter a Product Description.",
      }));
      return false;
    }
    setError((prev) => ({
      ...prev,
      descError: null,
    }));
    return true;
  };

  const [openImgWarning, setImgWarning] = useState(false);

  const imageValidation = (inputImg) => {
    const allowedMimes = ["image/jpeg", "image/jpg", "image/png"];
    console.log(image);
    if (inputImg !== "") {
      console.log("masuk");
      if (inputImg.size > 5 * 1024 * 1024) {
        // setError((prev) => ({
        //   ...prev,
        //   imageError: "Please select a file that is under 5 MB in size.",
        // }));
        setWarningMsg("Please select a file that is under 5 MB in size.");
        setWarningModal(true);
        return false;
      }
      if (!allowedMimes.includes(inputImg.type)) {
        // setError((prev) => ({
        //   ...prev,
        //   imageError:
        //     "Please select a file with a .jpg, .jpeg, or .png file extension.",
        // }));
        setWarningMsg(
          "Please select a file with a .jpg, .jpeg, or .png file extension."
        );
        setWarningModal(true);
        return false;
      }
    }
    setWarningMsg(null);
    setWarningModal(false);
    // setError((prev) => ({
    //   ...prev,
    //   imageError: null,
    // }));
    return true;
  };

  const handleConfirm = () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    console.log(token);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    if (selectedSubCategory === null || selectedSubCategory === "") {
      const addData = {
        name_product: productName,
        id_category: category,
        description: description,
      };
      console.log(addData);
      console.log(headers);
      handleAdd(addData, headers);
      setOpenModal(true);
    } else {
      const addData = {
        name_product: productName,
        id_category: category,
        id_sub_category: selectedSubCategory,
        description: description,
      };

      console.log(addData);
      console.log(headers);
      handleAdd(addData, headers);
      setOpenModal(true);
    }
  };

  const handleAdd = async (addData, headers) => {
    const response = await handleAddProduct(addData, headers);
    if (response !== false) {
      await handleAddProductPhoto(response);
      if (currCategoryFilter === category || currCategoryFilter === "-1") {
        await Update();
      }
      onClose();
    }
  };

  const handleAddProductPhoto = async (id) => {
    if (image) {
      const selectedImage = image;
      // const addedProductID = productID;
      // console.log(addedProductID);
      console.log(selectedImage);

      const token = JSON.parse(localStorage.getItem("currAdmin")).token;
      const formData = new FormData();
      formData.append("id_product", id);
      formData.append("image_product", selectedImage);
      console.log(`${formData}`);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      try {
        const response = await axios.post(
          `${baseURL}/product-photo`,
          formData,
          { headers }
        );
        console.log(response);
        setImage(selectedImage.name);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleAddProduct = async (addData, headers) => {
    try {
      console.log("!");
      const response = await axios.post(`${baseURL}/product`, addData, {
        headers,
      });
      console.log(response);
      if (response.status === 201) {
        console.log(
          addData.name_product,
          addData.id_category,
          addData.description
        );
        setProductAdded(true);
        setOpenModal(false);
        setAddSuccessModal(true);
        return response.data.result.id;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Product Name has to be unique.");
        setWarningModal(true);
        console.error("Axios Error:", error);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const inputImg = e.target.files[0];
      const imageValid = imageValidation(inputImg);
      if (!imageValid) {
        return;
      }
      setImage(inputImg);
      if (inputImg) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreview(e.target.result);
        };
        reader.readAsDataURL(inputImg);
      }
    }
  };
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    console.log("Selected Category:", selectedCategory);
    setSelectedCategory(selectedCategory);
    const filteredSubCats = subCategory.filter(
      (subCat) => Number(subCat.id_category) === Number(selectedCategory)
    );
    console.log("Filtered Subcategories:", filteredSubCats);
    setFilteredSubCategories(filteredSubCats);

    setCategory(selectedCategory);
  };

  return (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <Grid container spacing={2} style={{ margin: 0 }}>
            <Grid xs={11}>
              <div className={styles.modalTitle}>NEW PRODUCT</div>
            </Grid>
            <Grid xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </Grid>
            <Grid xs={12} style={{ maxHeight: "560px", overflow: "auto" }}>
              <div className={styles.formGroup}>
                <label htmlFor="productName" className={styles.textLabel}>
                  Product Name
                </label>
                <input
                  type="text"
                  id="productName"
                  className={styles.customField}
                  onChange={(e) => setProductName(e.target.value)}
                />
                {errorMsg.nameError !== null ? (
                  <div className={styles.errorMsg}>{errorMsg.nameError}</div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Category</label>
                <select
                  defaultValue="-1"
                  name="selectedCategory"
                  className={styles.customField}
                  onChange={handleCategoryChange}
                >
                  <option disabled value="-1">
                    (Select)
                  </option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name_category}
                    </option>
                  ))}
                </select>
                {errorMsg.categoryError !== null ? (
                  <div className={styles.errorMsg}>
                    {errorMsg.categoryError}
                  </div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Sub Category</label>
                {filteredSubCategories.length > 0 ? (
                  <select
                    defaultValue="-1"
                    name="selectedSubCategory"
                    className={styles.customField}
                    onChange={(e) => setSelectedSubCategory(e.target.value)}
                  >
                    <option value="-1" disabled>
                      (Select)
                    </option>
                    {filteredSubCategories.map((filteredSubCat) => (
                      <option key={filteredSubCat.id} value={filteredSubCat.id}>
                        {filteredSubCat.name_sub_category}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    name="selectedSubCategory"
                    className={styles.customField}
                    value={selectedSubCategory}
                    disabled
                  >
                    <option value="">No Sub Categories Available</option>
                  </select>
                )}

                {errorMsg.subCategoryError !== null ? (
                  <div className={styles.errorMsg}>
                    {errorMsg.subCategoryError}
                  </div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="description" className={styles.textLabel}>
                  Description
                </label>
                <textarea
                  id="description"
                  className={`${styles.customField} ${styles.customFieldTextArea}`}
                  onChange={(e) => setDescription(e.target.value)}
                  cols={30}
                  rows={10}
                />
                {errorMsg.descError !== null ? (
                  <div className={styles.errorMsg}>{errorMsg.descError}</div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Image</label>
                <label
                  className={`${styles.customField} ${styles.customUploadImageTextField}`}
                >
                  <div className={styles.uploadText}>
                    Upload photo max. size 5MB
                  </div>
                  <FileUploadOutlinedIcon className={styles.uploadIcon} />
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </label>
                {errorMsg.imageError !== null ? (
                  <div className={styles.errorMsg}>{errorMsg.imageError}</div>
                ) : null}
                {image !== "" ? (
                  <div className={styles.imageContainer}>
                    <img
                      src={previewImage}
                      alt="No Image"
                      className={styles.productImage}
                    />
                    <button
                      style={{ background: "transparent", border: "none" }}
                      onClick={() => {
                        setImage("");
                        setPreview("");
                      }}
                    >
                      <CloseRoundedIcon className={styles.closeIcon} />
                    </button>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid xs={6}>
              <button onClick={handleSave} className={styles.btnGroup}>
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button onClick={onClose} className={styles.btnGroup}>
                Cancel
              </button>
            </Grid>
            <ConfirmSubmitChanges
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
              handleSubmitData={handleConfirm}
            />
          </Grid>
        </Box>
      </Modal>
      <WarningFileSizeModal
        closeModal={() => setWarningModal(false)}
        openModal={warningModal}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={addSuccessModal}
        closeModal={() => setAddSuccessModal(false)}
        warningText={"Data Added Succsesfully"}
      />
    </>
  );
};

export default NewProductPage;
