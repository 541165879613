import Sidebar from "./components/sidebar/sidebar";
import "./App.css";
import Home from "./pages/home/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import User from "./pages/user/User"
import UserList from "./pages/userList/UserList";
import Issue from "./pages/issue/Issue";
import Product from "./pages/product/Product";
import Color from "./pages/color/Color";
import Contact from "./pages/contact/ContactUs";
import NewSubCategory from "./pages/newsubcategory/NewSubCategory";
import NewCategory from "./pages/newsubcategory/NewSubCategory";
import Login from "./pages/login/login";
import Models from "./pages/models/Models";
import ColorType from "./pages/colortype/ColorType";
import ColorAreas from "./pages/colorareas/ColorAreas";
import ColorFormula from "./pages/colorformula/ColorFormula";
import AboutUs from "./pages/aboutus/AboutUs";
import Changepassword from "./pages/changepassword/changepassword";
import TitleBar from "./components/titleBar/TitleBar";
import CmsUser from "./pages/cms/CmsUser";
import ProductComponent from "./components/product/product_component/product_component";
import FavoriteComponent from "./components/product/favorite_component/favorite_component";
import RoleUserWeb from "./components/role_user/role_user_web/role_user_web";
import RoleCms from "./pages/rolecms/RoleCms";
import NewRoleCms from "./pages/newrolecms/NewRoleCms";
import NewRoleUser from "./pages/newroleuser/NewRoleUser";
import NewRoleUserMobile from "./pages/newroleusermobile/NewRoleUserMobile";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import RoleUserMobile from "./components/role_user/role_user_mobile/role_user_mobile";
import Newsletter from "./pages/Newsletter/Newsletter";
import CategoryComponent from "./components/product/category_component/category_component";
import ProductSub from "./components/product/sub_category_component/sub_category_component";
import RoleUser from "./pages/role_user/role_user";
import Banner from "./pages/Banner/Banner";
import EditRoleMenuUser from "./pages/edit_role_menu_user/edit_role_menu_user";
import EditRoleCms from "./pages/edit_role_cms/edit_role_cms";
import ProtectedRoutes from "./functions/Authentication";
import RoleProtectedRoutes from "./functions/RoleAuthentication";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "./global_variable_";

const routes = [
  //404
  {
    path: "*",
    element: <PageNotFound titlePage={<TitleBar titlePage={"*"} />} />,
  },

  //DASHBOARD
  {
    name: "Dashboard",
    path: "/dashboard",
    element: <Home titlePage={<TitleBar titlePage={"DASHBOARD"} />} />,
  },

  //PRODUCT
  {
    name: "Product Menu",
    path: "/product/:component",
    element: <Product titlePage={<TitleBar titlePage={"PRODUCT"} />} />,
  },
  {
    name: "Product Menu",
    path: "/product",
    element: <Navigate to="/product/category" />,
  },

  //COLOR
  {
    name: "Color Category",
    path: "/color",
    element: <Color titlePage={<TitleBar titlePage={"COLOR"} />} />,
  },
  {
    name: "Color Category",
    path: "/color-type",
    element: <ColorType titlePage={<TitleBar titlePage={"COLOR"} />} />,
  },
  {
    name: "Color Category",
    path: "/color-areas",
    element: <ColorAreas titlePage={<TitleBar titlePage={"COLOR"} />} />,
  },
  {
    name: "Color Category",
    path: "/color-models",
    element: <Models titlePage={<TitleBar titlePage={"COLOR"} />} />,
  },
  {
    name: "Color",
    path: "/color-formula",
    element: (
      <ColorFormula titlePage={<TitleBar titlePage={"COLOR FORMULA"} />} />
    ),
  },

  //SUBMIT ISSUE
  {
    name: "Submit Issue",
    path: "/issue",
    element: <Issue titlePage={<TitleBar titlePage={"ISSUE"} />} />,
  },

  //ABOUT US
  {
    name: "About Us",
    path: "/about",
    element: <AboutUs titlePage={<TitleBar titlePage={"ABOUT US"} />} />,
  },

  //BANNER
  {
    name: "Banner",
    path: "/banner",
    element: <Banner titlePage={<TitleBar titlePage={"BANNER"} />} />,
  },

  //NEWSLETTER
  {
    name: "Newsletter",
    path: "/newsletter",
    element: <Newsletter titlePage={<TitleBar titlePage={"NEWSLETTER"} />} />,
  },

  //CONTACT US
  {
    name: "Contact Us",
    path: "/contact",
    element: <Contact titlePage={<TitleBar titlePage={"CONTACT"} />} />,
  },

  //CHANGE PASSWORD
  {
    name: "Allow Me",
    path: "/change-password",
    element: (
      <Changepassword titlePage={<TitleBar titlePage={"CHANGE PASSWORD"} />} />
    ),
  },

  ////USER
  {
    name: "User",
    path: "/users",
    element: <UserList titlePage={<TitleBar titlePage={"USERS"} />} />,
  },
  //ROLE USER
  {
    name: "Role User",
    path: "/edit-role-menu-user/:role_name/:role_id",
    element: (
      <EditRoleMenuUser
        titlePage={<TitleBar titlePage={"EDIT ROLE MENU USER"} />}
      />
    ),
  },
  {
    name: "Role User",
    path: "/edit-role-menu-user",
    element: (
      <EditRoleMenuUser
        titlePage={<TitleBar titlePage={"EDIT ROLE MENU USER"} />}
      />
    ),
  },
  {
    name: "Role User",
    path: "/new-role-user",
    element: (
      <NewRoleUser titlePage={<TitleBar titlePage={"NEW ROLE USER"} />} />
    ),
  },
  {
    name: "Role User",
    path: "/role-user",
    element: <RoleUser titlePage={<TitleBar titlePage={"ROLE USER"} />} />,
  },
  ////

  ////CMS
  {
    name: "CMS User",
    path: "/cms",
    element: <CmsUser titlePage={<TitleBar titlePage={"CMS USER"} />} />,
  },
  //ROLE CMS
  {
    name: "Role CMS",
    path: "/edit-role-cms/:role_name/:role_id",
    element: (
      <EditRoleCms titlePage={<TitleBar titlePage={"EDIT ROLE CMS"} />} />
    ),
  },
  {
    name: "Role CMS",
    path: "/new-role-cms",
    element: <NewRoleCms titlePage={<TitleBar titlePage={"NEW ROLE CMS"} />} />,
  },
  {
    name: "Role CMS",
    path: "/role-cms",
    element: <RoleCms titlePage={<TitleBar titlePage={"ROLE CMS"} />} />,
  },
  ////
];

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [listRoleMenu, setListRoleMenu] = useState([]);

  const getRoleAdminMenu = async () => {
    const currAdmin = JSON.parse(localStorage.getItem(`currAdmin`));
    if (currAdmin) {
      const token = currAdmin.token;
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get(
          `${baseURL}/admin-role-menu/?id_role_admin=${currAdmin.id_role}`,
          { headers }
        );
        if (response.data.status === 200) {
          console.log(response);
          setIsLoading(false);
          setListRoleMenu(response.data.result[0].data);
          return true;
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          setIsLoading(false);
          return [false, 500];
        }
        setIsLoading(false);
        console.error(error);
        return [false];
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getRoleAdminMenu();
  }, []);

  return !isLoading ? (
    <Router>
      <div className="container">
        <Sidebar />
        <Routes>
          <Route element={<Login />} path="/" />
          <Route element={<ProtectedRoutes />}>
            {routes.map((route, index) => (
              <Route
                element={
                  <RoleProtectedRoutes
                    roleMenuFunc={getRoleAdminMenu}
                    listRoleMenu={listRoleMenu}
                    routeName={route.name}
                    setLoading={() => {
                      setIsLoading(false);
                    }}
                  />
                }
              >
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              </Route>
            ))}
          </Route>
        </Routes>
      </div>
    </Router>
  ) : (
    <center>loading...</center>
  );
}

export default App;
