import axios from "axios";

const UploadImage = async ({ zipFile, forcePOST }) => {
  const baseURL = `https://abca-rev-api-v2.cranium.id/`;
  const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log(headers);
  if (zipFile) {
    const formData = new FormData();
    formData.append("file", zipFile);
    if (forcePOST) {
      console.log("masuk");
      formData.append("factory_input", forcePOST);
    }
    console.log(formData.get("file"));
    console.log(zipFile);
    try {
      const response = await axios.post(
        `${baseURL}color/upload-zip`,
        formData,
        { headers }
      );
      if (response.data.status === 200) {
        console.warn("Success");
        // console.log([true, response.data.status]);
        return [true, response.data.status];
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        return [false, 500];
      }
      console.log(error);
      console.log(error.response.status);
      console.log([false, error.response.data.result[0]]);
      return [false, error.response.status, error.response.data.result[0]];
    }
  }
};

export default UploadImage;
