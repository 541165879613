import styles from "./product.module.css";
import productIcon from "../../../icon/productIcon.png";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// SAMPLE for MANY PRODUCT
// const exProducts= [
//   { name:"contoh_1", count: "10" },
//   { name:"contoh_1", count: "10" },
//   { name:"contoh_1", count: "10" },
// ]

const Product = ({ allProductCount, productCatCount }) => {
  // if (Array.isArray(productCatCount)) {
  //   productCatCount.map((e) => console.log(e));
  // } else {
  //   console.error("productCatCount is not an array or is undefined.");
  // }

  const categoryContainerRef = useRef(null);
  // const scrollStep = 150;
  // MouseDrag
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  // const handleScroll = (direction) => {
  //   const container = categoryContainerRef.current;
  //   if (container) {
  //     const scrollAmount = direction === 'left' ? -scrollStep : scrollStep;
  //     container.scrollLeft += scrollAmount;
  //   }
  // };
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const container = categoryContainerRef.current;
    if (container) {
      const deltaX = e.clientX - startX;
      container.scrollLeft -= deltaX;
      setStartX(e.clientX);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const container = categoryContainerRef.current;
    if (container) {
      container.addEventListener("mousedown", handleMouseDown);
      container.addEventListener("mousemove", handleMouseMove);
      container.addEventListener("mouseup", handleMouseUp);
      container.addEventListener("mouseleave", handleMouseUp);
    }
    return () => {
      if (container) {
        container.removeEventListener("mousedown", handleMouseDown);
        container.removeEventListener("mousemove", handleMouseMove);
        container.removeEventListener("mouseup", handleMouseUp);
        container.removeEventListener("mouseleave", handleMouseUp);
      }
    };
  }, []);

  return (
    <Grid xs={12}>
      <div className={styles.productContainer}>
        <div className={`${styles.productSubContainerSpecial}`}>
          <img className={styles.productIcon} src={productIcon} alt="" />{" "}
          <div className={styles.specialNumber}>{allProductCount}</div>
          <div className={styles.contentBottomTitle}>All Product</div>
        </div>
        <div className={styles.productOuterSlideContainer}>
          <div className={styles.productArrowContainer}>
            <ArrowBackIosIcon />
            <div
              className={styles.productSlideContainer}
              ref={categoryContainerRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {Array.isArray(productCatCount)
                ? productCatCount.map((product, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div className={styles.productSubContainer}>
                          <div className={styles.productSubContainerTopContent}>
                            <div className={styles.Number}>
                              {product["count"]}
                            </div>
                            Products
                          </div>
                          <div className={styles.contentBottomTitle}>
                            {product["nameCategory"]}
                          </div>
                        </div>
                        <div className={styles.productSubContainer}>
                          <div className={styles.productSubContainerTopContent}>
                            <div className={styles.Number}>
                              {product["count"]}
                            </div>
                            Products
                          </div>
                          <div className={styles.contentBottomTitle}>
                            {product["nameCategory"]}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })
                : null}

              {/* MANY Product Tester */}
              {/* { exProducts.map((exProduct, index) => {
              return (
                <div key={index} className={styles.productSubContainer}>
                  <div className={styles.productSubContainerTopContent}>
                    <div className={styles.Number}>{exProduct.count}</div>
                    Products
                  </div>
                  <div className={styles.contentBottomTitle}>
                    {exProduct.name}
                  </div>
                </div>
              );
          })} */}
              {/* MANY Product Tester */}
            </div>
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Product;
