import { useState, useEffect, useRef } from "react";
import styles from "./AboutUs.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import axios from "axios";
import TextEditor from "../../components/TextEditor/TextEditor";
import { baseURL } from "../../global_variable_";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function AboutUs({ titlePage }) {
  const [openModal, setOpenModal] = useState(false); // Confirm Save Submit modal
  const handleOpenModal = () => {
    console.log(editorLeft);
    console.log(editorRight);
    console.log(fieldsValidation());
    if (!fieldsValidation()) {
      return;
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openWarningModal, setOpenWarningModal] = useState(false); // Warning Image File Size modal
  const handleOpenWarningModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  const [editorLeft, setEditorLeft] = useState(""); // TextEditor Saving Left - Right Changes
  const [editorRight, setEditorRight] = useState("");
  const handleEditorLeftChange = (data) => {
    setEditorLeft(data);
  };
  const handleEditorRightChange = (data) => {
    setEditorRight(data);
  };

  const getAboutUsText = async () => {
    try {
      const response = await axios.get(baseURL + "/about-us");
      console.log(response.data.result[0]?.data);
      if (!response.data.result[0]?.data) {
        return;
      } else {
        setEditorLeft(response.data.result[0]?.data[0].about);
        setEditorRight(response.data.result[0]?.data[1].about);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const handleSubmitData = () => {
    // Handle Sumbit ALL Data with API
    if (isImageChanged) {
      handleSubmitPhoto();
    }
    console.log("Left Text: ", editorLeft);
    console.log("Right Text: ", editorRight);
    handleSubmitTextEdit();
  };

  const handleSubmitTextEdit = async () => {
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      if (currAdmin.token) {
        const updatedData = [
          {
            id: 1,
            about: editorLeft,
          },
          {
            id: 2,
            about: editorRight,
          },
        ];
        for (const data of updatedData) {
          const response = await axios.put(`${baseURL}/about-us`, data, {
            headers: {
              Authorization: `Bearer ${currAdmin.token}`,
            },
          });
          console.log(
            `Data updated successfully for ${data.id} section:`,
            response.data
          );
          console.log(`Data updated for ${data.id} is`, data.about);
        }
      }
    } catch (error) {
      console.error("Error uploading text:", error);
    }
  };

  const handleSubmitPhoto = async () => {
    // Sumbit Photo with API
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      if (currAdmin.token) {
        const formData = new FormData();
        formData.append("image_about_us", imageTemp);
        const response = await axios.post(
          `${baseURL}/about-us-photo`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${currAdmin.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Image uploaded successfully:", response.data);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [isImageChanged, setIsImageChanged] = useState(false);
  const [imageTemp, setImageTemp] = useState(null);
  const fileInputRef = useRef(null);
  const [imageURL, setImageURL] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [errorMsg, setError] = useState({
    right: null,
    left: null,
    image: null,
  });
  const getAboutUsPhoto = async () => {
    // getAPI Image
    try {
      const response = await axios.get(baseURL + "/about-us-photo");
      if (!response.data.result[0]?.data[0]?.image_about_us) {
        setImageURL(null);
      } else {
        setImageURL(
          `${baseURL}/${response.data.result[0]?.data[0]?.image_about_us}`
        );
      }
    } catch (e) {
      console.error(e.message);
    }
  };
  const deleteAboutUsPhoto = () => {
    // delete Preview Image
    setImageTemp(null);
    setImageURL(null);
    setIsImageChanged(true);
  };

  function handleImageChange(e) {
    // input PreviewImage
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      console.log(selectedFile);
      console.log(selectedFile.name);

      if (
        selectedFile.size > 10 * 1024 * 1024 ||
        !allowedTypes.includes(selectedFile.type)
      ) {
        setError((prev) => ({
          ...prev,
          image:
            "Please select a file that is under 10 MB in size.\nIt should also be in one of the supported formats, such as .jpg, .jpeg, or .png.",
        }));
        handleOpenWarningModal();
        return;
      }
      setError((prev) => ({
        ...prev,
        image: null,
      }));
      setImageTemp(selectedFile);
      setIsImageChanged(true);
    }
  }

  useEffect(() => {
    setEditorLeft("");
    setEditorRight("");
    setError({ image: null, left: null, right: null });
    getAboutUsPhoto();
    getAboutUsText();
  }, []);

  const fieldsValidation = () => {
    const leftIsValid = leftTextValidation();
    const rightIsValid = rightTextValidation();

    if (leftIsValid && rightIsValid) {
      return true;
    }
    return false;
  };

  const leftTextValidation = () => {
    //isEmpty
    if (editorLeft.trim().length === 0) {
      setError((prev) => ({ ...prev, left: "Please fill Left Text field." }));
      return false;
    }
    setError((prev) => ({ ...prev, left: null }));
    return true;
  };

  const rightTextValidation = () => {
    //isEmpty
    if (editorRight.trim().length === 0) {
      setError((prev) => ({ ...prev, right: "Please fill Right Text field." }));
      return false;
    }
    setError((prev) => ({ ...prev, right: null }));
    return true;
  };

  return (
    <div className={styles.aboutUsPage}>
      {titlePage}
      <div className={styles.aboutUsFrame}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid xs={12}>
            <div className={styles.componentWrapper}>
              <div className={styles.textLabel}>Image</div>
              <label className={styles.imageUploadTextField}>
                <div>
                  {!imageTemp
                    ? "Upload image. Maximum file size 10 MB."
                    : `${imageTemp.name}`}
                </div>
                <div className={styles.iconRight}>
                  <AttachmentIcon className={styles.attachmentIcon} />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />
              </label>
              <div
                style={{
                  display: !imageTemp && !imageURL ? "none" : null,
                }}
              >
                <div
                  className={styles.imageWrapper}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {isHovered && (
                    <div
                      className={styles.deleteButton}
                      onClick={deleteAboutUsPhoto}
                    >
                      Delete Image
                      <DeleteForeverOutlinedIcon className={styles.trashIcon} />
                    </div>
                  )}
                  <img
                    src={!imageTemp ? imageURL : URL.createObjectURL(imageTemp)}
                    alt=""
                    style={{ filter: isHovered ? "brightness(0.5)" : null }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={styles.componentWrapper}>
              <div className={styles.textLabel}>Left Text</div>
              <div className={styles.tempTextArea}>
                <TextEditor
                  className={styles.textEditor}
                  onSave={handleEditorLeftChange}
                  availData={editorLeft}
                />
              </div>
              {errorMsg.left !== null ? (
                <div className={styles.errorMsg}>{errorMsg.left}</div>
              ) : null}
            </div>
          </Grid>
          <Grid xs={12}>
            <div className={styles.componentWrapper}>
              <div className={styles.textLabel}>Right Text</div>
              <div className={styles.tempTextArea}>
                <TextEditor
                  className={styles.textEditor}
                  onSave={handleEditorRightChange}
                  availData={editorRight}
                />
              </div>
              {errorMsg.right !== null ? (
                <div className={styles.errorMsg}>{errorMsg.right}</div>
              ) : null}
            </div>
          </Grid>
          <Grid>
            <button className={styles.saveButton} onClick={handleOpenModal}>
              Save
            </button>
          </Grid>
        </Grid>
      </div>
      <ConfirmSubmitChanges
        openModal={openModal}
        closeModal={handleCloseModal}
        handleSubmitData={handleSubmitData}
      />
      <WarningFileSizeModal
        openModal={openWarningModal}
        closeModal={handleCloseWarningModal}
        warningText={errorMsg.image}
      />
    </div>
  );
}
