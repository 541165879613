import styles from "./widgetLg.module.css";
import exclamationMark from "../../../icon/exclamationMark.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WidgetLg({ issues }) {
  // console.log(issues);
  const isIssueAvailable = () => {
    if (issues.issueCount && issues.issueDate && issues.issueAge) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (isIssueAvailable()) {
      setRecent(findRecentIssue());
    }
  }, []);

  const findRecentIssue = () => {
    const temp = issues.issueAge;
    if (temp.months !== 0) {
      return { recentTime: temp.months, recentType: "Month(s)" };
    }
    if (temp.weeks !== 0) {
      return { recentTime: temp.weeks, recentType: "Week(s)" };
    }
    if (temp.days !== 0) {
      return { recentTime: temp.days, recentType: "Day(s)" };
    }
    if (temp.hours !== 0) {
      return { recentTime: temp.hours, recentType: "Hour(s)" };
    }
    if (temp.minutes !== 0) {
      return { recentTime: temp.minutes, recentType: "Minute(s)" };
    }
  };

  const [recent, setRecent] = useState(0);

  const navigate = useNavigate();
  const handleViewReport = () => {
    navigate("/issue");
  };

  return (
    <div className={styles.widgetLg}>
      <div className={styles.iconContainer}>
        <img src={exclamationMark} alt="" />
      </div>
      <div className={styles.stringContainer}>
        <div className={styles.topString}>
          You have {issues.issueCount} issues report from users
        </div>
        <div className={styles.bottomString}>
          {recent.recentTime} {recent.recentType} ago
        </div>
      </div>
      <button onClick={handleViewReport}>View Report</button>
    </div>
  );
}
