import { useEffect, useState } from "react";
import styles from "./role_user_mobile.module.css";
import { DataGrid } from "@mui/x-data-grid";
// import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../../icon/CustomDelete";
import CustomeEdit from "../../../icon/CustomEdit";
import TitleBar from "../../titleBar/TitleBar";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useRef } from "react";
import EditRole from "../../edit_role_modal/edit_role_modal";

export default function RoleUserMobile({ titlePage }) {
  return (
    <>
      <div>
        <Link className={styles.AddRoleUserLink} to="/new-role-user-mobile">
          <button className={styles.newRoleUserBtn}>
            Add New Mobile Role
            <AddIcon className={styles.addIcon1} />
          </button>
        </Link>
      </div>
    </>
  );
}
