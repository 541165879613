// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_userContainer__tg7pH{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    width: 173px;
    height: 177px;
    margin-top: 20px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.user_Number__p6MS7, .user_String__yBI4R{
    font-weight: 600;
    margin-left: 20px;
}

.user_Number__p6MS7{
    color: #BE1620;
    font-size: 32px;
    margin-bottom: 10px;
}

.user_String__yBI4R{
    color: #000;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/user/user.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;;IAElB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;AACpD;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".userContainer{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: start;\n\n    width: 173px;\n    height: 177px;\n    margin-top: 20px;\n    border-radius: 20px;\n    background: #FFF;\n    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);\n}\n\n.Number, .String{\n    font-weight: 600;\n    margin-left: 20px;\n}\n\n.Number{\n    color: #BE1620;\n    font-size: 32px;\n    margin-bottom: 10px;\n}\n\n.String{\n    color: #000;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userContainer": `user_userContainer__tg7pH`,
	"Number": `user_Number__p6MS7`,
	"String": `user_String__yBI4R`
};
export default ___CSS_LOADER_EXPORT___;
