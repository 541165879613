import styles from "./PageNotFound.module.css";
import IconNotFound from "../../icon/IconNotFound.png";

export default function PageNotFound({titlePage}) {
    return (
        <div className={styles.PageNotFound}>
            <div style={{display: "none"}}>
                {titlePage}
            </div>
            <img src={IconNotFound} />
            <div className={styles.bigText}>
                Oops! Page Not Found.
            </div>
            <div className={styles.smallText}>
                Mohon maaf, halaman yang Anda cari tidak dapat ditemukan.
            </div>
        </div>
    )
}