import styles from "./profile.module.css";
import dropdownIcon from "../../icon/dropdownIcon.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 347,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const Profile = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [name, setName] = useState();
  const [role, setRole] = useState();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("currAdmin");
    localStorage.removeItem("tokenAdmin");
    navigate("/");
  };

  const handleChangePass = () => {
    navigate("/change-password");
  };

  useEffect(() => {
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin === null) {
      return navigate("/");
    }
    if (currAdmin.token) {
      setName(currAdmin.name);
      setRole(currAdmin.role);
    }
  }, []);

  return (
    <div className={styles.dropdown}>
      <div className={styles.profileContainer}>
        <div className={styles.profileStringContent}>
          <div className={styles.userName}>{name}</div>
          <div className={styles.userRole}>{role}</div>
        </div>
        <button
          className={styles.dropdownBtn}
          onClick={() => (open ? setOpen(false) : setOpen(true))}
        >
          <img src={dropdownIcon} />
        </button>
      </div>
      {open ? (
        <div className={styles.dropdownMenu}>
          <button className={`${styles.changePass}`} onClick={handleChangePass}>
            Change Password
          </button>
          
          <button className={`${styles.logout}`} onClick={handleOpenModal}>
            Logout
          </button>
          
        </div>
      ) : null}

      {/* Modal Confirm Logout */}
      <Modal open={openModal}>
        <Box sx={styleBox}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <ErrorOutlineIcon className={styles.errorIconModal} />
            </Grid>
            <Grid xs={12}>
              <div className={styles.confirmLogoutText}>
                Are you sure you want to logout?
              </div>
            </Grid>
            <Grid xs={6}>
              <button onClick={handleCloseModal} className={styles.cancelBtn}>
                Cancel
              </button>
            </Grid>
            <Grid xs={6}>
              <button onClick={handleLogout} className={styles.logoutBtn}>
                Logout
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default Profile;
