import styles from "./sidebar.module.css";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NearMeTwoToneIcon from "@mui/icons-material/NearMeTwoTone";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import { Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import companyLogo from "../../icon/companyLogo.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global_variable_";

let sideLinks = [
  {
    index: 0,
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined className={styles.sidebarIcon} />,
    text: "Dashboard",
  },
  {
    index: 1,
    name: "User",
    to: "/users",
    icon: <AccountCircleOutlinedIcon className={styles.sidebarIcon} />,
    text: "User",
  },
  {
    index: 2,
    name: "CMS User",
    to: "/cms",
    icon: <AccountCircleOutlinedIcon className={styles.sidebarIcon} />,
    text: "CMS User",
  },
  {
    index: 3,
    name: "Role User",
    to: "/role-user",
    icon: <AccountCircleOutlinedIcon className={styles.sidebarIcon} />,
    text: "Role User",
  },
  {
    index: 4,
    name: "Role CMS",
    to: "/role-cms",
    icon: <AccountCircleOutlinedIcon className={styles.sidebarIcon} />,
    text: "Role CMS",
  },
  {
    index: 5,
    name: "Product Menu",
    to: "/product",
    icon: <Inventory2OutlinedIcon className={styles.sidebarIcon} />,
    text: "Product",
  },
  {
    index: 6,
    name: "Color Menu",
    to: "/color",
    icon: <PaletteOutlinedIcon className={styles.sidebarIcon} />,
    text: "Color",
  },
  {
    index: 7,
    name: "Banner",
    to: "/banner",
    icon: <PhotoOutlinedIcon className={styles.sidebarIcon} />,
    text: "Banner",
  },
  {
    index: 8,
    name: "About Us",
    to: "/about",
    icon: <ChatOutlinedIcon className={styles.sidebarIcon} />,
    text: "About Us",
  },
  {
    index: 9,
    name: "Newsletter",
    to: "/newsletter",
    icon: <NearMeTwoToneIcon className={styles.sidebarIcon} />,
    text: "Newsletter",
  },
  {
    index: 10,
    name: "Submit Issue",
    to: "/issue",
    icon: <ErrorOutlineOutlinedIcon className={styles.sidebarIcon} />,
    text: "Issue",
  },
  {
    index: 11,
    name: "Contact Us",
    to: "/contact",
    icon: <ContactSupportOutlinedIcon className={styles.sidebarIcon} />,
    text: "Contact",
  },
];

export default function Sidebar() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [auth, setAuth] = useState(false);
  const [listRoleMenu, setListRoleMenu] = useState([]);
  const [visibleSideLinks, setVisibleSideLinks] = useState([]);

  const loginCheck = () => {
    const currAdmin = JSON.parse(localStorage.getItem(`currAdmin`));
    if (currAdmin) {
      if (currAdmin.token) {
        setLoggedIn(true);
        return true;
      }
    }
    setLoggedIn(false);
    return false;
  };

  const getRoleAdminMenu = async () => {
    const currAdmin = JSON.parse(localStorage.getItem(`currAdmin`));
    if (currAdmin) {
      const token = currAdmin.token;
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get(
          `${baseURL}/admin-role-menu/?id_role_admin=${currAdmin.id_role}`,
          { headers }
        );
        if (response.data.status === 200) {
          console.log(response);
          // setListRoleMenu(response.data.result[0].data);
          //console.log(listRoleMenu);
          console.log(response.data.result[0].data);
          return response.data.result[0].data;
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          return [false, 500];
        }
        console.error(error);
        return [false];
      }
    }
    return [false];
  };

  useEffect(() => {
    const fetchDataAndFilterSidelinks = async () => {
      console.log(loggedIn);
      if (loggedIn) {
        try {
          const newRoleMenu = await getRoleAdminMenu();
          setListRoleMenu(newRoleMenu);
          console.log("ini sukses", newRoleMenu);
          console.log(listRoleMenu);

          const matchedSidelinks = newRoleMenu
            .filter((menu) =>
              sideLinks.some((link) => link.name === menu.name_menu)
            )
            .sort((a, b) => {
              const indexA = sideLinks.find(
                (link) => link.name === a.name_menu
              ).index;
              const indexB = sideLinks.find(
                (link) => link.name === b.name_menu
              ).index;
              return indexA - indexB;
            });

          console.log(sideLinks);
          console.log(matchedSidelinks);

          if (matchedSidelinks.length > 0) {
            console.log("Matched Sidelinks:", matchedSidelinks);

            const combinedArray = matchedSidelinks.map((matchedLink) => {
              const matchingSideLink = sideLinks.find(
                (link) => link.name === matchedLink.name_menu
              );

              return {
                ...matchedLink,
                index: matchingSideLink.index,
                to: matchingSideLink.to,
                name: matchingSideLink.name,
                icon: matchingSideLink.icon,
                text: matchingSideLink.text,
              };
            });

            console.log(combinedArray);

            const newVisibleSideLinks = combinedArray.filter(
              (link) => link.is_visible === "show" || link.is_visible === "true"
            );

            setVisibleSideLinks(newVisibleSideLinks);
            console.log("Sidebar yang ditampil: ", newVisibleSideLinks);
          } else {
            console.log("Data matchedSideLinks belum tersedia.");
          }
        } catch (error) {
          console.error(
            "Failed to fetch or process role admin menu:",
            error.message
          );
        }
      }
    };

    fetchDataAndFilterSidelinks();
  }, [loggedIn]);

  useEffect(() => {
    setAuth(false);
    loginCheck();
    const role =
      localStorage.getItem(`currAdmin`) !== null
        ? JSON.parse(localStorage.getItem(`currAdmin`)).role
        : [];
    if (role === "Super Admin") {
      setAuth(true);
    }
  }, []);

  const location = useLocation();
  console.log(location);
  console.log(auth);
  console.log(listRoleMenu);
  return location.pathname !== "/" && loggedIn ? (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWrapper}>
        <div className={styles.sidebarMenu}>
          <Container className={styles.sidebarTop}>
            <img src={companyLogo} alt="" />
            <div className={styles.sidebarTitle}>abcacoating</div>
          </Container>
          <div className={styles.sidebarList}>
            <div style={{ marginTop: "20px" }}>
              {visibleSideLinks.map((visibleLink, index) => {
                if (
                  auth === false &&
                  (visibleLink.to === `/cms` ||
                    visibleLink.to === `/role-user` ||
                    visibleLink.to === `/role-cms`)
                ) {
                  return;
                }
                return (
                  <div key={index} className={styles.sidebarListItem}>
                    <Link
                      className={
                        location.pathname.startsWith(visibleLink.to)
                          ? styles.sidebarLinkActive
                          : styles.sidebarLink
                      }
                      to={visibleLink.to}
                    >
                      {visibleLink.icon}
                      {visibleLink.text}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
