// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_role_menu_user_inputContainerNewRoleUserMobile__\\+B-Ay{
    display: flex;
}

.edit_role_menu_user_btnContainer__yyVrS{
    display: flex;
    justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/edit_role_menu_user/edit_role_menu_user.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".inputContainerNewRoleUserMobile{\n    display: flex;\n}\n\n.btnContainer{\n    display: flex;\n    justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainerNewRoleUserMobile": `edit_role_menu_user_inputContainerNewRoleUserMobile__+B-Ay`,
	"btnContainer": `edit_role_menu_user_btnContainer__yyVrS`
};
export default ___CSS_LOADER_EXPORT___;
