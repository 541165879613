import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./editformula.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const EditFormula = ({
  isOpen,
  onClose,
  editedFormula,
  onSave,
  idColor,
  idCategory,
}) => {
  const [baseFormula, setBaseFormula] = useState("");
  const [amountFormula, setAmountFormula] = useState("");
  const [cumulative, setCumulative] = useState("");
  const [unit, setUnit] = useState("");
  const [formula, setFormula] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [baseFormulaError, setBaseFormulaError] = useState("");
  const [amountFormulaError, setAmountFormulaError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [userCategoryColor, setUserCategoryColor] = useState("");
  const [formulaCategories, setFormulaCategories] = useState([]);

  const handleOpenModal = () => {
    if (!baseFormulaError && !amountFormulaError) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validateFormula = () => {
    let isValid = true;

    if (baseFormula.trim() === "") {
      setBaseFormulaError("Please enter the base of the formula");
      isValid = false;
    } else {
      setBaseFormulaError("");
    }

    if (amountFormula.trim() === "") {
      setAmountFormulaError("Please enter the amount of the formula");
      isValid = false;
    } else {
      setAmountFormulaError("");
    }

    return isValid;
  };

  const handleBaseFormulaChange = (e) => {
    const value = e.target.value;
    setBaseFormula(value);
    if (value.trim() === "") {
      setBaseFormulaError("Please enter the base of the formula");
    } else {
      setBaseFormulaError("");
    }
  };

  const handleAmountFormulaChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setAmountFormula(value);
      setAmountFormulaError("Please enter the amount of the formula");
    } else if (!/^\d*(\.\d*)?$/.test(value)) {
      setAmountFormula(value);
      setAmountFormulaError("Please enter a valid numeric amount");
    } else {
      setAmountFormula(value);
      setAmountFormulaError("");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setBaseFormulaError("");
      setAmountFormulaError("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (editedFormula) {
      setBaseFormula(editedFormula.base_formula);
      setAmountFormula(editedFormula.amount_formula);
      setCumulative(editedFormula.cumulative_formula);
      setUnit(editedFormula.unit_formula);
      setSelectedCategory(formula.idCategory);
    }

    axios
      .get(`${baseURL}/formula`)
      .then((response) => {
        // Ensure the data received is an array before using it
        console.log(response.data.result);
        if (Array.isArray(response.data.result)) {
          setFormula(response.data.result);
        } else {
          console.error("Received data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch category data:", error);
      });
  }, [editedFormula]);
  console.log(editedFormula);

  useEffect(() => {
    axios
      .get(`${baseURL}/category-formula`)
      .then((response) => {
        if (Array.isArray(response.data.result[0].data)) {
          const categoryData = response.data.result[0].data;
          setFormulaCategories(categoryData);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch formula categories:", error);
      });
  }, []);

  const handleSave = () => {
    const isValid = validateFormula();

    if (isValid) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");

      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      const editedData = {
        id: editedFormula.id,
        id_color: idColor,
        base_formula: baseFormula,
        amount_formula: amountFormula,
        unit_formula: unit,
        id_category_formula: idCategory === "null" ? null : idCategory, // Include the selected category
      };
      console.log(editedData);
      onSave(editedData, headers);
    }
  };

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>EDIT FORMULA</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxHeight: "560px",
              overflow: "auto",
            }}
          >
            <form>
              <div className={styles.formGroup}>
                <label htmlFor="baseFormula" className={styles.textLabel}>
                  Base
                </label>
                <input
                  type="text"
                  id="baseFormula"
                  className={styles.customField}
                  value={baseFormula}
                  onChange={handleBaseFormulaChange}
                />
                <div className={styles.errorMsgContainer}>
                  {baseFormulaError && (
                    <div
                      className={styles.errorMsg}
                      style={{ color: "#BE1620" }}
                    >
                      {baseFormulaError}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="amountFormula" className={styles.textLabel}>
                  Amount
                </label>
                <input
                  type="text"
                  id="amountFormula"
                  className={styles.customField}
                  value={amountFormula}
                  onChange={handleAmountFormulaChange}
                />
                <div className={styles.errorMsgContainer}>
                  {amountFormulaError && (
                    <div
                      className={styles.errorMsg}
                      style={{ color: "#BE1620" }}
                    >
                      {amountFormulaError}
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="unit" className={styles.textLabel}>
                  Unit
                </label>
                <input
                  type="text"
                  id="cumulative"
                  className={styles.customField}
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  disabled
                />
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button onClick={handleOpenModal} className={styles.btnGroup}>
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button onClick={onClose} className={styles.btnGroup}>
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditFormula;
