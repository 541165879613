// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsletterSubscribers_SubsComponent__bE9Oe{
    width: 96%;
    display: flex;
    flex-direction: column;
}

.NewsletterSubscribers_exportImportContainer__MRopg{
    display: flex;
}

.NewsletterSubscribers_TitleRow__mp1fH{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.NewsletterSubscribers_TitleText__vLlHQ{
    color: #000000;
    font-family: "SF Pro Display-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
}

.NewsletterSubscribers_DataTable__ROitx{
    margin-top: 20px;
}
.NewsletterSubscribers_columnHeader__qiwis{
    color: #ffffff;
    background-color: var(--color_red-primer);
    font-family: "Inter-Bold", Helvetica !important;
    font-size: 14px;
    font-weight: 700;
}
.NewsletterSubscribers_iconContainer__2lN4f{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.NewsletterSubscribers_cellIcon__vwoe8{
    color: var(--color_red-primer);
    width: 100%;
    cursor: pointer;
}   
/* .customCell{
    border-left: 1px solid #FFC3C3;
    border-right: 1px solid #FFC3C3;
} */
.NewsletterSubscribers_dataGrid__iVqXJ{
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsletterComponent/NewsletterSubscribers.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,6CAA6C;IAC7C,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,yCAAyC;IACzC,+CAA+C;IAC/C,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,8BAA8B;IAC9B,WAAW;IACX,eAAe;AACnB;AACA;;;GAGG;AACH;IACI,mBAAmB;AACvB","sourcesContent":[".SubsComponent{\n    width: 96%;\n    display: flex;\n    flex-direction: column;\n}\n\n.exportImportContainer{\n    display: flex;\n}\n\n.TitleRow{\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n.TitleText{\n    color: #000000;\n    font-family: \"SF Pro Display-Bold\", Helvetica;\n    font-size: 14px;\n    font-weight: 700;\n}\n\n.DataTable{\n    margin-top: 20px;\n}\n.columnHeader{\n    color: #ffffff;\n    background-color: var(--color_red-primer);\n    font-family: \"Inter-Bold\", Helvetica !important;\n    font-size: 14px;\n    font-weight: 700;\n}\n.iconContainer{\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n}\n.cellIcon{\n    color: var(--color_red-primer);\n    width: 100%;\n    cursor: pointer;\n}   \n/* .customCell{\n    border-left: 1px solid #FFC3C3;\n    border-right: 1px solid #FFC3C3;\n} */\n.dataGrid{\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SubsComponent": `NewsletterSubscribers_SubsComponent__bE9Oe`,
	"exportImportContainer": `NewsletterSubscribers_exportImportContainer__MRopg`,
	"TitleRow": `NewsletterSubscribers_TitleRow__mp1fH`,
	"TitleText": `NewsletterSubscribers_TitleText__vLlHQ`,
	"DataTable": `NewsletterSubscribers_DataTable__ROitx`,
	"columnHeader": `NewsletterSubscribers_columnHeader__qiwis`,
	"iconContainer": `NewsletterSubscribers_iconContainer__2lN4f`,
	"cellIcon": `NewsletterSubscribers_cellIcon__vwoe8`,
	"dataGrid": `NewsletterSubscribers_dataGrid__iVqXJ`
};
export default ___CSS_LOADER_EXPORT___;
