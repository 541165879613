import styles from "./ContactSocial.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import axios from "axios";
import { useEffect, useState } from "react";
import ConfirmSubmitChanges from "../ConfirmSubmitChanges/ConfirmSubmitChanges";
import { baseURL } from "../../global_variable_";

export default function ContactSocial() {
  const [tempEmailName, setTempEmailName] = useState("");
  const [tempEmailUrl, setTempEmailUrl] = useState("");
  const [tempFacebookName, setTempFacebookName] = useState("");
  const [tempFacebookUrl, setTempFacebookUrl] = useState("");
  const [tempInstagramName, setTempInstagramName] = useState("");
  const [tempInstagramUrl, setTempInstagramUrl] = useState("");
  const [error, setError] = useState({
    faceName: null,
    faceURL: null,
    instaName: null,
    instaURL: null,
    emailName: null,
    emailURL: null,
  });

  const fieldsValidation = () => {
    const facebookNameIsValid = facebookNameValidation();
    const facebookURLIsValid = facebookURLValidation();
    const instaNameIsValid = instaNameValidation();
    const instaURLIsValid = instaURLValidation();
    const emailNameIsValid = emailNameValidation();
    const emailURLIsValid = emailURLValidation();

    if (
      facebookNameIsValid &&
      facebookURLIsValid &&
      instaNameIsValid &&
      instaURLIsValid &&
      emailNameIsValid &&
      emailURLIsValid
    ) {
      return true;
    }
    return false;
  };

  const facebookNameValidation = () => {
    if (tempFacebookName.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        faceName: "Please fill Facebook Display Name field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, faceName: null }));
    return true;
  };

  const facebookURLValidation = () => {
    if (tempFacebookUrl.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        faceURL: "Please fill Facebook URL field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, faceURL: null }));
    return true;
  };

  const instaNameValidation = () => {
    if (tempInstagramName.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        instaName: "Please fill Instagran Display Name field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, instaName: null }));
    return true;
  };

  const instaURLValidation = () => {
    if (tempInstagramUrl.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        instaURL: "Please fill Instagram URL field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, instaURL: null }));
    return true;
  };

  const emailNameValidation = () => {
    if (tempEmailName.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        emailName: "Please fill Email Display Name field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, emailName: null }));
    return true;
  };

  const emailURLValidation = () => {
    if (tempEmailUrl.trim().length === 0) {
      setError((prev) => ({
        ...prev,
        emailURL: "Please fill Email URL field.",
      }));
      return false;
    }

    setError((prev) => ({ ...prev, emailURL: null }));
    return true;
  };

  const getAddress = async () => {
    // getAPI Image
    try {
      const response = await axios.get(baseURL + "/social-media");
      console.log(response.data.result[0]?.data);
      setTempEmailName(response.data.result[0]?.data[0].account_name);
      setTempEmailUrl(response.data.result[0]?.data[0].link_social);
      setTempFacebookName(response.data.result[0]?.data[1].account_name);
      setTempFacebookUrl(response.data.result[0]?.data[1].link_social);
      setTempInstagramName(response.data.result[0]?.data[2].account_name);
      setTempInstagramUrl(response.data.result[0]?.data[2].link_social);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleEmailName = (event) => {
    setTempEmailName(event.target.value);
  };
  const handleEmailUrl = (event) => {
    setTempEmailUrl(event.target.value);
  };
  const handleFacebookName = (event) => {
    setTempFacebookName(event.target.value);
  };
  const handleFacebookUrl = (event) => {
    setTempFacebookUrl(event.target.value);
  };
  const handleInstagramName = (event) => {
    setTempInstagramName(event.target.value);
  };
  const handleInstagramUrl = (event) => {
    setTempInstagramUrl(event.target.value);
  };

  useEffect(() => {
    setError({
      faceName: null,
      faceURL: null,
      instaName: null,
      instaURL: null,
      emailName: null,
      emailURL: null,
    });
    getAddress();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    if (!fieldsValidation()) return;
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSubmitData = async () => {
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      try {
        const updatedData = [
          {
            id: 1,
            account_name: tempEmailName,
            link_social: tempEmailUrl,
          },
          {
            id: 2,
            account_name: tempFacebookName,
            link_social: tempFacebookUrl,
          },
          {
            id: 3,
            account_name: tempInstagramName,
            link_social: tempInstagramUrl,
          },
        ];
        for (const data of updatedData) {
          const response = await axios.put(`${baseURL}/social-media`, data, {
            headers: {
              Authorization: `Bearer ${currAdmin.token}`,
            },
          });
          console.log(
            `Data updated successfully for ID ${data.id}:`,
            response.data
          );
        }
        handleCloseModal();
      } catch (error) {
        console.error("Error updating data:", error);
      }
    }
  };

  return (
    <Grid container spacing={2} columns={{ xs: 6, lg: 12 }}>
      <Grid item xs={6}>
        <div className={styles.wrapper}>
          {/* <form> */}
          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
            >
              Facebook Display Name
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempFacebookName}
                // id="productName"
                className={styles.customField}
                onChange={handleFacebookName}
              />
            </div>
            {error.faceName !== null ? (
              <div className={styles.errorMsg}>{error.faceName}</div>
            ) : null}
          </div>
          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
              style={{ marginTop: 20 }}
            >
              URL
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempFacebookUrl}
                // id="productName"
                className={styles.customField}
                onChange={handleFacebookUrl}
              />
              <LinkRoundedIcon className={styles.iconRight} />
            </div>
            {error.faceURL !== null ? (
              <div className={styles.errorMsg}>{error.faceURL}</div>
            ) : null}
          </div>

          <div className={styles.customLine}></div>

          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
              style={{ marginTop: 20 }}
            >
              Instagram Display Name
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempInstagramName}
                // id="productName"
                className={styles.customField}
                onChange={handleInstagramName}
              />
            </div>
            {error.instaName !== null ? (
              <div className={styles.errorMsg}>{error.instaName}</div>
            ) : null}
          </div>
          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
              style={{ marginTop: 20 }}
            >
              URL
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempInstagramUrl}
                // id="productName"
                className={styles.customField}
                onChange={handleInstagramUrl}
              />
              <LinkRoundedIcon className={styles.iconRight} />
            </div>
            {error.instaURL !== null ? (
              <div className={styles.errorMsg}>{error.instaURL}</div>
            ) : null}
          </div>
          {/* </form> */}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={styles.wrapper}>
          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
            >
              Email Display Name
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempEmailName}
                // id="productName"
                className={styles.customField}
                onChange={handleEmailName}
              />
            </div>
            {error.emailName !== null ? (
              <div className={styles.errorMsg}>{error.emailName}</div>
            ) : null}
          </div>
          <div className={styles.formGroup}>
            <label
              // htmlFor="productName"
              className={styles.textLabel}
              style={{ marginTop: 20 }}
            >
              URL
            </label>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={tempEmailUrl}
                // id="productName"
                className={styles.customField}
                onChange={handleEmailUrl}
              />
              <LinkRoundedIcon className={styles.iconRight} />
            </div>
            {error.emailURL !== null ? (
              <div className={styles.errorMsg}>{error.emailURL}</div>
            ) : null}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        <button className={styles.saveButton} onClick={handleOpenModal}>
          Save
        </button>
      </Grid>

      <ConfirmSubmitChanges
        openModal={openModal}
        closeModal={handleCloseModal}
        handleSubmitData={handleSubmitData}
      />
    </Grid>
  );
}
