import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./editcategoryproduct.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400.jsx";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  maxHeight: "307px",
};

const EditCategoryProduct = ({ isOpen, onClose, editedProduct, Update }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [editSuccessModal, setEditSuccessModal] = useState(false);
  const handleOpenModal = () => {
    if (isEmpty() === false) {
      return;
    }
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [productName, setProductName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [errorMessage, setErrorMsg] = useState(null);

  useEffect(() => {
    if (editedProduct) {
      setProductName(editedProduct.category);
      setIsActive(editedProduct.status === "Deleted");
    }
    setErrorMsg(null);
  }, [editedProduct, isOpen]);

  const isEmpty = () => {
    if (productName.trim().length === 0) {
      setErrorMsg("Please enter a Category Name.");
      return false;
    }
    setErrorMsg(null);
    return true;
  };

  const editCategory = async (id) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.put(
        `${baseURL}/category`,
        {
          id: `${id}`,
          name_category: `${productName}`,
          isDeleted: `${isActive}`,
        },
        { headers }
      );
      if (response.data.status === 200) {
        console.log("Success");
        setEditSuccessModal(true);
        return true;
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Category Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      console.log(error);
      return false;
    }
  };

  const handleSave = async () => {
    const editedData = {
      id: editedProduct.id,
      category: productName,
      status: isActive,
    };
    console.log(editedData);
    const response = await editCategory(editedData.id);
    if (response) {
      Update();
      onClose();
    }
  };

  return (
    <>
      <Modal open={isOpen}>
        <Box sx={styleBox}>
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
            }}
          >
            <Grid xs={11}>
              <div className={styles.modalTitle}>EDIT CATEGORY</div>
            </Grid>
            <Grid xs={1}>
              <CloseRoundedIcon
                className={styles.closeIcon}
                onClick={onClose}
              />
            </Grid>
            <Grid
              xs={12}
              style={{
                maxWidth: "491px",
                maxHeight: "307px",
                overflow: "auto",
              }}
            >
              <form>
                <div className="form-group">
                  <label
                    htmlFor="productName"
                    className={styles.categorynamenew}
                  >
                    Category Name
                  </label>
                  <br></br>
                  <input
                    type="text"
                    id="categoryName"
                    value={productName}
                    className={styles.productnamenew}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                  {errorMessage !== null ? (
                    <div className={styles.errorMsg}>{errorMessage}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className={styles.labelstatusnew}>Status:</label>
                  <br></br>
                  <br></br>
                  <label className={styles.radiogroupnew}>
                    <input
                      type="radio"
                      name="status"
                      value="active"
                      checked={!isActive}
                      onChange={() => setIsActive(false)}
                    />
                    Active
                  </label>
                  <label className={styles.radiogroupnew2}>
                    <input
                      type="radio"
                      name="status"
                      value="nonactive"
                      checked={isActive}
                      onChange={() => setIsActive(true)}
                    />
                    Non-Active
                  </label>
                </div>
              </form>
            </Grid>
            <Grid xs={6}>
              <button
                onClick={handleOpenModal}
                className={styles.btnGroupNewCategoryProduct}
              >
                Save
              </button>
            </Grid>
            <Grid xs={6}>
              <button
                onClick={onClose}
                className={styles.btnGroupNewCategoryProduct}
              >
                Cancel
              </button>
            </Grid>
            <ConfirmSubmitChanges
              openModal={openModal}
              closeModal={handleCloseModal}
              handleSubmitData={handleSave}
            />
          </Grid>
        </Box>
      </Modal>
      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => setWarningModal(false)}
        warningText={warningMsg}
      />
      <ModalError400
        openModal={editSuccessModal}
        closeModal={() => setEditSuccessModal(false)}
        warningText={"Data Edited Succsesfully"}
      />
    </>
  );
};

export default EditCategoryProduct;
