import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./editcategorymanufacturer.module.css";
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
  maxWidth: "491px",
  maxHeight: "307px",
};

const EditCategoryManufacturer = ({
  isOpen,
  onClose,
  editedManufacturer,
  onSave,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const handleOpenModal = () => {
    if (validateManufacturerName()) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerNameError, setManufacturerNameError] = useState("");
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (editedManufacturer) {
      setManufacturerName(editedManufacturer.category);
      setIsActive(editedManufacturer.status === "Active");
    }
  }, [editedManufacturer]);

  const handleSave = () => {
    if (validateManufacturerName()) {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");

      const isDeleted = isActive ? "false" : "true";

      const editedData = {
        id: editedManufacturer.id,
        name_manufacturer: manufacturerName,
        isDeleted: isDeleted,
      };

      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };

      onSave(editedData, headers);
      setOpenModal(false);
    }
  };

  const validateManufacturerName = () => {
    if (manufacturerName.trim().length === 0) {
      setManufacturerNameError("Please enter a Manufacturer Name.");
      return false;
    }
    setManufacturerNameError(null);
    return true;
  };

  const handleManufacturerNameChange = (e) => {
    setManufacturerName(e.target.value);
  };
  useEffect(() => {
    if (!isOpen) {
      setManufacturerNameError("");
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Box sx={styleBox}>
        <Grid container spacing={2} style={{ margin: 0 }}>
          <Grid xs={11}>
            <div className={styles.modalTitle}>EDIT MANUFACTURER</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon className={styles.closeIcon} onClick={onClose} />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxWidth: "491px",
              maxHeight: "307px",
              overflow: "auto",
            }}
          >
            <form>
              <div className="form-group">
                <label htmlFor="productName" className={styles.categorynamenew}>
                  Manufacturer Name
                </label>
                <br />
                <input
                  type="text"
                  id="categoryName"
                  className={styles.productnamenew}
                  value={manufacturerName}
                  onChange={handleManufacturerNameChange}
                />
                {manufacturerNameError && (
                  <div
                    className={`error-msg ${styles.errorMsg}`}
                    style={{ color: "#BE1620" }}
                  >
                    {manufacturerNameError}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className={styles.labelstatusnew}>Status:</label>
                <br />
                <br />
                <label className={styles.radiogroupnew}>
                  <input
                    type="radio"
                    name="status"
                    value="active"
                    checked={isActive}
                    onChange={() => setIsActive(true)}
                  />{" "}
                  Active
                </label>
                <label className={styles.radiogroupnew2}>
                  <input
                    type="radio"
                    name="status"
                    value="nonactive"
                    checked={!isActive}
                    onChange={() => setIsActive(false)}
                  />{" "}
                  Non-Active
                </label>
              </div>
            </form>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={handleOpenModal}
              className={styles.btnGroupNewCategoryProduct}
            >
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button
              onClick={onClose}
              className={styles.btnGroupNewCategoryProduct}
            >
              Cancel
            </button>
          </Grid>
          <ConfirmSubmitChanges
            openModal={openModal}
            closeModal={handleCloseModal}
            handleSubmitData={handleSave}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditCategoryManufacturer;
