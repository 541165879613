// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.csv_template_modal_Modal__-iJ1d{
    display: grid;
    place-items: center;
}

.csv_template_modal_Title__ttUTG, .csv_template_modal_closeIcon__44XrR{
    color: var(--color_red-primer);
}

.csv_template_modal_Title__ttUTG{
    font-size: 16px;
    font-weight: bold;
}

.csv_template_modal_closeIcon__44XrR{
    cursor: pointer;
}

.csv_template_modal_modalOuterContainer__fAfaO{
    padding: 20px 16px;
    border-radius: 10px;
    background-color: var(--color_white);
    width: 25%;
}

.csv_template_modal_modalHeader__NQugQ{
    display: flex;
    justify-content: space-between;
}

.csv_template_modal_modalContent__2oLbs{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.csv_template_modal_Button__V03tE{
    cursor: pointer;
    width: 100%;
    padding: 6px 12px;
    border-radius: 10px;

    border: solid 1px #000000;
    background-color: var(--color_red-primer);
    color: var(--color_white);
}

.csv_template_modal_Button__V03tE:hover{
    background-color:  var(--color_white);
    color:var(--color_red-primer);
}`, "",{"version":3,"sources":["webpack://./src/components/csv_template_modal/csv_template_modal.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,oCAAoC;IACpC,UAAU;AACd;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,mBAAmB;;IAEnB,yBAAyB;IACzB,yCAAyC;IACzC,yBAAyB;AAC7B;;AAEA;IACI,qCAAqC;IACrC,6BAA6B;AACjC","sourcesContent":["\n.Modal{\n    display: grid;\n    place-items: center;\n}\n\n.Title, .closeIcon{\n    color: var(--color_red-primer);\n}\n\n.Title{\n    font-size: 16px;\n    font-weight: bold;\n}\n\n.closeIcon{\n    cursor: pointer;\n}\n\n.modalOuterContainer{\n    padding: 20px 16px;\n    border-radius: 10px;\n    background-color: var(--color_white);\n    width: 25%;\n}\n\n.modalHeader{\n    display: flex;\n    justify-content: space-between;\n}\n\n.modalContent{\n    margin-top: 10px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.Button{\n    cursor: pointer;\n    width: 100%;\n    padding: 6px 12px;\n    border-radius: 10px;\n\n    border: solid 1px #000000;\n    background-color: var(--color_red-primer);\n    color: var(--color_white);\n}\n\n.Button:hover{\n    background-color:  var(--color_white);\n    color:var(--color_red-primer);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": `csv_template_modal_Modal__-iJ1d`,
	"Title": `csv_template_modal_Title__ttUTG`,
	"closeIcon": `csv_template_modal_closeIcon__44XrR`,
	"modalOuterContainer": `csv_template_modal_modalOuterContainer__fAfaO`,
	"modalHeader": `csv_template_modal_modalHeader__NQugQ`,
	"modalContent": `csv_template_modal_modalContent__2oLbs`,
	"Button": `csv_template_modal_Button__V03tE`
};
export default ___CSS_LOADER_EXPORT___;
