import styles from "./color.module.css";
import colorIcon from "../../../icon/colorIcon.png";

const Color = ({colorCounts}) => {
  // console.log(colorCounts)
  return (
    <div className={styles.colorContainer}>
      <div className={styles.colorLeftSubContainer}>
        <div className={styles.colorLeftSubContainerContent}>
            <img src={colorIcon} alt="" />
            <div className={styles.leftSubContainerNumber}>{colorCounts.allColor}</div>
            <div className={styles.stringSubContainer}>All Colors</div>
        </div>
      </div>
      {/* <div className={styles.colorRightSubContainer}> */}
        <div className={styles.colorRightSubContainerContent}>
          <div className={styles.colorRightSubContainerSubContent}>
            <div className={styles.stringSubContainer}>Manufacturers</div>
            <div className={styles.rightSubContainerNumber}>{colorCounts.manuCount}</div>
          </div>
          <div className={`${styles.colorRightSubContainerSubContent} ${styles.colorRightSubContainerSubContentBottom}`}>
            <div className={styles.stringSubContainer}>Models</div>
            <div className={styles.rightSubContainerNumber}>{colorCounts.modelCount}</div>
          </div>
        </div>
        <div className={styles.colorRightSubContainerContent}>
          <div className={styles.colorRightSubContainerSubContent}>
            <div className={styles.stringSubContainer}>Year of Production</div>
            <div className={styles.rightSubContainerNumber}>{colorCounts.yearCount}</div>
          </div>
          <div className={`${styles.colorRightSubContainerSubContent} ${styles.colorRightSubContainerSubContentBottom}`}>
            <div className={styles.stringSubContainer}>Areas</div>
            <div className={styles.rightSubContainerNumber}>{colorCounts.areaCount}</div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default Color;
