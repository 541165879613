import styles from "./WarningFileSizeModal.module.css";
import { Box, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: `347px`,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

export default function WarningFileSizeModal({
  openModal,
  closeModal,
  warningText,
  wrongEntries,
  wrongRows,
  forcePostFunc,
  typeImport,
}) {
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (wrongRows) {
      checkImportType(wrongRows);
    }
  }, [openModal]);

  // console.log(wrongRows);
  // console.log(typeImport);

  const checkImportType = () => {
    switch (typeImport) {
      case `color_list`: {
        setErrorMsg(wrongRows.duplicates);
        return;
      }
      case `color_image`: {
        setErrorMsg(wrongRows.notFoundColorCodes);
        return;
      }
      case `formula`: {
        setErrorMsg(wrongRows);
        return;
      }
    }
  };

  const errorImport = () => {
    // console.log(errorMsg);
    switch (typeImport) {
      case `color_list`: {
        return wrongRows && errorMsg ? (
          <Grid xs={12}>
            <table className={styles.colorListErrorContainer}>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentHeader}`}
              >
                <th className={styles.wrongRowTitle}>REASON</th>
                <th className={styles.wrongRowTitle}>ROWS</th>
              </tr>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>CODE_COLOR</td>
                <td className={styles.wrongRowText}>
                  {JSON.stringify(errorMsg.code_color)
                    .replace("[", "")
                    .replace("]", "")}
                </td>
              </tr>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>NAME_COLOR</td>
                <td className={styles.wrongRowText}>
                  {JSON.stringify(errorMsg.name_color)
                    .replace("[", "")
                    .replace("]", "")}
                </td>
              </tr>
            </table>
          </Grid>
        ) : null;
      }
      case `color_image`: {
        return wrongRows && errorMsg ? (
          <Grid xs={12}>
            <table className={styles.colorListErrorContainer}>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentHeader}`}
              >
                <th className={styles.wrongRowTitle}>REASON</th>
                <th className={styles.wrongRowTitle}>IMAGES</th>
              </tr>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>COLOR CODE</td>
                <td className={styles.wrongRowText}>
                  {JSON.stringify(errorMsg).replace("[", "").replace("]", "")}
                </td>
              </tr>
              {/* <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>NAME_COLOR</td>
                <td className={styles.wrongRowText}>
                  {JSON.stringify(errorMsg.name_color)
                    .replace("[", "")
                    .replace("]", "")}
                </td>
              </tr> */}
            </table>
          </Grid>
        ) : null;
      }
      case `formula`: {
        return wrongRows && errorMsg ? (
          <Grid xs={12}>
            <table className={styles.colorListErrorContainer}>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentHeader}`}
              >
                <th className={styles.wrongRowTitle}>REASON</th>
                <th className={styles.wrongRowTitle}>ROWS</th>
              </tr>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>
                  FORMULA CATEGORY VIOLATION
                </td>
                <td className={styles.wrongRowText}>
                  {errorMsg.categoryViolatingData.rowNumbers.length !== 0
                    ? JSON.stringify(errorMsg.categoryViolatingData.rowNumbers)
                        .replace("[", "")
                        .replace("]", "")
                    : "none"}
                </td>
              </tr>
              <tr
                className={`${styles.colorListErrorContent} ${styles.colorListErrorContentRow}`}
              >
                <td className={styles.wrongRowText}>WRONG ID COLOR</td>
                <td className={styles.wrongRowText}>
                  {errorMsg.baseFormulaViolatingData.rowNumbers.length !== 0
                    ? JSON.stringify(
                        errorMsg.baseFormulaViolatingData.rowNumbers
                      )
                        .replace("[", "")
                        .replace("]", "")
                    : "none"}
                </td>
              </tr>
            </table>
          </Grid>
        ) : null;
      }
      default: {
        return null;
      }
    }
  };

  const handleOkBtn = async () => {
    await forcePostFunc();
    closeModal();
  };
  return (
    <Modal open={openModal}>
      <Box sx={styleBox}>
        <Grid className={styles.scrollabelContainer} container spacing={2}>
          <Grid xs={12}>
            <ErrorOutlineIcon className={styles.errorIconModal} />
          </Grid>
          <Grid xs={12}>
            <div className={styles.confirmSubmitText}>{warningText}</div>
          </Grid>
          {errorImport()}
          {wrongEntries === undefined || wrongEntries === false ? (
            <>
              <Grid xs={12}>
                <div className={styles.containerBtn}>
                  <button onClick={closeModal} className={styles.okBtn}>
                    OK
                  </button>
                </div>
              </Grid>
            </>
          ) : (
            <Grid xs={12}>
              <div className={styles.containerBtn}>
                <button onClick={handleOkBtn} className={styles.yesBtn}>
                  Yes
                </button>

                <button
                  onClick={() => {
                    window.location.reload();
                    closeModal();
                  }}
                  className={styles.noBtn}
                >
                  No
                </button>
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
