import React, { useState, useEffect } from "react";
import "./favorite.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Link } from "react-router-dom";
import CustomDelete from "../../../icon/CustomDelete";
import CustomEdit from "../../../icon/CustomEdit";
import EditCategoryProduct from "../../../pages/editcategoryproduct/EditCategoryProduct"; // Import EditProductModal
import NewCategoryProduct from "../../../pages/newcategoryproduct/NewCategoryProduct";
import CustomFavourite from "../../../icon/CustomFavourite";
import ConfirmSubmitChanges from "../../ConfirmSubmitChanges/ConfirmSubmitChanges";
import WarningFileSizeModal from "../../WarningFileSizeModal/WarningFileSizeModal";
import { baseURL } from "../../../global_variable_";

export default function FavoriteComponent() {
  useEffect(() => {
    APICalls();
    setFavorites(favorites);
  }, []);

  const APICalls = async () => {
    await getFavorites();
    await getCategories();
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState(null);
  const [categoryID, setCategoryID] = useState("-1");
  const [productCategoryID, setProductCategoryID] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [listCategories, setCategories] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [pickedProduct, setPickedProduct] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [categoryMap, setCategoryMap] = useState({});
  const [warningModal, setWarningModal] = useState(false);

  const getFavorites = async () => {
    try {
      let response = await axios.get(
        `${baseURL}/product?all_data=false&pagination=false`
      );
      setFavorites(response.data.result[0].data);
      //console.log(response.data.result[0].data)
    } catch (e) {
      console.log(e.message);
    }
  };

  const getCategories = async () => {
    try {
      let response = await axios.get(
        `${baseURL}/category?all_data=true$pagination=false`
      );
      const sortedCategories = response.data.result.sort((a, b) =>
        a.name_category.localeCompare(b.name_category)
      );
      setCategories(sortedCategories);
      console.log(sortedCategories);

      const categoryObj = {};
      sortedCategories.forEach((category) => {
        categoryObj[category.id] = category.name_category;
      });
      setCategoryMap(categoryObj);
      console.log(categoryObj);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getProudctByCategoryID = async (id) => {
    try {
      var url = `${baseURL}/product?all_data=false&pagination=false`;
      if (id !== "-1") {
        url = url + `&id_category=${id}`;
      }
      console.log(url);
      let response = await axios.get(`${url}`);
      setFavorites(response.data.result[0].data);
      console.log(response.data.result);
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteItem = async (itemId) => {
    try {
      // ...

      // Hanya admin yang dapat menghapus
      if (isAdmin) {
        // Send a DELETE request to the server to delete the category
        await axios.delete(`${baseURL}/category/${itemId}`);

        // Update the state by removing the deleted item from the products array
        const updatedFavorites = favorites.filter(
          (favorite) => favorite.id !== itemId
        );
        setFavorites(updatedFavorites);

        alert("Item berhasil dihapus.");
      } else {
        alert("Anda tidak memiliki izin untuk menghapus kategori.");
      }
    } catch (error) {
      console.error("Gagal menghapus item:", error);
    }
  };

  const handleFavBtn = (product) => {
    setPickedProduct(product);
    setConfirmModal(true);
  };

  const handleFavProduct = async (editedProduct) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };
    console.log(editedProduct);
    console.log(productCategoryID);
    try {
      const response = await axios.put(
        `${baseURL}/product`,
        {
          id: `${editedProduct.id}`,
          id_category: `${productCategoryID}`,
          favourite: editedProduct.favourite === true ? `false` : `true`,
        },
        { headers }
      );
      if (response.data.status === 201) {
        if (categoryID !== "-1") {
          await getProudctByCategoryID(categoryID);
        } else {
          await getFavorites();
        }
        console.log("success");
      }
    } catch (error) {
      if (
        error.response.data.result ===
        "Maximum of three favorites allowed for this category."
      ) {
        setWarningModal(true);
      }
      console.log(error.response.data.result);
    }
  };

  const openNewCategoryProductModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const columns = [
    {
      field: "favourite",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => {
        return (
          <CustomFavourite
            onClick={() => {
              setProductCategoryID(params.row.id_category);
              handleFavBtn(params.row);
            }}
            clickedProduct={params.row}
          />
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "customColumnHeader",
      flex: 2,
      renderCell: (params) => {
        //console.log('Image URL:', params.row.image); // Add this line for debugging
        const imageUrl = `${baseURL}/${params.row.image_product}`;
        console.log(imageUrl);
        return (
          <div className="imageCol">
            <img
              className="setImage"
              src={imageUrl}
              alt={`Image of ${params.row.name}`}
              onError={(e) => {
                console.error("Error loading image:", e);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "name_product",
      headerName: "Product Name",
      headerClassName: "customColumnHeader",
      flex: 7,
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        return categoryMap[params.row.id_category] || "Category Not Found";
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "customColumnHeader",
      flex: 7,
    },
  ];

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return isLoading !== true ? (
    <>
      <div className="headerFavorite">
        <div className="hintText">Choose maximal 3 Favorite Products</div>
        <select
          defaultValue="-1"
          className="categoryFilter"
          name=""
          id=""
          value={categoryID}
          onChange={async (e) => {
            setCategoryID(e.target.value);
            await getProudctByCategoryID(e.target.value);
          }}
        >
          <option className="categoryFilterOption" value="-1">
            - All Category -
          </option>
          {listCategories.map((category) => (
            <option
              className="categoryFilterOption"
              key={category.id}
              value={category.id}
            >
              {category.name_category}
            </option>
          ))}
        </select>
      </div>
      <DataGrid
        className="favoritePageTable"
        rows={favorites}
        columns={columns}
        autoHeight={true}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
          sorting: {
            sortModel: [{ field: "favourite", sort: "desc" }],
          },
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => "customRow"}
        getCellClassName={(params) => "customCell"}
        disableRowSelectionOnClick={true}
      />
      <ConfirmSubmitChanges
        openModal={confirmModal}
        closeModal={() => {
          setConfirmModal(false);
        }}
        handleSubmitData={() => {
          handleFavProduct(pickedProduct);
        }}
      />
      <WarningFileSizeModal
        openModal={warningModal}
        closeModal={() => setWarningModal(false)}
        warningText="Maximum of three favorites allowed for this category."
      />
    </>
  ) : (
    <div>loading...</div>
  );
}
