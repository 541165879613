import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useState } from 'react';

export default function TextEditor({ onSave, availData }) {

    const [data, setData] = useState('');
    const handleSaveData = (event, editor) => {
        const data = editor.getData();
        setData(data);
        console.log( { event, editor, data } );
        onSave(data);
    }

    return (
        <CKEditor
            editor=     {Editor}
            data=       {!availData ? "" : availData}
            onReady=    { editor => { console.log( 'Editor is ready to use!', editor );}}
            onChange=   {handleSaveData}
            onBlur=     {( event, editor ) => {console.log( 'Blur.',    editor );}}
            onFocus=    {( event, editor ) => {console.log( 'Focus.',   editor );}}
        />
        // <div>hello</div>
    )
}