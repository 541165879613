import { useEffect, useRef, useState } from "react";
import "./rolecms.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import TitleBar from "../../components/titleBar/TitleBar";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import EditRole from "../../components/edit_role_modal/edit_role_modal";
import CustomEye from "../../icon/CustomEye";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
  width: "97%",
};

export default function RoleCms({ titlePage }) {
  const [roleCMSList, setRoleCMSList] = useState([]);
  useEffect(() => {
    APICalls();
  }, []);

  const APICalls = async () => {
    await getRoleCMS();
  };

  const getRoleCMS = async () => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const url = "role-admin?all_data=true";
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.get(`${baseURL}/${url}`, { headers });
      if (response.data.status === 200) {
        console.log(response.data.result[0].data);
        setRoleCMSList(response.data.result[0].data);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteRoleCMS = async (itemId) => {
    const token = JSON.parse(localStorage.getItem("currAdmin")).token;
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.delete(`${baseURL}/role-admin/${itemId}`, {
        headers,
      });
      if (response.data.status === 200) {
        await getRoleCMS();
        console.warn("delete successfull");
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const [editModal, setEditModal] = useState(false);
  const editedRoleName = useRef(null);
  const editedRoleID = useRef(null);
  const editedDeletedStatus = useRef(null);

  const handleOpenEdit = (id, name, status) => {
    editedRoleName.current = name;
    editedRoleID.current = id;
    editedDeletedStatus.current = status;
    setEditModal(true);
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteRoleCMS} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit
          onClick={() => {
            handleOpenEdit(
              params.row.id,
              params.row.role_name,
              params.row.isDeleted
            );
          }}
        />
      ),
    },
    {
      field: "edit Role",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: `grid`,
            placeItems: `center`,
            width: `100%`,
            height: `100%,`,
          }}
        >
          <Link to={`/edit-role-cms/${params.row.role_name}/${params.row.id}`}>
            <CustomEye />
          </Link>
        </div>
      ),
    },

    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 14,
    },
    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Non-Active" : "Active"}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="rolecms">
        {titlePage}

        <Box sx={styleBox}>
          <div className="topContentRoleCMS">
            <Link className="AddRoleCmsLink" to="/new-role-cms">
              <button className="newRoleCMSBtn">
                Add New Role
                <AddIcon className="addIcon1" />
              </button>
            </Link>
          </div>

          <DataGrid
            rows={roleCMSList}
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              sorting: {
                sortModel: [{ field: "role_name", sort: "asc" }],
              },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </div>
      <EditRole
        Update={getRoleCMS}
        id={editedRoleID.current}
        isDeleteD={editedDeletedStatus.current}
        roleNamE={editedRoleName.current}
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        version="admin"
      />
    </>
  );
}
