import React, { useEffect, useState } from "react";
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styles from "./drawerfiltercolor.module.css";
import axios from "axios";
import { baseURL } from "../../global_variable_";

const drawerStyles = {
  width: "100%",
  borderRadius: "20px 0px 0px 20px",
  background: "#FFF",
  marginBottom: "72px",
};

export default function FilterDrawer({
  isOpen,
  onClose,
  onSelectedData,
  deletedFilterNum,
}) {
  const [selectedManufactures, setSelectedManufactures] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [showManufactures, setShowManufactures] = useState(false);
  const [manufactureData, setManufactureData] = useState([]);
  const [showModels, setShowModels] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [showYears, setShowYears] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [showAreas, setShowAreas] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [selectedManufactureCount, setSelectedManufactureCount] = useState(0);
  const [selectedModelCount, setSelectedModelCount] = useState(0);
  const [selectedYearCount, setSelectedYearCount] = useState(0);
  const [selectedAreaCount, setSelectedAreaCount] = useState(0);
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [showAllFiltersClicked, setShowAllFiltersClicked] = useState(false);
  const [selectAllManufacturersChecked, setSelectAllManufacturersChecked] =
    useState(false);
  const [selectAllModelsChecked, setSelectAllModelsChecked] = useState(false);
  const [selectAllYearsChecked, setSelectAllYearsChecked] = useState(false);
  const [selectAllAreasChecked, setSelectAllAreasChecked] = useState(false);

  const sortedManufactureData = [...manufactureData].sort((a, b) =>
    a.name_manufacturer.localeCompare(b.name_manufacturer)
  );

  const sortedModelData = [...modelData].sort((a, b) =>
    a.name_car_model.localeCompare(b.name_car_model)
  );

  const sortedYearData = [...yearData].sort((a, b) => a.id - b.id);

  const sortedAreaData = [...areaData].sort((a, b) => a.id - b.id);

  const distinctManufacturerNames = [
    ...new Map(
      sortedManufactureData.map((item) => [item.name_manufacturer, item])
    ).values(),
  ];

  const distinctModelNames = sortedModelData.reduce((acc, item) => {
    const existingModel = acc.find(
      (model) => model.name_car_model === item.name_car_model
    );

    if (existingModel) {
      if (existingModel.id.length > 1) {
        existingModel.id.push(item.id);
      } else {
        existingModel.id = [existingModel.id, item.id];
      }
    } else {
      acc.push({
        name_car_model: item.name_car_model,
        id_manufacturer: item.id_manufacturer,
        id: item.id,
      });
    }
    return acc;
  }, []);

  const newModelData = distinctModelNames.reduce((result, item) => {
    if (!Array.isArray(item.id)) {
      result.push({
        id: item.id,
        id_manufacturer: item.id_manufacturer,
        name_car_model: item.name_car_model,
      });
    }
    return result;
  }, []);

  const distinctYearNames = [
    ...new Map(
      sortedYearData.map((item) => [item.production_year, item])
    ).values(),
  ];

  const distinctAreaNames = [
    ...new Map(sortedAreaData.map((item) => [item.name_area, item])).values(),
  ];

  const fetchData = async (textAPI, setData) => {
    try {
      const response = await axios.get(`${baseURL}/${textAPI}?all_data=true`);
      if (Array.isArray(response.data.result[0].data)) {
        setData(response.data.result[0].data);
      } else {
        console.error("API response is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleManufactureSelect = (manufacture) => {
    if (selectedManufactures.includes(manufacture)) {
      setSelectedManufactures(
        selectedManufactures.filter((item) => item !== manufacture)
      );
      setSelectAllManufacturersChecked(false);
      setSelectedModels([]);
      setActiveModelCount(0);
    } else {
      setSelectedManufactures([...selectedManufactures, manufacture]);
    }
    const distinctModelNames = sortedModelData.reduce((acc, item) => {
      const existingModel = acc.find(
        (model) =>
          model.name_car_model === item.name_car_model &&
          selectedManufactures.includes(model.id_manufacturer)
      );

      if (existingModel) {
        if (existingModel.id.length > 1) {
          existingModel.id.push(item.id);
        } else {
          existingModel.id = [existingModel.id, item.id];
        }
      } else {
        acc.push({
          name_car_model: item.name_car_model,
          id_manufacturer: item.id_manufacturer,
          id: item.id,
        });
      }
      return acc;
    }, []);
    // setNewModelData(distinctModelNames);
  };

  const handleModelSelect = (models) => {
    const updatedSelection = [...selectedModels];

    const toggleModel = (model) => {
      const modelIndex = updatedSelection.indexOf(model);
      if (modelIndex !== -1) {
        updatedSelection.splice(modelIndex, 1);
      } else {
        updatedSelection.push(model);
      }
    };
    if (Array.isArray(models)) {
      models.forEach((model) => toggleModel(model));
    } else {
      toggleModel(models);
    }

    setSelectedModels(updatedSelection);
  };

  const handleYearSelect = (year) => {
    if (selectedYears.includes(year)) {
      setSelectedYears(selectedYears.filter((item) => item !== year));
      setSelectAllYearsChecked(false);
    } else {
      setSelectedYears([...selectedYears, year]);
    }
    // sendSelectedData();
  };

  const handleAreaSelect = (area) => {
    if (selectedAreas.includes(area)) {
      setSelectedAreas(selectedAreas.filter((item) => item !== area));
      setSelectAllAreasChecked(false);
    } else {
      setSelectedAreas([...selectedAreas, area]);
    }
    // sendSelectedData();
  };

  const handleSelectAllManufacturers = () => {
    setSelectAllManufacturersChecked(!selectAllManufacturersChecked);

    const updatedSelectedManufacturers = !selectAllManufacturersChecked
      ? distinctManufacturerNames.map((manufacturer) => manufacturer.id)
      : [];
    if (selectAllManufacturersChecked) {
      setSelectedModels([]);
      setActiveModelCount(0);
      setFilterStates({ ...filterStates, showModels: false });
    }

    setSelectedManufactures(updatedSelectedManufacturers);
    setActiveManufactureCount(updatedSelectedManufacturers.length);
  };

  const handleSelectAllYears = () => {
    setSelectAllYearsChecked(!selectAllYearsChecked);

    const updatedSelectedYears = !selectAllYearsChecked
      ? distinctYearNames.map((year) => year.id)
      : [];

    setSelectedYears(updatedSelectedYears);

    setActiveYearCount(updatedSelectedYears.length);
  };

  const handleSelectAllArea = () => {
    setSelectAllAreasChecked(!selectAllAreasChecked);

    const updatedSelectedAreas = !selectAllAreasChecked
      ? distinctAreaNames.map((area) => area.id)
      : [];

    setSelectedAreas(updatedSelectedAreas);
    setActiveAreaCount(updatedSelectedAreas.length);
  };

  const handleClearAllSelected = () => {
    setSelectedManufactures([]);
    setSelectedModels([]);
    setSelectedYears([]);
    setSelectedAreas([]);
    setActiveModelCount(0);
    setActiveManufactureCount(0);
    setActiveAreaCount(0);
    setActiveYearCount(0);
    setSelectAllManufacturersChecked(false);
    setSelectAllModelsChecked(false);
    setSelectAllYearsChecked(false);
    setSelectAllAreasChecked(false);
    setFilterStates({
      showManAndMod: true,
      showManufactures: false,
      showModels: false,
      showYears: false,
      showAreas: false,
    });
  };

  const handleSelectedData = () => {
    const selectedData = {
      manufactures: selectedManufactures,
      models: selectedModels,
      years: selectedYears,
      areas: selectedAreas,
      name_manufactures: selectedManufactures.map((manufactureId) => {
        const foundManufacture = distinctManufacturerNames.find(
          (manufacture) => manufacture.id === manufactureId
        );
        return foundManufacture ? foundManufacture.name_manufacturer : "";
      }),

      car_name_model: selectedModels.map((modelId) => {
        const foundCarModel = distinctModelNames.find(
          (model) => model.id === modelId
        );
        return foundCarModel ? foundCarModel.name_car_model : "";
      }),
      production_year: selectedYears.map((yearId) => {
        const foundYear = distinctYearNames.find((year) => year.id === yearId);
        return foundYear ? foundYear.production_year : "";
      }),
      name_area: selectedAreas.map((areaId) => {
        const foundArea = distinctAreaNames.find((area) => area.id === areaId);
        return foundArea ? foundArea.name_area : "";
      }),
    };
    console.log(selectedData); // tes view
    onSelectedData(selectedData);
  };

  const [filterStates, setFilterStates] = useState({
    showManAndMod: true,
    showManufactures: false,
    showModels: false,
    showYears: false,
    showAreas: false,
  });

  const toggleFilter = (filterName) => {
    if (filterName === "showAllFilters" && !showAllFiltersClicked) {
      setFilterStates((prevFilterStates) => {
        const closeAllFilters = !prevFilterStates.showAllFilters;
        setShowAllFiltersClicked(true);
        return {
          showManAndMod: closeAllFilters,
          showManufactures: closeAllFilters,
          showModels: closeAllFilters,
          showYears: closeAllFilters,
          showAreas: closeAllFilters,
          showAllFilters: closeAllFilters,
        };
      });
    } else {
      setFilterStates((prevFilterStates) => ({
        ...prevFilterStates,
        [filterName]: !prevFilterStates[filterName],
      }));
    }
  };

  const filterItems = (items, searchQuery) => {
    return items.filter((item) => {
      const manufacturerMatch = String(item.name_manufacturer)
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const modelMatch = String(item.name_car_model)
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const yearMatch = String(item.production_year)
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const areaMatch = String(item.name_area)
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      // Return true if there's a match in any category
      return manufacturerMatch || modelMatch || yearMatch || areaMatch;
    });
  };

  useEffect(() => {
    // *** PERLU
    // fetchData("manufacturer", setManufactureData);
    // fetchData("car-model", setModelData);
    // fetchData("year-of-production", setYearData);
    // fetchData("area", setAreaData);
    // *** PERLU
    // setSelectedItemCount(
    //   selectedManufactures.length +
    //   activeModelCount +
    //   selectedYears.length +
    //   selectedAreas.length
    // );
    // console.log("manufactures: ", selectedManufactures);
    // console.log("models: ", selectedModels);
    // console.log("years: ", selectedYears);
    // console.log("areas: ", selectedAreas);
    // *** PERLU
    // if (selectedManufactures.length < 1) {
    //   setFilterStates({
    //     showModels: false,
    //   });
    //   setSelectedModels([]);
    //   setActiveModelCount(0);
    // }
  }, [selectedManufactures, selectedModels, selectedYears, selectedAreas]);

  useEffect(() => {
    fetchData("manufacturer", setManufactureData);
    fetchData("car-model", setModelData);
    fetchData("year-of-production", setYearData);
    fetchData("area", setAreaData);
  }, []);

  useEffect(() => {
    setShowAllFiltersClicked(false);
    setShowAllFilters(true);
  }, [searchText]);

  useEffect(() => {
    // console.warn("belum masuk :", deletedFilterNum)
    // console.clear()
    // console.log(deletedFilterNum)
    if (Array.isArray(deletedFilterNum)) {
      deletedFilterNum.forEach((filterNum) => {
        switch (filterNum) {
          case 1:
            setSelectedManufactures([]);
            setActiveManufactureCount(0);
            setSelectAllManufacturersChecked(false);
            setFilterStates({ ...filterStates, showManufactures: false });
            setFilterStates({ ...filterStates, showModels: false });
            setSelectedModels([]);
            setActiveModelCount(0);
            break;
          case 2:
            setSelectedModels([]);
            setActiveModelCount(0);
            setSelectAllModelsChecked(false);
            setFilterStates({ ...filterStates, showModels: false });
            break;
          case 3:
            setSelectedYears([]);
            setActiveYearCount(0);
            setSelectAllYearsChecked(false);
            setFilterStates({ ...filterStates, showYears: false });
            break;
          case 4:
            setSelectedAreas([]);
            setActiveAreaCount(0);
            setSelectAllAreasChecked(false);
            setFilterStates({ ...filterStates, showAreas: false });
            break;
          default:
            console.warn("Delete Filter Component Failed");
            break;
        }
      });
    }
  }, [deletedFilterNum]);

  // component checkBox
  const [activeManufactureCount, setActiveManufactureCount] = useState(0);
  const [activeModelCount, setActiveModelCount] = useState(0);
  const [activeYearCount, setActiveYearCount] = useState(0);
  const [activeAreaCount, setActiveAreaCount] = useState(0);
  const [manufactureArrayOfId, setManufactureArrayOfId] = useState([]);
  const CheckBoxItem = ({
    id,
    data_name,
    selectedData,
    setSelectedData,
    setActiveCount,
    handleOnChange,
    oldData,
  }) => {
    const handleOnchangeDefault = (models) => {
      const updatedSelection = [...selectedData];

      const toggleModel = (model) => {
        const modelIndex = updatedSelection.indexOf(model);
        if (modelIndex !== -1) {
          updatedSelection.splice(modelIndex, 1);
        } else {
          updatedSelection.push(model);
        }
      };
      if (Array.isArray(models)) {
        models.forEach((model) => toggleModel(model));
      } else {
        toggleModel(models);
      }

      setSelectedData(updatedSelection);

      setActiveCount((prevCount) =>
        (
          Array.isArray(id)
            ? id.every((idItem) => selectedData.includes(idItem))
            : selectedData.includes(id)
        )
          ? prevCount - 1
          : prevCount + 1
      );

      console.log(selectedData);
    };

    let reChecked = false;
    let oldDataId = [];

    if (Array.isArray(oldData)) {
      // oldData.map(item => item.id);
      oldDataId = oldData.map((item) => item.id);
    }

    if (id !== "select-all") {
      if (
        Array.isArray(id)
          ? id.every((id) => selectedData.includes(id))
          : selectedData.includes(id)
      ) {
        reChecked = true;
      }
    } else {
      if (oldDataId.every((id) => selectedData.includes(id))) {
        reChecked = true;
      }
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={reChecked}
            onChange={
              handleOnChange
                ? () => handleOnChange(id)
                : () => handleOnchangeDefault(id)
            }
            style={{
              color: reChecked ? "#FFC3C3" : "white",
              width: "16px",
              height: "16px",
              border: "3px solid #FFC3C3",
              borderRadius: "2px",
              marginRight: "8px",
              padding: "8px",
            }}
          />
        }
        label={
          <span
            style={{
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              fontWeight: id !== "select-all" ? "400" : "bold",
              color: "black",
            }}
          >
            {data_name}
          </span>
        }
      />
    );
  };

  const SelectedIndicator = ({ num }) => {
    return <div className={styles.selectedIndicator}>{num}</div>;
  };

  return (
    <Drawer anchor="right" open={isOpen}>
      <div style={drawerStyles}>
        <div className={styles.topContent}>
          <div>Filters</div>
          <CloseRoundedIcon
            className={styles.closeIconDrawer}
            onClick={onClose}
          />
        </div>
        <div className={styles.searchBar}>
          <input
            className={styles.SearchBoxFilter}
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClick={() => toggleFilter("showAllFilters")}
          />
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        </div>

        <div className={styles.containerManufacture}>
          <Accordion
            expanded={filterStates.showManAndMod}
            style={{
              width: "100%",
            }}
          >
            <AccordionSummary onClick={() => toggleFilter("showManAndMod")}>
              <div className={styles.filterCat}>
                {filterStates.showManAndMod ? <RemoveIcon /> : <AddIcon />}
                Manufactures & Models
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.containerManufacture}>
                <Accordion
                  expanded={filterStates.showManufactures}
                  style={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary
                    // expandIcon={<AddIcon className={styles.addIcon}/>}
                    onClick={() => toggleFilter("showManufactures")}
                  >
                    <div className={styles.filterCat}>
                      {filterStates.showManufactures ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                      Manufactures
                      {selectedManufactures.length > 0 && (
                        <SelectedIndicator num={selectedManufactures.length} />
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.manufacturesList}>
                      <CheckBoxItem
                        id="select-all"
                        data_name="Select All"
                        selectedData={
                          selectAllManufacturersChecked
                            ? distinctManufacturerNames.map(
                                (manufacturer) => manufacturer.id
                              )
                            : []
                        }
                        setSelectedData={setSelectedManufactures}
                        setActiveCount={setActiveManufactureCount}
                        handleOnChange={handleSelectAllManufacturers}
                        oldData={distinctManufacturerNames}
                      />
                      {filterItems(distinctManufacturerNames, searchText).map(
                        (manufacturer, id) => (
                          <CheckBoxItem
                            key={id}
                            id={manufacturer.id}
                            data_name={manufacturer.name_manufacturer}
                            selectedData={selectedManufactures}
                            setSelectedData={setSelectedManufactures}
                            setActiveCount={setActiveManufactureCount}
                            handleOnChange={handleManufactureSelect}
                          />
                        )
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.containerManufacture}>
                <Accordion
                  expanded={filterStates.showModels}
                  disabled={!(selectedManufactures.length > 0)}
                  style={{
                    width: "100%",
                  }}
                >
                  <AccordionSummary onClick={() => toggleFilter("showModels")}>
                    <div className={styles.filterCat}>
                      {filterStates.showModels ? <RemoveIcon /> : <AddIcon />}
                      Models
                      {activeModelCount > 0 && (
                        <SelectedIndicator num={activeModelCount} />
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.manufacturesList}>
                      {filterItems(newModelData, searchText)
                        .filter((model) =>
                          selectedManufactures.includes(model.id_manufacturer)
                        )
                        .map((model, id) => (
                          <CheckBoxItem
                            key={id}
                            id={model.id}
                            data_name={model.name_car_model}
                            selectedData={selectedModels}
                            setSelectedData={setSelectedModels}
                            setActiveCount={setActiveModelCount}
                          />
                        ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={styles.containerManufacture}>
          <Accordion
            expanded={filterStates.showYears}
            style={{
              width: "100%",
            }}
          >
            <AccordionSummary
              // expandIcon={<AddIcon className={styles.addIcon}/>}
              onClick={() => toggleFilter("showYears")}
            >
              <div className={styles.filterCat}>
                {filterStates.showYears ? <RemoveIcon /> : <AddIcon />}
                Years of Production
                {selectedYears.length > 0 && (
                  <SelectedIndicator num={selectedYears.length} />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.manufacturesList}>
                <CheckBoxItem
                  id="select-all"
                  data_name="Select All"
                  selectedData={
                    selectAllYearsChecked
                      ? distinctYearNames.map((year) => year.id)
                      : []
                  }
                  setSelectedData={setSelectedYears}
                  setActiveCount={setActiveYearCount}
                  handleOnChange={handleSelectAllYears}
                  oldData={distinctYearNames}
                />
                {filterItems(distinctYearNames, searchText).map((year, id) => (
                  <CheckBoxItem
                    key={id}
                    id={year.id}
                    data_name={year.production_year}
                    selectedData={selectedYears}
                    setSelectedData={setSelectedYears}
                    setActiveCount={setActiveYearCount}
                    handleOnChange={handleYearSelect}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={styles.containerManufacture}>
          <Accordion
            expanded={filterStates.showAreas}
            style={{
              width: "100%",
            }}
          >
            <AccordionSummary
              // expandIcon={<AddIcon className={styles.addIcon}/>}
              onClick={() => toggleFilter("showAreas")}
            >
              <div className={styles.filterCat}>
                {filterStates.showAreas ? <RemoveIcon /> : <AddIcon />}
                Areas
                {selectedAreas.length > 0 && (
                  <SelectedIndicator num={selectedAreas.length} />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.manufacturesList}>
                <CheckBoxItem
                  id="select-all"
                  data_name="Select All"
                  selectedData={
                    selectAllAreasChecked
                      ? distinctAreaNames.map((area) => area.id)
                      : []
                  }
                  setSelectedData={setSelectedAreas}
                  setActiveCount={setActiveAreaCount}
                  handleOnChange={handleSelectAllArea}
                  // oldData={distinctAreaNames.length}
                  oldData={distinctAreaNames}
                  // activeCount={activeAreaCount}
                />
                {filterItems(distinctAreaNames, searchText).map((area, id) => (
                  <CheckBoxItem
                    key={id}
                    id={area.id}
                    data_name={area.name_area}
                    selectedData={selectedAreas}
                    setSelectedData={setSelectedAreas}
                    setActiveCount={setActiveAreaCount}
                    handleOnChange={handleAreaSelect}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div className={styles.bottomBtnGroup}>
        <button className={styles.bottomBtn} onClick={handleClearAllSelected}>
          Clear All
        </button>
        <button
          className={styles.bottomBtn2}
          onClick={() => {
            handleSelectedData();
            onClose();
          }}
        >
          Apply
        </button>
      </div>
    </Drawer>
  );
}
