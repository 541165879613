// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --color_blue: rgba(46, 0, 231, 1);
    --color_gray: rgba(100, 100, 100, 1);
    --color_gray-1: rgba(244, 244, 244, 1);
    --color_gray-6: rgba(242, 242, 242, 1);
    --color_red-2: rgb(255, 195, 195, 1);
    --color_red-3: rgba(190, 22, 32, 0.1);
    --color_red-primer: rgba(190, 22, 32, 1);
    --color_white: rgba(255, 255, 255, 1);
}

.container{
    display: flex;
    min-height: 100vh;
    background-color: var(--color_gray-6);
}

.content-container{

    display: flex;
    flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,oCAAoC;IACpC,sCAAsC;IACtC,sCAAsC;IACtC,oCAAoC;IACpC,qCAAqC;IACrC,wCAAwC;IACxC,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[":root{\n    --color_blue: rgba(46, 0, 231, 1);\n    --color_gray: rgba(100, 100, 100, 1);\n    --color_gray-1: rgba(244, 244, 244, 1);\n    --color_gray-6: rgba(242, 242, 242, 1);\n    --color_red-2: rgb(255, 195, 195, 1);\n    --color_red-3: rgba(190, 22, 32, 0.1);\n    --color_red-primer: rgba(190, 22, 32, 1);\n    --color_white: rgba(255, 255, 255, 1);\n}\n\n.container{\n    display: flex;\n    min-height: 100vh;\n    background-color: var(--color_gray-6);\n}\n\n.content-container{\n\n    display: flex;\n    flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
