import React, { useState, useEffect, useCallback } from "react";
import "./product.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Link, Navigate, useParams } from "react-router-dom";
import CustomDelete from "../../icon/CustomDelete";
import CustomEdit from "../../icon/CustomEdit";
import EditCategoryProduct from "../editcategoryproduct/EditCategoryProduct"; // Import EditProductModal
import ConfirmSubmitChanges from "../../components/ConfirmSubmitChanges/ConfirmSubmitChanges";
import CircleIcon from "@mui/icons-material/Circle";
import ProductComponent from "../../components/product/product_component/product_component";
import CategoryComponent from "../../components/product/category_component/category_component";
import FavoriteComponent from "../../components/product/favorite_component/favorite_component";
import ProductSub from "../../components/product/sub_category_component/sub_category_component";
import PageNotFound from "../PageNotFound/PageNotFound";
import TitleBar from "../../components/titleBar/TitleBar";
import { baseURL } from "../../global_variable_.jsx";

export default function Product(props) {
  const { component } = useParams();
  const [page, setPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [allowedComponents, setAllowedComp] = useState([
    "category",
    "sub-category",
    "product",
    "favorite",
  ]);

  const checkComponentAuth = () => {
    const localAllowedComponents = localStorage.getItem(
      "productAllowedComponents"
    );
    if (localAllowedComponents) {
      setAllowedComp(localAllowedComponents.split(","));
    }
  };

  useEffect(() => {
    // console.clear();
    checkComponentAuth();
    console.log(allowedComponents);
    console.log("useEffect called");
  }, [page]);

  const checkParams = () => {
    const validParam = ["category", "sub-category", "product", "favorite"];
    console.log(component);
    console.log(validParam.includes(component));
    if (validParam.includes(component) === true) {
      return true;
    } else {
      return false;
    }
  };
  const validURL = checkParams();
  if (validURL === false) {
    return <PageNotFound titlePage={<TitleBar titlePage={"*"} />} />;
  }

  const checkAdminStatus = async () => {
    try {
      const token = localStorage.getItem("tokenAdmin");
      const username = localStorage.getItem("username");
      const adminResponse = await axios.get(`${baseURL}/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const adminArray = adminResponse.data.result[0].data;
      const admin = adminArray.find((a) => a.username === username);
      if (admin) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };

  const productComponentSwitch = () => {
    switch (component) {
      case "category":
        return <CategoryComponent />;
      case "sub-category":
        return <ProductSub />;
      case "product":
        return <ProductComponent />;
      case "favorite":
        return <FavoriteComponent />;
      // default:
      //   return <CategoryComponent />;
    }
  };

  const productButtonContainer = () => {
    return (
      <div className="productPageBtnGroup">
        {allowedComponents.includes("category") ? (
          <Link to="/product/category">
            <button
              onClick={() => {
                setPage(1);
              }}
              className={
                "category" === component ? "productBtnActive" : "productBtn"
              }
            >
              Category
            </button>
          </Link>
        ) : null}
        {allowedComponents.includes("sub-category") ? (
          <Link to="/product/sub-category">
            <button
              onClick={() => {
                setPage(2);
              }}
              className={
                "sub-category" === component ? "productBtnActive" : "productBtn"
              }
            >
              Sub-Category
            </button>
          </Link>
        ) : null}
        {allowedComponents.includes("product") ? (
          <Link to="/product/product">
            <button
              onClick={() => {
                setPage(3);
              }}
              className={
                "product" === component ? "productBtnActive" : "productBtn"
              }
            >
              Product
            </button>
          </Link>
        ) : null}
        {allowedComponents.includes("favorite") ? (
          <Link to="/product/favorite">
            <button
              onClick={() => {
                setPage(4);
              }}
              className={
                "favorite" === component ? "productBtnActive" : "productBtn"
              }
            >
              Favorite
            </button>
          </Link>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="productPage">
        {props.titlePage}
        {/* <div className="productPageBtnGroup">
          <Link to="/product/category">
            <button
              onClick={() => {
                setPage(1);
              }}
              className={
                "category" === component ? "productBtnActive" : "productBtn"
              }
            >
              Category
            </button>
          </Link>
          <Link to="/product/sub-category">
            <button
              onClick={() => {
                setPage(2);
              }}
              className={
                "sub-category" === component ? "productBtnActive" : "productBtn"
              }
            >
              Sub-Category
            </button>
          </Link>
          <Link to="/product/product">
            <button
              onClick={() => {
                setPage(3);
              }}
              className={
                "product" === component ? "productBtnActive" : "productBtn"
              }
            >
              Product
            </button>
          </Link>
          <Link to="/product/favorite">
            <button
              onClick={() => {
                setPage(4);
              }}
              className={
                "favorite" === component ? "productBtnActive" : "productBtn"
              }
            >
              Favorite
            </button>
          </Link>
        </div> */}
        {productButtonContainer()}
        <div className="productPageTableContainer">
          {productComponentSwitch()}
        </div>
      </div>
    </>
  );
}
