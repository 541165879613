import styles from "./not_authorized_page.module.css";
import IconNotFound from "../../icon/IconNotFound.png";
import TitleBar from "../../components/titleBar/TitleBar";

export default function NotAuthorizedPage({ titlePage }) {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.titleBarContainer}>
        <div className={styles.titleBar}>
          <TitleBar />
        </div>
      </div>

      <div className={styles.NotAuthorizedPage}>
        <center className={styles.exclemationMark}>!</center>
        <div className={styles.bigText}>
          You are not authorized to view this page.
        </div>
        <div className={styles.smallText}>
          Anda tidak berhak untuk melihat halaman ini.
        </div>
      </div>
    </div>
  );
}
