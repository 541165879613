import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./newcolorpage.module.css";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ModalError400 from "../../components/ModalError400/ModalError400";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  padding: "20px 16px",
};

const NewColorPage = ({ isOpen, onClose, editedColor, onSave, baseURL }) => {
  const [colorName, setColorName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [manufactures, setManufactures] = useState([]);
  const [models, setModels] = useState([]);
  const [yearsOfProduction, setYearsOfProduction] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedManufacture, setSelectedManufacture] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYearOfProduction, setSelectedYearOfProduction] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [optionManufacture, setOptionManufacture] = useState("");
  const [optionModel, setOptionModel] = useState("");
  const [optionYearOfProduction, setOptionYearOfProduction] = useState("");
  const [optionArea, setOptionArea] = useState("");
  const [image, setImage] = useState(null);
  const [filteredOptionModel, setFilteredOptionModel] = useState("");
  const [showImageErrorModal, setShowImageErrorModal] = useState(false);
  //   const [imageURL, setImageURL] = useState(null);

  const handleCloseSelf = () => {
    onClose();
    setImage(null);
  };

  useEffect(() => {
    setColorName("");
    setColorCode("");
    setSelectedManufacture("");
    setSelectedModel("");
    setSelectedYearOfProduction("");
    setSelectedArea("");

    setErrorMsg({
      area: "",
      colorCode: "",
      colorName: "",
      manu: "",
      model: "",
      YoP: "",
    });
  }, [isOpen]);

  useEffect(
    () => {
      if (editedColor) {
        setColorName(editedColor.cName);
        setColorCode(editedColor.code);
        setManufactures(editedColor.idManufacturer);
        setModels(editedColor.idModel);
        setYearsOfProduction(editedColor.idYearOfProduction);
        setAreas(editedColor.idArea);
        setImage(editedColor.image);
      }
      axios
        .get(`${baseURL}/manufacturer`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedOptionManufacture = response.data.result[0].data.sort(
              (a, b) => a.name_manufacturer.localeCompare(b.name_manufacturer)
            );
            setOptionManufacture(sortedOptionManufacture);
            // console.log(response.data.result[0].data)
            const selectedManufacturerData = response.data.result[0].data.find(
              (manufacturer) => manufacturer.id === editedColor.idManufacturer
            );
            if (selectedManufacturerData) {
              setSelectedManufacture(
                selectedManufacturerData.name_manufacturer
              );
              // console.log(selectedManufacturerData.name_manufacturer)
            } else {
              console.error(
                "Tidak dapat menemukan data manufacturer yang sesuai."
              );
            }
          } else {
            console.error(
              "Data manufactures yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data manufactures:", error);
        });

      axios
        .get(`${baseURL}/car-model`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            setOptionModel(response.data.result[0].data);

            // Filter models based on the selected manufacturer
            const filteredModels = response.data.result[0].data.filter(
              (model) => model.id_manufacturer === editedColor?.idManufacturer
            );

            filteredModels.sort((a, b) =>
              a.name_car_model.localeCompare(b.name_car_model)
            );

            setFilteredOptionModel(filteredModels);
            console.log(filteredModels);
          } else {
            console.error(
              "Data models yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data models:", error);
        });

      axios
        .get(`${baseURL}/year-of-production`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedYearOfProduction = response.data.result[0].data;
            sortedYearOfProduction.sort((a, b) => a.id - b.id);

            setOptionYearOfProduction(sortedYearOfProduction);
            const selectedYearData = response.data.result[0].data.find(
              (year) => year.id === editedColor.idYearOfProduction
            );
            if (selectedYearData) {
              setSelectedYearOfProduction(selectedYearData.production_year);
              console.log(selectedYearData.production_year);
            } else {
              console.error("Tidak dapat menemukan data year yang sesuai.");
            }
          } else {
            console.error(
              "Data years of production yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data years of production:", error);
        });

      axios
        .get(`${baseURL}/area`)
        .then((response) => {
          if (Array.isArray(response.data.result[0].data)) {
            const sortedAreas = response.data.result[0].data;
            sortedAreas.sort((a, b) => a.id - b.id);
            console.log(sortedAreas);

            const uniqueNamesSet = new Set();
            const distinctAreaNames = sortedAreas.filter((area) => {
              if (!uniqueNamesSet.has(area.name_area)) {
                uniqueNamesSet.add(area.name_area);
                return true;
              }
              return false;
            });
            setOptionArea(distinctAreaNames);
            const selectedAreaData = response.data.result[0].data.find(
              (area) => area.id === editedColor.idArea
            );
            if (selectedAreaData) {
              setSelectedArea(selectedAreaData.id);
              console.log(selectedAreaData.name_area);
            } else {
              console.error("Tidak dapat menemukan data year yang sesuai.");
            }
          } else {
            console.error(
              "Data areas yang diterima bukan array:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil data areas:", error);
        });
    },
    [editedColor],
    selectedManufacture,
    optionModel
  );

  const handleManufactureChange = (e) => {
    const selectedManufactureId = Number(e.target.value);
    setSelectedManufacture(selectedManufactureId);
    console.log("Selected Manufacture ID:", selectedManufactureId);

    console.log("Option Model sebelum pemfilteran:", optionModel);

    const filteredModels = optionModel.filter(
      (model) => model.id_manufacturer === selectedManufactureId
    );
    setFilteredOptionModel(filteredModels);
    console.log("Filtered Models:", filteredModels);
  };

  const validateAllFields = () => {
    const colorNameValid = colorNameValidation();
    const colorCodeValid = colorCodeValidation();
    const manufacturerValid = manufacturerValidation();
    const modelValid = modelValidation();
    const YoPValid = YoPValidation();
    const areaValid = areaValidation();
    if (
      colorNameValid &&
      colorCodeValid &&
      manufacturerValid &&
      modelValid &&
      YoPValid &&
      areaValid
    ) {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    if (!validateAllFields()) {
      return false;
    }
    const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
    if (currAdmin.token) {
      // if (!image) {
      //   setShowImageErrorModal(true);
      //   return;
      // }

      const addData = {
        name_color: colorName,
        code_color: colorCode,
        id_manufacturer: selectedManufacture,
        id_car_model: selectedModel,
        id_year_of_production: selectedYearOfProduction,
        id_area: selectedArea,
        image_file: image,
      };
      const formData = new FormData();
      for (const item in addData) {
        formData.append(item, addData[item]);
      }
      const headers = {
        Authorization: `Bearer ${currAdmin.token}`,
        "Content-Type": "multipart/form-data",
      };
      onSave(formData, headers);
    }
  };

  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  let imgErrMsg = null;

  const handleImageChange = (e) => {
    if (e.target.files[0] && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      const imgIsValid = imageValidation(selectedImage);
      if (!imgIsValid) {
        openImgWarning();
        return false;
      }
      // const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      // const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      // if (
      //   selectedImage.size > maxSizeInBytes ||
      //   !allowedTypes.includes(selectedImage.type)
      // ) {
      //   handleOpenWarningModal();
      //   return;
      // }
      setImage(selectedImage);
    }
  };

  const [errorMsg, setErrorMsg] = useState({
    colorName: "",
    colorCode: "",
    manu: "",
    model: "",
    YoP: "",
    area: "",
  });

  const colorNameValidation = () => {
    if (colorName.trim().length === 0) {
      setErrorMsg((prev) => ({
        ...prev,
        colorName: "Please enter a Color Name.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      colorName: "",
    }));
    return true;
  };
  const colorCodeValidation = () => {
    if (colorCode.trim().length === 0) {
      setErrorMsg((prev) => ({
        ...prev,
        colorCode: "Please enter a Color Code.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      colorCode: "",
    }));
    return true;
  };
  const manufacturerValidation = () => {
    if (selectedManufacture === "") {
      setErrorMsg((prev) => ({
        ...prev,
        manu: "Please pick a Manufacturer.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      manu: "",
    }));
    return true;
  };
  const modelValidation = () => {
    if (selectedModel === "") {
      setErrorMsg((prev) => ({
        ...prev,
        model: "Please pick a Model.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      model: "",
    }));
    return true;
  };
  const YoPValidation = () => {
    if (selectedYearOfProduction === "") {
      setErrorMsg((prev) => ({
        ...prev,
        YoP: "Please pick a Year of Production.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      YoP: "",
    }));
    return true;
  };
  const areaValidation = () => {
    if (selectedArea === "") {
      setErrorMsg((prev) => ({
        ...prev,
        area: "Please pick an Area.",
      }));
      return false;
    }
    setErrorMsg((prev) => ({
      ...prev,
      area: "",
    }));
    return true;
  };

  const imageValidation = (inputImg) => {
    // console.log("Image Changed:", imgChanged);
    console.log("Image:", image);

    const allowedMimes = ["image/jpeg", "image/jpg", "image/png"];
    if (inputImg.size > 5 * 1024 * 1024) {
      imgErrMsg = `Please select a file that is under 5 MB in size.`;
      // setError((prev) => ({
      //   ...prev,
      //   imageError: "Please select a file that is under 5 MB in size.",
      // }));
      console.log(2);
      return false;
    }
    if (!allowedMimes.includes(inputImg.type)) {
      imgErrMsg = `Please select a file with a .jpg, .jpeg, or .png file extension.`;

      // setError((prev) => ({
      //   ...prev,
      //   imageError:
      //     "Please select a file with a .jpg, .jpeg, or .png file extension.",
      // }));
      console.log(3);
      return false;
    }

    imgErrMsg = null;
    // setError((prev) => ({
    //   ...prev,
    //   imageError: null,
    // }));
    return true;
  };

  const openImgWarning = () => {
    console.log(imgErrMsg);
    setWarningMsg(imgErrMsg);
    setWarningModal(true);
  };

  const [openWarningModal, setOpenWarningModal] = useState(false); // Warning Image File Size modal
  const handleOpenWarningModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  const openImageErrorModal = () => {
    setShowImageErrorModal(true);
  };

  const closeImageErrorModal = () => {
    setShowImageErrorModal(false);
  };

  return (
    <Modal
      open={isOpen}
      // onClose={onClose}
    >
      <Box sx={styleBox}>
        <Grid
          container
          spacing={2}
          style={{
            margin: 0,
          }}
        >
          <Grid xs={11}>
            <div className={styles.modalTitle}>NEW COLOR</div>
          </Grid>
          <Grid xs={1}>
            <CloseRoundedIcon
              className={styles.closeIcon}
              onClick={handleCloseSelf}
            />
          </Grid>
          <Grid
            xs={12}
            style={{
              maxHeight: "560px",
              overflow: "auto",
            }}
          >
            <form>
              <div className={styles.formGroup}>
                <label htmlFor="colorName" className={styles.textLabel}>
                  Color Name
                </label>
                <input
                  type="text"
                  id="colorName"
                  className={styles.customField}
                  value={colorName}
                  onChange={(e) => setColorName(e.target.value.trim())}
                />
                {errorMsg.colorName.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.colorName}</div>
                ) : null}
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="codeColor" className={styles.textLabel}>
                  Code
                </label>
                <input
                  type="text"
                  id="colorName"
                  className={styles.customField}
                  value={colorCode}
                  onChange={(e) => setColorCode(e.target.value.trim())}
                />
                {errorMsg.colorCode.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.colorCode}</div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Manufactures</label>
                <select
                  name="selectedManufactures"
                  className={styles.customField}
                  onChange={handleManufactureChange}
                >
                  <option value="">(Select)</option>
                  {Array.isArray(optionManufacture) &&
                    optionManufacture.map((manufacture) => (
                      <option key={manufacture.id} value={manufacture.id}>
                        {manufacture.name_manufacturer}
                      </option>
                    ))}
                </select>{" "}
                {errorMsg.manu.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.manu}</div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Models</label>
                <select
                  name="selectedModels"
                  className={styles.customField}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  <option value="">(Select)</option>
                  {Array.isArray(filteredOptionModel) &&
                    filteredOptionModel.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name_car_model}
                      </option>
                    ))}
                </select>
                {errorMsg.model.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.model}</div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Year of Production</label>
                <select
                  name="selectedYear"
                  className={styles.customField}
                  onChange={(e) => setSelectedYearOfProduction(e.target.value)}
                >
                  <option value="">(Select)</option>
                  {Array.isArray(optionYearOfProduction) &&
                    optionYearOfProduction.map((year) => (
                      <option key={year.id} value={year.id}>
                        {year.production_year}
                      </option>
                    ))}
                </select>
                {errorMsg.YoP.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.YoP}</div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Areas</label>
                <select
                  name="selectedAreas"
                  className={styles.customField}
                  onChange={(e) => setSelectedArea(e.target.value)}
                >
                  <option value="">(Select)</option>
                  {Array.isArray(optionArea) &&
                    optionArea.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name_area}
                      </option>
                    ))}
                </select>
                {errorMsg.area.length !== 0 ? (
                  <div className={styles.errorMsg}>{errorMsg.area}</div>
                ) : null}
              </div>

              <div className={styles.formGroup}>
                <label className={styles.textLabel}>Image</label>
                <label
                  className={`${styles.customField} ${styles.customUploadImageTextField}`}
                >
                  <div className={styles.uploadText}>
                    {!image ? "Upload photo max. size 10MB" : `${image.name}`}
                  </div>
                  <FileUploadOutlinedIcon className={styles.uploadIcon} />
                  <input
                    type="file"
                    id="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              {!image ? null : (
                <div className={styles.imagePreview}>
                  <img src={URL.createObjectURL(image)} alt="" />
                  <CloseRoundedIcon
                    className={styles.closeIcon}
                    onClick={() => setImage(null)}
                  />
                </div>
              )}
            </form>
          </Grid>
          <Grid xs={6}>
            <button onClick={handleSave} className={styles.btnGroup}>
              Save
            </button>
          </Grid>
          <Grid xs={6}>
            <button onClick={handleCloseSelf} className={styles.btnGroup}>
              Cancel
            </button>
          </Grid>
        </Grid>
        <WarningFileSizeModal
          closeModal={() => setWarningModal(false)}
          openModal={warningModal}
          warningText={warningMsg}
        />
        <WarningFileSizeModal
          openModal={openWarningModal}
          closeModal={handleCloseWarningModal}
          warningText={
            "Please select a file that is under 10 MB in size.\nIt should also be in one of the supported formats, such as .jpg, .jpeg, or .png."
          }
        />
        <ModalError400
          openModal={showImageErrorModal}
          warningText={"You need to upload an image to be able edit the photo"}
          closeModal={closeImageErrorModal}
        />
      </Box>
    </Modal>
  );
};

export default NewColorPage;
