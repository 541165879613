import "./newsubcategory.css"


export default function NewSubCategory() {
  return (
    <>
    <div className='Title'>NEW SUBCATEGORY</div>
    
    <div className='Name'>
          Subcategory Name
        <input placeholder='Subcateogry Name' className='NameBox' />
    </div>

    <div className='Category'>
        Category
        <select name='selectedCategory' className="SelectBox">
            <option value="manufactures">Manufactures</option>
            <option value="models">Models</option>
            <option value="type">Type</option>
            <option value="areas">Areas</option>
        </select>
    </div>

    <div className='Save'>
        <button className="savebutton">Save</button>
    </div>
    <div className='Cancel'>
        <button className='cancelbutton'>Cancel</button>
    </div>
    </>
  )
}
