import { useEffect, useState } from "react";
import "./models.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import EditCategoryModel from "../editcategorymodel/EditCategoryModel"; // Import EditProductModal
import NewCategoryModel from "../newcategorymodel/NewCategoryModel";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ModalError400 from "../../components/ModalError400/ModalError400";
import WarningFileSizeModal from "../../components/WarningFileSizeModal/WarningFileSizeModal";
import ExportImportBtn from "../../components/button/export_import_btn/export_import_btn";
import { DownloadDataCsv } from "../../functions/DownloadDataCsv";
import { baseURL } from "../../global_variable_.jsx";

export default function Models({ titlePage }) {
  const [allowedSubMenus, setAllowedSubMenus] = useState([
    "color-category",
    "color",
  ]);
  const [warningModal, setWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(null);
  const [models, setModels] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacturerMap, setManufacturerMap] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedModel, setEditedModel] = useState(null);
  const [newCategoryModalIsOpen, setNewCategoryModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addSuccessModal, setAddSuccessModal] = useState(false);
  const [editSuccessModal, setEditSuccessModal] = useState(false);

  useEffect(() => {
    checkSubMenuAuth();
    APICalls();
  }, []);

  const APICalls = async () => {
    await getModels();
    await getManufacturers();
    setIsLoading(false);
  };
  const checkSubMenuAuth = () => {
    const allowedSubMenu = localStorage.getItem("colorAllowedComponents");
    if (allowedSubMenu) {
      setAllowedSubMenus(allowedSubMenu.split(","));
    }
  };
  const getModels = async () => {
    try {
      let response = await axios.get(`${baseURL}/car-model?all_data=true`);
      setModels(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getManufacturers = async () => {
    try {
      const response = await axios.get(`${baseURL}/manufacturer?all_data=true`);
      setManufacturers(response.data.result[0].data);
      const manufacturerData = response.data.result[0].data;
      const manufacturerObj = {};
      manufacturerData.forEach((manufacturer) => {
        manufacturerObj[manufacturer.id] = manufacturer.name_manufacturer;
      });
      setManufacturerMap(manufacturerObj);
      console.log(manufacturerObj);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getModels();
    getManufacturers();
  }, []);

  const openEditModal = (model) => {
    console.log("Selected Model ID:", model.id);
    setEditedModel(model);
    setModalIsOpen(true);
  };

  const handleEditModel = async (editedData, headers) => {
    try {
      console.log(editedData);
      const response = await axios.put(`${baseURL}/car-model`, editedData, {
        headers,
      });
      console.log(response);
      console.log(editedData);
      console.log(headers);
      setModalIsOpen(false);
      if (response.data.status === 200) {
        APICalls();
        setEditSuccessModal(true);
        console.log(response.data.status);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Model Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
      console.error("Gagal mengupdate maodel:", error);
    }
  };

  const openNewCategoryModelModal = () => {
    setNewCategoryModalIsOpen(true);
  };

  const handleAddModel = async (newModelData, headers) => {
    try {
      const response = await axios.post(`${baseURL}/car-model`, newModelData, {
        headers,
      });
      console.log(response);
      setNewCategoryModalIsOpen(false);
      console.log("tess");
      console.log("tes upload data:", newModelData);
      console.log("tes token upload data:", headers);
      if (response.data.status === 201) {
        APICalls();
        setAddSuccessModal(true);
        console.log(response.data.status);
      } else {
        console.log("Error deleting category");
      }
    } catch (error) {
      if (error.response.data.status === 400) {
        setWarningMsg("Duplicate value: Model Name has to be unique.");
        setWarningModal(true);
      } else {
        setWarningMsg("Failed to execute requested action, Please Try Again.");
        setWarningModal(true);
      }
      return false;
      console.error("Gagal menambahkan kategori baru:", error);
    }
  };

  // delete models
  const deleteModels = async (ModelsId) => {
    try {
      const getToken = JSON.parse(localStorage.getItem("currAdmin"));
      const token = getToken.token;
      localStorage.setItem("tokenAdmin", token);
      const tesToken = localStorage.getItem("tokenAdmin");
      console.log("Deleted manufacturer ID:", ModelsId);
      const headers = {
        Authorization: `Bearer ${tesToken}`,
      };
      if (tesToken) {
        const response = await axios.delete(
          `${baseURL}/car-model/${ModelsId}`,
          { headers }
        );
        console.log(response);
        console.log(response.status);
        if (response.status === 200) {
          console.log("berhasil menghapus model");
          APICalls();
          setModalIsOpen(false);
        } else {
          console.log("Error deleting model");
        }
      } else {
        console.log("Error deleting model");
      }
    } catch (error) {
      console.error("Failed to delete model:", error);
    }
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteModels} />
      ),
    },
    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit onClick={() => openEditModal(params.row)} />
      ),
    },
    //{ field: 'id', headerName: 'ID', width: 240 },
    // { field: '', headerName: '', width: 70 },
    {
      field: "category",
      headerName: "Model Name",
      headerClassName: "customColumnHeader",
      flex: 10,
    },
    {
      field: "manufactures",
      headerName: "Manufactures",
      headerClassName: "customColumnHeader",
      flex: 6,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.value === "Non-Active" ? "deletedCell" : "activeCell";
        const circleColor = params.value === "Active" ? "#4CD964" : "red";
        return (
          <div className={`statusCell ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  let formattedModels = [];
  if (Array.isArray(models)) {
    formattedModels = models.map((model) => ({
      id: model.id,
      category: model.name_car_model,
      manufactures: manufacturerMap[model.id_manufacturer],
      status: model.isDeleted ? "Non-Active" : "Active",
    }));

    // const movePage = () => {
    //   window.location.href = "/newsubcategory"
    // }

    // const movePage1 = () => {
    //   window.location.href = "/color"
    // }

    // const movePage2 = () => {
    //   window.location.href = "/color-type"
    // }

    // const movePage3 = () => {
    //   window.location.href = "/color-areas"
    // }

    // const movePage4 = () => {
    //   window.location.href = "/color-formula"
    // }

    const btnSubMenuContainer = () => {
      return (
        <div className="outSideBtnGroup">
          {allowedSubMenus.includes("color-category") ? (
            <button className="categorybuttonModel">Category</button>
          ) : null}
          {allowedSubMenus.includes("color") ? (
            <Link to="/color-formula">
              <button className="colorbuttonModel">Color</button>
            </Link>
          ) : null}
        </div>
      );
    };

    return isLoading !== true ? (
      <>
        <div className="colorModelPage">
          {titlePage}
          {btnSubMenuContainer()}

          <div className="tableContainerModel">
            <div className="inSideBtnGroup">
              <Link to="/color">
                <button className="manufacturesbuttonModel">
                  Manufactures
                </button>
              </Link>
              <button className="modelsbuttonModel">Models</button>
              <Link to="/color-type">
                <button className="typebuttonModel">Years</button>
              </Link>
              <Link to="/color-areas">
                <button className="areasbuttonModel">Areas</button>
              </Link>
              <ExportImportBtn
                btnType="Export"
                addStyle={{ marginLeft: `auto`, marginRight: `20px` }}
                onClick={() =>
                  DownloadDataCsv("model_list", `${baseURL}/car-model/csv`)
                }
              />
              <button
                onClick={openNewCategoryModelModal}
                className="newsubcategorybutton2"
              >
                Add New Model
                <AddIcon className="addIcon1" />
              </button>
            </div>
            <DataGrid
              rows={formattedModels}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: "category", sort: "asc" }],
                },
              }}
              pageSizeOptions={[5, 10]}
              getRowClassName={(params) => "customRow"}
              getCellClassName={(params) => "customCell"}
              disableRowSelectionOnClick={true}
            />
          </div>
        </div>
        <WarningFileSizeModal
          openModal={warningModal}
          closeModal={() => setWarningModal(false)}
          warningText={warningMsg}
        />
        <NewCategoryModel
          isOpen={newCategoryModalIsOpen}
          onClose={() => setNewCategoryModalIsOpen(false)}
          onSave={handleAddModel}
        />
        <EditCategoryModel
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          editedModel={editedModel}
          onSave={handleEditModel}
        />
        <ModalError400
          openModal={addSuccessModal}
          closeModal={() => setAddSuccessModal(false)}
          warningText={"Data Added Succsesfully"}
        />
        <ModalError400
          openModal={editSuccessModal}
          closeModal={() => setEditSuccessModal(false)}
          warningText={"Data Edited Succsesfully"}
        />
      </>
    ) : (
      <div>loading...</div>
    );
  }
}
