// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ConfirmSubmitChanges_errorIconModal__jRFzk{
    color: var(--color_red-primer);
    width: 100% !important;
    margin: auto;
}

.ConfirmSubmitChanges_confirmSubmitText__6Qkme{
    color: var(--color_red-primer);
    font-family: "SF Pro Display-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
}

.ConfirmSubmitChanges_noBtn__xJgzY, .ConfirmSubmitChanges_yesBtn__c2hgr{
    width: 100%;
    font-family: "SF Pro Display-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid;
    border-radius: 10px;
    padding: 14px 10px;
    cursor: pointer;
}

.ConfirmSubmitChanges_noBtn__xJgzY{
    background-color: var(--color_white);
    color: var(--color_red-primer);
    border: 1px solid;
}

.ConfirmSubmitChanges_yesBtn__c2hgr{
    background-color: var(--color_red-primer); 
    color: var(--color_white);
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmSubmitChanges/ConfirmSubmitChanges.module.css"],"names":[],"mappings":";;AAEA;IACI,8BAA8B;IAC9B,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,gDAAgD;IAChD,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,6CAA6C;IAC7C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["\n\n.errorIconModal{\n    color: var(--color_red-primer);\n    width: 100% !important;\n    margin: auto;\n}\n\n.confirmSubmitText{\n    color: var(--color_red-primer);\n    font-family: \"SF Pro Display-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n    text-align: center;\n    white-space: pre-line;\n}\n\n.noBtn, .yesBtn{\n    width: 100%;\n    font-family: \"SF Pro Display-Bold\", Helvetica;\n    font-size: 14px;\n    font-weight: 700;\n    border: 1px solid;\n    border-radius: 10px;\n    padding: 14px 10px;\n    cursor: pointer;\n}\n\n.noBtn{\n    background-color: var(--color_white);\n    color: var(--color_red-primer);\n    border: 1px solid;\n}\n\n.yesBtn{\n    background-color: var(--color_red-primer); \n    color: var(--color_white);\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorIconModal": `ConfirmSubmitChanges_errorIconModal__jRFzk`,
	"confirmSubmitText": `ConfirmSubmitChanges_confirmSubmitText__6Qkme`,
	"noBtn": `ConfirmSubmitChanges_noBtn__xJgzY`,
	"yesBtn": `ConfirmSubmitChanges_yesBtn__c2hgr`
};
export default ___CSS_LOADER_EXPORT___;
