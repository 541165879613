// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not_authorized_page_outerContainer__uA1YO{
    width: 100%;
    /* height: 100vh; */
}

.not_authorized_page_titleBarContainer__a5gNq{
    
    display: flex;
    justify-content: end;
    margin-right: 20px;
    margin-top: 20px;
    
}

.not_authorized_page_titleBar__Osj\\+8{
    position: absolute;
}
.not_authorized_page_NotAuthorizedPage__oHd1h{ 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}


.not_authorized_page_exclemationMark__bTKPL{
    padding: 10px;
    background-color: var(--color_red-primer);
    border: 1px solid ;
    color: #FFFFFF;
    font-size: 40px;
}

.not_authorized_page_bigText__Y9iye{
    margin-top: 30px;
    text-align: center;
    color: var(--color_gray);
    font-family: "SF Pro Display-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
}
.not_authorized_page_smallText__4ENuY{
    margin-top: 10px;
    text-align: center;
    color: var(--color_gray);
    font-family: "SF Pro Display-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/not_authorized_page/not_authorized_page.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,aAAa;IACb,yCAAyC;IACzC,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;IACxB,6CAA6C;IAC7C,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;IACxB,gDAAgD;IAChD,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".outerContainer{\n    width: 100%;\n    /* height: 100vh; */\n}\n\n.titleBarContainer{\n    \n    display: flex;\n    justify-content: end;\n    margin-right: 20px;\n    margin-top: 20px;\n    \n}\n\n.titleBar{\n    position: absolute;\n}\n.NotAuthorizedPage{ \n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.exclemationMark{\n    padding: 10px;\n    background-color: var(--color_red-primer);\n    border: 1px solid ;\n    color: #FFFFFF;\n    font-size: 40px;\n}\n\n.bigText{\n    margin-top: 30px;\n    text-align: center;\n    color: var(--color_gray);\n    font-family: \"SF Pro Display-Bold\", Helvetica;\n    font-size: 16px;\n    font-weight: 700;\n}\n.smallText{\n    margin-top: 10px;\n    text-align: center;\n    color: var(--color_gray);\n    font-family: \"SF Pro Display-Regular\", Helvetica;\n    font-size: 14px;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `not_authorized_page_outerContainer__uA1YO`,
	"titleBarContainer": `not_authorized_page_titleBarContainer__a5gNq`,
	"titleBar": `not_authorized_page_titleBar__Osj+8`,
	"NotAuthorizedPage": `not_authorized_page_NotAuthorizedPage__oHd1h`,
	"exclemationMark": `not_authorized_page_exclemationMark__bTKPL`,
	"bigText": `not_authorized_page_bigText__Y9iye`,
	"smallText": `not_authorized_page_smallText__4ENuY`
};
export default ___CSS_LOADER_EXPORT___;
