import { useEffect, useRef, useState } from "react";
import styles from "./role_user.module.css";
import { DataGrid } from "@mui/x-data-grid";
// import { Link } from "react-router-dom";
import axios from "axios";
import CustomDelete from "../../icon/CustomDelete";
import CustomeEdit from "../../icon/CustomEdit";
import TitleBar from "../../components/titleBar/TitleBar";
import { Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate, useParams } from "react-router-dom";
import RoleUserWeb from "../../components/role_user/role_user_web/role_user_web";
import RoleUserMobile from "../../components/role_user/role_user_mobile/role_user_mobile";
import PageNotFound from "../PageNotFound/PageNotFound";
import EditRole from "../../components/edit_role_modal/edit_role_modal";
import CustomEye from "../../icon/CustomEye";
import { baseURL } from "../../global_variable_.jsx";

const styleBox = {
  // width: "100%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  padding: "20px 16px",
  marginTop: "20px",
  boxShadow: "0px 4px 10px #0000001a",
};

export default function RoleUser({ titlePage }) {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const getRoleUser = async () => {
    try {
      const currAdmin = JSON.parse(localStorage.getItem("currAdmin"));
      let response = await axios.get(`${baseURL}/role?all_data=true`, {
        headers: { Authorization: `Bearer ${currAdmin.token}` },
      });
      console.log(response);
      setUsers(response.data.result[0].data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteRoleUser = async (itemId) => {
    const token = JSON.parse(localStorage.getItem(`currAdmin`)).token;
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios.delete(`${baseURL}/role/${itemId}`, {
        headers,
      });
      if (response.data.status === 200) {
        console.log(`success`);
        getRoleUser();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [editModal, setEditModal] = useState(false);
  const editedRoleID = useRef(null);
  const editedRoleName = useRef(null);
  const editedRoleDeleteStatus = useRef(null);

  const openEditModal = (id, name, delStatus) => {
    console.log("Currently edited role id:", id);
    editedRoleID.current = id;
    editedRoleName.current = name;
    editedRoleDeleteStatus.current = delStatus;
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const columns = [
    {
      field: "delete",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomDelete itemId={params.row.id} onDelete={deleteRoleUser} />
      ),
    },

    {
      field: "edit",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <CustomeEdit
          onClick={() => {
            openEditModal(
              params.row.id,
              params.row.role_name,
              params.row.isDeleted
            );
          }}
        />
      ),
    },
    {
      field: "edit_role_menu",
      headerName: "",
      headerClassName: "customColumnHeader",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/edit-role-menu-user/${params.row.role_name}/${params.row.id}`}
          className={styles.editRoleMenuBtn}
        >
          <CustomEye />
        </Link>
      ),
    },

    {
      field: "role_name",
      headerName: "Role Name",
      headerClassName: "customColumnHeader",
      flex: 14,
    },

    {
      field: "isDeleted",
      headerName: "Status",
      headerClassName: "customColumnHeader",
      flex: 4,
      renderCell: (params) => {
        const cellColor =
          params.row.isDeleted === false ? "greenCell" : "redCell";
        const circleColor = params.row.isDeleted === false ? "#4CD964" : "red";
        return (
          <div className={`statusCellcmsuser ${cellColor}`}>
            <CircleIcon
              style={{
                width: "9px",
                height: "9px",
                color: circleColor,
                borderRadius: "50%",
                boxShadow: "none",
                border: "none",
                paddingRight: "6px",
              }}
            />
            {params.row.isDeleted === true ? "Non-Active" : "Active"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getRoleUser();
  }, []);

  return (
    <>
      <div className={styles.roleuserpage}>
        {titlePage}

        <div className={styles.roleUserTalbeContainer}>
          <Link className={styles.AddRoleUserLink} to="/new-role-user">
            <button className={styles.newRoleUserBtn}>
              Add New Role
              <AddIcon className={styles.addIcon1} />
            </button>
          </Link>

          <DataGrid
            rows={users}
            columns={columns}
            autoHeight={true}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              // sorting: {
              //   sortModel: [{ field: "role_name", sort: "asc" }],
              // },
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) => "customRow"}
            getCellClassName={(params) => "customCell"}
            disableRowSelectionOnClick={true}
          />
        </div>
      </div>
      <EditRole
        Update={getRoleUser}
        isOpen={editModal}
        onClose={closeEditModal}
        id={editedRoleID.current}
        roleNamE={editedRoleName.current}
        isDeleteD={editedRoleDeleteStatus.current}
        version="user"
      />
    </>
  );
}
